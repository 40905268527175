import * as React from "react";
import { IOrder } from "../../types/order.types";
import { Box } from "./Box";
import { CleaningTabPane } from "./tabbars/cleaningtabs/cleaningtabpane/CleaningTabPane";

interface IProps {
    orders: IOrder[];
}

export const CleaningList = (props: IProps) => {
    const listRenderer = (order: IOrder, index: number) => {
        const location = order.container.location && order.container.location.location;

        return (
            <Box className={`${index !== 0 ? "margin-left" : ""}`}>
                <CleaningTabPane
                    slot={location}
                    containerNumber={order.container.sapId}
                    state={order.state}
                    active={false}
                />
            </Box>
        );
    };

    return (
        <div className="flex fill-width flex-start">
            {
                props.orders.map(listRenderer)
            }
        </div>
    );
};
