import * as React from "react";
import { useEffect } from "react";
import { itactOrderUpdateItactDoneDoneActionCreator } from "../../store/actions/itact";
import { ItactDoneButton } from "../components/modals/itactmodal/panes/statuspane/ItactDoneButton";
import { useOrder } from "../hooks/useOrder";
import { useOrderUpdate } from "../hooks/useOrderUpdate";

interface IProps {
    orderId: number;
}

export const ItactDoneButtonContainer = (props: IProps) => {
    const [order,, loadOrder] = useOrder();
    const [update, ordersBeingSaved] = useOrderUpdate(itactOrderUpdateItactDoneDoneActionCreator);

    useEffect(() => {
        loadOrder(props.orderId);
    }, []);

    const itactDoneSaving = ordersBeingSaved[props.orderId] && ordersBeingSaved[props.orderId].itactDone;

    const onItactDoneSave = () => {
        update(props.orderId, { itactDone: true });
    };

    return (
        <ItactDoneButton
            itactDoneSaving={!!itactDoneSaving}
            onItactDoneSave={onItactDoneSave}
            state={order && order.state}
        />
    );
};
