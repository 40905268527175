import * as React from "react";
import { useSelector } from "react-redux";
import { scheduledMaintenancesSelector } from "../../../store/selectors/tank.selector";
import { IScheduledMaintenance } from "../../../types/scheduledMaintenance.types";
import { ScheduledMaintenanceEvent } from "../../components/timeline/ScheduledMaintenanceEvent";

interface IProps {
    maintenanceID: number;
}

export const ScheduledMaintenanceEventContainer = (props: IProps) => {
    const scheduledMaintenances: IScheduledMaintenance[] = useSelector(scheduledMaintenancesSelector);
    const scheduledMaintenance: IScheduledMaintenance | undefined = scheduledMaintenances.find(s => s.id === props.maintenanceID);

    return scheduledMaintenance ? <ScheduledMaintenanceEvent scheduledMaintenance={scheduledMaintenance}/> : null;
};
