import { StopOutlined, WarningOutlined } from "@ant-design/icons/lib";
import * as React from "react";
import { TANK_BLOCKING_REASONS } from "../../../../constants/blockingReasons";
import { IMessage } from "../../../../types/message.types";
import { IConstraint, IConstraintStatus, IHybridSlot, ISlotBookingConfig } from "../../../../types/slot.types";
import { getReason } from "../../../../utils/format";

export const slotStatusRenderer = (slot: IHybridSlot, config: ISlotBookingConfig, formatMessage: (message: IMessage) => string) => {
    if (slot.blockedDependsOn) {
        return <div>
            <StopOutlined className={"margin-right"}/>
            {getReason("BLOCKED_DEPENDS_ON", formatMessage)}
        </div>;
    }

    if (slot.slot.constraints.length) {
        // priority 1 is highest priority
        const topConstraint: IConstraint = slot.slot.constraints.slice()
            .sort((c1, c2) => (c1.priority > c2.priority) ? 1 : ((c2.priority > c1.priority) ? -1 : 0))[0];

        switch (IConstraintStatus[topConstraint.status.toUpperCase().toString() as keyof typeof IConstraintStatus]) {
        case IConstraintStatus.BLOCKED:
        case IConstraintStatus.DISABLED:
            return <div>
                <StopOutlined className={"margin-right"}/>
                {getReason(topConstraint.message, formatMessage)} {topConstraint.reason && formatMessage(TANK_BLOCKING_REASONS[topConstraint.reason])}
            </div>;
        case IConstraintStatus.WARNING:
            return <div>
                <WarningOutlined className={"margin-right"}/>
                {slot.slot.zoneBookings} {getReason(topConstraint.message, formatMessage)}
            </div>;
        }
    }
};

export const slotStartRenderer = (start: string) => {
    return <div className="text-primary">{start}</div>;
};
