import { pick, pickBy, values } from "lodash";
import { TIME_FORMATS } from "../constants/time";

import { IAntSortOrder, IPage, IPageMeta, IPageRequest, IPageResponse, ISort } from "../types/api.types";
import { IBookedSlot } from "../types/bookedSlot.types";
import { ICalendarEvent } from "../types/calendar.types";
import { ICleaningProcedure } from "../types/order.types";
import { IScheduledMaintenance } from "../types/scheduledMaintenance.types";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

type SpringPage<T> = {
    "size": number;
    "number": number;
    "totalElements": number;
    "content": T[];
};

export const mapSpringPageToIPage = <T>(springPage: SpringPage<T>): IPage<T> => {
    const meta: IPageMeta = pick(springPage, ["size", "number", "totalElements"]);
    const content = springPage.content;
    return ({
        meta,
        content,
    });
};

export const mapIPageMetaToAntPagination = (meta: IPageMeta) => {
    return {
        total: meta.totalElements,
        current: meta.number + 1,
        pageSize: meta.size,
        showSizeChanger: false,
    };
};

export const mapAntSortOrderToSortOrder = (antSortOrder: IAntSortOrder) => {
    switch (antSortOrder) {
    case "ascend":
        return "asc";
    case "descend":
        return "desc";
    }
};

export const mapAntSortToSort = (antSort: { columnKey: string; order: IAntSortOrder }): ISort => {
    return {
        property: antSort.columnKey as string,
        order: mapAntSortOrderToSortOrder(antSort.order as IAntSortOrder),
    };
};

export const mapPageResponseToPageRequest = <T, F>(pageResponse: IPageResponse<T, F>): IPageRequest<F> => {
    return ({
        pageNumber: pageResponse.page.meta.number,
        filter: pageResponse.filter,
        sorting: pageResponse.sorting
    });
};

export const mapCleaningProcedureObjToArr = (compartmentInfo: ICleaningProcedure) => values(pickBy(compartmentInfo, (val, key: string) => key.startsWith("step"))) as string[];

export const concatDateTime = (date: string, time: string): Dayjs => {
    return dayjs(date.split("T").shift()).add(dayjs.duration(time));
};

export const extractTimeSlotString = (date: string): string => {
    return dayjs(date).format(TIME_FORMATS.TIME);
};

export const bookedSlotToCalendarEvent = (bookedSlot: IBookedSlot): ICalendarEvent => {
    return {
        id: bookedSlot.id.toString(),
        resourceId: bookedSlot.tank.id.toString(),
        title: bookedSlot.id.toString(),
        start: bookedSlot.fromDate,
        end: bookedSlot.untilDate,
        allDay: false,
        extendedProps: {
            isBookedSlotEvent: true
        }
    };
};

export const scheduledMaintenanceToCalendarEvent = (scheduledMaintenance: IScheduledMaintenance): ICalendarEvent => {
    return {
        id: scheduledMaintenance.id,
        resourceId: scheduledMaintenance.tank.id.toString(),
        title: scheduledMaintenance.reason,
        start: dayjs.utc(scheduledMaintenance.fromDate).format(),
        end: dayjs.utc(scheduledMaintenance.untilDate).format(),
        allDay: false,
        extendedProps: {
            isBookedSlotEvent: false
        }
    };
};
