import { eventChannel } from "redux-saga";
import { call, cancelled, put, take, takeLatest } from "redux-saga/effects";

import * as security from "../../security";
import { loginAction, loginFailedAction, loginOkAction, logoutAction } from "../actions";
import { setHttpAuthentication } from "../api/httpClient";
import axios from "axios";

/* eslint-disable no-unreachable */
/**
 * Typeguard to check a response is an AuthResponse
 * @param response
 */
function isAuthResponse(response: unknown): response is security.AuthResponse {
    return !!(response as security.AuthResponse).accessToken;
}

/**
 * Typeguard to check if a response is an AuthError
 * @param response
 */
function isAuthError(response: unknown): response is security.AuthError {
    return !!(response as security.AuthError).message;
}

/**
 * Create the login channel to funnel the AuthErrors and AuthResponses
 * coming from the security library. We use a saga eventChannel here to
 * get non-saga events/callbacks back into the redux-saga machinery. Channels
 * are versatile and can funnel all sorts of events back into redux-saga e.g. websockets.
 *
 * Read more: https://redux-saga.js.org/docs/advanced/Channels.html
 */
function createLoginChannel() {
    return eventChannel<(security.AuthError | security.AuthResponse)>((emitter) => {
        security.login(emitter);
        return () => { /** Unsubscribe actions */ };
    });
}

function* login() {
    // Create the login channel
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const chan = yield call(createLoginChannel);

    try {
        // Keep looping the channel and take every message
        // in it
        while (true) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const response = yield take(chan);

            // If it is an AuthResponse, conclude the login process
            // successfully
            if (isAuthResponse(response)) {
                yield put(loginOkAction(response));
            }

            // If it is an AuthError, close the login process with
            // an error. We cloack the error in an object because
            // the saga machinery does not threat the errors well
            if (isAuthError(response)) {
                yield put(loginFailedAction(new Error(response.message)));
            }
        }
    } catch (e) {
        if (axios.isAxiosError(e) && e.response) {
            console.warn(`Authentication failure occurred: ${e.message}`);
        }
    } finally {
        // When an END is emitted, we close the channel
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (yield cancelled()) {
            chan.close();
        }
    }
}

function* loginOk(action: ReturnType<typeof loginOkAction>) {
    const { rawIdToken } = action.payload.idToken;
    yield call(setHttpAuthentication, rawIdToken);
}

function* logout() {
    yield call(security.logout);
}

/**
 * Saga watcher: login actions
 */
export function* authenticationSaga() {
    yield takeLatest(loginAction.type, login);
    yield takeLatest(loginOkAction.type, loginOk);

    yield takeLatest(logoutAction.type, logout);
}
