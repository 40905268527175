import { Timeline } from "antd";
import * as React from "react";
import { useIntl } from "react-intl";
import { Event, IStateTransition, State, StateGroup } from "../../../../../../types/order.types";
import { formatState, formatStateGroup, optionalFormatIso8601DateTime } from "../../../../../../utils/format";
import { TimelineItem } from "./TimelineItem";

interface IProps {
    statusHistory: IStateTransition[];
}

export const StatusTimeline = (props: IProps) => {
    const intl = useIntl();

    const getGroups = (stateTransitions: IStateTransition[]): IStateTransition[][] => {
        const getGroup = (s: IStateTransition[]) => {
            let i = 0;
            while (i < s.length && s[0].targetStateGroup === s[i].targetStateGroup) {
                ++i;
            }

            return s.splice(0, i);
        };

        // We make a copy because otherwise inserting into the original array causes cleaned states to be added multiple times
        // ForEach decides its range once at the start.
        // If we then insert something the current val shifts forward in the array and get reprocessed in the next iteration
        const stateTransitionsCopy: IStateTransition[] = [];

        stateTransitions.forEach((stateTransition) => {
            if (stateTransition.sourceState === State.CLEANING && stateTransition.targetStateGroup === StateGroup.FINISH_UP) {
                const fakeTransition: IStateTransition = {
                    ...stateTransition,
                    id: -1,
                    targetState: State.CLEANED,
                    targetStateGroup: StateGroup.CLEANING,
                    event: Event.FAKE,
                };

                // We push instead of splice because if there are multiple cleaned states the index on which to insert \
                // gets messed up as we are using the index from the unaltered copy
                // Another possibility is to look up the index of val in the copy and use that index to splice
                stateTransitionsCopy.push(fakeTransition);
            }

            stateTransitionsCopy.push(stateTransition);
        });

        const groups: IStateTransition[][] = [];
        while (stateTransitionsCopy.length) {
            groups.push(getGroup(stateTransitionsCopy));
        }

        return groups;
    };

    const renderStatuses = (statuses: IStateTransition[]) => (
        <TimelineItem key={statuses[0].timestamp} title={formatStateGroup(statuses[0].targetStateGroup as StateGroup, intl)} active>
            <div className="flex flex-row space-between">
                <div className="flex flex-column margin-half-right">
                    {
                        statuses.map(s => (
                            <div key={s.timestamp}>
                                {formatState(s.targetState as State, intl)}
                            </div>
                        ))
                    }
                </div>
                <div className="flex flex-column">
                    {
                        statuses.map(s => (
                            <div key={s.timestamp}>
                                <small>{optionalFormatIso8601DateTime(s.timestamp)}</small>
                            </div>
                        ))
                    }
                </div>
            </div>
        </TimelineItem>
    );

    const statusGroups = getGroups([...props.statusHistory].sort((a, b) => a.timestamp > b.timestamp ? 1 : -1));

    return (
        <Timeline mode="left">
            {statusGroups.map(renderStatuses)}
        </Timeline>
    );
};
