import { Button } from "antd";
import * as React from "react";
import { defineMessages, useIntl } from "react-intl";

interface IProps {
    onConfirm: () => void;
    onCancel: () => void;
    onEdit: () => void;
}

const MESSAGES = defineMessages({
    close: {
        id: "time_line_information_footer.close",
        defaultMessage: "Sluiten"
    },
    cancel: {
        id: "time_line_information_footer.cancel",
        defaultMessage: "Annuleer slot"
    },
    edit: {
        id: "time_line_information_footer.edit",
        defaultMessage: "Bewerk slot"
    }
});

export const TimeLineInformationFooter = (props: IProps) => {
    const intl = useIntl();

    return (
        <div>
            <Button onClick={props.onEdit} className="flex-row text-middle margin-half-bottom" type="primary">
                {intl.formatMessage(MESSAGES.edit)}
            </Button>
            <div className="text-middle">
                <Button onClick={props.onCancel} className="margin-half-right" type="default">
                    {intl.formatMessage(MESSAGES.cancel)}
                </Button>
                <Button onClick={props.onConfirm} type="default">
                    {intl.formatMessage(MESSAGES.close)}
                </Button>
            </div>
        </div>
    );
};
