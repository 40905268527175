import { combineReducers, createReducer, PayloadAction } from "@reduxjs/toolkit";
import { StorageLocation } from "../../types/salesOrder.types";
import {
    filterSetAllTerminalsAction, filterSetAllTypeValuesAction,
    filterSetTerminalAction,
    filterSetTypeValuesAction
} from "../actions/filter.actions";
import { CheckInReason } from "../../types/checkIn.types";

const terminals = createReducer<StorageLocation[]>([], {
    [filterSetTerminalAction.type]: (state, { payload }: PayloadAction<StorageLocation[]>) => payload
});

const allTerminals = createReducer<boolean>(false, {
    [filterSetAllTerminalsAction.type]: (state, { payload }: PayloadAction<boolean>) => payload
});

const typeValues = createReducer<CheckInReason[]>([], {
    [filterSetTypeValuesAction.type]: (state, { payload }: PayloadAction<CheckInReason[]>) => payload
});

const allTypeValues = createReducer<boolean>(false, {
    [filterSetAllTypeValuesAction.type]: (state, { payload }: PayloadAction<boolean>) => payload
});

export const filterReducer = combineReducers({
    terminals,
    allTerminals,
    typeValues,
    allTypeValues
});
