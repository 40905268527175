import { notification } from "antd";
import { defineMessages, IntlShape } from "react-intl";

interface IProps {
    intl: IntlShape;
    reset: () => void;
}

const MESSAGES = defineMessages({
    blockSucceededMessage: {
        id: "block_succeeded_notification.message",
        defaultMessage: "Tank succesvol geblokkeerd"
    },
});

export const BlockSucceededNotification = (props: IProps) => {
    notification.success({
        message: props.intl.formatMessage(MESSAGES.blockSucceededMessage),
        onClose: () => props.reset(),
        placement: "bottomRight"
    });
    return null;
};
