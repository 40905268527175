import { Checkbox } from "antd";
import * as React from "react";
import { useState } from "react";
import { FilterFooter } from "./FilterFooter";

interface IProps {
    closeFilter: (() => void) | undefined;
    onFilter: (value?: boolean) => void;
    trueText: string;
    falseText: string;
}

/*
0 0 => no filterval
0 1 => false
1 0 => true
1 1 => no filterval
*/
export const BoolFilter = (props: IProps) => {
    const [truthy, setTruthy] = useState<boolean>(false);
    const [falsy, setFalsy] = useState<boolean>(false);

    const onFilter = () => {
        if (truthy === falsy) {
            props.onFilter();
        } else {
            props.onFilter(truthy);
        }
    };

    return (
        <div className="padded">
            <div className="margin-half-bottom flex flex-column">
                <Checkbox onChange={(e) => setTruthy(e.target.checked)} className="margin-half-bottom">
                    {props.trueText}
                </Checkbox>
                <Checkbox onChange={(e) => setFalsy(e.target.checked)} className="no-margin">
                    {props.falseText}
                </Checkbox>
            </div>
            <FilterFooter onCancel={props.closeFilter} onFilter={onFilter}/>
        </div>
    );
};
