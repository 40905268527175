import * as React from "react";
import { List } from "antd";
import { defineMessages, useIntl } from "react-intl";
import { CheckCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { CmrWeightForm } from "./CmrWeightForm";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkInUpdateActionCreator } from "../../../../store/actions/checkIn";
import {
    checkInRecordSelector,
    hasBulkUnLoadingOrdersSelector
} from "../../../../store/selectors/checkInOverview.selector";
import { ICmrWeightForm } from "../../../../types/forms.types";

const MESSAGES = defineMessages({
    title: {
        id: "cmr_weight_form.inputs.weight.label",
        defaultMessage: "CMR gewicht (kg)"
    }
});

export const CmrWeightFormContainer = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const hasBulkUnloadOrders = useSelector(hasBulkUnLoadingOrdersSelector);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const checkIn = useSelector(checkInRecordSelector);

    const onSubmit = (data: ICmrWeightForm) => {
        if (checkIn) {
            dispatch(checkInUpdateActionCreator({ checkinId: checkIn.id, data, setIsLoading }));
        }
    };

    return checkIn && hasBulkUnloadOrders ? (
        <List.Item title={intl.formatMessage(MESSAGES.title)}>
            <List.Item.Meta
                avatar={checkIn.cmrWeightDispatcher ? <CheckCircleOutlined className="text-success" style={{ fontSize: 21 }} /> : <ExclamationCircleOutlined className="text-error" style={{ fontSize: 21 }} />}
                title={intl.formatMessage(MESSAGES.title)}
                description={<CmrWeightForm isLoading={isLoading} onSubmit={onSubmit} checkIn={checkIn} />}
            />
        </List.Item>
    ) : null;
};
