import { Button } from "antd";
import * as React from "react";
import { useIntl } from "react-intl";
import { CheckInReason, CheckInState, ICheckIn } from "../../../../../types/checkIn.types";
import { CHECK_IN_STATE_MESSAGES } from "../../../../../utils/format";

interface IProps {
  onClick: () => void;
  checkIn: ICheckIn | null;
}

export const ReturnToKioskCheckinButton = (props: IProps) => {
    const intl = useIntl();
    const isProcessing = props.checkIn?.checkInState === CheckInState.PROCESSING;
    const isReadyForOperations = props.checkIn?.checkInState === CheckInState.READY_FOR_OPERATIONS;
    const hasCorrectCheckinReason = props.checkIn?.checkInReason === CheckInReason.WRAPPED || props.checkIn?.checkInReason === CheckInReason.BULK;

    return props.checkIn && hasCorrectCheckinReason && (isProcessing || isReadyForOperations) ? (
        <Button onClick={props.onClick} type="default">
            {intl.formatMessage(CHECK_IN_STATE_MESSAGES[CheckInState.BACK_TO_KIOSK])}
        </Button>
    ) : null;
};
