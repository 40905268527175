import { StorageLocation } from "./salesOrder.types";

export interface ISelfCheckInParameters {
    settingType: SettingsType;
    terminal?: StorageLocation;
    language?: Language;
    value: string;
}

export interface ISettingsUpdate {
    parameter: ISelfCheckInParameters;
}

export enum SettingsType {
    IDLE_TIMER = "IDLE_TIMER",
    POP_UP_TIMER = "POP_UP_TIMER",
}

export enum Language {
    NL = "NL",
    EN = "EN",
    FR = "FR",
    DE = "DE",
}

export interface ISelfCheckInSettingsResponse {
    settingType: SettingsType;
    terminal?: StorageLocation;
    language?: Language;
    value: string;
}