import { Button, Input } from "antd";
import * as React from "react";
import { useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { InfoLabel } from "../../../../../../modals/customerservicemodal/panes/detailinfopane/sections/infofields/InfoLabel";
import { ErrorModal } from "./ErrorModal";

interface IProps {
    unNumber: string;
    onError: (fields: string, submittedBy: string, msg?: string) => void;
    onOk: (fields: string) => void;
    prev: () => void;
}

const MESSAGES = defineMessages({
    unNumberFormPrompt: {
        id: "un_number_form.prompt",
        defaultMessage: "Geef het UN nummer in:"
    },
    next: {
        id: "un_number_form.next",
        defaultMessage: "Volgende"
    },
    prev: {
        id: "un_number_form.prev",
        defaultMessage: "Vorige"
    },
    modalTitle: {
        id: "un_number_form.modal_title",
        defaultMessage: "Het ingegeven UN nummer ''{actual}'' komt niet overeen met het UN nummer in het systeem ''{expected}''."
    },
});

export const UNNumberForm = (props: IProps) => {
    const intl = useIntl();
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [input, setInput] = useState<string | undefined>();

    const onNext = () => {
        if (input !== props.unNumber) {
            setModalVisible(true);
            return;
        }

        props.onOk(input);
    };

    return (
        <div>
            <InfoLabel text={intl.formatMessage(MESSAGES.unNumberFormPrompt)}/>
            <Input onChange={(e) => setInput(e.target.value)} className="margin-half-top"/>
            <div className="margin-top">
                <Button onClick={props.prev}>
                    {intl.formatMessage(MESSAGES.prev)}
                </Button>
                <Button type="primary" onClick={onNext} disabled={!input} className="margin-half-left">
                    {intl.formatMessage(MESSAGES.next)}
                </Button>
            </div>
            <ErrorModal
                closeModal={() => setModalVisible(false)}
                visible={modalVisible}
                onConfirm={(submittedBy, msg) => input && props.onError(input, submittedBy, msg)}
                title={intl.formatMessage(MESSAGES.modalTitle, {
                    actual: input,
                    expected: props.unNumber
                })}
            />
        </div>
    );
};
