import { combineReducers, createReducer } from "@reduxjs/toolkit";
import { IOrderFilter, IPageResponse } from "../../types/api.types";
import { IOrder } from "../../types/order.types";
import {
    cleaningPageFetchFailedCreator,
    cleaningPageLoadCreator,
    cleaningPageReceivedCreator
} from "../actions/cleaning";

export interface ICleaningState {
    page: IPageResponse<IOrder, IOrderFilter> | null;
    loadingPage: boolean;
}

const pageReducer = createReducer<IPageResponse<IOrder, IOrderFilter> | null>(null, {
    [cleaningPageReceivedCreator.type]: (state, action) => action.payload,
});

const loadingPageReducer = createReducer<boolean>(false, {
    [cleaningPageLoadCreator.type]: () => true,
    [cleaningPageReceivedCreator.type]: () => false,
    [cleaningPageFetchFailedCreator.type]: () => false
});

export const cleaningReducer = combineReducers<ICleaningState>({
    page: pageReducer,
    loadingPage: loadingPageReducer
});
