import { useEffect } from "react";
import * as React from "react";
import { IBookedSlotListFetchParams } from "../../../types/bookedSlot.types";
import { IProductionOrder, ISalesOrder } from "../../../types/salesOrder.types";
import { SlotBookingInformation } from "../../components/SlotbookingInformation";
import { useBookedSlot } from "../../hooks/useBookedSlot";

interface IProps {
    salesOrder: ISalesOrder;
    date?: string;
    tankId?: string;
    setSelectedProductionOrder: (productionOrder: IProductionOrder | null) => void;
    selectedProductionOrder: IProductionOrder | null;
}

export const SlotBookingInformationContainer = (props: IProps) => {
    const [, , , , , list, fetchList, ] = useBookedSlot();

    useEffect(() => {
        const bookedSlotListFetchParams: IBookedSlotListFetchParams = {
            salesOrderReference: props.salesOrder.sapReference
        };

        fetchList(bookedSlotListFetchParams);
    }, []);

    return (
        <SlotBookingInformation
            setSelectedProductionOrder={props.setSelectedProductionOrder}
            selectedProductionOrder={props.selectedProductionOrder}
            salesOrder={props.salesOrder}
            tankId={props.tankId}
            date={props.date}
            bookings={list}
        />
    );
};
