import { Select } from "antd";
import * as React from "react";
import { useEffect } from "react";
import { defineMessages, useIntl } from "react-intl";
import { IBookedSlot } from "../../types/bookedSlot.types";
import { IProductionOrder, ISalesOrder } from "../../types/salesOrder.types";
import { isTruckBlendOrder } from "../../utils/order";

interface IProps {
    salesOrder: ISalesOrder;
    date?: string;
    tankId?: string;
    setSelectedProductionOrder: (productionOrder: IProductionOrder | null) => void;
    selectedProductionOrder: IProductionOrder | null;
    bookings: IBookedSlot[] | null;
}

const MESSAGES = defineMessages({
    productPlaceholder: {
        id: "product_placeholder_select",
        defaultMessage: "Selecteer een product."
    }
});

export const SlotBookingInformation = (props: IProps) => {
    const productionOrder = props.salesOrder.productionOrders[0];
    const truckBlend = isTruckBlendOrder(props.salesOrder);

    // const products = props.salesOrder.productionOrders.map((productionOrder) => productionOrder.product);

    useEffect(() => {
        props.setSelectedProductionOrder(truckBlend ? null : productionOrder);
    }, []);

    // const onSelect = (val: any) => {
    // const prodOrder = props.salesOrder.productionOrders.find((productionOrder) => productionOrder.product === val) || null;
    // props.setSelectedProductionOrder(prodOrder);
    // };

    const intl = useIntl();

    // const shouldProductOptionBeDisabled = () => {
    //     if (props.bookings === null || props.bookings === undefined) {
    //         return true;
    //     }
    //
    //     const productionOrder = props.salesOrder.productionOrders.find(productionOrder => (
    //         productionOrder.salesProduct.id === product.id
    //     ));
    //
    //     // See if booking belong to productionOrder of product
    //     return !productionOrder || props.bookings.findIndex(booking => booking.orderReference === productionOrder.sapReference) !== -1;
    // };

    return (
        <>
            {
                truckBlend &&
                    <div className="fill-width">
                        <Select
                            // onSelect={onSelect}
                            style={{ minWidth: "150px" }}
                            placeholder={intl.formatMessage(MESSAGES.productPlaceholder)}
                            className="fill-width"
                        >
                            {
                                // products.map((product) =>
                                //     <Select.Option
                                //         disabled={shouldProductOptionBeDisabled(product)}
                                //         key={product.id}
                                //         value={product.id}
                                //     >
                                //         {product.materialName}
                                //     </Select.Option>)
                            }
                        </Select>
                    </div>
            }
            {
                // props.selectedProductionOrder && props.selectedProductionOrder.salesProduct &&
                //     <div className={`${truckBlend && "margin-top"}`}>
                //         {truckBlend && <Divider/>}
                //         <SlotBookingOrderInformationContainer
                //             productionOrder={props.selectedProductionOrder}
                //             salesProduct={props.selectedProductionOrder.salesProduct}
                //             date={props.date}
                //             tankId={props.tankId}
                //             salesOrder={props.salesOrder}
                //         />
                //     </div>
            }
        </>
    );
};
