import React, { useState } from "react";
import { ICheckIn } from "../../../../../../types/checkIn.types";
import { Pane } from "../../../customerservicemodal/panes/Pane";
import { Box } from "../../../../Box";
import { defineMessages, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import {
    documentsPerCheckInSelector,
    fetchDocumentsFailedSelector
} from "../../../../../../store/selectors/documents.selector";
import { Image } from "antd";

interface IProps {
  checkIn: ICheckIn | null;
  className?: string;
}

const MESSAGES = defineMessages({
    title: {
        id: "document_pane.title",
        defaultMessage: "Gescande documenten"
    },
    failed: {
        id: "document_pane.somethingWentWrong",
        defaultMessage: "Er liep iets mis met het ophalen van de documenten of er zijn geen documenten aanwezig."
    }
});

export const DocumentsPane = (props: IProps) => {
    if (!props.checkIn) {return (<></>);}
    const failed = useSelector(fetchDocumentsFailedSelector);

    const intl = useIntl();
    const documents = useSelector(documentsPerCheckInSelector(props.checkIn.id));
    const [showImage, setShowImage] = useState<string | null>(null);

    return (
        <Pane className={props.className} title={intl.formatMessage(MESSAGES.title)}>
            <Box>
                <>
                    {failed &&
                      <p>{intl.formatMessage(MESSAGES.failed)}</p>
                    }
                    {documents?.map((document) =>
                        <div key={document.fileName}>
                            <a onClick={() => setShowImage(document.fileName)} key={document.fileName}>{document.fileName}</a>
                            <div className="display-none">
                                <Image.PreviewGroup preview={{ visible: showImage === document.fileName, onVisibleChange: () => setShowImage(null) }}>
                                    <Image src={document.imageLink} />
                                </Image.PreviewGroup>
                            </div>
                        </div>
                    )}
                </>
            </Box>
        </Pane>
    );
};
