import { combineReducers, createReducer } from "@reduxjs/toolkit";
import { isNil, keys, omit, omitBy } from "lodash";
import { IOrderUpdateDto } from "../../types/api.types";
import { IOrder, IStateTransition, State } from "../../types/order.types";
import { formatFilterQueryParams } from "../../utils/format";
import {
    orderAllowedTransitionsFetchCreator,
    orderAllowedTransitionsFetchFailedCreator,
    orderAllowedTransitionsReceivedCreator,
    orderFetchFailedCreator,
    orderLoadCreator,
    orderReceivedCreator,
    orderTotalElementsFetchCreator,
    orderTotalElementsFetchFailedCreator,
    orderTotalElementsReceivedCreator,
    orderTransitionsFetchCreator,
    orderTransitionsFetchFailedCreator,
    orderTransitionsReceivedCreator,
    orderUpdateCreator,
    orderUpdateDoneCreator,
    orderUpdateFailedCreator
} from "../actions";
import { externalViewTransitionsFetchActionCreator } from "../actions/externalView";

export interface IOrderState {
    transitions: IStateTransition[];
    fetchingTransitions: boolean;
    allowedTransitions: State[];
    fetchingAllowedTransitions: boolean;
    order: IOrder | null;
    loadingOrder: boolean;
    ordersBeingUpdated: { [orderId: number]: IOrderUpdateDto };
    filterTotalElementsMap: { [filterString: string]: number};
    totalElementsFiltersLoading: string[];
}

export const transitionReducer = createReducer<IStateTransition[]>([], {
    [orderTransitionsFetchCreator.type]: () => [],
    [orderTransitionsReceivedCreator.type]: (state, action) => action.payload,
});

export const fetchingEventsReducer = createReducer<boolean>(false, {
    [orderTransitionsFetchCreator.type]: () => true,
    [orderTransitionsReceivedCreator.type]: () => false,
    [orderTransitionsFetchFailedCreator.type]: () => false,
    [externalViewTransitionsFetchActionCreator.type]: () => true
});

export const transitionsReducer = createReducer<State[]>([], {
    [orderAllowedTransitionsFetchCreator.type]: () => [],
    [orderAllowedTransitionsReceivedCreator.type]: (state, action) => action.payload,
});

export const fetchingTransitionsReducer = createReducer<boolean>(false, {
    [orderAllowedTransitionsFetchCreator.type]: () => true,
    [orderAllowedTransitionsReceivedCreator.type]: () => false,
    [orderAllowedTransitionsFetchFailedCreator.type]: () => false,
});

export const orderDetailReducer = createReducer<IOrder | null>(null, {
    [orderReceivedCreator.type]: (state, action) => action.payload,
    [orderLoadCreator.type]: () => null,
});

export const loadingOrderReducer = createReducer<boolean>(false, {
    [orderLoadCreator.type]: () => true,
    [orderReceivedCreator.type]: () => false,
    [orderFetchFailedCreator.type]: () => false,
});

export const updateOrderReducer = createReducer<{ [orderId: number]: IOrderUpdateDto }>({}, (builder) => {
    builder.addCase(orderUpdateCreator, (state, action) => {
        const newState = { ...state };
        const oldUpdateDto = newState[action.payload.orderId];
        const newUpdateDto = { ...oldUpdateDto, ...action.payload.orderUpdateDto };

        newState[action.payload.orderId] = newUpdateDto;

        return newState;
    });
    builder.addCase(orderUpdateDoneCreator, (state, action) => {
        const newState = { ...state };
        const oldUpdateDto = newState[action.payload.orderId];
        const newUpdateDto = omit(oldUpdateDto, keys(omitBy(action.payload.orderUpdateDto, isNil)));

        if (keys(newUpdateDto).length) {
            newState[action.payload.orderId] = newUpdateDto;
        } else {
            delete newState[action.payload.orderId];
        }

        return newState;
    });
    builder.addCase(orderUpdateFailedCreator, (state, action) => {
        const newState = { ...state };
        const oldUpdateDto = newState[action.payload.action.payload.orderId];
        const newUpdateDto = omit(oldUpdateDto, keys(omitBy(action.payload.action.payload.orderUpdateDto, isNil)));

        if (keys(newUpdateDto).length) {
            newState[action.payload.action.payload.orderId] = newUpdateDto;
        } else {
            delete newState[action.payload.action.payload.orderId];
        }

        return newState;
    });
});

export const filterTotalElementsMapReducer = createReducer<{ [filterString: string]: number }>({}, {
    [orderTotalElementsReceivedCreator.type]: (state, action: ReturnType<typeof orderTotalElementsReceivedCreator>) => {
        const filterString = formatFilterQueryParams(action.payload.filter);

        const newState = { ...state };
        newState[filterString] = action.payload.count;

        return newState;
    },
    [orderTotalElementsFetchFailedCreator.type]: (state, action: ReturnType<typeof orderTotalElementsFetchFailedCreator>) => {
        const filterString = formatFilterQueryParams(action.payload.action.payload.filter);

        const newState = { ...state };
        delete newState[filterString];

        return newState;
    }
});

export const totalElementsFiltersLoading = createReducer<string[]>([], {
    [orderTotalElementsFetchCreator.type]: (state, action: ReturnType<typeof orderTotalElementsFetchCreator>) => {
        const filterString = formatFilterQueryParams(action.payload);
        const newState = [...state];

        if (!newState.includes(filterString)) {
            newState.push(filterString);
        }

        return newState;
    },
    [orderTotalElementsFetchFailedCreator.type]: (state, action: ReturnType<typeof orderTotalElementsFetchFailedCreator>) => {
        const filterString = formatFilterQueryParams(action.payload.action.payload);

        return state.filter(element => element !== filterString);
    },
    [orderTotalElementsReceivedCreator.type]: (state, action: ReturnType<typeof orderTotalElementsReceivedCreator>) => {
        const filterString = formatFilterQueryParams(action.payload.filter);

        return state.filter(element => element !== filterString);
    }
});

export const orderReducer = combineReducers<IOrderState>({
    transitions: transitionReducer,
    fetchingTransitions: fetchingEventsReducer,
    allowedTransitions: transitionsReducer,
    fetchingAllowedTransitions: fetchingTransitionsReducer,
    order: orderDetailReducer,
    loadingOrder: loadingOrderReducer,
    ordersBeingUpdated: updateOrderReducer,
    filterTotalElementsMap: filterTotalElementsMapReducer,
    totalElementsFiltersLoading: totalElementsFiltersLoading
});
