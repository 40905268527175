import * as React from "react";
import { FormattedMessage } from "react-intl";
import { OrderInfoContainer } from "../../../../../containers/OrderInfoContainer";
import { Box } from "../../../../Box";
import { Pane } from "../Pane";

interface IProps {
    orderId: number;
}

export const OrderInfoPane = (props: IProps) => {
    return (
        <Pane title={<FormattedMessage id="detail_info_section.title" defaultMessage="Detail info"/>}>
            <Box>
                <OrderInfoContainer orderId={props.orderId}/>
            </Box>
        </Pane>
    );
};
