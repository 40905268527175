import React from "react";
import { DatePicker } from "antd";
import type { Dayjs } from "dayjs";

interface IProps {
    onDateChange: (date: Dayjs | any, dateString: string) => void;
    date: Dayjs|any;
}

export const TimeLineDatePicker = (props: IProps) => (
    <div className="time-line-date-picker">
        <DatePicker
            onChange={props.onDateChange}
            value={props.date}
            size="large"
            format="DD/MM/YYYY"
            disabledDate={(current) => {
                if (current !== null) {
                    const day = parseInt(current.format("d"));
                    // Start counting with sunday (0) and end with saturday (6).
                    return day === 0 || day === 6;
                }

                return false;
            }}
        />
    </div>
);
