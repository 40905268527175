import * as React from "react";
import { Pane } from "../../../customerservicemodal/panes/Pane";
import { IContractor } from "../../../../../../types/checkIn.types";
import { Box } from "../../../../Box";
import { defineMessages, useIntl } from "react-intl";
import { Row } from "antd";
import { InfoLabel } from "../../../customerservicemodal/panes/detailinfopane/sections/infofields/InfoLabel";
import { InfoField } from "../../../customerservicemodal/panes/detailinfopane/sections/infofields/InfoField";
import { formatIso8601Date } from "../../../../../../utils/format";

interface IProps {
    person: IContractor;
}

const MESSAGES = defineMessages({
    title: {
        id: "check_in_modal.exam_pane.title",
        defaultMessage: "Examen info"
    },
    subTitle: {
        id: "check_in_modal.exam_pane.sub_title",
        defaultMessage: "Laatst geslaagde test"
    },
});

export const ExamPane = (props: IProps) => {
    const intl = useIntl();
    return (
        <Pane title={intl.formatMessage(MESSAGES.title)}>
            <Box>
                <Row className="margin-bottom">
                    <InfoLabel text={intl.formatMessage(MESSAGES.subTitle)}/>
                </Row>
                {
                    Object.keys(props.person.dateOfSuccessPerTerminal).map((key, i) => (
                        <Row key={i}>
                            <InfoField value={key + " : " + formatIso8601Date(props.person.dateOfSuccessPerTerminal[key])}/>
                        </Row>
                    ))
                }
            </Box>
        </Pane>
    );
};
