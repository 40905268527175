import { createSelector } from "@reduxjs/toolkit";
import { IItactState } from "../reducers/itact";
import { IApplicationState } from "../state";

const itactStateSelector = (state: IApplicationState) => state.itact;

export const itactPageSelector = createSelector(
    itactStateSelector,
    (itactState: IItactState) => itactState.page
);

export const itactLoadingPageSelector = createSelector(
    itactStateSelector,
    (itactState: IItactState) => itactState.loadingPage
);
