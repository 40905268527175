import { Button } from "antd";
import * as React from "react";
import { defineMessages, useIntl } from "react-intl";

interface IProps {
    onCancel: (() => void) | undefined;
    onFilter: () => void;
    onReset?: () => void;
    resetDisabled?: boolean;
}

const MESSAGES = defineMessages({
    cancel: {
        id: "filter_footer.cancel",
        defaultMessage: "Annuleer",
    },
    filter: {
        id: "filter_footer.filter",
        defaultMessage: "Filter",
    },
    reset: {
        id: "filter_footer.reset",
        defaultMessage: "Reset",
    }
});

export const FilterFooter = (props: IProps) => {
    const intl = useIntl();

    return (
        <div className="flex flex-row">
            {
                props.onReset ?
                    <Button onClick={props.onReset} className="margin-half-right" disabled={!!props.resetDisabled}>{intl.formatMessage(MESSAGES.reset)}</Button>
                    :
                    <Button onClick={props.onCancel} className="margin-half-right">{intl.formatMessage(MESSAGES.cancel)}</Button>
            }
            <Button onClick={props.onFilter} type="primary">
                {intl.formatMessage(MESSAGES.filter)}
            </Button>
        </div>
    );
};
