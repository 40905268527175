import { createAction } from "@reduxjs/toolkit";
import { IFailedActionPayload } from "../../types/action.types";
import { BookingType, ISalesOrder, ISalesOrderListFetchParams } from "../../types/salesOrder.types";

enum SalesOrderActionTypes {
    SALE_ORDER_FETCH = "[SalesOrder] FETCH",
    SALE_ORDER_RECEIVED = "[SalesOrder] RECEIVED",
    SALE_ORDER_FETCH_FAILED = "[SalesOrder] FETCH_FAILED",

    SALE_ORDER_LIST_FETCH = "[SalesOrderList] FETCH",
    SALE_ORDER_LIST_RECEIVED = "[SalesOrderList] RECEIVED",
    SALE_ORDER_LIST_FETCH_FAILED = "[SalesOrderList] FETCH_FAILED",
}

export const salesOrderFetchCreator = createAction<{reference: string; bookingType?: BookingType }>(SalesOrderActionTypes.SALE_ORDER_FETCH);
export const salesOrderReceivedCreator = createAction<ISalesOrder | null>(SalesOrderActionTypes.SALE_ORDER_RECEIVED);
export const salesOrderFetchFailedCreator = createAction<IFailedActionPayload>(SalesOrderActionTypes.SALE_ORDER_FETCH_FAILED);

export const salesOrderListFetchCreator = createAction<{salesOrderListFetchParams: ISalesOrderListFetchParams}>(SalesOrderActionTypes.SALE_ORDER_LIST_FETCH);
export const salesOrderListReceivedCreator = createAction<ISalesOrder[]>(SalesOrderActionTypes.SALE_ORDER_LIST_RECEIVED);
export const salesOrderListFetchFailedCreator = createAction<IFailedActionPayload>(SalesOrderActionTypes.SALE_ORDER_LIST_FETCH_FAILED);
