import { Input } from "antd";
import * as React from "react";
import { useState } from "react";
import { FilterFooter } from "./FilterFooter";

export type SearchFilterResult = string | number | undefined;

type Props = {
  closeFilter: (() => void) | undefined;
  onFilter: (value: SearchFilterResult) => void;
  placeholder?: string;
  type?: string;
  clearFilter?: (() => void);
};

export const SearchFilter = (props: Props) => {
    const [userInput, setUserInput] = useState<string | undefined>();

    const onFilter = () => {
        props.onFilter(userInput || undefined);
        if (userInput === "") {
            props.clearFilter && props.clearFilter();
        }
        props.closeFilter && props.closeFilter();
    };

    const onClear = () => {
        setUserInput(undefined);
        props.clearFilter && props.clearFilter();
        props.closeFilter && props.closeFilter();
    };

    return (
        <div className="padded">
            <Input
                value={userInput}
                allowClear
                placeholder={props.placeholder}
                className="margin-half-bottom"
                onChange={(event) => setUserInput(event.target.value)}
                type={props.type || "text"}
            />
            <FilterFooter onCancel={props.closeFilter} onFilter={onFilter} onReset={props.clearFilter && onClear} resetDisabled={userInput === undefined || userInput === ""}/>
        </div>
    );
};
