import { Button, Popconfirm } from "antd";
import * as React from "react";

interface IProps {
    label: string | React.ReactNode;
    disabled?: boolean;
    onConfirm?: () => void;
    shouldConfirm: boolean;
    confirmLabel: string | React.ReactNode;
}

export const ConfirmableButton = (props: IProps) => {
    const disabled = !!props.disabled;
    return (
        <Popconfirm title={props.confirmLabel} onConfirm={props.onConfirm} disabled={!props.shouldConfirm || disabled}>
            <Button type="primary" disabled={disabled} onClick={!props.shouldConfirm ? props.onConfirm : undefined}>
                {props.label}
            </Button>
        </Popconfirm>
    );
};
