import { Row, Table } from "antd";
import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import { ICompartment } from "../../../../../../../types/order.types";
import { formatBoolField, formatKg } from "../../../../../../../utils/format";

interface IProps {
    showRestWeight: boolean;
    compartments: ICompartment[];
}

const MESSAGES = defineMessages({
    compartment: {
        id: "rest_section.compartment",
        defaultMessage: "Comp"
    },
    rest: {
        id: "rest_section.rest",
        defaultMessage: "REST"
    },
    restWeight: {
        id: "rest_section.weight",
        defaultMessage: "Rest gewicht",
    },
    product: {
        id: "rest_section.product",
        defaultMessage: "Product"
    }
});

export const RestSection = (props: IProps) => {
    const intl = useIntl();

    return (
        <Row>
            <Table
                size="small"
                pagination={false}
                dataSource={props.compartments}
            >
                <Table.Column
                    key="cid"
                    dataIndex="cid"
                    title={intl.formatMessage(MESSAGES.compartment)}
                />
                <Table.Column
                    key="product"
                    dataIndex="product.name"
                    title={intl.formatMessage(MESSAGES.product)}
                    render={(_, record: ICompartment) => record.product.name}
                />
                <Table.Column
                    key="rest"
                    dataIndex="restFound"
                    title={intl.formatMessage(MESSAGES.rest)}
                    render={(_, record: ICompartment) => formatBoolField(record.restFound, intl)}
                />
                {props.showRestWeight &&
                    <Table.Column
                        key="id"
                        dataIndex="id"
                        title={intl.formatMessage(MESSAGES.restWeight)}
                        render={(_, record: ICompartment) => formatKg(record.restAmount)}
                    />}
            </Table>
        </Row>
    );
};
