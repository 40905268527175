import { useDispatch, useSelector } from "react-redux";
import { planningLoadingPageSelector, planningPageSelector } from "../../../store/selectors/planning.selector";
import { IApplicationState } from "../../../store/state";
import { IOrderFilter, IPageRequest, IPageResponse, ISort } from "../../../types/api.types";
import { IPageResourceLoader } from "../../../types/hook.types";
import { IOrder } from "../../../types/order.types";
import { planningPageFetchCreator, planningPageLoadCreator } from "../../../store/actions/planning";
import { mapPageResponseToPageRequest } from "../../../utils/mapping";

export type IPlanningPageLoader = IPageResourceLoader<IOrder, IOrderFilter>;

export const usePlanningPageLoader = (): IPlanningPageLoader => {
    const dispatch = useDispatch();

    const page = useSelector<IApplicationState, IPageResponse<IOrder, IOrderFilter> | null>(planningPageSelector);
    const loading = useSelector<IApplicationState, boolean>(planningLoadingPageSelector);

    const loadResource = (pageRequest: IPageRequest<IOrderFilter>) => {
        const sorting: ISort = {
            property: "priority,dateParkingIn",
            order: "asc"
        };

        const pr: IPageRequest<IOrderFilter> = {
            ...pageRequest,
            sorting: pageRequest.sorting || sorting,
            filter: pageRequest.filter
        };

        return dispatch(planningPageLoadCreator(pr));
    };

    const refresh = () => {
        const pageRequest: IPageRequest<IOrderFilter> = page ? mapPageResponseToPageRequest(page) : {
            pageNumber: 0,
            filter: {},
            sorting: {
                property: "priority,dateParkingIn",
                order: "asc"
            }
        };

        return dispatch(planningPageFetchCreator(pageRequest));
    };

    return {
        resource: page,
        loadingResource: loading,
        loadResource,
        refresh
    };
};
