import * as React from "react";
import { useState } from "react";
import { ICheckIn } from "../../../../types/checkIn.types";
import { useDispatch } from "react-redux";
import { CleaningModal } from "../cleaningmodal/CleaningModal";
import { checkInCancelActionCreator } from "../../../../store/actions/checkIn";
import { CheckInDetailCancelModal } from "./CheckInDetailCancelModal";
import { ModalFooter } from "./ModalFooter";
import { defineMessages, useIntl } from "react-intl";

interface IProps {
    visible: boolean;
    closeModal: () => void;
    checkIn: ICheckIn;
}

const MESSAGES = defineMessages({
    cancel: {
        id: "check_in_cancel_modal_footer.close",
        defaultMessage: "Teruggaan"
    },
    submit: {
        id: "check_in_cancel_modal_footer.submit",
        defaultMessage: "Annuleer check-in"
    }
});

export const CheckInDetailCancelModalContainer = (props: IProps) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [isSubmitDisabled, setSubmitDisabled] = useState<boolean>(true);
    const [cancelReason, setCancelReason] = useState<string>("");

    const closeModal = () => {
        props.closeModal();
    };

    const onReasonTyping = (value: string | null) => {
        setCancelReason(value === null ? "" : value);
        setSubmitDisabled(value === null || value === "");
    };

    const handleSubmit = () => {
        dispatch(checkInCancelActionCreator({ checkinId: props.checkIn.id, reason: cancelReason }));
        closeModal();
    };

    const renderFooter = () => (
        <ModalFooter
            disabled={isSubmitDisabled}
            onClose={closeModal}
            onSubmit={handleSubmit}
            cancelLabel={intl.formatMessage(MESSAGES.cancel)}
            confirmLabel={intl.formatMessage(MESSAGES.submit)}
        />
    );

    return (
        <CleaningModal open={props.visible} closeModal={closeModal} width="20%" footer={renderFooter()}>
            <CheckInDetailCancelModal onTyping={onReasonTyping} handleSubmit={handleSubmit} />
        </CleaningModal>
    );
};
