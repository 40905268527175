import { useDispatch, useSelector } from "react-redux";
import { IOrderFilter, IPageRequest } from "../../../types/api.types";
import { IPageResourceLoader } from "../../../types/hook.types";
import { IOrder } from "../../../types/order.types";
import { mapPageResponseToPageRequest } from "../../../utils/mapping";
import { externalViewPageFetchActionCreator, externalViewPageLoadActionCreator } from "../../../store/actions/externalView";
import { externalViewLoadingPageSelector, externalViewPageSelector } from "../../../store/selectors/externalView.selector";

export type IExternalViewPageLoader = IPageResourceLoader<IOrder, IOrderFilter>

export const useExternalViewPageLoader = (): IExternalViewPageLoader => {
    const dispatch = useDispatch();

    const page = useSelector(externalViewPageSelector);
    const loadingPage = useSelector(externalViewLoadingPageSelector);
    const loadPage = (pageRequest: IPageRequest<IOrderFilter>) => dispatch(externalViewPageLoadActionCreator(pageRequest));
    const refresh = () => {
        const pageRequest = page ? mapPageResponseToPageRequest(page) : {
            pageNumber: 0,
            filter: {}
        };

        return dispatch(externalViewPageFetchActionCreator(pageRequest));
    };

    return {
        resource: page,
        loadingResource: loadingPage,
        loadResource: loadPage,
        refresh
    };
};
