import { defineMessages } from "react-intl";
import { IMessages } from "../types/message.types";

export const TANK_BLOCKING_REASONS: IMessages = defineMessages({
    SHIP: {
        defaultMessage: "Schip",
        id: "tank_blocking_reasons.ship"
    },
    MAINTENANCE: {
        defaultMessage: "Maintenance",
        id: "tank_blocking_reasons.maintenance"
    },
    DRUMMING: {
        defaultMessage: "Drumming",
        id: "tank_blocking_reasons.drumming"
    },
    TRAIN: {
        defaultMessage: "Trein",
        id: "tank_blocking_reasons.train"
    }
});
