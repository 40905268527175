import { LoadingOutlined } from "@ant-design/icons/lib";
import { Button, Select } from "antd";
import * as React from "react";
import { useState } from "react";

interface IProps {
    options: string[];
    onSave: (selectedOption: string) => void;
    buttonText: string;
    placeholder: string;
    loading: boolean;
    optionRenderer?: (option: string) => string;
    saving: boolean;
}

export const DropdownMenu = (props: IProps) => {
    const [selectedOption, setSelectedOption] = useState(null);

    const onSave = () => {
        const option = selectedOption;
        setSelectedOption(null);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        props.onSave(option);
    };

    return (
        <div className="fill-width">
            <Select
                disabled={props.saving}
                className="margin-half-right"
                style={{ width: "60%" }}
                placeholder={props.placeholder}
                loading={props.loading}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                onChange={setSelectedOption}
                allowClear
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                value={selectedOption}
            >
                {
                    props.options.map((option, index) => (
                        <Select.Option value={option} key={index}>
                            {
                                props.optionRenderer ? props.optionRenderer(option) : option
                            }
                        </Select.Option>
                    ))
                }
            </Select>
            <Button
                type="primary"
                onClick={onSave}
                disabled={!selectedOption || props.saving}
            >
                {
                    props.saving ?
                        <LoadingOutlined/> :
                        props.buttonText
                }
            </Button>
        </div>
    );
};
