import { combineReducers, createReducer } from "@reduxjs/toolkit";
import { IChecklist } from "../../types/checklist.types";
import {
    checklistsFetchActionCreator,
    checklistsFetchFailedActionCreator,
    checklistsReceivedActionCreator,
    checklistStepSubmitActionCreator,
    checklistStepSubmitFailedActionCreator,
    checklistStepSubmittedActionCreator
} from "../actions/checklist";

export interface IChecklistState {
    checklists: IChecklist[];
    fetching: boolean;
    submitting: boolean;
}

export const fetchingReducer = createReducer<boolean>(false, {
    [checklistsFetchActionCreator.type]: () => true,
    [checklistsReceivedActionCreator.type]: () => false,
    [checklistsFetchFailedActionCreator.type]: () => false,
});

export const checklistReducer = createReducer<IChecklist[]>([], {
    [checklistsFetchActionCreator.type]: () => [],
    [checklistsReceivedActionCreator.type]: (state, action) => action.payload,
});

export const submittingReducer = createReducer<boolean>(false, {
    [checklistStepSubmitActionCreator.type]: () => true,
    [checklistStepSubmittedActionCreator.type]: () => false,
    [checklistStepSubmitFailedActionCreator.type]: () => false,
});

export const checklistStateReducer = combineReducers<IChecklistState>({
    checklists: checklistReducer,
    fetching: fetchingReducer,
    submitting: submittingReducer
});
