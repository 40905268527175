import { ChecklistItem } from "./checklist.types";
import { OrderType, State, StateGroup } from "./order.types";
import { CheckInReason, CheckInState, ExtendedCheckInState } from "./checkIn.types";
import { StorageLocation } from "./salesOrder.types";

export interface IPageMeta {
    size: number;
    number: number;
    totalElements: number;
}

export interface ISpringPage<T> {
    content: T[];
    size: number;
    number: number;
    totalElements: number;
}

export interface IPage<T> {
    meta: IPageMeta;
    content: Array<T>;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type IFilter = {}

export interface IOrderFilter {
    customer?: string;
    orderNumber?: number;
    containerNumber?: string;
    productName?: string;
    storageDays?: number;
    state?: State[];
    stateGroup?: StateGroup;
    itactRequired?: boolean;
    itactNeeded?: boolean;
    itactDone?: boolean;
    csActionRequired?: boolean;
    dateParkingInBefore?: string;
    dateParkingInAfter?: string;
    allStates?: boolean;
    orderType?: OrderType;
    restFound?: boolean;
    cleaningOrder?: boolean;
    isPrio?: boolean;
    deliveryId?: string;
    dateCleanedBefore?: string;
    dateCleanedAfter?: string;
    dateAvailableBefore?: string;
    dateAvailableAfter?: string;
    dateParkingOutBefore?: string;
    dateParkingOutAfter?: string;
}

export interface ICheckInFilter {
    archived: boolean;
    firstName?: string;
    lastName?: string;
    transportCompany?: string;
    checkInReason?: CheckInReason[];
    checkInState?: CheckInState[];
    extendedCheckInState?: ExtendedCheckInState[];
    customerReference?: string;
    assignedTo?: string;
    productName?: string;
    tank?: string;
    terminals?: StorageLocation[];
}

export type IAntSortOrder = "ascend" | "descend";
export type ISortOrder = "asc" | "desc";

export interface ISort {
    property: string;
    order: ISortOrder;
}

export interface IResourceRequest<F> {
    sorting?: ISort;
    filter: F;
}

export interface IPageRequest<F> extends IResourceRequest<F> {
    uuid?: string;
    pageNumber: number;
}

export interface IPageResponse<T, F> {
    page: IPage<T>;
    sorting?: ISort;
    filter: F;
}

export interface IOrderUpdateDto {
    dateParkingIn?: string;
    priority?: string | null;
    beforeLeaveMessage?: string;
    fromCleaningMessage?: string;
    toCleaningMessage?: string;
    fromItactMessage?: string;
    toItactMessage?: string;
    state?: State;
    dateParkingOut?: string;
    itactNeeded?: boolean;
    itactDone?: boolean;
}

export enum Paths {
    ITACT = "/cleaning/itact",
    CUSTOMER_SERVICE = "/cleaning/customerservice",
    CLEANING = "/cleaning",
    SLOTBOOKING = "/slotbooking",
    SLOTBOOKING_PLANNER = "/slotbookings/planner",
    SLOTBOOKING_LIST = "/list",
    ROOT = "/",
    EXTERNAL_VIEW = "/cleaning/externalview",
    CHECKIN = "/check-ins",
    CHECKIN_ARCHIVED = "/check-ins/archived",
    CHECKIN_OVERVIEW = "/check-ins/list",
    CHECKIN_OVERVIEW_READ_ONLY = "/check-ins/list-read-only",
    CHECKIN_DRIVERS = "/check-ins/drivers",
    SETTINGS = "/settings",
    ITACT_NEEDED = "/settings/itact-needed",
    FREE_STORAGE_DAYS = "/settings/free-storage-days",
    SELF_CHECK_IN = "/settings/self-check-in",
    TOLERANCES = "/settings/tolerances",
    ARCHIVE_SALESORDERS = "/settings/archive-sales-orders",
    WEIGHT_BADGE_TANK = "/settings/weight-badge-tank",
    CERTIFICATE = "/settings/certificate",
    LOGOUT = "/logout",
}

export enum ROLES {
    CLEANING = "cleaning",
    SLOTBOOKING = "slotbooking",
    CHECKIN = "checkin",
    ADMIN = "admin",
}

export interface IChecklistEntryStepSubmit {
    checklistItem: ChecklistItem;
    errorDetected: boolean;
    customerMessage?: string;
    formFields?: string;
    stepOrder: number;
    restFound: boolean;
    restAmount?: number;
    manualWorkNeeded: boolean;
    submittedBy?: string;
}
