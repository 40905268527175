import * as React from "react";

interface IProps {
    text: string;
    className?: string;
}

export const InfoLabel = (props: IProps) => (
    <strong className={props.className}>
        {props.text}
    </strong>
);
