import { createAction } from "@reduxjs/toolkit";
import { IFailedActionPayload } from "../../types/action.types";
import { IOrderFilter, IPageRequest, IPageResponse } from "../../types/api.types";
import { IOrder } from "../../types/order.types";

enum CleaningActionTypes {
    CLEANING_PAGE_LOAD = "[Cleaning] PAGE_LOAD",
    CLEANING_PAGE_FETCH = "[Cleaning] PAGE_FETCH",
    CLEANING_PAGE_RECEIVED = "[Cleaning] PAGE_RECEIVED",
    CLEANING_PAGE_FETCH_FAILED = "[Cleaning] PAGE_FETCH_FAILED"
}

export const cleaningPageLoadCreator = createAction<IPageRequest<IOrderFilter>>(CleaningActionTypes.CLEANING_PAGE_LOAD);
export const cleaningPageFetchCreator = createAction<IPageRequest<IOrderFilter>>(CleaningActionTypes.CLEANING_PAGE_FETCH);
export const cleaningPageReceivedCreator = createAction<IPageResponse<IOrder, IOrderFilter>>(CleaningActionTypes.CLEANING_PAGE_RECEIVED);
export const cleaningPageFetchFailedCreator = createAction<IFailedActionPayload>(CleaningActionTypes.CLEANING_PAGE_FETCH_FAILED);
