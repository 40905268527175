import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { ERROR_TRANSLATION_CODES } from "../../constants/errorTranslationCodes";
import { NOTIFICATION_TYPES } from "../../constants/notification";
import { IPage, IPageResponse } from "../../types/api.types";
import { IBookedSlot, IBookedSlotPageFetchParams } from "../../types/bookedSlot.types";
import { mapSpringPageToIPage } from "../../utils/mapping";
import {
    bookedSlotCreateCreator,
    bookedSlotCreateFailedCreator,
    bookedSlotDeleteCreator,
    bookedSlotDeletedCreator,
    bookedSlotDeleteFailedCreator,
    bookedSlotFetchCreator,
    bookedSlotFetchFailedCreator,
    bookedSlotListFetchCreator,
    bookedSlotListFetchFailedCreator,
    bookedSlotListReceivedCreator,
    bookedSlotPageFetchActionCreator,
    bookedSlotPageFetchFailedActionCreator,
    bookedSlotPageLoadActionCreator,
    bookedSlotPageReceivedActionCreator,
    bookedSlotReceivedCreator,
    bookedSlotUpdateCreator,
    bookedSlotUpdateFailedCreator,
    salesOrderReceivedCreator,
    selectedSlotReceivedCreator,
    slotsReceivedCreator
} from "../actions";
import { showNotificationActionCreator } from "../actions/notifications";
import { bookedSlotApi } from "../api/bookedSlot";
import { errorHandler } from "./errorHandler";
import { AxiosResponse } from "axios";
import dayjs from "dayjs";

export function* createBookedSlot(action: ReturnType<typeof bookedSlotCreateCreator>) {
    yield call(bookedSlotApi.postBookedSlot, action.payload.bookedSlotBodyParams);
    yield put(bookedSlotListFetchCreator({ salesOrderReference: action.payload.bookedSlotBodyParams.salesOrderReference }));
    yield resetSlotBooking();
    yield put(showNotificationActionCreator(NOTIFICATION_TYPES.BOOK_SLOT_SUCCEEDED));
}

export function* resetSlotBooking() {
    yield put(salesOrderReceivedCreator(null));
    yield put(slotsReceivedCreator([]));
    yield put(selectedSlotReceivedCreator(null));
}

export function* updateBookedSlot(action: ReturnType<typeof bookedSlotUpdateCreator>) {
    yield call(bookedSlotApi.putBookedSlot, action.payload.slotId, action.payload.bookedSlotBodyParams);
    yield resetSlotBooking();
    yield put(showNotificationActionCreator(NOTIFICATION_TYPES.UPDATE_SLOT_SUCCEEDED));
}

export function* deleteBookedSlot(action: ReturnType<typeof bookedSlotDeleteCreator>) {
    yield call(bookedSlotApi.deleteBookedSlot, action.payload.id);
    yield put(bookedSlotDeletedCreator());
}

export function* fetchBookedSlot(action: ReturnType<typeof bookedSlotFetchCreator>) {
    const response: AxiosResponse<IBookedSlot> = yield call(bookedSlotApi.getBookedSlot, action.payload.id);
    yield put(bookedSlotReceivedCreator(response.data));
}

export function* fetchBookedSlotList(action: ReturnType<typeof bookedSlotListFetchCreator>) {
    // TODO: needs more refactoring...
    const payload = { ...action.payload };

    payload.from = payload.from && dayjs(payload.from).toISOString();
    payload.until = payload.until && dayjs(payload.until).toISOString();
    payload.type = payload.type && payload.type.toString().replace("-", "_").toUpperCase();
    payload.tank = action.payload.tank;

    const response: AxiosResponse<IBookedSlot[] | null> = yield call(bookedSlotApi.getBookedSlotList, payload);
    yield put(bookedSlotListReceivedCreator(response.data));
}

export function* showNotFoundNotification() {
    yield put(showNotificationActionCreator(NOTIFICATION_TYPES.SLOT_NOT_FOUND));
}

export function* fetchPage(action: ReturnType<typeof bookedSlotPageFetchActionCreator>) {
    const response: AxiosResponse<any> = yield call(bookedSlotApi.getBookedSlotPage, action.payload);
    const page: IPage<IBookedSlot> = mapSpringPageToIPage(response.data);
    const pageResponse: IPageResponse<IBookedSlot, IBookedSlotPageFetchParams> = {
        page,
        sorting: action.payload.sorting,
        filter: action.payload.filter
    };

    yield put(bookedSlotPageReceivedActionCreator(pageResponse));
}

export function* loadPage(action: ReturnType<typeof bookedSlotPageLoadActionCreator>) {
    yield put(bookedSlotPageFetchActionCreator(action.payload));
}

export function* showNotification(action: ReturnType<typeof bookedSlotCreateFailedCreator>) {
    // Axios errors have this property and we check if the property is present.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const response = action.payload.e.response;

    if (response && response.data) {
        const translationCode = response.data["translationCode"];

        if (translationCode.includes(ERROR_TRANSLATION_CODES.ALREADY_BOOKING_SALES_ORDER)) {
            yield put(showNotificationActionCreator(NOTIFICATION_TYPES.ALREADY_BOOKING_SALES_ORDER));
        }
    }
}

export function* bookedSlotSaga() {
    yield takeLatest(bookedSlotCreateCreator.type, errorHandler(createBookedSlot, bookedSlotCreateFailedCreator));
    yield takeLatest(bookedSlotUpdateCreator.type, errorHandler(updateBookedSlot, bookedSlotUpdateFailedCreator));
    yield takeEvery(bookedSlotUpdateFailedCreator.type, errorHandler(showNotFoundNotification));
    yield takeEvery(bookedSlotFetchCreator.type, errorHandler(fetchBookedSlot, bookedSlotFetchFailedCreator));
    yield takeEvery(bookedSlotDeleteCreator.type, errorHandler(deleteBookedSlot, bookedSlotDeleteFailedCreator));
    yield takeLatest(bookedSlotListFetchCreator.type, errorHandler(fetchBookedSlotList, bookedSlotListFetchFailedCreator));
    yield takeEvery(bookedSlotDeletedCreator.type, errorHandler(fetchBookedSlotList, bookedSlotListFetchFailedCreator));
    yield takeLatest(bookedSlotPageLoadActionCreator.type, errorHandler(loadPage));
    yield takeLatest(bookedSlotPageFetchActionCreator.type, errorHandler(fetchPage, bookedSlotPageFetchFailedActionCreator));
    yield takeEvery(bookedSlotCreateFailedCreator.type, errorHandler(showNotification));
}
