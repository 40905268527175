import { createAction } from "@reduxjs/toolkit";
import { ISelfCheckInSettingsResponse, ISettingsUpdate } from "../../types/selfCheckIn.types";
import { IFailedActionPayload } from "../../types/action.types";

enum SelfCheckInSettingsActionTypes {
    FETCH = "[SETTINGS] FETCH",
    SET = "[SETTINGS] SET",
    FETCH_FAILED = "[SETTINGS] FETCH FAILED",
    UPDATE = "[SETTINGS] UPDATE",
}

export const settingsFetchActionCreator = createAction(SelfCheckInSettingsActionTypes.FETCH);
export const settingsSetActionCreator = createAction<ISelfCheckInSettingsResponse[]>(SelfCheckInSettingsActionTypes.SET);
export const settingsFetchFailedActionCreator = createAction<IFailedActionPayload>(SelfCheckInSettingsActionTypes.FETCH_FAILED);
export const settingsUpdateActionCreator = createAction< {data: ISettingsUpdate[], callback: () => void }>(SelfCheckInSettingsActionTypes.UPDATE);
