import { createSelector } from "@reduxjs/toolkit";
import { IApplicationState } from "../state";
import { ICheckInState } from "../reducers/checkIn.reducers";
import { CONFIG } from "../../config/config";
import { CheckInReason, ICheckIn } from "../../types/checkIn.types";
import {
    IProductionOrder,
    ISalesOrder,
    OrderAction,
    SlotBookingOrderType,
    StorageLocation
} from "../../types/salesOrder.types";
import { checkIfAdrIsExpired } from "../../utils/checkIn";
import { AdrTypes } from "../../types/adr.types";

const checkInStateSelector = (state: IApplicationState) => state.checkIn;

export const checkInPageSelector = createSelector(
    checkInStateSelector,
    (checkInState: ICheckInState) => checkInState.page
);

export const checkInShowLinkSalesOrderModalSelector = createSelector(
    checkInStateSelector,
    (checkInState: ICheckInState) => checkInState.linkSalesOrderModalManualOrderId
);

export const checkInLoadingPageSelector = createSelector(
    checkInStateSelector,
    (checkInState: ICheckInState) => checkInState.loadingPage
);

export const checkInRecordSelector = createSelector(
    checkInStateSelector,
    (checkInState: ICheckInState) => checkInState.record
);

export const weightCalculationRecordSelector = createSelector(
    checkInRecordSelector,
    (checkIn: ICheckIn | null) => checkIn?.weightCalculation || null
);

export const compartmentsRecordSelector = createSelector(
    checkInRecordSelector,
    (checkIn: ICheckIn | null) => checkIn?.compartmentDistributions || []
);

export const checkInFiltersSelector = createSelector(
    checkInPageSelector,
    (page) => page && page.filter ? page.filter : { ...CONFIG.defaultCheckinFilters }
);

export const checkInHasReasonSelector = (checkInReason: CheckInReason) => createSelector(
    checkInRecordSelector,
    (checkIn: ICheckIn | null) => checkIn?.checkInReason === checkInReason
);

export const checkInIsBulkFlowSelector = createSelector(
    checkInRecordSelector,
    (checkIn: ICheckIn | null) => checkIn?.checkInReason ? CheckInReason.BULK.includes(checkIn?.checkInReason) : false
);

export const activeCheckInSalesOrdersSelector = createSelector(
    checkInRecordSelector,
    (checkIn: ICheckIn | null) => checkIn?.salesOrders || []
);

export const approvedSalesOrderIdSelector = createSelector(
    checkInStateSelector,
    (checkInState) => checkInState.approvedSalesOrderId
);

export const salesOrderLinkedToManualOrderSelector = (manualOrderId: number) => createSelector(
    activeCheckInSalesOrdersSelector,
    (salesOrders) => salesOrders.find((salesOrder) => salesOrder.manualOrderId === manualOrderId)
);

export const hasBulkUnLoadingOrdersSelector = createSelector(
    activeCheckInSalesOrdersSelector,
    (salesOrders: ISalesOrder[]) => salesOrders.some((salesOrder) => salesOrder.orderType === SlotBookingOrderType.BULK && salesOrder.orderAction === OrderAction.UNLOAD)
);

export const checkInProductionOrdersSelector = createSelector(
    activeCheckInSalesOrdersSelector,
    (salesOrders: ISalesOrder[]) => salesOrders.flatMap(salesOrder => salesOrder.productionOrders)
);

export const checkInRowHasStorageLocationSelector = (location: StorageLocation) => createSelector(
    checkInProductionOrdersSelector,
    (productionOrders: IProductionOrder[]) => productionOrders.some((productionOrder) => productionOrder.storageLocation === location)
);

export const isValidatableCheckIn = createSelector(
    checkInRecordSelector,
    hasBulkUnLoadingOrdersSelector,
    checkInRowHasStorageLocationSelector(StorageLocation.ADPO),
    checkInIsBulkFlowSelector,
    (checkIn: ICheckIn | null, hasUnloadOrders: boolean, isADPOSalesOrder: boolean, isBulkFlow: boolean) => {
        if (!checkIn) {
            return false;
        }

        if (hasUnloadOrders) {
            return !!(checkIn.lenelBadge && checkIn.cmrWeightDispatcher);
        } else if (isADPOSalesOrder && isBulkFlow && checkIn.requiresWeightBadge) {
            return !!(checkIn.lenelBadge && checkIn.wiegandBadge);
        } else {
            return !!checkIn.lenelBadge;
        }
    }
);

export const checkInRecordHasExpiredByAdrType = (adrType: AdrTypes) => createSelector(
    checkInRecordSelector,
    (record) => checkIfAdrIsExpired(record, adrType)
);
