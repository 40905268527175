import { IApplicationState } from "../state";
import { createSelector } from "@reduxjs/toolkit";
import { ISettingsState } from "../reducers/selfCheckInSettings.reducers";
import { SettingsType } from "../../types/selfCheckIn.types";

const settingsStateSelector = (state: IApplicationState) => state.settings;

export const settingsValuePerTypeSelector = (type: SettingsType) => createSelector(
    settingsStateSelector,
    (settingsState: ISettingsState) => {
        return settingsState?.availableSettings && settingsState.availableSettings.find(item => item.settingType === type);
    }
);