import { createAction } from "@reduxjs/toolkit";
import { IFailedActionPayload } from "../../types/action.types";
import { IPageRequest, IPageResponse } from "../../types/api.types";
import {
    IBookedSlot,
    IBookedSlotBodyParams,
    IBookedSlotListFetchParams,
    IBookedSlotPageFetchParams
} from "../../types/bookedSlot.types";

enum BookedSlotActionTypes {
    BOOKED_SLOT_CREATE = "[BookedSlot] CREATE",
    BOOKED_SLOT_CREATE_FAILED = "[BookedSlot] CREATE_FAILED",

    BOOKED_SLOT_UPDATE = "[BookedSlot] UPDATE",
    BOOKED_SLOT_UPDATE_FAILED = "[BookedSlot] UPDATE_FAILED",

    BOOKED_SLOT_DELETE = "[BookedSlot] DELETE",
    BOOKED_SLOT_DELETED = "[BookedSlot] DELETED",
    BOOKED_SLOT_DELETE_FAILED = "[BookedSlot] DELETE_FAILED",

    BOOKED_SLOT_FETCH = "[BookedSlot] FETCH",
    BOOKED_SLOT_RECEIVED = "[BookedSlot] RECEIVED",
    BOOKED_SLOT_FETCH_FAILED = "[BookedSlot] FETCH_FAILED",

    BOOKED_SLOT_LIST_RECEIVED = "[BookedSlot] LIST_RECEIVED",
    BOOKED_SLOT_LIST_FETCH = "[BookedSlot] LIST_FETCH",
    BOOKED_SLOT_LIST_FETCH_FAILED = "[BookedSlot] LIST_FETCH_FAILED",

    DEPENDS_ON_BOOKED_SLOT_RECEIVED = "[BookedSlot] DEPENDS_ON_RECEIVED",

    BOOKED_SLOT_PAGE_LOAD = "[BookedSlot] PAGE_LOAD",
    BOOKED_SLOT_PAGE_RECEIVED = "[BookedSlot] PAGE_RECEIVED",
    BOOKED_SLOT_PAGE_FETCH = "[BookedSlot] PAGE_FETCH",
    BOOKED_SLOT_PAGE_FETCH_FAILED = "[BookedSlot] PAGE_FETCH_FAILED",
}

export const bookedSlotCreateCreator = createAction<{bookedSlotBodyParams: IBookedSlotBodyParams}>(BookedSlotActionTypes.BOOKED_SLOT_CREATE);
export const bookedSlotCreateFailedCreator = createAction<IFailedActionPayload>(BookedSlotActionTypes.BOOKED_SLOT_CREATE_FAILED);

export const bookedSlotUpdateCreator = createAction<{slotId: number; bookedSlotBodyParams: IBookedSlotBodyParams}>(BookedSlotActionTypes.BOOKED_SLOT_UPDATE);
export const bookedSlotUpdateFailedCreator = createAction<IFailedActionPayload>(BookedSlotActionTypes.BOOKED_SLOT_UPDATE_FAILED);

export const bookedSlotFetchCreator = createAction<{id: number}>(BookedSlotActionTypes.BOOKED_SLOT_FETCH);
export const bookedSlotReceivedCreator = createAction<IBookedSlot>(BookedSlotActionTypes.BOOKED_SLOT_RECEIVED);
export const bookedSlotFetchFailedCreator = createAction<IFailedActionPayload>(BookedSlotActionTypes.BOOKED_SLOT_FETCH_FAILED);

export const dependsOnBookedSlotReceivedCreator = createAction<IBookedSlot>(BookedSlotActionTypes.DEPENDS_ON_BOOKED_SLOT_RECEIVED);

export const bookedSlotDeleteCreator = createAction<{id: number}>(BookedSlotActionTypes.BOOKED_SLOT_DELETE);
export const bookedSlotDeletedCreator = createAction(BookedSlotActionTypes.BOOKED_SLOT_DELETED);
export const bookedSlotDeleteFailedCreator = createAction<IFailedActionPayload>(BookedSlotActionTypes.BOOKED_SLOT_DELETE_FAILED);

export const bookedSlotListFetchCreator = createAction<IBookedSlotListFetchParams>(BookedSlotActionTypes.BOOKED_SLOT_LIST_FETCH);
export const bookedSlotListReceivedCreator = createAction<IBookedSlot[] | null>(BookedSlotActionTypes.BOOKED_SLOT_LIST_RECEIVED);
export const bookedSlotListFetchFailedCreator = createAction<IFailedActionPayload>(BookedSlotActionTypes.BOOKED_SLOT_LIST_FETCH_FAILED);

export const bookedSlotPageLoadActionCreator = createAction<IPageRequest<IBookedSlotPageFetchParams>>(BookedSlotActionTypes.BOOKED_SLOT_PAGE_LOAD);
export const bookedSlotPageFetchActionCreator = createAction<IPageRequest<IBookedSlotPageFetchParams>>(BookedSlotActionTypes.BOOKED_SLOT_PAGE_FETCH);
export const bookedSlotPageReceivedActionCreator = createAction<IPageResponse<IBookedSlot, IBookedSlotPageFetchParams>>(BookedSlotActionTypes.BOOKED_SLOT_PAGE_RECEIVED);
export const bookedSlotPageFetchFailedActionCreator = createAction<IFailedActionPayload>(BookedSlotActionTypes.BOOKED_SLOT_PAGE_FETCH_FAILED);
