import { call, put, takeLatest } from "redux-saga/effects";
import { slotsFetchCreator, slotsFetchFailedCreator, slotsReceivedCreator } from "../actions";
import { errorHandler } from "./errorHandler";
import { slotApi } from "../api/slot";
import { ISlot, ISlotBookingConfig, ISlotFetchParams, ISlotResponse } from "../../types/slot.types";
import { IScheduledMaintenance } from "../../types/scheduledMaintenance.types";
import {
    slotBookingConfigFetchCreator,
    slotBookingConfigFetchFailedCreator,
    slotBookingConfigReceivedCreator
} from "../actions/slotBookingConfig";
import { slotBookingConfigApi } from "../api/slotBookingConfig";
import { TIME_FORMATS } from "../../constants/time";
import { AxiosResponse } from "axios";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
dayjs.extend(duration);

export interface IScheduledMaintenanceResponse {
    data: IScheduledMaintenance[];
}

export function* fetchSlots(action: ReturnType<typeof slotsFetchCreator>) {
    const tankId = action.payload.tankId;
    const slotsFetchParams: ISlotFetchParams = action.payload.slotsFetchParams;

    const slotsResponse: AxiosResponse<ISlotResponse[]> = yield call(slotApi.getSlots, tankId, slotsFetchParams);
    const slotResponses = slotsResponse.data;

    const slots: ISlot[] = yield slotResponses.map((slotResponse: ISlotResponse) => {
        const obj: ISlot = {
            date: dayjs(slotsFetchParams.from).format(),
            start: dayjs.utc(slotsFetchParams.from).startOf("hour").startOf("day").add(dayjs.duration(slotResponse.start)).local().format(TIME_FORMATS.TIME),
            end: dayjs.utc(slotsFetchParams.from).startOf("hour").startOf("day").add(dayjs.duration(slotResponse.end)).local().format(TIME_FORMATS.TIME),
            tankBookings: slotResponse.tankBookings,
            zoneBookings: slotResponse.zoneBookings,
            tankTimeOverlap: slotResponse.tankTimeOverlap,
            constraints: slotResponse.constraints,
        };
        return obj;
    });

    yield put(slotsReceivedCreator(slots));
}

export function* fetchSlotBookingConfig(action: ReturnType<typeof slotBookingConfigFetchCreator>) {
    const configResponse: AxiosResponse<ISlotBookingConfig> = yield call(slotBookingConfigApi.getConfig, action.payload);
    yield put(slotBookingConfigReceivedCreator(configResponse.data));
}

export function* slotSaga() {
    yield takeLatest(slotsFetchCreator.type, errorHandler(fetchSlots, slotsFetchFailedCreator));
    yield takeLatest(slotBookingConfigFetchCreator.type, errorHandler(fetchSlotBookingConfig, slotBookingConfigFetchFailedCreator));
}
