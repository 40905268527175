import { map, reduce } from "lodash";
import { CHECKLIST_ITEM_STEPS_AMOUNT } from "../constants/checklist";
import { ChecklistItem, IChecklist, IChecklistEntry, IChecklistEntryStep } from "../types/checklist.types";

export const checklistEntryItemIndex = (checklistEntry: IChecklistEntry) => {
    return Object.keys(ChecklistItem).findIndex(val => val === checklistEntry.checklistItem);
};

const sortChecklists = (checklists: IChecklist[]): IChecklist[] => {
    return [...checklists].sort((a: IChecklist, b: IChecklist) => {
        if (a.createdDate === b.createdDate) {
            return 0;
        }

        if (a.createdDate > b.createdDate) {
            return 1;
        }

        return -1;
    });
};

export const getLatestChecklist = (checklists: IChecklist[]): IChecklist | undefined => {
    return sortChecklists(checklists).slice(-1)[0];
};

export const sortEntries = (checklistEntries: IChecklistEntry[]) => {
    return [...checklistEntries].sort((a, b) => {
        const aIndex = checklistEntryItemIndex(a);
        const bIndex = checklistEntryItemIndex(b);

        return aIndex - bIndex;
    });
};

export const sortSteps = (steps: IChecklistEntryStep[]) => {
    return [...steps].sort((a, b) => a.stepOrder - b.stepOrder);
};

export const checklistEntryContainsError = (checklistEntry: IChecklistEntry): boolean => {
    return reduce(checklistEntry.steps, (result: boolean, checklistEntryStep: IChecklistEntryStep) => result || checklistEntryStep.errorDetected, false);
};

export const checklistContainsError = (checklist: IChecklist): boolean => {
    return reduce(map(checklist.entries, checklistEntryContainsError), (result: boolean, errorDetected: boolean) => result || errorDetected, false);
};

export const currentChecklistIndex = (checklistEntries: IChecklistEntry[]): number => {
    const sortedList = sortEntries(checklistEntries);
    const lastEntry = sortedList.slice(-1)[0];

    if (lastEntry) {
        // If last entry has error we return its index.
        if (checklistEntryContainsError(lastEntry)) {
            return sortedList.length - 1;
        }

        // If not all steps are present we are still in last entry present in the checklist
        // Ant design steps are 0 indexed so length of entries points to the index of the next item
        return lastEntry.steps.length === CHECKLIST_ITEM_STEPS_AMOUNT[lastEntry.checklistItem] ? sortedList.length : sortedList.length - 1;
    }

    return 0;
};

export const checklistFinished = (checklist: IChecklist) => {
    const sortedList = sortEntries(checklist.entries);

    return sortedList.length && sortedList.slice(-1)[0].checklistItem === ChecklistItem.CONTAINER_DONE;
};
