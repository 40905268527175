import * as React from "react";
import { useEffect } from "react";
import { ORDER_FILTERS } from "../../constants/order";
import { PlanningTabBar } from "../components/tabbars/planningtabs/PlanningTabBar";
import { Elements, ITotalElements, useFilterTotalElementsLoader } from "../hooks/useFilterTotalElementsLoader";
import { CleaningListContainer } from "./CleaningListContainer";

// I would have rather put each tab in its own container but Ant design doesn't like wrappers around their tabs.
export const PlanningTabBarContainer = () => {
    const ctrTotalElementsLoader = useFilterTotalElementsLoader(ORDER_FILTERS.CTR);
    const restTotalElementsLoader = useFilterTotalElementsLoader(ORDER_FILTERS.REST);
    const rtcTotalElementsLoader = useFilterTotalElementsLoader(ORDER_FILTERS.RTC);
    const ibcTotalElementsLoader = useFilterTotalElementsLoader(ORDER_FILTERS.IBC);
    const hoseTotalElementsLoader = useFilterTotalElementsLoader(ORDER_FILTERS.HOSE);
    const mannedTotalElementsLoader = useFilterTotalElementsLoader(ORDER_FILTERS.MANNED);

    useEffect(() => {
        ctrTotalElementsLoader.loadResource();
        restTotalElementsLoader.loadResource();
        rtcTotalElementsLoader.loadResource();
        ibcTotalElementsLoader.loadResource();
        hoseTotalElementsLoader.loadResource();
        mannedTotalElementsLoader.loadResource();
    }, []);

    const getNumber = (resource: ITotalElements | undefined, type: Elements) => {
        if (!resource) {
            return undefined;
        }

        switch (type) {
        case Elements.PRIORITY:
            return resource.numberOfPriorityElements;
        case Elements.TOTAL:
            return resource.numberOfElements;
        default:
            throw Error("This can never occur");
        }
    };

    return (
        <div>
            <CleaningListContainer/>
            <PlanningTabBar
                className="margin-half-top"
                ctrTotalElementsLoading={ctrTotalElementsLoader.loadingResource}
                restTotalElementsLoading={restTotalElementsLoader.loadingResource}
                rtcTotalElementsLoading={rtcTotalElementsLoader.loadingResource}
                ibcTotalElementsLoading={ibcTotalElementsLoader.loadingResource}
                hoseTotalElementsLoading={hoseTotalElementsLoader.loadingResource}
                mannedTotalElementsLoading={mannedTotalElementsLoader.loadingResource}
                ctrTotalElements={getNumber(ctrTotalElementsLoader.resource, Elements.TOTAL)}
                restTotalElements={getNumber(restTotalElementsLoader.resource, Elements.TOTAL)}
                rtcTotalElements={getNumber(rtcTotalElementsLoader.resource, Elements.TOTAL)}
                ibcTotalElements={getNumber(ibcTotalElementsLoader.resource, Elements.TOTAL)}
                hoseTotalElements={getNumber(hoseTotalElementsLoader.resource, Elements.TOTAL)}
                mannedTotalElements={getNumber(mannedTotalElementsLoader.resource, Elements.TOTAL)}
                ctrTotalPriorityElements={getNumber(ctrTotalElementsLoader.resource, Elements.PRIORITY)}
                restTotalPriorityElements={getNumber(restTotalElementsLoader.resource, Elements.PRIORITY)}
                rtcTotalPriorityElements={getNumber(rtcTotalElementsLoader.resource, Elements.PRIORITY)}
                ibcTotalPriorityElements={getNumber(ibcTotalElementsLoader.resource, Elements.PRIORITY)}
                hoseTotalPriorityElements={getNumber(hoseTotalElementsLoader.resource, Elements.PRIORITY)}
                mannedTotalPriorityElements={getNumber(mannedTotalElementsLoader.resource, Elements.PRIORITY)}
            />
        </div>
    );
};
