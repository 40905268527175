import { Select } from "antd";
import * as React from "react";
import { useState } from "react";
import { FilterFooter } from "./FilterFooter";

interface IProps {
  closeFilter: (() => void) | undefined;
  onFilter: (value: string | undefined) => void;
  options: string[];
  placeholder?: string;
  optionRenderer?: (option: string) => string;
}

export const DropdownFilter = (props: IProps) => {
    const [selectedOption, setSelectedOption] = useState<string | undefined>();

    const onFilter = () => {
        props.onFilter(selectedOption);
        props.closeFilter && props.closeFilter();
    };

    return (
        <div className="padded">
            <Select
                allowClear
                className="margin-half-bottom fill-width"
                placeholder={props.placeholder}
                //eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                onChange={setSelectedOption}
            >
                {
                    props.options.map((option: string, index: number) => (
                        <Select.Option key={`${option}${index}`} value={option}>
                            {
                                props.optionRenderer ? props.optionRenderer(option) : option
                            }
                        </Select.Option>)
                    )
                }
            </Select>
            <FilterFooter onCancel={props.closeFilter} onFilter={onFilter}/>
        </div>
    );
};
