import * as React from "react";
import { CancelCheckinButton } from "./buttons/CancelCheckinButton";
import { ICheckIn } from "../../../../types/checkIn.types";
import { ValidateCheckinButton } from "./buttons/ValidateCheckinButton";
import { AssignCheckinButton } from "./buttons/AssignCheckinButton";
import { FinishCheckinButton } from "./buttons/FinishCheckinButton";
import { UnAssignCheckinButton } from "./buttons/UnAssignCheckinButton";
import { CloseCheckinButton } from "./buttons/CloseCheckinButton";
import { ReopenCheckinButton } from "./buttons/ReopenCheckinButton";
import { DeleteCheckinButton } from "./buttons/DeleteCheckinButton";
import { ReturnToKioskCheckinButton } from "./buttons/ReturnToKioskCheckinButton";

interface IProps {
    onClose: () => void;
    onCancel: () => void;
    onAssign: () => void;
    onUnAssign: () => void;
    onValidate: () => void;
    onFinish: () => void;
    onReopen: () => void;
    onDelete: () => void;
    onReturnToKiosk: () => void;
    checkIn: ICheckIn | null;
}

export const CheckInModalFooter = (props: IProps) => {
    return (
        <div className="text-middle">
            <DeleteCheckinButton onClick={props.onDelete} checkIn={props.checkIn} />
            <CancelCheckinButton onCancel={props.onCancel} checkIn={props.checkIn} />
            <AssignCheckinButton onClick={props.onAssign} checkIn={props.checkIn} />
            <UnAssignCheckinButton onClick={props.onUnAssign} checkIn={props.checkIn} />
            <ValidateCheckinButton onClick={props.onValidate} checkIn={props.checkIn} />
            <FinishCheckinButton onClick={props.onFinish} checkIn={props.checkIn} />
            <CloseCheckinButton onClick={props.onClose} checkIn={props.checkIn} />
            <ReopenCheckinButton onClick={props.onReopen} checkIn={props.checkIn} disabled={!props?.checkIn?.isEditable} />
            <ReturnToKioskCheckinButton onClick={props.onReturnToKiosk} checkIn={props.checkIn} />
        </div>
    );
};
