import { combineReducers, createReducer, PayloadAction } from "@reduxjs/toolkit";
import { IDocumentsPerCheckIn, IDocumentsState } from "../../types/document.types";
import {
    documentsForCheckinFetchFailedActionCreator,
    documentsForCheckinSetActionCreator
} from "../actions/documents.actions";

const documentsPerCheckIn = createReducer<IDocumentsPerCheckIn | null>(null, {
    [documentsForCheckinSetActionCreator.type]: (state, { payload }: PayloadAction<IDocumentsPerCheckIn>) => {
        if (!state) {
            return payload;
        }
        return {
            ...state,
            ...payload
        };
    },
});

const fetchDocumentsFailed = createReducer<boolean>(false, {
    [documentsForCheckinFetchFailedActionCreator.type]: (state, { payload }: PayloadAction<boolean>) => payload
});

export const documentsReducer = combineReducers<IDocumentsState>({
    documentsPerCheckIn,
    fetchDocumentsFailed
});
