import { all, fork } from "redux-saga/effects";

import { authenticationSaga } from "./authentication";
import { bookedSlotSaga } from "./bookedSlot";
import { calendarSaga } from "./calendar";
import { compartmentSaga } from "./checklist";
import { cleaningSaga } from "./cleaning";
import { customerSaga } from "./customer";
import { customerServiceSaga } from "./customerservice";
import { externalViewSaga } from "./externalView";
import { init } from "./init";
import { itactSaga } from "./itact";
import { orderSaga } from "./order";
import { pitSaga } from "./pit";
import { planningSaga } from "./planning.sagas";
import { salesOrderSaga } from "./salesOrder";
import { slotSaga } from "./slot";
import { tankSaga } from "./tank";
import { ttatCalculationSaga } from "./ttatCalculation";
import { checkInSaga } from "./checkin.sagas";
import { selfCheckInSettingsSaga } from "./selfCheckInSettings.sagas";
import { documentSaga } from "./documents.saga";
import { applicationVersionSaga } from "./applicationVersion.sagas";
import { remarksSaga } from "./remarks.saga";

export function* rootSaga() {
    yield all([
        fork(init),
        fork(authenticationSaga),
        fork(salesOrderSaga),
        fork(slotSaga),
        fork(bookedSlotSaga),
        fork(orderSaga),
        fork(tankSaga),
        fork(compartmentSaga),
        fork(pitSaga),
        fork(ttatCalculationSaga),
        fork(cleaningSaga),
        fork(customerServiceSaga),
        fork(planningSaga),
        fork(pitSaga),
        fork(customerSaga),
        fork(itactSaga),
        fork(externalViewSaga),
        fork(calendarSaga),
        fork(checkInSaga),
        fork(selfCheckInSettingsSaga),
        fork(documentSaga),
        fork(remarksSaga),
        fork(applicationVersionSaga)
    ]);
}
