import { IOrderFilter, IPageRequest } from "../../types/api.types";
import { formatPageQueryParams } from "../../utils/format";
import { getHttpClient } from "./httpClient";
import { URL_PATHS } from "../../constants/api.constants";

const baseUrl = URL_PATHS.CLEANING + "/public/orders/";

const getPage = (uuid: string, queryParams: IPageRequest<IOrderFilter>) => getHttpClient().get(`${baseUrl}${uuid}${formatPageQueryParams(queryParams)}`);
const getTransitions = (uuid: string, orderId: number) => getHttpClient().get(`${baseUrl}${uuid}/${orderId}/state-transitions`);
const getCsvUuid = (uuid: string, pageRequest: IPageRequest<IOrderFilter>) => getHttpClient().get(`${baseUrl}${uuid}/csv${formatPageQueryParams(pageRequest)}`);

export const publicOrderApi = {
    getPage,
    getTransitions,
    getCsvUuid
};
