
import { getHttpClient } from "./httpClient";
import { URL_PATHS } from "../../constants/api.constants";

const baseUrl = URL_PATHS.BOOKING + "/pits";

const getPitList = () => getHttpClient().get(baseUrl);

export const pitsApi = {
    getPitList,
};
