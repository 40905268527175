export enum ChecklistItem {
    PRE_OPERATION_CHECKLIST = "PRE_OPERATION_CHECKLIST",
    CLEANING_PROCEDURE = "CLEANING_PROCEDURE",
    POST_OPERATION_CHECKLIST = "POST_OPERATION_CHECKLIST",
    CONTAINER_DONE = "CONTAINER_DONE"
}

export interface IChecklistEntryStep {
    errorDetected: boolean;
    formFields: string;
    stepOrder: number;
}

export interface IChecklistEntry {
    completedOn: string;
    checklistItem: ChecklistItem;
    steps: IChecklistEntryStep[];
}

export interface IChecklist {
    id: number;
    createdDate: string;
    entries: IChecklistEntry[];
}
