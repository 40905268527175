import { combineReducers, createReducer } from "@reduxjs/toolkit";
import {
    slotsFetchCreator,
    slotsReceivedCreator,
    selectedSlotReceivedCreator,
    slotsFetchFailedCreator, ttatCalculationReceivedCreator
} from "../actions";
import { ISelectedSlot, ISlot, ISlotBookingConfig } from "../../types/slot.types";
import { ITtatCalculation } from "../../types/ttatCalculation.types";
import { slotBookingConfigReceivedCreator } from "../actions/slotBookingConfig";

export interface ISlotState {
    selected: ISelectedSlot | null;
    ttatCalculation: ITtatCalculation | null;
    available: ISlot[];
    config: ISlotBookingConfig | null;
    loading: boolean;
}

export const selectedSlot = createReducer<ISelectedSlot | null>(null, {
    [selectedSlotReceivedCreator.type]: (selectedSlot, action) => action.payload,
});

export const ttatCalculation = createReducer<ITtatCalculation | null>(null, {
    [ttatCalculationReceivedCreator.type]: (ttatCalculation, action) => action.payload,
});

export const slots = createReducer<ISlot[]>([], {
    [slotsFetchCreator.type]: () => [],
    [slotsReceivedCreator.type]: (slots, action) => action.payload,
});

export const config = createReducer<ISlotBookingConfig | null>(null, {
    [slotBookingConfigReceivedCreator.type]: (config, action) => action.payload,
});

export const loading = createReducer<boolean>(false, {
    [slotsFetchCreator.type]: () => true,
    [slotsReceivedCreator.type]: () => false,
    [slotsFetchFailedCreator.type]: () => false,
});


export const slotReducer = combineReducers<ISlotState>({
    selected: selectedSlot,
    ttatCalculation: ttatCalculation,
    available: slots,
    config: config,
    loading: loading
});
