import { Col, Row } from "antd";
import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import { PADDING } from "../../../../../../../theme/typescript";
import { ICompartment, IOrder } from "../../../../../../../types/order.types";
import { mapCleaningProcedureObjToArr } from "../../../../../../../utils/mapping";
import { Box } from "../../../../../Box";
import { InfoField } from "../../../../../modals/customerservicemodal/panes/detailinfopane/sections/infofields/InfoField";
import { Message } from "../../../../../modals/customerservicemodal/panes/messagesPane/message/Message";
import { CleaningProcedureList } from "./CleaningProcedureList";
import { DangerCodes } from "./DangerCodes";
import { PbmInstructions } from "./PbmInstructions";

interface IProps {
    order: IOrder;
    compartment: ICompartment;
}

const MESSAGES = defineMessages({
    containerNumber: {
        id: "compartment_info.container_number",
        defaultMessage: "Containernummer",
    },
    product: {
        id: "compartment_info.product",
        defaultMessage: "Product",
    },
    customerServiceMessage: {
        id: "compartment_info.customer_service_message",
        defaultMessage: "Customer service bericht",
    },
    pbmInstructions: {
        id: "compartment_info.pbm_codes",
        defaultMessage: "PBM-instructie afwijkingen"
    }
});

export const CompartmentInfo = (props: IProps) => {
    const intl = useIntl();

    return (
        <Box>
            <InfoField value={props.order.container.sapId} className="text-large"/>
            <InfoField value={props.compartment.product.name} className="margin-bottom" />

            <Row gutter={PADDING}>
                <Col span={8}>
                    <Message
                        editable={false}
                        title={intl.formatMessage(MESSAGES.customerServiceMessage)}
                        body={props.order.toCleaningMessage}
                    />

                    <PbmInstructions product={props.compartment.product} className="margin-top" />
                </Col>
                <Col span={8}>
                    <DangerCodes product={props.compartment.product}/>
                </Col>
                <Col span={8}>
                    <CleaningProcedureList steps={mapCleaningProcedureObjToArr(props.compartment.product.cleaningProcedure)}/>
                </Col>
            </Row>
        </Box>
    );
};
