import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { Pane } from "../../modals/customerservicemodal/panes/Pane";
import { UploadArea } from "../../areas/UploadArea";
import { buildUrl, URL_PATHS } from "../../../../constants/api.constants";

const MESSAGES = defineMessages({
    uploadTitle: {
        id: "self_check_in_form.upload_title",
        defaultMessage: "Bestand met contractor start gegevens:"
    },
    uploadMessage: {
        id: "terminal_settings_pane.upload_message",
        defaultMessage: "Selecteer een CSV bestand om te uploaden met records."
    },
});

export const ContractorUploadPane = () => {
    const intl = useIntl();

    return (
        <Pane className="margin-top" title={intl.formatMessage(MESSAGES.uploadTitle)}>
            <div style={{ minHeight: 250 }}>
                <UploadArea
                    formattedMessage={intl.formatMessage(MESSAGES.uploadMessage)}
                    action={buildUrl(URL_PATHS.CHECKIN, "contractor-terminal-import")}
                />
            </div>
        </Pane>
    );
};