import { AppLayout } from "./AppLayout";
import * as React from "react";
import { useEffect } from "react";
import { SelfCheckInContainer } from "../containers/SelfCheckInContainer";
import { settingsFetchActionCreator } from "../../store/actions/selfCheckInSettings.actions";
import { useDispatch } from "react-redux";

export const SelfCheckInScreen = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(settingsFetchActionCreator());
    }, []);

    return (
        <AppLayout>
            <SelfCheckInContainer />
        </AppLayout>
    );
};
