import { ModalProps } from "antd/lib/modal";
import * as React from "react";
import { ReactComponent as WarningIcon } from "../../../../../../../../assets/warning.svg";
import { CleaningModal } from "../../../../../../modals/cleaningmodal/CleaningModal";
import { InfoLabel } from "../../../../../../modals/customerservicemodal/panes/detailinfopane/sections/infofields/InfoLabel";
import Icon from "@ant-design/icons";

interface IProps extends ModalProps{
    visible: boolean;
    message: string;
    onOk: () => void;
}

export const WarningModal = (props: IProps) => {
    return (
        <CleaningModal
            {...props}
            closeModal={props.onOk}
            open={props.visible}
        >
            <div className="flex flex-column justify-center align-items-center">
                <Icon component={WarningIcon} className="icon-large text-red margin-half-bottom"/>
                <InfoLabel text={props.message}/>
            </div>
        </CleaningModal>
    );
};
