import * as React from "react";
import { useDispatch } from "react-redux";
import { checklistStepSubmitActionCreator } from "../../../store/actions/checklist";
import { IChecklistEntryStepSubmit } from "../../../types/api.types";
import { ChecklistItem } from "../../../types/checklist.types";
import { IProduct } from "../../../types/order.types";
import { ProcedureForm } from "../../components/tabbars/cleaningtabs/cleaningtab/compartmenttab/checklist/forms/ProcedureForm";

interface IProps {
    checklistId: number;
    product: IProduct;
    prev: () => void;
    compartmentId: number;
}

export const ProcedureFormContainer = (props: IProps) => {
    const dispatch = useDispatch();

    const submit = (error: boolean, submittedBy?: string, msg?: string) => {
        const checklistEntryStepSubmit: IChecklistEntryStepSubmit = {
            errorDetected: error,
            customerMessage: msg,
            stepOrder: 0,
            checklistItem: ChecklistItem.CLEANING_PROCEDURE,
            restFound: false,
            manualWorkNeeded: false,
            submittedBy: submittedBy
        };

        dispatch(checklistStepSubmitActionCreator({
            checklistId: props.checklistId,
            checklistEntryStepSubmit,
            compartmentId: props.compartmentId
        }));
    };

    const onError = (submittedBy: string, msg?: string) => {
        submit(true, submittedBy, msg);
    };

    const onOk = () => {
        submit(false);
    };

    return (
        <ProcedureForm
            product={props.product}
            prev={props.prev}
            onError={onError}
            onOk={onOk}
        />
    );
};
