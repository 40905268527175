import { Col, Row } from "antd";
import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import {
    disableDateAfter,
    disableDateBefore,
    disableTimeAfter,
    disableTimeBefore
} from "../../../../../../../utils/time";
import { DateTimePicker } from "../../../../../forms/inputs/DateTimePicker";
import { DateTimeInfoField } from "./infofields/DateTimeInfoField";
import { InfoLabel } from "./infofields/InfoLabel";
import dayjs, { Dayjs } from "dayjs";
interface IProps {
    orderId: number;
    dateParkingIn?: string;
    dateCleaned?: string;
    dateAvailable?: string;
    dateParkingOut?: string;
    onDateParkingInSave?: (m: Dayjs | null) => void;
    savingDateParkingIn: boolean;
    savingDateParkingOut: boolean;
    onDateParkingOutSave?: (m: Dayjs | null) => void;
}

const MESSAGES = defineMessages({
    dateParkingIn: {
        id: "date_section.date_parking_in",
        defaultMessage: "Datum parking in",
    },
    dateCleaned: {
        id: "date_section.date_cleaned",
        defaultMessage: "Datum gereinigd",
    },
    dateAvailable: {
        id: "date_section.date_available",
        defaultMessage: "Datum beschikbaar",
    },
    dateParkingOut: {
        id: "date_section.date_parking_out",
        defaultMessage: "Datum parking uit",
    }
});

export const DateSection = (props: IProps) => {
    const intl = useIntl();

    const dateParkingInDateDisableFn = props.dateCleaned ? ((current: Dayjs | null) => disableDateAfter(current, dayjs(props.dateCleaned))) : undefined;
    const dateParkingOutDateDisableFn = props.dateAvailable ? ((current: Dayjs | null) => disableDateBefore(current, dayjs(props.dateAvailable))) : undefined;

    const dateParkingInTimeDisableFn = props.dateCleaned ? ((m?: Dayjs | null) => disableTimeAfter(dayjs(props.dateCleaned), m)) : undefined;
    const dateParkingOutTimeDisableFn = props.dateAvailable ? ((m?: Dayjs | null) => disableTimeBefore(dayjs(props.dateAvailable), m)) : undefined;

    return (
        <div>
            <Row className="margin-bottom">
                <Col span={12}>
                    <InfoLabel text={intl.formatMessage(MESSAGES.dateParkingIn)}/>
                </Col>
                <Col span={12}>
                    {
                        props.dateParkingIn || !(props.onDateParkingInSave)?
                            <DateTimeInfoField value={props.dateParkingIn}/>
                            :
                            <DateTimePicker
                                onSave={props.onDateParkingInSave}
                                disableDateFn={dateParkingInDateDisableFn}
                                disableTimeFn={dateParkingInTimeDisableFn}
                                saving={props.savingDateParkingIn}
                            />
                    }
                </Col>
            </Row>
            <Row className="margin-bottom">
                <Col span={12}>
                    <InfoLabel text={intl.formatMessage(MESSAGES.dateCleaned)}/>
                </Col>
                <Col span={12}>
                    <DateTimeInfoField value={props.dateCleaned}/>
                </Col>
            </Row>
            <Row className="margin-bottom">
                <Col span={12}>
                    <InfoLabel text={intl.formatMessage(MESSAGES.dateAvailable)}/>
                </Col>
                <Col span={12}>
                    <DateTimeInfoField value={props.dateAvailable}/>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <InfoLabel text={intl.formatMessage(MESSAGES.dateParkingOut)}/>
                </Col>
                <Col span={12}>
                    {
                        props.dateParkingOut || !(props.onDateParkingOutSave)?
                            <DateTimeInfoField value={props.dateParkingOut}/>
                            :
                            <DateTimePicker
                                onSave={props.onDateParkingOutSave}
                                saving={props.savingDateParkingOut}
                                defaultValue={props.dateParkingOut}
                                disableDateFn={dateParkingOutDateDisableFn}
                                disableTimeFn={dateParkingOutTimeDisableFn}
                            />}
                </Col>
            </Row>
        </div>
    );
};

