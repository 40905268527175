import fileDownload from "js-file-download";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { IOrderFilter, IPage, IPageResponse } from "../../types/api.types";
import { IOrder } from "../../types/order.types";
import { mapPageResponseToPageRequest, mapSpringPageToIPage } from "../../utils/mapping";
import { orderTransitionsFetchFailedCreator, orderTransitionsReceivedCreator } from "../actions";
import {
    externalViewCsvDownloadActionCreator,
    externalViewPageFetchActionCreator,
    externalViewPageFetchFailedActionCreator,
    externalViewPageLoadActionCreator,
    externalViewPageOrderUpdateDoneActionCreator,
    externalViewPageReceivedActionCreator,
    externalViewTransitionsFetchActionCreator
} from "../actions/externalView";
import { publicOrderApi } from "../api/publicOrder";
import { externalViewPageSelector } from "../selectors/externalView.selector";
import { errorHandler } from "./errorHandler";
import { AxiosResponse } from "axios";

export function* fetchPage(action: ReturnType<typeof externalViewPageFetchActionCreator>) {
    const uuid = action.payload.uuid;

    if (uuid) {
        const response: AxiosResponse<any> = yield call(publicOrderApi.getPage, uuid, action.payload);
        const page: IPage<IOrder> = mapSpringPageToIPage(response.data);

        const pageResponse: IPageResponse<IOrder, IOrderFilter> = {
            page,
            sorting: action.payload.sorting,
            filter: action.payload.filter,
        };
        yield put(externalViewPageReceivedActionCreator(pageResponse));
    }
}

export function* downloadCsv(action: ReturnType<typeof externalViewCsvDownloadActionCreator>) {
    const uuid = action.payload.uuid;

    if (uuid) {
        const response: AxiosResponse<any> = yield call(publicOrderApi.getCsvUuid, uuid, action.payload);
        if (response.headers && response.headers["content-disposition"]) {
            const fileName = response.headers["content-disposition"].split("filename=")[1];
            fileDownload(response.data, fileName);
        }
    }
}

export function* loadPage(action: ReturnType<typeof externalViewPageLoadActionCreator>) {
    yield put(externalViewPageFetchActionCreator(action.payload));
}

export function* updateDone() {
    const page: IPageResponse<IOrder, IOrderFilter> = yield select(externalViewPageSelector);
    const pageRequest = mapPageResponseToPageRequest(page);
    yield put(externalViewPageFetchActionCreator(pageRequest));
}

export function* fetchTransitions(action: ReturnType<typeof externalViewTransitionsFetchActionCreator>) {
    const uuid = action.payload.uuid;
    const id = action.payload.id;
    const response: AxiosResponse<any> = yield call(publicOrderApi.getTransitions, uuid, id);
    yield put(orderTransitionsReceivedCreator(response.data));
}

export function* externalViewSaga() {
    yield takeLatest(externalViewPageLoadActionCreator.type, errorHandler(loadPage));
    yield takeLatest(externalViewPageFetchActionCreator.type, errorHandler(fetchPage, externalViewPageFetchFailedActionCreator));
    yield takeLatest(externalViewPageOrderUpdateDoneActionCreator.type, errorHandler(updateDone));
    yield takeLatest(externalViewTransitionsFetchActionCreator.type, errorHandler(fetchTransitions, orderTransitionsFetchFailedCreator));
    yield takeLatest(externalViewCsvDownloadActionCreator.type, errorHandler(downloadCsv));
    yield takeLatest(externalViewTransitionsFetchActionCreator.type, errorHandler(fetchTransitions, orderTransitionsFetchFailedCreator));
}
