import { Button } from "antd";
import * as React from "react";
import { defineMessages, useIntl } from "react-intl";

interface IProps {
    onConfirm: () => void;
    onBack: () => void;
    disabled?: boolean;
}

const MESSAGES = defineMessages({
    confirm: {
        id: "confirm_delete_modal_footer.confirm",
        defaultMessage: "Bevestig annulering van boeking"
    },
    back: {
        id: "confirm_delete_modal_footer.back",
        defaultMessage: "Keer terug"
    }
});

export const ConfirmDeleteModalFooter = (props: IProps) => {
    const intl = useIntl();

    return (
        <div className="text-middle">
            <Button onClick={props.onBack}>
                {intl.formatMessage(MESSAGES.back)}
            </Button>
            <Button className="margin-half-left" type="primary" onClick={props.onConfirm} disabled={props.disabled}>
                {intl.formatMessage(MESSAGES.confirm)}
            </Button>
        </div>
    );
};
