export const NOTIFICATION_TYPES = {
    BLOCK_SUCCEEDED: "BLOCK_SUCCEEDED",
    BLOCK_ALREADY_SCHEDULED: "BLOCK_ALREADY_SCHEDULED",
    BLOCK_SLOT_BOOKINGS_EXIST: "BLOCK_SLOT_BOOKINGS_EXIST",
    BOOK_SLOT_SUCCEEDED: "BOOK_SLOT_SUCCEEDED",
    UPDATE_SLOT_SUCCEEDED: "UPDATE_SLOT_SUCCEEDED",

    SALES_ORDER_NOT_FOUND: "SALES_ORDER_NOT_FOUND",
    PRODUCTION_ORDERS_NOT_FOUND: "PRODUCTION_ORDERS_NOT_FOUND",
    PRODUCTS_NOT_FOUND: "PRODUCTS_NOT_FOUND",
    SLOT_NOT_FOUND: "SLOT_NOT_FOUND",

    WRITTEN_TO_CLIPBOARD: "WRITTEN_TO_CLIPBOARD",

    NOT_TRUCK_BLEND_BOOKING_TYPE: "NOT_TRUCK_BLEND_BOOKING_TYPE",

    ALREADY_BOOKING_SALES_ORDER: "ALREADY_BOOKING_SALES_ORDER"
};
