import { Divider } from "antd";
import type { Dayjs } from "dayjs";
import * as React from "react";
import { IOrder } from "../../../../../../types/order.types";
import { allowedToUpdatePriority, products } from "../../../../../../utils/order";
import { DateSection } from "./sections/DateSection";
import { InfoSection } from "./sections/InfoSection";
import { ITACTSection } from "./sections/ITACTSection";
import { PaidStorageSection } from "./sections/PaidStorageSection";
import { RestSection } from "./sections/RestSection";

interface IProps {
    order: IOrder;
    onDateParkingInSave?: (m: Dayjs | null) => void;
    onPrioritySave?: (m: Dayjs | null) => void;
    savingDateParkingIn: boolean;
    savingPriority: boolean;
    savingDateParkingOut: boolean;
    onDateParkingOutSave?: (m: Dayjs | null) => void;
    createNotification: (notificationType: string) => void;
    isInternalView: boolean;
}

export const OrderInfo = (props: IProps) => {
    const priorityPickerEnabled = allowedToUpdatePriority(props.order.state);
    const productsArr = products(props.order);
    const productsStr = productsArr.length ? productsArr.join(", ") : "-";

    return (
        <>
            <InfoSection
                customer={props.order.cleaningCustomer}
                containerNumber={props.order.container.sapId}
                product={productsStr}
                type={props.order.orderType}
                onPrioritySave={props.onPrioritySave}
                priorityPickerEnabled={priorityPickerEnabled}
                priority={props.order.priority}
                savingPriority={props.savingPriority}
                deliveryId={props.order.deliveryId}
                sapId={props.order.sapId}
                clientOrderRef={props.order.clientOrderRef}
                createNotification={props.createNotification}
                externalViewLinkEnabled={props.isInternalView}
            />

            <Divider/>

            <DateSection
                orderId={props.order.id}
                dateParkingIn={props.order.dateParkingIn}
                dateParkingOut={props.order.dateParkingOut}
                dateCleaned={props.order.dateCleaned}
                dateAvailable={props.order.dateAvailable}
                onDateParkingInSave={props.onDateParkingInSave}
                savingDateParkingIn={props.savingDateParkingIn}
                savingDateParkingOut={props.savingDateParkingOut}
                onDateParkingOutSave={props.onDateParkingOutSave}
            />

            <Divider/>

            <RestSection compartments={props.order.compartments} showRestWeight={props.isInternalView}/>

            <Divider/>

            <ITACTSection itactNeeded={props.order.itactNeeded} editable={false} manualWork={props.order.manualWorkNeeded}/>

            <Divider/>

            <PaidStorageSection paidStorageDays={props.order.storageDays} toplosser={props.order.toplosser} baffles={props.order.baffles} />
        </>
    );
};
