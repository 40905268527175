import * as React from "react";
import { useEffect, useState } from "react";
import { IOrderFilter, IPageRequest, IPageResponse } from "../../../types/api.types";
import { IOrder } from "../../../types/order.types";
import { ExternalViewModal } from "../../components/modals/externalviewmodal/ExternalViewModal";
import { ExternalViewTable } from "../../components/tables/externalviewtable/ExternalViewTable";
import { ICsvDownloadPageLoader } from "../../hooks/pageloaders/useCsvDownloadAllSelectedOrdersCustomerService";
import { useCsvDownloadExternalView } from "../../hooks/pageloaders/useCsvDownloadExternalView";
import { IExternalViewPageLoader, useExternalViewPageLoader } from "../../hooks/pageloaders/useExternalViewPageLoader";

interface IProps {
    uuid: string;
}

export const ExternalViewTableContainer = (props: IProps) => {
    const [clickedOrderId, setClickedOrderId] = useState<number | undefined>();
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const externalViewPageLoader: IExternalViewPageLoader = useExternalViewPageLoader();
    const csvDownload: ICsvDownloadPageLoader = useCsvDownloadExternalView();

    useEffect(() => {
        const pageRequest: IPageRequest<IOrderFilter> = {
            uuid: props.uuid,
            pageNumber: externalViewPageLoader.resource?.page.meta.number || 0,
            filter: {}
        };

        externalViewPageLoader.loadResource(pageRequest);
    }, []);

    const onRow = (record: IOrder) => ({
        onClick: () => {
            setClickedOrderId(record.id);
            setModalVisible(true);
        }
    });

    const onModalClose = () => {
        setModalVisible(false);
        externalViewPageLoader.refresh();
    };

    const resource: IPageResponse<IOrder, IOrderFilter> | null = externalViewPageLoader.resource;
    return (
        <>
            <ExternalViewTable
                csvDownload={(pageRequest: IPageRequest<IOrderFilter>) => csvDownload.requestResource(pageRequest)}
                uuid={props.uuid}
                fetchingPage={externalViewPageLoader.loadingResource}
                fetchPage={externalViewPageLoader.loadResource}
                filter={resource ? resource.filter : undefined}
                sorting={resource ? resource.sorting : undefined}
                page={resource ? resource.page : undefined}
                onRow={onRow}
            />
            <ExternalViewModal
                visible={modalVisible}
                closeModal={onModalClose}
                orderId={clickedOrderId}
                uuid={props.uuid}
            />
        </>);
};
