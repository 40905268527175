import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { applicationVersionGetActionCreator } from "../../../store/actions/applicationVersion.action";
import { APPLICATION_VERSION } from "../../../config/settings";
import "./ApplicationVersion.css";

export const ApplicationVersion = () => {
    const dispatch = useDispatch();
    const [backendVersion, setBackendVersion] = useState("x.x.x");

    const callback = (version: string) => {
        if (version) {
            setBackendVersion(version);
        }
    };

    useEffect(() => {
        dispatch(applicationVersionGetActionCreator({ callback }));
    }, [dispatch]);

    return (
        <div className="version">{`Frontend: ${ APPLICATION_VERSION } | Backend: ${ backendVersion }`}</div>
    );
};