import { Button, Form, Radio } from "antd";
import * as React from "react";
import { useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { WarningModal } from "./WarningModal";

interface IProps {
    onOk: (fields: any) => void;
    prev: () => void;
}

const MESSAGES = defineMessages({
    labelsRemoved: {
        id: "post_radio_form.labels_removed",
        defaultMessage: "(Gevaren) labels & zegels verwijderd?"
    },
    freeForPickup: {
        id: "post_radio_form.free_for_pickup",
        defaultMessage: "Container vrij voor weghalen?"
    },
    cleaningFinished: {
        id: "post_radio_form.cleaning_finished",
        defaultMessage: "Einde reiniging?"
    },
    yes: {
        id: "post_radio_form.yes",
        defaultMessage: "Ja"
    },
    no: {
        id: "post_radio_form.no",
        defaultMessage: "Nee"
    },
    prev: {
        id: "post_radio_form.prev",
        defaultMessage: "Vorige"
    },
    confirm: {
        id: "post_radio_form.confirm",
        defaultMessage: "Bevestig bovenstaande gegevens en beëindig cleaning"
    },
    labelModalTitle: {
        id: "post_radio_form.label_modal_title",
        defaultMessage: "Gelieve gevarenlabels te verwijderen en te bevestigen."
    },
    freeModalTitle: {
        id: "post_radio_form.free_modal_title",
        defaultMessage: "Gelieve de container vrij te maken voor weghalen"
    },
    cleaningModalVisible: {
        id: "post_radio_form.cleaning_modal_visible",
        defaultMessage: "Gelieve de reiniging te beëindigen"
    },
    fieldRequired: {
        defaultMessage: "Alle velden moeten ingevuld zijn.",
        id: "post_radio_form.field_required",
    }
});

export const PostRadioForm = (props: IProps) => {
    const intl = useIntl();
    const [labelModalVisible, setLabelModalVisible] = useState<boolean>(false);
    const [freeModalVisible, setFreeModalVisible] = useState<boolean>(false);
    const [cleaningModalVisible, setCleaningModalVisible] = useState<boolean>(false);
    const [form] = Form.useForm();

    // User is allowed to submit if none of the properties in values is equal to boolean false
    const canSubmit = (values: any) => !(Object.values(values).find(val => val === false) === false);

    const onSubmit = (values: any) => {
        if (!values.labelsRemoved) {
            setLabelModalVisible(true);
        }

        if (!values.freeForPickup) {
            setFreeModalVisible(true);
        }

        if (!values.cleaningFinished) {
            setCleaningModalVisible(true);
        }

        if (canSubmit(values)) {
            props.onOk(values);
        }
    };

    return (
        <div className="flex justify-center align-items-center fill-width">
            <Form onFinish={onSubmit} form={form} layout="vertical">
                <Form.Item
                    name="labelsRemoved"
                    label={intl.formatMessage(MESSAGES.labelsRemoved)}
                    rules={[
                        {
                            required: true,
                            message: intl.formatMessage(MESSAGES.fieldRequired)
                        }
                    ]}
                >
                    <Radio.Group>
                        <Radio.Button value={true}>{intl.formatMessage(MESSAGES.yes)}</Radio.Button>
                        <Radio.Button value={false}>{intl.formatMessage(MESSAGES.no)}</Radio.Button>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    name="cleaningFinished"
                    label={intl.formatMessage(MESSAGES.cleaningFinished)}
                    rules={[
                        {
                            required: true,
                            message: intl.formatMessage(MESSAGES.fieldRequired)
                        }
                    ]}
                >
                    <Radio.Group>
                        <Radio.Button value={true}>{intl.formatMessage(MESSAGES.yes)}</Radio.Button>
                        <Radio.Button value={false}>{intl.formatMessage(MESSAGES.no)}</Radio.Button>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    name="freeForPickup"
                    label={intl.formatMessage(MESSAGES.freeForPickup)}
                    rules={[
                        {
                            required: true,
                            message: intl.formatMessage(MESSAGES.fieldRequired)
                        }
                    ]}
                >
                    <Radio.Group>
                        <Radio.Button value={true}>{intl.formatMessage(MESSAGES.yes)}</Radio.Button>
                        <Radio.Button value={false}>{intl.formatMessage(MESSAGES.no)}</Radio.Button>
                    </Radio.Group>
                </Form.Item>

                <div className="margin-top fill-width flex flex-row">
                    <Form.Item>
                        <Button onClick={props.prev}>
                            {intl.formatMessage(MESSAGES.prev)}
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="margin-half-left">
                            {intl.formatMessage(MESSAGES.confirm)}
                        </Button>
                    </Form.Item>
                </div>
            </Form>
            <WarningModal visible={labelModalVisible} message={intl.formatMessage(MESSAGES.labelModalTitle)} onOk={() => setLabelModalVisible(false)}/>
            <WarningModal visible={freeModalVisible} message={intl.formatMessage(MESSAGES.freeModalTitle)} onOk={() => setFreeModalVisible(false)}/>
            <WarningModal visible={cleaningModalVisible} message={intl.formatMessage(MESSAGES.cleaningModalVisible)} onOk={() => setCleaningModalVisible(false)}/>
        </div>
    );
};
