import { useEffect } from "react";
import {
    filterSetAllTerminalsAction,
    filterSetAllTypeValuesAction,
    filterSetTerminalAction, filterSetTypeValuesAction
} from "../../store/actions/filter.actions";
import { checkInPageClearFiltersActionCreator } from "../../store/actions/checkIn";
import { Action } from "redux";

interface IProps {
 dispatch : (action: Action) => void
}

export const UseClearFiltersOnDestroy = (props: IProps) => {

    useEffect(() => {
        return () => {
            props.dispatch(filterSetAllTerminalsAction(false));
            props.dispatch(filterSetTerminalAction([]));
            props.dispatch(filterSetAllTypeValuesAction(false));
            props.dispatch(filterSetTypeValuesAction([]));
            props.dispatch(checkInPageClearFiltersActionCreator());
        };
    }, []);
};
