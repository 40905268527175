import * as React from "react";
import { useState } from "react";
import { notification, Upload } from "antd";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { InboxOutlined } from "@ant-design/icons";
import { UploadChangeParam } from "antd/lib/upload/interface";

const { Dragger } = Upload;

const MESSAGES = defineMessages({
    successUpload: {
        id: "upload_blacklist_area.success",
        defaultMessage: "De CSV is succesvol verwerkt in de database.",
    },
    uploading: {
        id: "upload_blacklist_area.info",
        defaultMessage: "De CSV wordt nu verwerkt door het systeem.",
    },
    errorUpload: {
        id: "upload_blacklist_area.error",
        defaultMessage: "Er is iets misgegaan met het verwerken van de CSV. Probeer opnieuw.",
    },
});

interface IProps {
    formattedMessage: string;
    action: string;
}

export const UploadArea = (props: IProps) => {
    const idToken = localStorage.getItem("msal.idtoken");
    const intl = useIntl();
    const [isUploadingTriggered, setUploadingTriggered] = useState(false);

    const onChange = (info: UploadChangeParam) => {
        const { status } = info.file;

        switch (status) {
        case "done":
            notification.success({
                message: intl.formatMessage(MESSAGES.successUpload),
                placement: "bottomRight"
            });

            setUploadingTriggered(false);
            break;
        case "uploading":
            if (!isUploadingTriggered) {
                notification.info({
                    message: intl.formatMessage(MESSAGES.uploading),
                    placement: "bottomRight"
                });
                setUploadingTriggered(true);
            }
            break;
        case "error":
            notification.error({
                message: intl.formatMessage(MESSAGES.errorUpload),
                placement: "bottomRight"
            });

            setUploadingTriggered(false);
            break;
        default:
        }
    };

    return (
        <Dragger
            onChange={onChange}
            showUploadList={false}
            method="POST"
            multiple={false}
            name="file"
            action={props.action}
            headers={{ Authorization: `Bearer ${idToken}` }}
        >
            <p className="ant-upload-drag-icon">
                <InboxOutlined />
            </p>
            <p className="ant-upload-text">
                <FormattedMessage id="upload_area.title" defaultMessage="Klik of sleep een bestand naar dit gebied om te uploaden" />
            </p>
            <p className="ant-upload-hint">
                {props.formattedMessage}
            </p>
        </Dragger>
    );
};
