import { DatePicker } from "antd";
//eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { RangePickerValue } from "antd/es/date-picker/interface";
import * as React from "react";
import { useState } from "react";
import { FilterFooter } from "./FilterFooter";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

interface IProps {
    closeFilter: (() => void) | undefined;
    onFilter: (startDate?: string, endDate?: string) => void;
    placeholder?: string;
    from?: string;
    until?: string;
    format?: string;
}

export const DateRangeFilter = (props: IProps) => {
    const [startDate, setStartDate] = useState<Dayjs>(dayjs(props.from));
    const [endDate, setEndDate] = useState<Dayjs>(dayjs(props.until));

    const onChange = (dates: RangePickerValue) => {
        setStartDate(dates[0]);
        setEndDate(dates[1]);
    };

    const onFilter = () => {
        const utcStartString = startDate ? dayjs.utc(startDate).format() : undefined;
        const utcEndString = endDate ? dayjs.utc(endDate).format() : undefined;

        props.onFilter(utcStartString, utcEndString);
        props.closeFilter && props.closeFilter();
    };

    return (
        <div className="padded">
            <DatePicker.RangePicker
                allowClear
                showTime
                className="margin-half-bottom"
                onChange={onChange}
                value={[startDate, endDate]}
                format={props.format}
            />
            <FilterFooter onCancel={props.closeFilter} onFilter={onFilter}/>
        </div>
    );
};
