import { Button } from "antd";
import TextArea from "antd/es/input/TextArea";
import * as React from "react";
import { SyntheticEvent, useState } from "react";

import { ReactComponent as DisketteIcon } from "../../../../../../../assets/diskette.svg";
import Icon, { LoadingOutlined } from "@ant-design/icons";

interface IProps {
    body?: string;
    onSave?: (msg: string) => void;
    editable: boolean;
    saving?: boolean;
    onChange?: (msg: string) => void;
}

export const MessageBody = (props: IProps) => {
    const [text, setText] = useState<string | undefined>(props.body);

    const onChange = (e: SyntheticEvent) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const val = e.nativeEvent.target.value;

        setText(val);
        props.onChange && props.onChange(val);
    };

    const onSave = () => {
        if (text === undefined || text === props.body) {
            return;
        }

        props.onSave?.(text);
    };

    return (
        <div className="flex">
            {
                props.editable ?
                    <>
                        <TextArea
                            value={text}
                            onChange={onChange}
                            rows={5}
                            style={{ resize: "none" }}
                            disabled={props.saving}
                        />
                        {
                            props.onSave &&
                            <Button
                                type="primary"
                                disabled={props.body === text || props.saving}
                                onClick={onSave}
                                className="margin-half-left">
                                {
                                    props.saving ?
                                        <LoadingOutlined/>
                                        :
                                        <Icon component={DisketteIcon}/>
                                }
                            </Button>
                        }
                    </>
                    :
                    <TextArea
                        className="readonly"
                        readOnly
                        value={text}
                        onChange={onChange}
                        rows={5}
                        autoSize={false}
                        style={{ resize: "none" }}
                    />
            }
        </div>
    );
};
