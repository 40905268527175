import { Tabs } from "antd";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { PlanningTabBarContainer } from "../containers/PlanningTabBarContainer";
import { CustomerServiceTableContainer } from "../containers/tablecontainers/CustomerServiceTableContainer";
import { AppLayout } from "./AppLayout";

export const CustomerServiceScreen = () => {
    const { TabPane } = Tabs;

    return (
        <AppLayout>
            <Tabs defaultActiveKey="1" destroyInactiveTabPane animated={false}>
                <TabPane tab={<FormattedMessage id="customerservice.tabs.list" defaultMessage="List"/>} key="1">
                    <CustomerServiceTableContainer/>
                </TabPane>
                <TabPane tab={<FormattedMessage id="customerservice.tabs.planing" defaultMessage="Planning"/>} key="2">
                    <PlanningTabBarContainer/>
                </TabPane>
            </Tabs>
        </AppLayout>
    );
};
