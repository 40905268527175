import * as React from "react";
import { AutoComplete, Button, DatePicker, Form, Select, TimePicker } from "antd";
import { defineMessages, useIntl } from "react-intl";
import type { Dayjs } from "dayjs";
import { ITank } from "../../../types/salesOrder.types";
import { ITankBlockParams, ITankListFetchParams } from "../../../types/tank.types";
import { SelectValue } from "antd/lib/select";
import { AUTOCOMPLETE_MINIMUM_LENGTH } from "../../../constants/autocomplete";
import { TANK_BLOCKING_REASONS } from "../../../constants/blockingReasons";
import _ from "lodash";
import { TIME_FORMATS } from "../../../constants/time";

interface IProps {
    blockTank: (id: number, tankBlockParams: ITankBlockParams) => void;
    tankList: ITank[];
    fetchTankList: (tankListFetch: ITankListFetchParams) => void;
}

const MESSAGES = defineMessages({
    date: {
        id: "tank_blocking_section.date",
        defaultMessage: "Datum",
    },
    startTime: {
        id: "tank_blocking_section.start_time",
        defaultMessage: "Starttijd",
    },
    endTime: {
        id: "tank_blocking_section.end_time",
        defaultMessage: "Stoptijd",
    },
    reason: {
        id: "tank_blocking_section.reason",
        defaultMessage: "Reden",
    },
    tank: {
        id: "tank_blocking_section.tank",
        defaultMessage: "Tank",
    },
    dateRequired: {
        id: "tank_blocking_section.date_required",
        defaultMessage: "Selecteer een datum"
    },
    timeRequired: {
        id: "tank_blocking_section.time_required",
        defaultMessage: "Selecteer een tijdstip"
    },
    reasonRequired: {
        id: "tank_blocking_section.reason_required",
        defaultMessage: "Selecteer een reden"
    },
    tankRequired: {
        id: "tank_blocking_section.tank_required",
        defaultMessage: "Zoek een tank",
    },
    blockPeriod: {
        id: "tank_blocking_section.block_period",
        defaultMessage: "Blokkeer periode"
    },
    startBeforeEnd: {
        id: "tank_blocking_section.start_before_end",
        defaultMessage: "Eind tijd moet na start tijd zijn"
    },
});

export const TankBlockingForm = (props: IProps) => {
    const [form] = Form.useForm();
    const intl = useIntl();

    const onSubmit = (values: any) => {
        const fromDate = values.orderDate
            .clone()
            .set({
                hour: values.startTime.hour(),
                minute: values.startTime.minute(),
                second: values.startTime.second(),
                millisecond: 0
            });

        const untilDate = values.orderDate
            .clone()
            .set({
                hour: values.endTime.hour(),
                minute: values.endTime.minute(),
                second: values.endTime.second(),
                millisecond: 0
            });
        const id = parseInt(values.tank);
        const tankBlockParams: ITankBlockParams = {
            fromDate: fromDate,
            untilDate: untilDate,
            reason: values.reason
        };

        props.blockTank(id, tankBlockParams);
    };

    const onChange = (value: SelectValue) => {
        if (value && value.toString().length >= AUTOCOMPLETE_MINIMUM_LENGTH.TANK) {
            const fetchParams: ITankListFetchParams = {
                name: value.toString()
            };
            props.fetchTankList(fetchParams);
        }
    };

    const renderOption = (item: ITank) => {
        return (
            <AutoComplete.Option key={item.id} value={item.id}>
                {item.name}
            </AutoComplete.Option>
        );
    };

    const timeValidator = (rule: any, value: any, callback: (s?: string) => void) => {
        const startTime: Dayjs = form.getFieldValue("startTime");
        const endTime: Dayjs = form.getFieldValue("endTime");

        if (startTime && endTime && startTime.isAfter(endTime)) {
            callback(intl.formatMessage(MESSAGES.startBeforeEnd));
        }

        callback();
    };

    return (
        <div>
            <Form onFinish={onSubmit} layout="vertical" autoComplete="off" form={form}>
                <div className="space-evenly">
                    <Form.Item
                        name="orderDate"
                        label={intl.formatMessage(MESSAGES.date)}
                        className="inline-block margin-right"
                        rules={[{
                            required: true,
                            message: intl.formatMessage(MESSAGES.dateRequired)
                        }]}
                    >
                        <DatePicker/>
                    </Form.Item>

                    <Form.Item
                        name="startTime"
                        label={intl.formatMessage(MESSAGES.startTime)}
                        className="inline-block margin-right"
                        rules={[{
                            required: true,
                            message: intl.formatMessage(MESSAGES.timeRequired)
                        },
                        {
                            validator: timeValidator
                        }
                        ]}
                    >
                        <TimePicker format={TIME_FORMATS.TIME} minuteStep={15}/>
                    </Form.Item>

                    <Form.Item
                        label={intl.formatMessage(MESSAGES.endTime)}
                        className="inline-block"
                        name="endTime"
                        rules={[{
                            required: true,
                            message: intl.formatMessage(MESSAGES.timeRequired)
                        },
                        {
                            validator: timeValidator
                        }
                        ]}
                    >
                        <TimePicker format={TIME_FORMATS.TIME} minuteStep={15}/>
                    </Form.Item>
                </div>
                <div className="space-evenly">
                    <Form.Item
                        name="tank"
                        label={intl.formatMessage(MESSAGES.tank)}
                        className="inline-block margin-right"
                        rules={[{
                            required: true,
                            message: intl.formatMessage(MESSAGES.tankRequired)
                        }]}
                    >
                        <AutoComplete
                            placeholder={intl.formatMessage(MESSAGES.tankRequired)}
                            dataSource={props.tankList.map(renderOption)}
                            onChange={onChange}
                        />
                    </Form.Item>

                    <Form.Item
                        label={intl.formatMessage(MESSAGES.reason)}
                        className="inline-block width-40-percent"
                        name="reason"
                        rules={[{
                            required: true,
                            message: intl.formatMessage(MESSAGES.reasonRequired)
                        }]}
                    >
                        <Select placeholder={intl.formatMessage(MESSAGES.reasonRequired)}>
                            {_.keys(TANK_BLOCKING_REASONS).map(key => (
                                <Select.Option key={key} value={key}>
                                    {intl.formatMessage(TANK_BLOCKING_REASONS[key])}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </div>
                <Form.Item>
                    <Button htmlType="submit">
                        {intl.formatMessage(MESSAGES.blockPeriod)}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};
