import { combineReducers, createReducer } from "@reduxjs/toolkit";
import { ISelfCheckInSettingsResponse } from "../../types/selfCheckIn.types";
import { settingsSetActionCreator, } from "../actions/selfCheckInSettings.actions";

export interface ISettingsState {
    availableSettings: ISelfCheckInSettingsResponse[];
}

export const settings = createReducer<ISelfCheckInSettingsResponse[]>([], {
    [settingsSetActionCreator.type]: (settings, action) => action.payload,
});

export const settingsReducer = combineReducers<ISettingsState>({
    availableSettings: settings,
});