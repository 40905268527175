import { Steps } from "antd";
import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import { Box } from "../../../../../Box";
import { InfoLabel } from "../../../../../modals/customerservicemodal/panes/detailinfopane/sections/infofields/InfoLabel";

interface IProps {
    steps: string[];
    className?: string;
}

const MESSAGES = defineMessages({
    cleaningProcedure: {
        id: "compartment_info.cleaning_procedure",
        defaultMessage: "Cleaning procedure"
    }
});

export const CleaningProcedureList = (props: IProps) => {
    const intl = useIntl();

    return (
        <div className={props.className}>
            <InfoLabel text={intl.formatMessage(MESSAGES.cleaningProcedure)}/>
            <Box className="margin-half-top">
                <Steps direction="vertical">
                    {
                        props.steps
                            .filter(step => step)
                            .map((step, index) => <Steps.Step status="wait" title={step} key={step + index}/>)
                    }
                </Steps>
            </Box>
        </div>
    );
};
