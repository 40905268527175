import * as React from "react";
import Icon from "@ant-design/icons";
import { CustomIconComponentProps } from "@ant-design/icons/es/components/Icon";

type Props = {
  icon?: React.ComponentType<CustomIconComponentProps | React.SVGProps<SVGSVGElement>>;
  text: string;
};

export const MenuItemTitle = (props: Props) => (
    <span>
        {props.icon && <Icon component={props.icon}/>}
        <span>{props.text}</span>
    </span>
);
