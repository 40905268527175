import { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { selfCheckInSettingsApi } from "../api/selfCheckInSettings.api";
import {
    settingsFetchActionCreator,
    settingsFetchFailedActionCreator,
    settingsSetActionCreator,
    settingsUpdateActionCreator
} from "../actions/selfCheckInSettings.actions";
import { errorHandler } from "./errorHandler";

export function* fetchSettings() {
    const response: AxiosResponse = yield call(selfCheckInSettingsApi.getSettings);
    yield put(settingsSetActionCreator(response.data));
}

export function* updateSetting(action: ReturnType<typeof settingsUpdateActionCreator>) {
    yield all(action.payload.data.map((settingUpdate) => call(selfCheckInSettingsApi.putSetting, settingUpdate.parameter)));
    yield fetchSettings();
    action.payload.callback();
}

export function* selfCheckInSettingsSaga() {
    yield takeLatest(settingsFetchActionCreator.type, errorHandler(fetchSettings, settingsFetchFailedActionCreator));
    yield takeLatest(settingsUpdateActionCreator.type, errorHandler(updateSetting, settingsFetchFailedActionCreator));
}