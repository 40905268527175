import * as React from "react";
import { useDispatch } from "react-redux";
import { checklistStepSubmitActionCreator } from "../../../store/actions/checklist";
import { IChecklistEntryStepSubmit } from "../../../types/api.types";
import { ChecklistItem } from "../../../types/checklist.types";
import { ContainerNumberForm } from "../../components/tabbars/cleaningtabs/cleaningtab/compartmenttab/checklist/forms/ContainerNumberForm";

interface IProps {
    containerLocation?: string;
    containerNumber: string;
    checklistId: number;
    prev: () => void;
    compartmentId: number;
}

export const ContainerNumberFormContainer = (props: IProps) => {
    const dispatch = useDispatch();

    const submit = (error: boolean, fields: string) => {
        const checklistEntryStepSubmit: IChecklistEntryStepSubmit = {
            errorDetected: error,
            stepOrder: 0,
            checklistItem: ChecklistItem.PRE_OPERATION_CHECKLIST,
            formFields: fields,
            restFound: false,
            manualWorkNeeded: false,
        };

        dispatch(checklistStepSubmitActionCreator({
            checklistId: props.checklistId,
            checklistEntryStepSubmit,
            compartmentId: props.compartmentId
        }));
    };

    const onOk = (fields: string) => {
        submit(false, fields);
    };

    return (
        <ContainerNumberForm
            containerNumber={props.containerNumber}
            onOk={onOk}
            prev={props.prev}
            containerLocation={props.containerLocation}
        />
    );
};
