import * as React from "react";
import { useSelector } from "react-redux";
import { IApplicationState } from "../../../../../../../store/state";
import { IChecklist } from "../../../../../../../types/checklist.types";
import { ICompartment, IOrder, State } from "../../../../../../../types/order.types";
import { checklistContainsError, checklistFinished, getLatestChecklist } from "../../../../../../../utils/checklist";
import { Box } from "../../../../../Box";
import { ChecklistForm } from "./ChecklistForm";
import { ChecklistSteps } from "./ChecklistSteps";

interface IProps {
    className?: string;
    compartment: ICompartment;
    order: IOrder;
    checklists: IChecklist[];
    fetchingChecklists: boolean;
}

export const Checklist = (props: IProps) => {
    const submitting = useSelector((state: IApplicationState) => state.checklist.submitting);
    const latestChecklist = getLatestChecklist(props.checklists);

    const checklistState = props.order.state === State.READY_FOR_CLEANING || props.order.state === State.CLEANING;
    const checklistEnabled =
        latestChecklist &&
        !checklistContainsError(latestChecklist) &&
        !checklistFinished(latestChecklist) &&
        checklistState;

    return (
        <Box className={props.className}>
            <div className="flex flex-row flex-start align-items-stretch">
                <div>
                    <ChecklistSteps checklist={latestChecklist} disabled={!checklistEnabled}/>
                </div>
                {
                    !submitting && !props.fetchingChecklists && latestChecklist && checklistEnabled &&
                    <div className="margin-left flex justify-center align-items-center" style={{ flexGrow: 2 }}>
                        <ChecklistForm
                            compartment={props.compartment}
                            order={props.order}
                            checklist={latestChecklist}
                        />
                    </div>
                }
            </div>
        </Box>
    );
};

