import * as React from "react";
import { useEffect } from "react";
import { isAuthorized } from "../../../security/authorization";
import { useAuthentication } from "../../hooks/useAuthentication";
import { Paths, ROLES } from "../../../types/api.types";
import { ForbiddenScreen } from "../../screens/ForbiddenScreen";
import { defineMessages, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

export interface IProps {
    roles: string[];
    children?: React.ReactNode;
}

export const RoleViewer: React.FC<IProps> = (props) => {
    const [authentication] = useAuthentication();
    const roles = authentication.roles || [];
    return isAuthorized(roles, props.roles) ? <>{props.children}</> : <ForbiddenScreen/>;
};

export const MESSAGES = defineMessages({
    error: {
        id: "error.no_roles",
        defaultMessage: "Het account waar u mee bent ingelogd heeft geen rechten voor deze applicatie."
    },
});

export const DefaultPageRoleViewer = () => {
    const [authentication] = useAuthentication();
    const roles = authentication.roles || [];
    const navigate = useNavigate();
    const intl = useIntl();

    const containsCleaning = roles.indexOf(ROLES.CLEANING) > -1;
    const containsCheckIn = roles.indexOf(ROLES.CHECKIN) > -1;
    const containsAdmin = roles.indexOf(ROLES.ADMIN) > -1;

    useEffect(() => {
        if (containsCleaning) {
            navigate(Paths.CUSTOMER_SERVICE);
            return;
        }

        if (containsCheckIn) {
            navigate(Paths.CHECKIN_OVERVIEW);
            return;
        }

        if (containsAdmin) {
            navigate(Paths.SETTINGS);
            return;
        }
    }, []);

    return <p>{intl.formatMessage(MESSAGES.error)}</p>;
};
