import { createReducer } from "@reduxjs/toolkit";
import { salesOrderListReceivedCreator, salesOrderReceivedCreator } from "../actions";
import { ISalesOrder } from "../../types/salesOrder.types";

export const salesOrderReducer = createReducer<ISalesOrder | null>(null, {
    [salesOrderReceivedCreator.type]: (salesOrder, action) => action.payload,
});

export const salesOrderListReducer = createReducer<ISalesOrder[]>([], {
    [salesOrderListReceivedCreator.type]: (salesOrderList, action) => action.payload,
});
