import * as React from "react";
import { useEffect, useState } from "react";
import { ICheckIn } from "../../types/checkIn.types";
import { CheckInDetailModal } from "../components/modals/checkInDetailModal/CheckInDetailModal";
import { CleaningModal } from "../components/modals/cleaningmodal/CleaningModal";
import { CheckInModalFooter } from "../components/modals/checkInDetailModal/CheckInModalFooter";
import { CheckInDetailCancelModalContainer } from "../components/modals/checkInDetailModal/CheckInDetailCancelModalContainer";
import { useCheckIn } from "../hooks/useCheckIn";
import { defineMessages, useIntl } from "react-intl";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { checkInShowLinkSalesOrderModalSelector } from "../../store/selectors/checkInOverview.selector";
import { LinkSalesOrderModal } from "../components/modals/checkInDetailModal/LinkSalesOrderModal";
import {
    checkInReturnToKioskActionCreator,
    checkInSetSalesOrderIdForLinkActionCreator,
    checkInSetViewSalesOrderLinkModalSetManualOrderIdActionCreator
} from "../../store/actions/checkIn";
import { CheckInDetailDeleteModalContainer } from "../components/modals/checkInDetailModal/CheckInDetailDeleteModalContainer";
import { AxiosResponse } from "axios";

interface IProps {
    visible: boolean;
    closeModal: () => void;
    checkIn: ICheckIn;
}

const MESSAGES = defineMessages({
    genericError: {
        id: "checkin.detail.modal.container.generic_error",
        defaultMessage: "Er is een fout opgetreden met het valideren van deze check-in. Kijk de badge nummer na."
    }
});

export const CheckInDetailModalContainer = (props: IProps) => {
    const { visible } = props;
    const checkInHook = useCheckIn();
    const dispatch = useDispatch();
    const intl = useIntl();
    const showLinkManualSalesOrderModal = useSelector(checkInShowLinkSalesOrderModalSelector);

    const [loaded, setLoading] = useState<boolean | null>(null);
    const [cancelModalVisible, setCancelModalVisible] = useState<boolean>(false);
    const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);

    const checkIn = checkInHook.resource;

    const onLoaded = (success: boolean) => {
        setLoading(success);
    };

    const closeModal = () => {
        checkInHook.clear();
        setLoading(null);
        dispatch(checkInSetSalesOrderIdForLinkActionCreator({ salesOrderId: null }));
        props.closeModal();
    };

    /**
     * Cancel modal event handlers
     */
    const onOpenCancel = () => {
        setCancelModalVisible(true);
    };

    const onCancelClose = () => {
        setCancelModalVisible(false);
    };

    const onOpenDelete = () => {
        setDeleteModalVisible(true);
    };

    const onCloseDelete = () => {
        setDeleteModalVisible(false);
        closeModal();
    };

    const onFailureValidate = (code: number, response: AxiosResponse<any>) => {
        if (code === 422 && response.data && response.data.length > 0) {
            return response.data.map((errMessage: string) => message.error(errMessage));
        }
        return message.error(intl.formatMessage(MESSAGES.genericError));
    };

    useEffect(() => {
        if (visible) {
            checkInHook.loadResource(props.checkIn.id, onLoaded);
        }
    }, [visible]);

    const onCloseLinkModal = () => {
        dispatch(checkInSetViewSalesOrderLinkModalSetManualOrderIdActionCreator(null));
    };

    return (
        <>
            <CleaningModal closable open={props.visible && !showLinkManualSalesOrderModal} closeModal={closeModal} width="90%" footer={
                <CheckInModalFooter
                    onDelete={onOpenDelete}
                    onCancel={onOpenCancel}
                    onClose={closeModal}
                    onValidate={() => checkInHook.onValidate(onFailureValidate)}
                    onAssign={checkInHook.onAssign}
                    onUnAssign={() => checkInHook.onUnAssign(closeModal)}
                    onFinish={checkInHook.onFinish}
                    onReopen={checkInHook.onReopen}
                    onReturnToKiosk={() => checkIn && dispatch(checkInReturnToKioskActionCreator({ checkinId: checkIn.id }))}
                    checkIn={checkIn}
                />
            }>
                {loaded && checkIn && <CheckInDetailModal visible={visible} checkIn={checkIn} />}
                {loaded && checkIn && (
                    <CheckInDetailCancelModalContainer
                        closeModal={onCancelClose}
                        visible={cancelModalVisible}
                        checkIn={checkIn}
                    />
                )}
                {loaded && checkIn && (
                    <CheckInDetailDeleteModalContainer
                        closeModal={onCloseDelete}
                        visible={deleteModalVisible}
                        checkIn={checkIn}
                    />
                )}

                {loaded === false ? <p>Failed to load record</p> : null}
                {loaded === null ? <p>loading</p> : null}
            </CleaningModal>
            <LinkSalesOrderModal
                manualOrderId={showLinkManualSalesOrderModal}
                onClose={() => onCloseLinkModal()}
                activeCheckIn={checkIn as ICheckIn}
            />
        </>
    );
};
