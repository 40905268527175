import { Col, Row } from "antd";
import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import { Checkbox } from "../../../../../forms/inputs/Checkbox";
import { BoolInfoField } from "./infofields/BoolInfoField";
import { InfoLabel } from "./infofields/InfoLabel";

interface IProps {
    itactNeeded: boolean;
    manualWork?: boolean;
    editable: boolean;
    onItactNeededSave?: (itactNeeded: boolean) => void;
    savingItactNeeded?: boolean;
}

const MESSAGES = defineMessages({
    itactNeeded: {
        id: "itact_section.itact_needed",
        defaultMessage: "ITACT vereist",
    },
    manualWork: {
        id: "itact_section.manualWork",
        defaultMessage: "Manueel werk",
    }
});

export const ITACTSection = (props: IProps) => {
    const intl = useIntl();

    return (
        <Row>
            <Col span={12}>
                <InfoLabel text={intl.formatMessage(MESSAGES.itactNeeded)}/>
                {
                    props.editable && props.onItactNeededSave ?
                        <Checkbox
                            defaultValue={props.itactNeeded}
                            onSave={props.onItactNeededSave}
                            saving={props.savingItactNeeded || false}
                        /> :
                        <BoolInfoField value={props.itactNeeded}/>
                }
            </Col>
            <Col span={12}>
                <InfoLabel text={intl.formatMessage(MESSAGES.manualWork)}/>
                <BoolInfoField value={props.manualWork}/>
            </Col>
        </Row>
    );
};
