import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
// import { BookingType } from "../../types/salesOrder.types";
// import { isTruckBlendOrder } from "../../utils/order";
import {
    salesOrderFetchCreator,
    salesOrderFetchFailedCreator,
    salesOrderListFetchCreator,
    salesOrderListFetchFailedCreator,
    salesOrderListReceivedCreator,
    salesOrderReceivedCreator
} from "../actions";
import { errorHandler } from "./errorHandler";
import { salesOrderApi } from "../api/salesOrder";
import { showNotificationActionCreator } from "../actions/notifications";
import { NOTIFICATION_TYPES } from "../../constants/notification";
import { AxiosResponse } from "axios";
import { ISalesOrder } from "../../types/salesOrder.types";

export function* fetchSalesOrder(action: ReturnType<typeof salesOrderFetchCreator>) {
    const response: AxiosResponse<ISalesOrder | null> = yield call(salesOrderApi.getSalesOrder, action.payload.reference);

    if (response && response.data) {
        // const productionOrders = response.data["productionOrders"];

        // if (action.payload.bookingType !== BookingType.TRUCK_BLEND && isTruckBlendOrder(response.data)) {
        //     yield put(showNotificationActionCreator(NOTIFICATION_TYPES.NOT_TRUCK_BLEND_BOOKING_TYPE));
        //     yield put(salesOrderReceivedCreator(null));
        //     return;
        // } else if (productionOrders.length == 0) {
        //     yield put(showNotificationActionCreator(NOTIFICATION_TYPES.PRODUCTION_ORDERS_NOT_FOUND));
        // } else if (productionOrders[0].salesProduct == null) {
        //     yield put(showNotificationActionCreator(NOTIFICATION_TYPES.PRODUCTS_NOT_FOUND));
        // }
    }

    yield put(salesOrderReceivedCreator(response.data));
}

export function* fetchSalesOrderList(action: ReturnType<typeof salesOrderListFetchCreator>) {
    const response: AxiosResponse<ISalesOrder[]> = yield call(salesOrderApi.getSalesOrderList, action.payload.salesOrderListFetchParams);
    yield put(salesOrderListReceivedCreator(response.data));
}

export function* showNotification() {
    yield put(showNotificationActionCreator(NOTIFICATION_TYPES.SALES_ORDER_NOT_FOUND));
}

export function* salesOrderSaga() {
    yield takeLatest(salesOrderFetchCreator.type, errorHandler(fetchSalesOrder, salesOrderFetchFailedCreator));
    yield takeLatest(salesOrderListFetchCreator.type, errorHandler(fetchSalesOrderList, salesOrderListFetchFailedCreator));
    yield takeEvery(salesOrderFetchFailedCreator.type, errorHandler(showNotification));
}
