import { Steps } from "antd";
import React from "react";
import { useIntl } from "react-intl";
import { ChecklistItem, IChecklist, IChecklistEntry } from "../../../../../../../types/checklist.types";
import { checklistEntryContainsError, currentChecklistIndex } from "../../../../../../../utils/checklist";
import { formatChecklistItem, optionalFormatIso8601DateTime } from "../../../../../../../utils/format";
import { Box } from "../../../../../Box";

interface IProps {
    checklist?: IChecklist;
    disabled: boolean;
}

export const ChecklistSteps = (props: IProps) => {
    const intl = useIntl();
    const checklistEntries: IChecklistEntry[] = props.checklist ? props.checklist.entries : [];

    const renderSteps = (checklist: IChecklistEntry[]) => {
        const steps = [];

        for (const checklistItem in ChecklistItem) {
            const checklistEntry = checklist.find(checklistEntry => checklistEntry.checklistItem === checklistItem);
            const description = checklistEntry && optionalFormatIso8601DateTime(checklistEntry.completedOn);

            steps.push(
                <Steps.Step
                    title={formatChecklistItem(ChecklistItem[checklistItem as ChecklistItem], intl)}
                    description={description}
                    key={checklistItem}
                    disabled={props.disabled}
                    status={checklistEntry && checklistEntryContainsError(checklistEntry) ? "error" : undefined}
                />
            );
        }

        return steps;
    };

    return (
        <Box>
            <Steps direction="vertical" current={currentChecklistIndex(checklistEntries)} status={props.disabled ? "wait" : undefined}>
                {
                    renderSteps(checklistEntries)
                }
            </Steps>
        </Box>
    );
};
