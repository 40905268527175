import * as React from "react";
import { useEffect } from "react";
import { itactOrderUpdateItactNeededDoneActionCreator } from "../../store/actions/itact";
import { ItactOrderInfo } from "../components/modals/itactmodal/panes/detailinfopane/ItactOrderInfo";
import { useOrder } from "../hooks/useOrder";
import { useOrderUpdate } from "../hooks/useOrderUpdate";

interface IProps {
    orderId: number;
}

export const ItactOrderInfoContainer = (props: IProps) => {
    const [order,, loadOrder] = useOrder();
    const [update, ordersBeingsSaved] = useOrderUpdate(itactOrderUpdateItactNeededDoneActionCreator);

    useEffect(() => {
        loadOrder(props.orderId);
    }, []);

    const onItactNeededSave = (itactNeeded: boolean) => {
        update(props.orderId, { itactNeeded });
    };

    const itactNeededSaving = ordersBeingsSaved[props.orderId] && !!ordersBeingsSaved[props.orderId].itactNeeded;

    return (
        order &&
            <ItactOrderInfo
                order={order}
                onItactNeededSave={onItactNeededSave}
                itactNeededSaving={itactNeededSaving}
                manualWork={order.manualWorkNeeded}
            />
    );
};
