import { Layout } from "antd";
import * as React from "react";
import { TopBar } from "../components/topbar/TopBar";

type Props = {
    children?: React.ReactNode;
};

export const NoSidebarLayout = (props: Props) => {
    const { Header, Content } = Layout;

    return (
        <Layout className="full-page" hasSider>
            <Layout>
                <Header className="no-padding">
                    <TopBar/>
                </Header>
                <Content className="padded scroll" style={{ backgroundColor: "white" }}>{props.children}</Content>
            </Layout>
        </Layout>
    );
};
