import { combineReducers, createReducer } from "@reduxjs/toolkit";
import { IOrderFilter, IPageResponse } from "../../types/api.types";
import { IOrder } from "../../types/order.types";
import { externalViewPageFetchFailedActionCreator, externalViewPageLoadActionCreator, externalViewPageReceivedActionCreator } from "../actions/externalView";

export interface IExternalViewState {
    page: IPageResponse<IOrder, IOrderFilter> | null;
    loadingPage: boolean;
}

const pageReducer = createReducer<IPageResponse<IOrder, IOrderFilter> | null>(null, (builder) => {
    builder.addCase(externalViewPageReceivedActionCreator, (state, action) => {
        return action.payload;
    });
});

const loadingPageReducer = createReducer<boolean>(false, (builder) => {
    builder.addCase(externalViewPageLoadActionCreator, () => true);
    builder.addCase(externalViewPageReceivedActionCreator, () => false);
    builder.addCase(externalViewPageFetchFailedActionCreator, () => false);
});

export const externalViewReducer = combineReducers<IExternalViewState>({
    page: pageReducer,
    loadingPage: loadingPageReducer
});
