import { PayloadAction } from "@reduxjs/toolkit";
import * as React from "react";
import { useIntl } from "react-intl";
import { IOrderFilter, IPage, IPageRequest, ISort } from "../../../../types/api.types";
import { IOrder } from "../../../../types/order.types";
import { MainOrderTable } from "../MainOrderTable";
import {
    clientOrderRefColumn,
    dateAvailableColumn,
    dateCleanedColumn,
    dateParkingInColumn, dateParkingOutColumn,
    deliveryIdColumn,
    productColumn,
    sapIdColumn,
    stateColumn,
    stateGroupColumn,
    storageDaysColumn
} from "../ordertable/OrderTableColumns";
//eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { TableEventListeners } from "antd/es/table";

interface IProps {
    csvDownload?: (pageRequest: IPageRequest<IOrderFilter>) => void;
    uuid?: string;
    page?: IPage<IOrder>;
    fetchingPage: boolean;
    filter?: IOrderFilter;
    sorting?: ISort;
    fetchPage: (pageRequest: IPageRequest<IOrderFilter>) => PayloadAction<IPageRequest<IOrderFilter>>;
    onRow: (record: IOrder, index: number) => TableEventListeners;
}

export const ExternalViewTable = (props: IProps) => {
    const intl = useIntl();

    const columnFactory = (onFilter: (filter: IOrderFilter) => any) => {
        return [
            clientOrderRefColumn(intl, true, onFilter, props.filter),

            deliveryIdColumn(intl, true, onFilter, props.filter),

            sapIdColumn(intl, onFilter, props.filter),

            productColumn(intl, true, onFilter, props.filter),

            stateGroupColumn(intl, true, onFilter, props.filter),

            stateColumn(intl, true, onFilter, props.filter),

            dateParkingInColumn(intl, true, onFilter, props.filter),

            dateCleanedColumn(intl, true, onFilter, props.filter),

            dateAvailableColumn(intl, true, onFilter, props.filter),

            dateParkingOutColumn(intl, true, onFilter, props.filter),

            storageDaysColumn(intl, true, onFilter, props.filter),
        ];
    };

    return (
        <MainOrderTable
            csvDownloadAllSelectedOrders={props.csvDownload}
            uuid={props.uuid}
            page={props.page}
            fetchingPage={props.fetchingPage}
            filter={props.filter}
            sorting={props.sorting}
            fetchPage={props.fetchPage}
            columnFactory={columnFactory}
            onRow={props.onRow}
        />
    );
};
