import { getHttpClient } from "./httpClient";
import { ITankBlockParams, ITankListFetchParams } from "../../types/tank.types";
import { IScheduledMaintenancesFetchParams } from "../../types/scheduledMaintenance.types";
import { URL_PATHS } from "../../constants/api.constants";

const baseUrl = URL_PATHS.BOOKING + "/tanks/";

const getTankList = (tankListFetch: ITankListFetchParams) => getHttpClient().get(baseUrl, { params: tankListFetch });
const getTank = (tankId: number) => getHttpClient().get(`${baseUrl}${tankId}`);
const block = (tankId: number, tankBlock: ITankBlockParams) => getHttpClient().post(`${baseUrl}${tankId}/scheduled-maintenances`, tankBlock);
const getScheduledMaintenances = (tankId: number, scheduledMaintenancesFechParams: IScheduledMaintenancesFetchParams) => getHttpClient().get(`${baseUrl}${tankId}/scheduled-maintenances`, { params: scheduledMaintenancesFechParams });

export const tankApi = {
    getTankList,
    getTank,
    block,
    getScheduledMaintenances,
};
