import { notification } from "antd";
import { defineMessages, useIntl } from "react-intl";

interface IProps {
    reset: () => void;
}

const MESSAGES = defineMessages({
    notTruckBlendMessage: {
        id: "not_truck_blend.message",
        defaultMessage: "Gelieve truckblend te selecteren"
    }
});

export const NotTruckBlendNotification = (props: IProps) => {
    const intl = useIntl();
    notification.error({
        message: intl.formatMessage(MESSAGES.notTruckBlendMessage),
        onClose: props.reset,
        placement: "bottomRight",
    });

    return null;
};
