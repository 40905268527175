import * as React from "react";
import { MessageBody } from "./MessageBody";
import { MessageHeader } from "./MessageHeader";

interface IProps {
    title: string;
    body?: string;
    timestamp?: string;
    editable: boolean;
    onSave?: (msg: string) => void;
    className?: string;
    saving?: boolean;
    onChange?: (msg: string) => void;
}

export const Message = (props: IProps) => {
    return (
        <div className={props.className}>
            <MessageHeader
                className="margin-half-bottom"
                title={props.title}
                timestamp={props.timestamp}
            />
            <MessageBody
                body={props.body}
                editable={props.editable}
                onSave={props.onSave}
                saving={props.saving}
                onChange={props.onChange}
            />
        </div>
    );
};
