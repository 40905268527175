import { isNil } from "lodash";
import * as React from "react";
import { useIntl } from "react-intl";
import { formatBoolField } from "../../../../../../../../utils/format";
import { InfoField } from "./InfoField";


interface IProps {
    value?: boolean;
    nillable?: boolean;
}

export const BoolInfoField = (props: IProps) => {
    const intl = useIntl();
    const boolFormatter = (val: boolean | undefined) => formatBoolField(val, intl);
    const formatter = props.nillable && isNil(props.value) ? undefined : boolFormatter;

    return <InfoField<boolean> value={props.value} formatter={formatter}/>;
};
