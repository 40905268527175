import * as React from "react";
import { Form, Input } from "antd";
import { defineMessages, useIntl } from "react-intl";

interface IProps {
  handleSubmit: (values: any) => void;
  onTyping: (value: string | null) => void;
}

const MESSAGES = defineMessages({
    reason: {
        id: "check_in_detail_cancel.reason",
        defaultMessage: "Reden"
    },
    reasonPlaceholder: {
        id: "check_in_detail_cancel.reason_placeholder",
        defaultMessage: "Vul je reden in"
    },
    reasonError: {
        id: "check_in_detail_cancel.reason_error",
        defaultMessage: "Geef een reden in."
    }
});
export const CheckInDetailCancelModal = (props: IProps) => {
    const intl = useIntl();
    const [form] = Form.useForm();

    return (
        <Form onFinish={props.handleSubmit} form={form}>
            <Form.Item
                name="reason"
                label={intl.formatMessage(MESSAGES.reason)}
                rules={[
                    {
                        required: true,
                        message: intl.formatMessage(MESSAGES.reasonError),
                    },
                ]}
            >
                <Input.TextArea
                    placeholder={intl.formatMessage(MESSAGES.reasonPlaceholder)}
                    autoSize={{ minRows: 3, maxRows: 10 }}
                    onChange={(e) => props.onTyping(e.target.value)}
                />
            </Form.Item>
        </Form>
    );
};
