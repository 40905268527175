import { createAction } from "@reduxjs/toolkit";
import { IFailedActionPayload, IOrderUpdateActionPayload } from "../../types/action.types";
import { IOrderFilter, IPageRequest, IPageResponse } from "../../types/api.types";
import { IOrder } from "../../types/order.types";

enum ExternalViewActionTypes {
    EXTERNAL_VIEW_PAGE_LOAD = "[ExternalView] PAGE_LOAD",
    EXTERNAL_VIEW_PAGE_FETCH = "[ExternalView] PAGE_FETCH",
    EXTERNAL_VIEW_PAGE_RECEIVED = "[ExternalView] PAGE_RECEIVED",
    EXTERNAL_VIEW_PAGE_FETCH_FAILED = "[ExternalView] PAGE_FETCH_FAILED",

    EXTERNAL_VIEW_PAGE_ORDER_UPDATE_DONE = "[ExternalView] PAGE_ORDER_UPDATE_DONE",

    EXTERNAL_VIEW_TRANSITIONS_FETCH = "[ExternalView] TRANSITIONS_FETCH",

    EXTERNAL_VIEW_CSV_DOWNLOAD = "[ExternalView] EXTERNAL_VIEW_CSV_DOWNLOAD",
}

export const externalViewPageLoadActionCreator = createAction<IPageRequest<IOrderFilter>>(ExternalViewActionTypes.EXTERNAL_VIEW_PAGE_LOAD);
export const externalViewPageFetchActionCreator = createAction<IPageRequest<IOrderFilter>>(ExternalViewActionTypes.EXTERNAL_VIEW_PAGE_FETCH);
export const externalViewPageReceivedActionCreator = createAction<IPageResponse<IOrder, IOrderFilter>>(ExternalViewActionTypes.EXTERNAL_VIEW_PAGE_RECEIVED);
export const externalViewPageFetchFailedActionCreator = createAction<IFailedActionPayload>(ExternalViewActionTypes.EXTERNAL_VIEW_PAGE_FETCH_FAILED);

export const externalViewPageOrderUpdateDoneActionCreator = createAction<IOrderUpdateActionPayload>(ExternalViewActionTypes.EXTERNAL_VIEW_PAGE_ORDER_UPDATE_DONE);

export const externalViewTransitionsFetchActionCreator = createAction<{ id: number; uuid: string }>(ExternalViewActionTypes.EXTERNAL_VIEW_TRANSITIONS_FETCH);

export const externalViewCsvDownloadActionCreator = createAction<IPageRequest<IOrderFilter>>(ExternalViewActionTypes.EXTERNAL_VIEW_CSV_DOWNLOAD);
