import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const MESSAGES = defineMessages({
    yes: {
        id: "adr_display.yes",
        defaultMessage: "Ja",
    },
    exemptAdr: {
        id: "adr_display.exempt_adr",
        defaultMessage: "ADR vrijgesteld",
    }
});
export const ExemptAdrDisplay = () => {
    const intl = useIntl();
    return (
        <div>
            <span className="stripe-through">{intl.formatMessage(MESSAGES.yes)}</span>
            {" "} {intl.formatMessage(MESSAGES.exemptAdr)}
            {" "} <ExclamationCircleOutlined className="text-warning" />
        </div>
    );
};
