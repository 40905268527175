import * as React from "react";
import { Form, Typography } from "antd";
import { defineMessages, useIntl } from "react-intl";
import { ISelectedSlot } from "../../../types/slot.types";
import { layout } from "../../../theme/typescript";
import { ITtatCalculation } from "../../../types/ttatCalculation.types";
import { TIME_FORMATS } from "../../../constants/time";
import {
    calculateEndTime,
    calculateTankEndTime,
    calculateTankStartTime,
    calculateTotalTime,
    durationToTimeString
} from "../../../utils/ttat";
import dayjs from "dayjs";

interface IProps {
    selectedSlot: ISelectedSlot;
    ttatCalculation: ITtatCalculation | null;
}

const MESSAGES = defineMessages({
    notFound: {
        id: "selected_slot_form.not_found",
        defaultMessage: "Niet gevonden",
    },
    slotDetails: {
        id: "customerservice.tabs.slot.slotdetails",
        defaultMessage: "Slot details",
    },
    startSlot: {
        id: "customerservice.tabs.slot.startslot",
        defaultMessage: "Start slot",
    },
    endSlot: {
        id: "customerservice.tabs.slot.endSlot",
        defaultMessage: "Einde slot",
    },
    duration: {
        id: "customerservice.tabs.slot.duration",
        defaultMessage: "Duurtijd",
    },
    atTank: {
        id: "customerservice.tabs.slot.at_tank",
        defaultMessage: "Aan tank",
    },
    finalCall: {
        id: "customerservice.tabs.slot.final_call",
        defaultMessage: "Final call",
    }
});

export const SlotBookingSelectedSlotForm = (props: IProps) => {
    const intl = useIntl();
    const selectedSlot = props.selectedSlot;
    const ttatCalculation = props.ttatCalculation;

    const totalTime = ttatCalculation && durationToTimeString(calculateTotalTime(ttatCalculation));
    const atTankStartTime = ttatCalculation && durationToTimeString(calculateTankStartTime(selectedSlot, ttatCalculation));
    const atTankEndTime = ttatCalculation && durationToTimeString(calculateTankEndTime(selectedSlot, ttatCalculation));
    const atTankTIme = ttatCalculation && `${atTankStartTime} - ${atTankEndTime}`;
    const endTime = ttatCalculation && totalTime && durationToTimeString(calculateEndTime(selectedSlot, ttatCalculation));

    return (
        <>
            <h4>{intl.formatMessage(MESSAGES.slotDetails)}</h4>
            {selectedSlot && <Form {...layout} layout="horizontal" labelAlign="left">
                <Form.Item label={intl.formatMessage(MESSAGES.startSlot)}>
                    <Typography.Text className="ant-form-text" type="secondary">
                        {selectedSlot.startTime}
                    </Typography.Text>
                </Form.Item>
                <Form.Item label={intl.formatMessage(MESSAGES.endSlot)}>
                    <Typography.Text className="ant-form-text" type="secondary">
                        {endTime ? endTime : "..."}
                    </Typography.Text>
                </Form.Item>
                <Form.Item label={intl.formatMessage(MESSAGES.duration)}>
                    <Typography.Text className="ant-form-text" type="secondary">
                        {totalTime ? totalTime : "..."}
                    </Typography.Text>
                </Form.Item>
                <Form.Item label={intl.formatMessage(MESSAGES.atTank)}>
                    <Typography.Text className="ant-form-text" type="secondary">
                        {atTankTIme ? atTankTIme: "..."}
                    </Typography.Text>
                </Form.Item>
                <Form.Item label={intl.formatMessage(MESSAGES.finalCall)}>
                    <Typography.Text className="ant-form-text" type="secondary">
                        {dayjs(selectedSlot.startTime, "HH:mm").add(30, "minutes").format(TIME_FORMATS.TIME)}
                    </Typography.Text>
                </Form.Item>
            </Form>}
        </>
    );
};
