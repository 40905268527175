import { Button } from "antd";
import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import { CheckInState, ICheckIn } from "../../../../../types/checkIn.types";
import { useSelector } from "react-redux";
import { isAssignedToMeSelector } from "../../../../../store/selectors/authentication.selector";
import { IApplicationState } from "../../../../../store/state";

interface IProps {
  onCancel: () => void;
  checkIn: ICheckIn | null;
}

const MESSAGES = defineMessages({
    cancel: {
        id: "check_in_modal_footer.cancel",
        defaultMessage: "Annuleer check-in"
    }
});

export const CancelCheckinButton = (props: IProps) => {
    const intl = useIntl();
    const isAssignedToMe = useSelector<IApplicationState, boolean>(isAssignedToMeSelector(props.checkIn?.assignedTo || null));

    return props.checkIn && props.checkIn.checkInState !== CheckInState.CANCELLED && props.checkIn.checkInState !== CheckInState.FINISHED && isAssignedToMe ? (
        <Button onClick={props.onCancel} type="primary">
            {intl.formatMessage(MESSAGES.cancel)}
        </Button>
    ) : null;
};
