import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import { Box } from "../../../../Box";
import { Pane } from "../../../customerservicemodal/panes/Pane";
import { CmrWeightFormContainer } from "../../../../forms/cmr-weight/CmrWeightFormContainer";
import { List } from "antd";
import { useSelector } from "react-redux";
import { IApplicationState } from "../../../../../../store/state";
import { isAssignedToMeSelector } from "../../../../../../store/selectors/authentication.selector";
import { checkInRecordSelector } from "../../../../../../store/selectors/checkInOverview.selector";
import { BadgeSerialFormContainer } from "../../../../forms/badge-serial/BadgeSerialFormContainer";

const MESSAGES = defineMessages({
    title: {
        id: "actions_details_pane.title",
        defaultMessage: "Acties dispatcher"
    }
});

interface IProps {
    className?: string;
}

export const ActionsPane = (props: IProps) => {
    const intl = useIntl();
    const checkIn = useSelector(checkInRecordSelector);
    const isAssignedToMe = useSelector<IApplicationState, boolean>(isAssignedToMeSelector(checkIn?.assignedTo || null));

    return isAssignedToMe ? (
        <Pane className={props.className} title={intl.formatMessage(MESSAGES.title)}>
            <Box>
                <List>
                    <CmrWeightFormContainer />
                    <BadgeSerialFormContainer />
                </List>
            </Box>
        </Pane>
    ) : null;
};
