import { Button, Form, Input } from "antd";
import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import { IBookedSlotBodyParams } from "../../../types/bookedSlot.types";
import { ISelectedSlot, ISlotBookingConfig } from "../../../types/slot.types";
import { ITtatCalculation } from "../../../types/ttatCalculation.types";
import { calculateUntilDate, momentToIsoString } from "../../../utils/ttat";
import { SelectedSlotWarning } from "../alerts/SelectedSlotWarning";
import { IBooking } from "../../../types/salesOrder.types";
import { formatEnumString } from "../../../utils/format";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
dayjs.extend(duration);

interface IProps {
    form: any;
    selectedSlot?: ISelectedSlot;
    createBookedSlot: (bookedSlotBodyParams: IBookedSlotBodyParams) => void;
    updateBookedSlot: (slotId: number, bookedSlotBodyParams: IBookedSlotBodyParams) => void;
    ttatCalculation: ITtatCalculation | null;
    updateId?: number;
    updatingSlotStartTime?: string;
    updatingSlotEndTime?: string;
    updatingTankId?: number;
    config: ISlotBookingConfig;
    booking: IBooking;
}

const MESSAGES = defineMessages({
    bookSlot: {
        id: "sale_order_booking_selected_booked_slot_section.book_slot",
        defaultMessage: "Boek slot",
    },
    bookSelectedSlot: {
        id: "sale_order_booking_selected_booked_slot_section.book_selected_slot",
        defaultMessage: "Boek geselecteerd slot",
    },
    updateSlot: {
        id: "sale_order_booking_selected_booked_slot_section.update_slot",
        defaultMessage: "Update slot",
    },
    updateSelectedSlot: {
        id: "sale_order_booking_selected_booked_slot_section.update_selected_slot",
        defaultMessage: "Update geselecteerd slot",
    },
    transporterEmailAddress: {
        id: "sale_order_booking_selected_booked_slot_section.transporter_email_address",
        defaultMessage: "Transporteur email adres:",
    },
    incorrectFormatEmailAddress: {
        id: "sale_order_booking_selected_booked_slot_section.incorrect_format_email_address",
        defaultMessage: "Incorrect email adres",
    }
});

export const SlotBookingBookSelectedSlotForm = (props: IProps) => {
    const intl = useIntl();
    const { getFieldDecorator } = props.form;

    // don't show a warning when we select a slot that is within the updating slot
    const selectedSlotWithinUpdatingSlotBounds = (): boolean => {
        if (!(props.selectedSlot && props.updatingSlotStartTime && props.updatingSlotEndTime && props.updatingTankId !== undefined)) {
            return false;
        }

        const updatingStart = dayjs.duration(props.updatingSlotStartTime);
        const updatingEnd = dayjs.duration(props.updatingSlotEndTime);
        const selectedStart = dayjs.duration(props.selectedSlot.startTime);

        const startDiff = updatingStart.subtract(selectedStart).asMilliseconds();
        const endDiff = selectedStart.subtract(updatingEnd).asMilliseconds();

        return startDiff <= 0 && endDiff <= 0 && props.selectedSlot.tank.id === props.updatingTankId;
    };

    const onSubmit = (values: any) => {
        if (props.selectedSlot && props.ttatCalculation) {
            const fromDate = dayjs(props.selectedSlot?.startDate).toISOString();
            const untilDate = momentToIsoString(calculateUntilDate(props.selectedSlot, props.ttatCalculation));

            const tankId = props.selectedSlot.tank.id;
            const bookedSlotBodyParams: IBookedSlotBodyParams = {
                fromDate: fromDate,
                untilDate: untilDate,
                tankId: tankId,
                productionOrderReference: props.selectedSlot.productionOrderReference,
                emailAddressTransporter: values.emailAddressTransporter,
                dependsOnBookingId: props.selectedSlot.dependsOnBooking,
                type: formatEnumString(props.booking.type),
                salesOrderReference: props.selectedSlot.salesOrderReference
            };
            props.updateId !== undefined ? props.updateBookedSlot(props.updateId, bookedSlotBodyParams) : props.createBookedSlot(bookedSlotBodyParams);
            props.form.resetFields();
        }
    };

    return (<>
        <h4>{props.updateId !== undefined ? intl.formatMessage(MESSAGES.updateSlot) : intl.formatMessage(MESSAGES.bookSlot)}</h4>
        <Form onFinish={onSubmit} layout="vertical" labelAlign="left">
            <Form.Item>
                <h5>{intl.formatMessage(MESSAGES.transporterEmailAddress)}</h5>
                {getFieldDecorator("emailAddressTransporter", {
                    rules: [
                        {
                            required: false,
                            pattern: new RegExp(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/),
                            message: intl.formatMessage(MESSAGES.incorrectFormatEmailAddress)
                        }
                    ]
                })(<Input allowClear/>)}
            </Form.Item>
            <Form.Item>
                <Button htmlType="submit">
                    {props.updateId !== undefined ? intl.formatMessage(MESSAGES.updateSelectedSlot) : intl.formatMessage(MESSAGES.bookSelectedSlot)}
                </Button>
            </Form.Item>
        </Form>
        {!selectedSlotWithinUpdatingSlotBounds() && <SelectedSlotWarning selectedSlot={props.selectedSlot} config={props.config}/>}
    </>);
};
