import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import { ICompartment, ITanker } from "../../../../../../types/checkIn.types";
import { Alert, Col, Row } from "antd";
import { InfoLabel } from "../../../customerservicemodal/panes/detailinfopane/sections/infofields/InfoLabel";
import { InfoField } from "../../../customerservicemodal/panes/detailinfopane/sections/infofields/InfoField";
import { VehicleInfo } from "./VehicleInfo";
import { useSelector } from "react-redux";
import { checkInRecordHasExpiredByAdrType } from "../../../../../../store/selectors/checkInOverview.selector";
import { AdrTypes } from "../../../../../../types/adr.types";

interface IProps {
    tanker: ITanker;
    compartments: ICompartment[];
    showAdrInfo: boolean;
}

const MESSAGES = defineMessages({
    tanker: {
        id: "tanker_info.tanker",
        defaultMessage: "Tanker"
    },
    licensePlate: {
        id: "tanker_info.license_plate",
        defaultMessage: "Nummerplaat"
    },
    adrCode: {
        id: "tanker_info.adr_code",
        defaultMessage: "ADR code"
    },
    adrExpirationDate: {
        id: "tanker_info.adr_expiration_date",
        defaultMessage: "ADR vervaldatum"
    },
    adrExpiredWarning: {
        id: "tanker_info.adr_expired_warning",
        defaultMessage: "ADR code is vervallen."
    },
    numberOfCompartments: {
        id: "tanker_info.number_of_compartments",
        defaultMessage: "Aantal vakken"
    }
});

export const TankerInfo = (props: IProps) => {
    const intl = useIntl();
    const tankerAdrExpired = useSelector(checkInRecordHasExpiredByAdrType(AdrTypes.TANKER));

    return (
        <VehicleInfo title={intl.formatMessage(MESSAGES.tanker)}>
            <Row className="margin-bottom">
                <Col span={12}>
                    <InfoLabel text={intl.formatMessage(MESSAGES.licensePlate)}/>
                    <InfoField value={props.tanker.licensePlate}/>
                </Col>
            </Row>
            {
                props.tanker.adrCertificate &&
                <>
                    {
                        props.showAdrInfo && (
                            <Row className="margin-bottom">
                                <Col span={12}>
                                    <InfoLabel text={intl.formatMessage(MESSAGES.adrCode)}/>
                                    <InfoField value={props.tanker.adrCertificate.adrCode}/>
                                </Col>
                                <Col span={12}>
                                    <InfoLabel text={intl.formatMessage(MESSAGES.adrExpirationDate)}/>
                                    <InfoField value={props.tanker.adrCertificate.adrExpirationDate}/>
                                </Col>
                            </Row>
                        )
                    }
                    {
                        props.showAdrInfo && tankerAdrExpired &&
                        <Row className="margin-bottom">
                            <Col span={24}>
                                <Alert message={intl.formatMessage(MESSAGES.adrExpiredWarning)}
                                    type={"error"}
                                />
                            </Col>

                        </Row>
                    }
                </>
            }
            <Row className="margin-bottom">
                <Col span={12}>
                    <InfoLabel text={intl.formatMessage(MESSAGES.numberOfCompartments)}/>
                    <InfoField value={props.compartments.length}/>
                </Col>
            </Row>
        </VehicleInfo>
    );
};
