import { useDispatch } from "react-redux";
import { IOrderFilter, IPageRequest } from "../../../types/api.types";
import { IResourceRequester } from "../../../types/hook.types";
import { IOrder } from "../../../types/order.types";
import { externalViewCsvDownloadActionCreator } from "../../../store/actions/externalView";

export type ICsvDownloadPageLoader = IResourceRequester<IOrder, IOrderFilter>;

export const useCsvDownloadExternalView = (): ICsvDownloadPageLoader => {
    const dispatch = useDispatch();

    const requestResource = (pageRequest: IPageRequest<IOrderFilter>) => dispatch(externalViewCsvDownloadActionCreator(pageRequest));

    return {
        resource: null,
        requestResource: requestResource
    };
};
