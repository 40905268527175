import { PayloadAction } from "@reduxjs/toolkit";
import { Table } from "antd";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { TableEventListeners } from "antd/es/table";
import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import { IOrderFilter, IPage, IPageRequest, ISort } from "../../../../types/api.types";
import { IOrder } from "../../../../types/order.types";
import { MainOrderTable } from "../MainOrderTable";
import {
    clientOrderRefColumn,
    commentColumn,
    customerNameColumn,
    dateAvailableColumn,
    dateCleanedColumn,
    dateParkingInColumn, dateParkingOutColumn,
    deliveryIdColumn,
    productColumn,
    sapIdColumn,
    stateColumn,
    stateGroupColumn
} from "../ordertable/OrderTableColumns";
import { BoolFilter } from "../filters/BoolFilter";
import { SearchOutlined } from "@ant-design/icons";

interface IProps {
    page?: IPage<IOrder>;
    fetchingPage: boolean;
    filter?: IOrderFilter;
    sorting?: ISort;
    fetchPage: (pageRequest: IPageRequest<IOrderFilter>) => PayloadAction<IPageRequest<IOrderFilter>>;
    itactNeededRenderer: (order: IOrder) => any;
    itactDoneRenderer: (order: IOrder) => any;
    onRow: (record: IOrder, index: number) => TableEventListeners;
    csvDownload?: (pageRequest: IPageRequest<IOrderFilter>) => void;
}

const MESSAGES = defineMessages({
    itactNeededTitle: {
        id: "itact_table.itact_needed_title",
        defaultMessage: "ITACT vereist"
    },
    itactDoneTitle: {
        id: "itact_table.itact_done_title",
        defaultMessage: "Afgewerkt"
    },
    itactNeededFilterYes: {
        id: "itact_table.filter.itact_needed.yes",
        defaultMessage: "ITACT vereist",
    },
    itactNeededFilterNo: {
        id: "itact_table.filter.itact_needed.no",
        defaultMessage: "ITACT niet vereist",
    },
    itactDoneFilterYes: {
        id: "itact_table.filter.itact_needed.finished",
        defaultMessage: "Afgewerkt",
    },
    itactDoneFilterNo: {
        id: "itact_table.filter.itact_needed.not_finished",
        defaultMessage: "Niet afgewerkt",
    },
});

export const ItactTable = (props: IProps) => {
    const intl = useIntl();
    const filter: IOrderFilter = { ...props.filter };

    const columnFactory = (onFilter: (filter: IOrderFilter) => any) => {
        return (
            [
                customerNameColumn(intl, true, onFilter, props.filter),

                clientOrderRefColumn(intl, true, onFilter, props.filter),

                deliveryIdColumn(intl, true, onFilter, props.filter),

                sapIdColumn(intl, onFilter, props.filter),

                productColumn(intl, true, onFilter, props.filter),

                stateGroupColumn(intl, true, onFilter, props.filter),

                stateColumn(intl, true, onFilter, props.filter),

                dateParkingInColumn(intl, true, onFilter, props.filter),

                dateCleanedColumn(intl, true, onFilter, props.filter),

                dateAvailableColumn(intl, true, onFilter, props.filter),

                dateParkingOutColumn(intl, true, onFilter, props.filter),

                <Table.Column<IOrder>
                    key="itactNeeded"
                    dataIndex="itactNeeded"
                    title={intl.formatMessage(MESSAGES.itactNeededTitle)}
                    render={(t, r: IOrder) => props.itactNeededRenderer(r)}
                    align="center"
                    filterDropdown={({ confirm }) => (
                        <BoolFilter
                            closeFilter={confirm}
                            onFilter={value => onFilter({ ...filter, itactNeeded: value })}
                            trueText={intl.formatMessage(MESSAGES.itactNeededFilterYes)}
                            falseText={intl.formatMessage(MESSAGES.itactNeededFilterNo)}
                        />
                    )}
                    filterIcon={<SearchOutlined className={`${filter.itactNeeded ? "icon-filtered" : ""}`}/>}
                />,

                <Table.Column<IOrder>
                    key="itactDone"
                    dataIndex="itactDone"
                    title={intl.formatMessage(MESSAGES.itactDoneTitle)}
                    render={(t, r: IOrder) => props.itactDoneRenderer(r)}
                    align="center"
                    filterDropdown={({ confirm }) => (
                        <BoolFilter
                            closeFilter={confirm}
                            onFilter={value => onFilter({ ...filter, itactDone: value })}
                            trueText={intl.formatMessage(MESSAGES.itactDoneFilterYes)}
                            falseText={intl.formatMessage(MESSAGES.itactDoneFilterNo)}
                        />
                    )}
                    filterIcon={<SearchOutlined className={`${filter.itactDone ? "icon-filtered" : ""}`}/>}
                />,

                commentColumn(intl)
            ]
        );
    };

    return (
        <MainOrderTable
            csvDownloadAllSelectedOrders={props.csvDownload}
            page={props.page}
            fetchingPage={props.fetchingPage}
            filter={props.filter}
            sorting={props.sorting}
            fetchPage={props.fetchPage}
            columnFactory={columnFactory}
            onRow={props.onRow}
        />
    );
};
