import * as React from "react";
import { ICompartment, IOrder } from "../../../../../../types/order.types";
import { ChecklistContainer } from "../../../../../containers/ChecklistContainer";
import { CompartmentInfo } from "./compartmentinfo/CompartmentInfo";

interface IProps {
    order: IOrder;
    compartment: ICompartment;
    loadingOrders: boolean;
}

export const CompartmentTab = (props: IProps) => (
    <div className="scroll">
        <CompartmentInfo order={props.order} compartment={props.compartment}/>
        <ChecklistContainer order={props.order} compartment={props.compartment} className="margin-top"/>
    </div>
);
