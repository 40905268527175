import * as React from "react";
import { AppLayout } from "./AppLayout";
import {
    CheckInArchivedOverviewTableContainer
} from "../containers/tablecontainers/CheckInArchivedOverviewTableContainer";

export const CheckinArchivedOverviewScreen = () => {
    return (
        <AppLayout>
            <CheckInArchivedOverviewTableContainer/>
        </AppLayout>
    );
};
