import * as React from "react";
import { useEffect, useState } from "react";
import { Button, Spin } from "antd";
import { defineMessages, useIntl } from "react-intl";
import { CONFIG } from "../../../config/config";
import { countDown } from "../../../utils/timer.utils";
import { Paths } from "../../../types/api.types";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { hideHeaderSelector } from "../../../store/selectors/ui.selector";

interface IProps {
    callback: () => void;
    loading: boolean;
}

export const MESSAGES = defineMessages({
    autoRefresh: {
        id: "auto_refresh_button.label",
        defaultMessage: "auto refresh"
    },
    off: {
        id: "auto_refresh_button.label_off",
        defaultMessage: "Off"
    },
    on: {
        id: "auto_refresh_button.label_on",
        defaultMessage: "On"
    },
});

export const AutoRefreshButton = (props: IProps) => {
    const intl = useIntl();
    const [isOn, setIsOn] = useState<boolean>(CONFIG.refreshCheckIns);
    const [pid , setPid] = useState<number | null>(null);
    const location = useLocation();
    const hideHeader = useSelector(hideHeaderSelector);

    const getHideButton = () => {
        return location.pathname === Paths.CHECKIN_OVERVIEW_READ_ONLY && hideHeader;
    };

    useEffect(() => {
        if (isOn) {
            const interval = countDown(CONFIG.refreshIntervalCheckIns, (counter: number) => {
                if (counter === 0) {
                    props.callback();
                }
            });

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            setPid(interval);
        } else {
            if (pid) {
                clearInterval(pid);
                setPid(null);
            }
        }
    }, [isOn]);

    return (
        <>
            {props.loading && <Spin />}{" "}
            <Button className={getHideButton() ? "display-none" : ""} onClick={() => setIsOn(!isOn)}>
                {intl.formatMessage(MESSAGES.autoRefresh)}: <strong style={{ marginLeft: "5px" }}>{isOn ? intl.formatMessage(MESSAGES.on) : intl.formatMessage(MESSAGES.off)}</strong>
            </Button>
        </>
    );
};
