import * as React from "react";
import { PaneTitle } from "../../checkInDetailModal/panes/PaneTitle";

interface IProps {
    title: string | React.ReactNode;
    children: React.ReactNode;
    className?: string;
}

export const Pane = (props: IProps) => (
    <div className={props.className}>
        <PaneTitle title={props.title}/>
        {props.children}
    </div>
);
