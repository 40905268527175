import { createAction, PayloadActionCreator } from "@reduxjs/toolkit";
import { IFailedActionPayload, IOrderUpdateActionPayload } from "../../types/action.types";
import { IOrderFilter, IPageRequest, IPageResponse, IResourceRequest } from "../../types/api.types";
import { IOrder } from "../../types/order.types";

enum PlanningActionTypes {
    PLANNING_PAGE_LOAD = "[Planning] PAGE_LOAD",
    PLANNING_LIST_PRINT = "[Planning] LIST_PRINT",
    PLANNING_PAGE_FETCH = "[Planning] PAGE_FETCH",
    PLANNING_PAGE_RECEIVED = "[Planning] PAGE_RECEIVED",
    PLANNING_PAGE_FETCH_FAILED = "[Planning] LIST_FETCH_FAILED",
    PLANNING_PAGE_ORDER_UPDATE_DONE = "[Planning] PLANNING_PAGE_ORDER_UPDATE_DONE",
}

export const planningPageLoadCreator = createAction<IPageRequest<IOrderFilter>>(PlanningActionTypes.PLANNING_PAGE_LOAD);
export const planningListPrintCreator = createAction<IResourceRequest<IOrderFilter>>(PlanningActionTypes.PLANNING_LIST_PRINT);
export const planningPageFetchCreator = createAction<IPageRequest<IOrderFilter>>(PlanningActionTypes.PLANNING_PAGE_FETCH);
export const planningPageReceivedCreator = createAction<IPageResponse<IOrder, IOrderFilter>>(PlanningActionTypes.PLANNING_PAGE_RECEIVED);
export const planningPageFetchFailedCreator = createAction<IFailedActionPayload>(PlanningActionTypes.PLANNING_PAGE_FETCH_FAILED);
export const planningUpdateDoneCreator= createAction<IOrderUpdateActionPayload & {doneAction?: PayloadActionCreator<IOrderUpdateActionPayload>}>(PlanningActionTypes.PLANNING_PAGE_ORDER_UPDATE_DONE);
