import { combineReducers, createReducer } from "@reduxjs/toolkit";
import { IOrderFilter, IPageResponse } from "../../types/api.types";
import { IOrder } from "../../types/order.types";
import {
    itactPageFetchFailedActionCreator,
    itactPageLoadActionCreator,
    itactPageReceivedActionCreator
} from "../actions/itact";

export interface IItactState {
    page: IPageResponse<IOrder, IOrderFilter> | null;
    loadingPage: boolean;
}

const pageReducer = createReducer<IPageResponse<IOrder, IOrderFilter> | null>(null, (builder) => {
    builder.addCase(itactPageReceivedActionCreator, (state, action) => {
        return action.payload;
    });
});

const loadingPageReducer = createReducer<boolean>(false, (builder) => {
    builder.addCase(itactPageLoadActionCreator, () => true);
    builder.addCase(itactPageReceivedActionCreator, () => false);
    builder.addCase(itactPageFetchFailedActionCreator, () => false);
});

export const itactReducer = combineReducers<IItactState>({
    page: pageReducer,
    loadingPage: loadingPageReducer
});
