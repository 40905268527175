import { Button, Checkbox as AntCheckbox } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import * as React from "react";
import { useState } from "react";
import { ReactComponent as DisketteIcon } from "../../../../assets/diskette.svg";
import Icon, { LoadingOutlined } from "@ant-design/icons";

interface IProps {
    defaultValue: boolean;
    onSave: (val: boolean) => void;
    saving: boolean;
}

export const Checkbox = (props: IProps) => {
    const [toggled, setToggled] = useState<boolean>(props.defaultValue);

    const onChange = (e: CheckboxChangeEvent) => {
        setToggled(e.target.checked);
    };

    const onButtonClick = () => {
        props.onSave(toggled);
    };

    return (
        <div>
            <AntCheckbox checked={toggled} onChange={onChange} className="margin-half-right"/>
            <Button type="primary" onClick={onButtonClick} disabled={props.defaultValue === toggled || props.saving}>
                {
                    props.saving ?
                        <LoadingOutlined/> :
                        <Icon component={DisketteIcon}/>
                }
            </Button>
        </div>
    );
};
