import { createAction } from "@reduxjs/toolkit";
import { IFailedActionPayload } from "../../types/action.types";
import { ICheckInFilter, IPageRequest, IPageResponse } from "../../types/api.types";
import { IBadgeParameters, ICheckIn, IUpdateCheckInParameters } from "../../types/checkIn.types";
import { AxiosResponse } from "axios";
import { StorageLocation } from "../../types/salesOrder.types";

enum CheckInActionTypes {
    CHECKIN_PAGE_LOAD = "[CheckIn] PAGE_LOAD",
    CHECKIN_PAGE_RECEIVED = "[CheckIn] PAGE_RECEIVED",
    CHECKIN_PAGE_CLEAR_FILTERS = "[CheckIn] PAGE_CLEAR_FILTERS",
    CHECKIN_PAGE_FETCH = "[CheckIn] PAGE_FETCH",
    CHECKIN_PAGE_FETCH_FAILED = "[CheckIn] PAGE_FETCH_FAILED",
    CHECKIN_RECORD_LOAD = "[CheckIn] RECORD_LOAD",
    CHECKIN_RECORD_RECEIVED = "[CheckIn] RECORD_RECEIVED",
    CHECKIN_CANCELLED = "[CheckIn] CANCEL",
    CHECKIN_ASSIGN = "[CheckIn] ASSIGN",
    CHECKIN_UN_ASSIGN = "[CheckIn] UN ASSIGN",
    CHECKIN_RETURN_TO_KIOSK = "[CheckIn] RETURN TO KIOSK",
    CHECKIN_VALIDATE = "[CheckIn] VALIDATE",
    CHECKIN_FINISH = "[CheckIn] FINISH",
    CHECKIN_REOPEN = "[CheckIn] REOPEN",
    CHECKIN_UPDATE = "[CheckIn] UPDATE",
    CHECKIN_DELETE = "[CheckIn] DELETE",
    CHECKIN_SET_VIEW_SALES_ORDER_LINK_MODAL = "[CheckIn] CHECKIN SET VIEW SALES ORDER LINK_MODAL",
    CHECKIN_GET_SALES_ORDER_ID_FOR_LINK = "[CheckIn] CHECKIN GET SALES ORDER ID FOR LINK",
    CHECKIN_SET_SALES_ORDER_ID_FOR_LINK = "[CheckIn] CHECKIN SET SALES ORDER ID FOR LINK",
    CHECKIN_LINK_ADD_SALES_ORDER_TO_MANUAL_ORDER = "[CheckIn] CHECKIN LINK ADD SALES ORDER TO MANUAL ORDER",
    CHECKIN_LINK_UPDATE_SALES_ORDER_TO_MANUAL_ORDER = "[CheckIn] CHECKIN LINK UPDATE SALES ORDER TO MANUAL ORDER",
    ACTIVATE_BADGE = "[CheckIn] ACTIVATE BADGE",
    LINK_BADGE = "[CheckIn] LINK BADGE",
    PRINT_COMPARTMENTS = "[CheckIn] PRINT COMPARTMENTS",
}

export const checkInPageLoadActionCreator = createAction<IPageRequest<ICheckInFilter>>(CheckInActionTypes.CHECKIN_PAGE_LOAD);
export const checkInPageFetchActionCreator = createAction<IPageRequest<ICheckInFilter>>(CheckInActionTypes.CHECKIN_PAGE_FETCH);
export const checkInPageReceivedActionCreator = createAction<IPageResponse<ICheckIn, ICheckInFilter>>(CheckInActionTypes.CHECKIN_PAGE_RECEIVED);
export const checkInPageClearFiltersActionCreator = createAction(CheckInActionTypes.CHECKIN_PAGE_CLEAR_FILTERS);
export const checkInPageFetchFailedActionCreator = createAction<IFailedActionPayload>(CheckInActionTypes.CHECKIN_PAGE_FETCH_FAILED);
export const checkInRecordLoadActionCreator = createAction<{ checkinId: number; callback: (success: boolean) => void }>(CheckInActionTypes.CHECKIN_RECORD_LOAD);
export const checkInRecordReceivedActionCreator = createAction<ICheckIn | null>(CheckInActionTypes.CHECKIN_RECORD_RECEIVED);
export const checkInCancelActionCreator = createAction<{ checkinId: number; reason: string }>(CheckInActionTypes.CHECKIN_CANCELLED);
export const checkInAssignActionCreator = createAction<{ checkinId: number }>(CheckInActionTypes.CHECKIN_ASSIGN);
export const checkInUnAssignActionCreator = createAction<{ checkinId: number; callback: () => void }>(CheckInActionTypes.CHECKIN_UN_ASSIGN);
export const checkInReturnToKioskActionCreator = createAction<{ checkinId: number }>(CheckInActionTypes.CHECKIN_RETURN_TO_KIOSK);
export const checkInValidateActionCreator = createAction<{ checkinId: number; cmrWeight: number, onFailure: (code: number, response: AxiosResponse<any>) => void }>(CheckInActionTypes.CHECKIN_VALIDATE);
export const checkInFinishActionCreator = createAction<{ checkinId: number }>(CheckInActionTypes.CHECKIN_FINISH);
export const checkInReopenActionCreator = createAction<{ checkinId: number }>(CheckInActionTypes.CHECKIN_REOPEN);
export const checkInDeleteActionCreator = createAction<{ checkinId: number }>(CheckInActionTypes.CHECKIN_DELETE);

export const checkInUpdateActionCreator = createAction<{ checkinId: number; data: IUpdateCheckInParameters; setIsLoading?: (isLoading: boolean) => void }>(CheckInActionTypes.CHECKIN_UPDATE);
export const checkInSetViewSalesOrderLinkModalSetManualOrderIdActionCreator = createAction<number | null>(CheckInActionTypes.CHECKIN_SET_VIEW_SALES_ORDER_LINK_MODAL);
export const checkInGetSalesOrderIdForLinkActionCreator = createAction<{callBack: (response: any | null) => void, reservationId: string, terminal: StorageLocation}>(CheckInActionTypes.CHECKIN_GET_SALES_ORDER_ID_FOR_LINK);
export const checkInSetSalesOrderIdForLinkActionCreator = createAction<{salesOrderId: number | null}>(CheckInActionTypes.CHECKIN_SET_SALES_ORDER_ID_FOR_LINK);
export const checkInAddLinkSalesOrderToManualOrderActionCreator = createAction<{ checkinId: number; salesOrderId: number; manualOrderId: number }>(CheckInActionTypes.CHECKIN_LINK_ADD_SALES_ORDER_TO_MANUAL_ORDER);
export const checkInUpdateLinkSalesOrderToManualOrderActionCreator = createAction<{ checkinId: number; salesOrderId: number; manualOrderId: number }>(CheckInActionTypes.CHECKIN_LINK_UPDATE_SALES_ORDER_TO_MANUAL_ORDER);
export const activateBadgeActionCreator = createAction<{ checkinId: number; data: IBadgeParameters; setIsLoading: (isLoading: boolean) => void, onFailure: (response: AxiosResponse<any>) => void }>(CheckInActionTypes.ACTIVATE_BADGE);
export const linkBadgeActionCreator = createAction<{ checkinId: number; data: IBadgeParameters; setIsLoading: (isLoading: boolean) => void, onFailure: (response: AxiosResponse) => void }>(CheckInActionTypes.LINK_BADGE);
export const printCompartmentsActionCreator = createAction<{ checkinId: number; setIsLoading: (isLoading: boolean) => void, onFailure: (response: AxiosResponse<any>) => void }>(CheckInActionTypes.PRINT_COMPARTMENTS);
