import { Col, Row } from "antd";
import * as React from "react";
import { MESSAGE_PERMISSIONS, MESSAGE_TITLES_FACTORIES } from "../../../../constants/messages";
import { PADDING } from "../../../../theme/typescript";
import { CleaningModal } from "../cleaningmodal/CleaningModal";
import { MessagesPane } from "../customerservicemodal/panes/messagesPane/MessagesPane";
import { ItactOrderInfoPane } from "./panes/ItactOrderInfoPane";
import { ItactStatusPane } from "./panes/statuspane/ItactStatusPane";

interface IProps {
    visible: boolean;
    closeModal: () => void;
    orderId?: number;
}

export const ItactModal = (props: IProps) => {
    return (
        props.orderId ?
            <CleaningModal
                closeModal={props.closeModal}
                width="90%"
                open={props.visible}
            >
                <Row gutter={PADDING}>
                    <Col span={8}>
                        <ItactOrderInfoPane orderId={props.orderId}/>
                    </Col>
                    <Col span={8}>
                        <ItactStatusPane orderId={props.orderId}/>
                    </Col>
                    <Col span={8}>
                        <MessagesPane
                            orderId={props.orderId}
                            permissions={MESSAGE_PERMISSIONS.itact}
                            titleFactory={MESSAGE_TITLES_FACTORIES.itact}
                        />
                    </Col>
                </Row>
            </CleaningModal>
            :
            <></>
    );
};
