import { Spin } from "antd";
import * as React from "react";
import { useEffect } from "react";
import { StatusTimeline } from "../components/modals/customerservicemodal/panes/statuspane/StatusTimeline";
import { useExternalViewTransitionsLoader } from "../hooks/useExternalViewTransitionsLoader";

interface IProps {
    orderId: number;
    uuid: string;
}

export const ExternalViewStateTimelineContainer = (props: IProps) => {
    const externalTransitionsLoader = useExternalViewTransitionsLoader();

    useEffect(() => {
        externalTransitionsLoader.loadResource(props.uuid, props.orderId);
    }, []);

    return (
        <div>
            {
                externalTransitionsLoader.loadingResource ? <Spin/> : <StatusTimeline statusHistory={externalTransitionsLoader.resource}/>
            }
        </div>
    );
};
