import * as Msal from "msal";
import { END } from "redux-saga";

import { ENV } from "../config/environments";
import { MsalLock } from "./lock";

/**
 * Why put this Msal related stuff in a separate file? Well, it is security related
 * and it depends on a microsoft library to do the heavy lifting: MSAL. As this is
 * prone to change we use the util types and the functions below as a facade in order
 * to isolate the changes. The only thing we need up front are some types and a function
 * which accepts an emitter to funnel events back into the saga machinery.
 *
 * @see authentication saga
 */

// Util types
export type AuthResponse = Msal.AuthResponse;
export type AuthError = { message: string };
export type AuthEmitter = (input: AuthResponse | AuthError | END) => void;

// Bugfix for deployed solutions, the redirection URI is often bloated with
// paths or a query string...
function getRedirectUri() {
    if (window?.location?.href) {
        const match = /(https|http):\/\/([a-zA-Z0-9.\-:]+)/.exec(window.location.href);
        if (match && match.length > 0) {
            return match[0];
        }
    }
    return "http://location:3000";
}

// MSAL config. Configured via two env variables
const msalConfig: Msal.Configuration = {
    auth: {
        clientId: ENV.REACT_APP_AUTH_CLIENTID || "not-defined",
        authority: ENV.REACT_APP_AUTH_AUTHORITY || "not-defined",
        redirectUri: getRedirectUri(),
        navigateToLoginRequestUrl: false,
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,
    },
};

const scopeRequestConfig: Msal.AuthenticationParameters = {
    scopes: ["openid", "profile", "email"]
};

const scopeRequestForceConfig: Msal.AuthenticationParameters = {
    scopes: ["openid", "profile", "email"],
    forceRefresh: true,
};

// Create an msal instance
const msalInstance = new Msal.UserAgentApplication(msalConfig);
const msalLock = new MsalLock();

function closeEmitter(emitter: AuthEmitter) {
    msalLock.unlock();
    setTimeout(() => emitter(END), 2000);
}

/**
 * Login the application via the MSAL instance
 *
 * @param emitter saga emitter
 */
export function login(emitter: AuthEmitter) {
    // Get the account from msal (local storage, configured above)
    const account = msalInstance.getAccount();

    // Set a redirect callback on msal
    msalInstance.handleRedirectCallback((error, response) => {
        // Converge error and response streams
        if (error) {
            emitter({ message: error.message });
        }

        if (response) {
            emitter(response);
        }

        // Close the channel
        closeEmitter(emitter);
    });

    // Perform login redirection when the login is null and no lock is available.
    if (!account && !msalLock.hasLock()) {
        msalInstance.loginRedirect();
        return;
    } else {
        // Lock the process
        msalLock.lock();
    }

    if (account) {
        // Check expiry
        const expiration = parseInt(account.idToken["exp"], 10);

        // Redirect if expired. Somehow the acquireTokenSilent does not refresh the token
        if (expiration < (new Date().getTime() / 1000)) {
            msalInstance.loginRedirect(scopeRequestConfig);
        }

        // Try to acquire the token
        msalInstance.acquireTokenSilent(scopeRequestConfig)
            // Emit the token
            .then((response) => {
                emitter(response);
            })

            // Redirect to login page if failed
            .catch((e) => {
                console.warn(`Catched error ${e.errorCode} while acquireTokenSilent`);

                if (e.errorCode === "block_token_requests") {
                    console.debug(`Not redirecting error code ${e.errorCode}`);
                    return;
                }

                // Emit the error
                emitter(e);

                // Login to redirect
                msalInstance.loginRedirect(scopeRequestConfig);
            })

            // Close the channel
            .finally(() => {
                closeEmitter(emitter);
            });
    }
}

/**
 * Logout the application via the MSAL instance
 */
export function logout() {
    msalInstance.logout();
}

export const getToken = async(forceRefresh = false) => {
    try {
        return (await msalInstance.acquireTokenSilent(forceRefresh ? scopeRequestForceConfig : scopeRequestConfig)).idToken.rawIdToken;
    } catch (e) {
        if (!forceRefresh) {
            await getToken(true);
        }
    }
};
