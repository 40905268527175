import { round } from "lodash";
import { defineMessages, IntlShape } from "react-intl";
import { TIME_FORMATS } from "../constants/time";
import { IPageRequest, IResourceRequest, ISort } from "../types/api.types";
import {
    AdrVehicleSpecification,
    CheckInReason,
    CheckInState,
    ExtendedCheckInState,
    TransportationMethod
} from "../types/checkIn.types";
import { ChecklistItem } from "../types/checklist.types";
import { IMessage, IMessages } from "../types/message.types";
import { OrderType, State, StateGroup } from "../types/order.types";
import { optionalApply, valOrAlt } from "./fn";
import { ISalesOrder, OrderAction, SlotBookingOrderType } from "../types/salesOrder.types";
import dayjs from "dayjs";

// https://www.iso.org/iso-8601-date-and-time-format.html
const formatIso8601 = (isoString: string, format: string) => dayjs(isoString).format(format);
const formatIso8601DateTime = (isoString: string) => dayjs(isoString).format(TIME_FORMATS.DATE_TIME);
export const formatIso8601Date = (isoString?: string) => isoString && formatIso8601(isoString, TIME_FORMATS.DATE);
export const formatIso8601Time = (isoString?: string) => isoString && formatIso8601(isoString, TIME_FORMATS.TIME);

export const optionalFormatIso8601DateTime = optionalApply(formatIso8601DateTime);

export const valOrInfoFieldDefaultVal = valOrAlt("-");

const BOOL_MESSAGES = defineMessages({
    truthy: {
        id: "bool.true",
        defaultMessage: "Ja"
    },
    falsy: {
        id: "bool.false",
        defaultMessage: "Nee"
    }
});
const formatTrue = (intl: IntlShape) => intl.formatMessage(BOOL_MESSAGES.truthy);

const formatFalse = (intl: IntlShape) => intl.formatMessage(BOOL_MESSAGES.falsy);

export const formatThousands = (number: number): string => {
    return new Intl.NumberFormat("de-DE").format(number);
};

export const formatField = valOrInfoFieldDefaultVal;

export const formatBoolField = (val: any, intl: IntlShape) => val ? formatTrue(intl) : formatFalse(intl);

const STATUS_MESSAGES = defineMessages({
    toClean: {
        id: "state.to_clean",
        defaultMessage: "Te reinigen",
    },
    finishUp: {
        id: "state.finish_up",
        defaultMessage: "Afhandeling",
    },
    cleaning: {
        id: "state.cleaning",
        defaultMessage: "In reiniging",
    },
    done: {
        id: "state.done",
        defaultMessage: "Klaar",
    },
    preparation: {
        id: "state.preparation",
        defaultMessage: "Voorbereiding"
    }
});

export const formatStateGroup = (status: StateGroup, intl: IntlShape) => {
    switch (status) {
    case StateGroup.TO_CLEAN:
        return intl.formatMessage(STATUS_MESSAGES.toClean);
    case StateGroup.FINISH_UP:
        return intl.formatMessage(STATUS_MESSAGES.finishUp);
    case StateGroup.CLEANING:
        return intl.formatMessage(STATUS_MESSAGES.cleaning);
    case StateGroup.DONE:
        return intl.formatMessage(STATUS_MESSAGES.done);
    case StateGroup.PREPARATION:
        return intl.formatMessage(STATUS_MESSAGES.preparation);
    }
};

const SUBSTATUS_MESSAGES = defineMessages({
    orderCreated: {
        id: "substate.order_created",
        defaultMessage: "Order aangemaakt",
    },
    containerArrived: {
        id: "substate.container_arrived",
        defaultMessage: "Aangekomen",
    },
    cleanedReadyForPickup: {
        id: "substate.cleaned_ready_for_pickup",
        defaultMessage: "Cleaned & beschikbaar voor ophaal",
    },
    orderReceived: {
        id: "substate.order_received",
        defaultMessage: "Order ontvangen",
    },
    planned: {
        id: "substate.planned",
        defaultMessage: "In wachtrij",
    },
    waitingForFeedbackCustomer: {
        id: "substate.waiting_for_feedback_customer",
        defaultMessage: "Wachten feedback klant",
    },
    waitingForInternalProcessing: {
        id: "substate.waiting_for_internal_processing",
        defaultMessage: "Interne afhandeling",
    },
    errorDuringCleaning: {
        id: "substate.error_during_cleaning",
        defaultMessage: "Fout tijdens reinigen",
    },
    notCleanedReadyForPickup: {
        id: "substate.not_cleaned_ready_for_pickup",
        defaultMessage: "Beschikbaar voor ophaal",
    },
    readyForCleaning: {
        id: "substate.ready_for_cleaning",
        defaultMessage: "Gereed voor reinigen",
    },
    cleaning: {
        id: "substate.cleaning",
        defaultMessage: "Wordt gereinigd",
    },
    itactRepair: {
        id: "substate.itact_repair",
        defaultMessage: "ITACT",
    },
    orderDeleted: {
        id: "substate.order_deleted",
        defaultMessage: "Order verwijderd",
    },
    cleaningFinished: {
        id: "substate.cleaning_finished",
        defaultMessage: "Reinigen gereed",
    },
    donePickedUp: {
        id: "substate.done_picked_up",
        defaultMessage: "Opgehaald",
    },
    cleaned: {
        id: "substate.cleaned",
        defaultMessage: "Gereinigd"
    }
});

export const formatState = (subStatus: State, intl: IntlShape): string => {
    switch (subStatus) {
    case State.ORDER_CREATED:
        return intl.formatMessage(SUBSTATUS_MESSAGES.orderCreated);
    case State.CONTAINER_ARRIVED:
        return intl.formatMessage(SUBSTATUS_MESSAGES.containerArrived);
    case State.CLEANED_READY_FOR_PICKUP:
        return intl.formatMessage(SUBSTATUS_MESSAGES.cleanedReadyForPickup);
    case State.ORDER_RECEIVED:
        return intl.formatMessage(SUBSTATUS_MESSAGES.orderReceived);
    case State.PLANNED:
        return intl.formatMessage(SUBSTATUS_MESSAGES.planned);
    case State.WAITING_FOR_INTERNAL_PROCESSING:
        return intl.formatMessage(SUBSTATUS_MESSAGES.waitingForInternalProcessing);
    case State.WAITING_FOR_FEEDBACK_CUSTOMER:
        return intl.formatMessage(SUBSTATUS_MESSAGES.waitingForFeedbackCustomer);
    case State.ERROR_DURING_CLEANING:
        return intl.formatMessage(SUBSTATUS_MESSAGES.errorDuringCleaning);
    case State.NOT_CLEANED_READY_FOR_PICKUP:
        return intl.formatMessage(SUBSTATUS_MESSAGES.notCleanedReadyForPickup);
    case State.READY_FOR_CLEANING:
        return intl.formatMessage(SUBSTATUS_MESSAGES.readyForCleaning);
    case State.CLEANING:
        return intl.formatMessage(SUBSTATUS_MESSAGES.cleaning);
    case State.ITACT_REPAIR:
        return intl.formatMessage(SUBSTATUS_MESSAGES.itactRepair);
    case State.ORDER_DELETED:
        return intl.formatMessage(SUBSTATUS_MESSAGES.orderDeleted);
    case State.CLEANING_FINISHED:
        return intl.formatMessage(SUBSTATUS_MESSAGES.cleaningFinished);
    case State.DONE_PICKED_UP:
        return intl.formatMessage(SUBSTATUS_MESSAGES.donePickedUp);
    case State.CLEANED:
        return intl.formatMessage(SUBSTATUS_MESSAGES.cleaned);
    default:
        return "";
    }
};

const CHECKLIST_ITEM_MESSAGES = defineMessages({
    preOperationChecklist: {
        id: "checklist_item.pre_operation_checklist",
        defaultMessage: "Checklist voor operatie"
    },
    cleaningProcedure: {
        id: "checklist_item.cleaning_procedure",
        defaultMessage: "Cleaning procedure"
    },
    postOperationChecklist: {
        id: "checklist_item.post_operation_checklist",
        defaultMessage: "Checklist na operatie"
    },
    containerDone: {
        id: "checklist_item.container_done",
        defaultMessage: "Container klaar"
    }
});

export const formatChecklistItem = (checklistItem: ChecklistItem, intl: IntlShape) => {
    switch (checklistItem) {
    case ChecklistItem.CLEANING_PROCEDURE:
        return intl.formatMessage(CHECKLIST_ITEM_MESSAGES.cleaningProcedure);
    case ChecklistItem.CONTAINER_DONE:
        return intl.formatMessage(CHECKLIST_ITEM_MESSAGES.containerDone);
    case ChecklistItem.POST_OPERATION_CHECKLIST:
        return intl.formatMessage(CHECKLIST_ITEM_MESSAGES.postOperationChecklist);
    case ChecklistItem.PRE_OPERATION_CHECKLIST:
        return intl.formatMessage(CHECKLIST_ITEM_MESSAGES.preOperationChecklist);
    }
};

const ORDER_TYPE_MESSAGES = defineMessages({
    unmannedCleaning: {
        id: "order_type.unmanned_cleaning",
        defaultMessage: "Onbemande cleaning"
    },
    mannedCleaning: {
        id: "order_type.manned_cleaning",
        defaultMessage: "Bemande cleaning"
    },
    internalContainer: {
        id: "order_type.internal_container",
        defaultMessage: "Interne container",
    },
    wagon: {
        id: "order_type.wagon",
        defaultMessage: "Wagon"
    },
    ibc: {
        id: "order_type.ibc",
        defaultMessage: "IBC"
    },
    hose: {
        id: "order_type.hose",
        defaultMessage: "Slang"
    }
});

export const formatOrderType = (orderType: OrderType, intl: IntlShape) => {
    switch (orderType) {
    case OrderType.MANNED_CLEANING:
        return intl.formatMessage(ORDER_TYPE_MESSAGES.mannedCleaning);
    case OrderType.UNMANNED_CLEANING:
        return intl.formatMessage(ORDER_TYPE_MESSAGES.unmannedCleaning);
    case OrderType.INTERNAL_CONTAINER:
        return intl.formatMessage(ORDER_TYPE_MESSAGES.internalContainer);
    case OrderType.WAGON:
        return intl.formatMessage(ORDER_TYPE_MESSAGES.wagon);
    case OrderType.IBC:
        return intl.formatMessage(ORDER_TYPE_MESSAGES.ibc);
    case OrderType.HOSE:
        return intl.formatMessage(ORDER_TYPE_MESSAGES.hose);
    }
};

const formatSortQueryParam = (sort: ISort) => `sort=${sort.property},${sort.order}`;
const formatPageQueryParam = (pageNumber: number) => `page=${pageNumber}`;
export const formatFilterQueryParams = (filter: Record<string, any>) => {
    let queryStringResult = "";

    new URLSearchParams(filter).forEach((value, key) => {
        const valuesArray = value.split(",");
        valuesArray.forEach((arrayValue) => {
            queryStringResult += `&${key}=${arrayValue}`;
        });
    });

    return queryStringResult;
};

export const formatQueryParams = (request: IResourceRequest<any>) => {
    let formattedFilterParams = formatFilterQueryParams(request.filter);
    const formattedSortParam = request.sorting ? `${formatSortQueryParam(request.sorting)}` : "";

    formattedFilterParams = formattedFilterParams && `${formattedFilterParams}`;

    return `?${formattedSortParam}${formattedFilterParams}`;
};

export const formatPageQueryParams = (pageRequest: IPageRequest<any>) => {
    const queryParams = formatQueryParams(pageRequest);
    const formattedPageParam = formatPageQueryParam(pageRequest.pageNumber);

    return `${queryParams}&${formattedPageParam}`;
};

export const parseSearchQuery = (searchQuery: string) => {
    return new URLSearchParams(searchQuery.replace("?", ""));
};

export const formatEnumString = (enumString: string) => {
    return enumString.replace("-", "_").toUpperCase();
};

const CONSTRAINT_MESSAGES: IMessages = defineMessages({
    REASON: {
        id: "slot_row.reason",
        defaultMessage: "Geblokkeerd door: "
    },
    MAXIMUM_EXCEEDED: {
        id: "slot_row.maximum_exceeded",
        defaultMessage: "Maximaal slots bereikt"
    },
    SLOTS_RESERVED: {
        id: "slot_row.slots_reserved",
        defaultMessage: "slots gereserveerd"
    },
    TANK_BLOCKED: {
        id: "slot_row.tank_blocked",
        defaultMessage: "Tank geblokkeerd"
    },
    BLOCKED_DEPENDS_ON: {
        id: "slot_row.blocked_depends_on",
        defaultMessage: "Geboekt slot vorige booking"
    }
});

export const getReason = (reason: string, formatMessage: (message: IMessage) => string) => {
    switch (reason) {
    case "REASON":
        return formatMessage(CONSTRAINT_MESSAGES.REASON);
    case "MAXIMUM_EXCEEDED":
        return formatMessage(CONSTRAINT_MESSAGES.MAXIMUM_EXCEEDED);
    case "SLOTS_RESERVED":
        return formatMessage(CONSTRAINT_MESSAGES.SLOTS_RESERVED);
    case "TANK_BLOCKED":
        return formatMessage(CONSTRAINT_MESSAGES.TANK_BLOCKED);
    case "BLOCKED_DEPENDS_ON":
        return formatMessage(CONSTRAINT_MESSAGES.BLOCKED_DEPENDS_ON);
    }
};

export const DEFAULT_MESSAGES = defineMessages({
    NOT_FOUND: {
        id: "sale_order_information_section.not_found",
        defaultMessage: "Niet gevonden",
    },
});

export const formatKg = (kg: number) => `${round(kg, 2)} kg`;

export const CHECK_IN_REASON_MESSAGES = defineMessages({
    [CheckInReason.VISIT]: {
        id: "check_in_reason.visit",
        defaultMessage: "Bezoek"
    },
    [CheckInReason.WRAPPED]: {
        id: "check_in_reason.wrapped",
        defaultMessage: "Verpakt"
    },
    [CheckInReason.BULK]: {
        id: "check_in_reason.bulk",
        defaultMessage: "Bulk"
    },
    [CheckInReason.CONTAINER]: {
        id: "check_in_reason.container",
        defaultMessage: "Containers"
    },
    [CheckInReason.SUPPLIER]: {
        id: "quickFilters.suppliers",
        defaultMessage: "Leveranciers"
    },
    [CheckInReason.CONTRACTOR]: {
        id: "quickFilters.contractor",
        defaultMessage: "Contractors"
    },
    [CheckInReason.VISIT]: {
        id: "quickFilters.visitors",
        defaultMessage: "Bezoekers"
    }
});

export const formatCheckInReason = (checkInReason: CheckInReason | null, intl: IntlShape) => checkInReason ? intl.formatMessage(CHECK_IN_REASON_MESSAGES[checkInReason]) : checkInReason;

export const CHECK_IN_STATE_MESSAGES = defineMessages({
    [CheckInState.IN_PROGRESS]: {
        id: "check_in_state.in_progress",
        defaultMessage: "Bezig"
    },
    [CheckInState.QUEUED]: {
        id: "check_in_state.queued",
        defaultMessage: "In wachtrij"
    },
    [CheckInState.PROCESSING]: {
        id: "check_in_state.processing",
        defaultMessage: "Wordt verwerkt"
    },
    [CheckInState.READY_FOR_OPERATIONS]: {
        id: "check_in_state.ready_for_operations",
        defaultMessage: "Klaar voor operaties"
    },
    [CheckInState.FINISHED]: {
        id: "check_in_state.finished",
        defaultMessage: "Afgewerkt"
    },
    [CheckInState.CANCELLED]: {
        id: "check_in_state.cancelled",
        defaultMessage: "Geannuleerd"
    },
    [CheckInState.BACK_TO_KIOSK]: {
        id: "check_in_state.backToKiosk",
        defaultMessage: "Terug naar kiosk"
    }
});

export const EXTENDED_CHECK_IN_STATE_MESSAGES = defineMessages({
    ...CHECK_IN_STATE_MESSAGES,
    ["BLACKLISTED"]: {
        id: "check_in_state.blacklisted",
        defaultMessage: "Blacklist"
    }
});

export const formatCheckInState = (checkInState: CheckInState | null, intl: IntlShape) =>
    checkInState && CHECK_IN_STATE_MESSAGES[checkInState] ? intl.formatMessage(CHECK_IN_STATE_MESSAGES[checkInState]) : checkInState;

export const formatExtendedCheckInState = (checkInState: ExtendedCheckInState | null, intl: IntlShape) =>
    checkInState && EXTENDED_CHECK_IN_STATE_MESSAGES[checkInState] ? intl.formatMessage(EXTENDED_CHECK_IN_STATE_MESSAGES[checkInState]) : checkInState;

const ADR_VEHICLE_SPECIFICATION_MESSAGES = defineMessages({
    [AdrVehicleSpecification.AT]: {
        id: "adr_vehicle_specification_messages.at",
        defaultMessage: "AT"
    },
    [AdrVehicleSpecification.FL]: {
        id: "adr_vehicle_specification_messages.fl",
        defaultMessage: "FL"
    },
    [AdrVehicleSpecification.FL_AT]: {
        id: "adr_vehicle_specification_messages.fl_at",
        defaultMessage: "FL/AT"
    }
});

export const formatAdrVehicleSpecification = (adrVehicleSpecification: AdrVehicleSpecification, intl: IntlShape) => intl.formatMessage(ADR_VEHICLE_SPECIFICATION_MESSAGES[adrVehicleSpecification]);

const TRANSPORTATION_METHOD_MESSAGES = defineMessages({
    [TransportationMethod.ROAD_SEA]: {
        id: "transportation_method.road_sea",
        defaultMessage: "Weg + zee"
    },
    [TransportationMethod.ROAD_RAIL]: {
        id: "transportation_method.road_rail",
        defaultMessage: "Weg + spoor"
    },
    [TransportationMethod.ROAD]: {
        id: "transportation_method.road",
        defaultMessage: "Weg"
    }
});

export const formatTransportationMethod = (transportationMethod: TransportationMethod, intl: IntlShape) => intl.formatMessage(TRANSPORTATION_METHOD_MESSAGES[transportationMethod]);

const ORDER_ACTION_MESSAGES = defineMessages({
    load: {
        id: "order_action.load",
        defaultMessage: "Laden"
    },
    unload: {
        id: "order_action.unload",
        defaultMessage: "Lossen"
    },
    pickup: {
        id: "order_action.pickup",
        defaultMessage: "Opzetten"
    },
    drop: {
        id: "order_action.drop",
        defaultMessage: "Afzetten"
    },
    vulextruck: {
        id: "order_action.vulextruck",
        defaultMessage: "Lossen op vaten"
    }
});

export const formatOrderAction = (orderAction: OrderAction | undefined, intl: IntlShape, containerAction?: boolean) => {
    if (orderAction === OrderAction.VULEXTRUCK) return intl.formatMessage(ORDER_ACTION_MESSAGES.vulextruck);

    if (containerAction) {
        if (orderAction === OrderAction.LOAD) {
            return intl.formatMessage(ORDER_ACTION_MESSAGES.pickup);
        } else {
            return intl.formatMessage(ORDER_ACTION_MESSAGES.drop);
        }
    } else {
        if (orderAction === OrderAction.LOAD) {
            return intl.formatMessage(ORDER_ACTION_MESSAGES.load);
        } else {
            return intl.formatMessage(ORDER_ACTION_MESSAGES.unload);
        }
    }
};

const DISTRIBUTION_CHANNEL_MESSAGES = defineMessages({
    parking: {
        id: "distribution_channel.parking",
        defaultMessage: "Parking"
    },
    cleaning: {
        id: "distribution_channel.cleaning",
        defaultMessage: "Cleaning"
    },
});

export const formatDistributionChannel = (distributionChannel: string, intl: IntlShape) => {
    if (distributionChannel === "01") {
        return intl.formatMessage(DISTRIBUTION_CHANNEL_MESSAGES.parking);
    } else {
        return intl.formatMessage(DISTRIBUTION_CHANNEL_MESSAGES.cleaning);
    }
};

const SLOT_BOOKING_ORDER_TYPE_MESSAGES = defineMessages({
    [SlotBookingOrderType.BULK]: {
        id: "slot_booking_order_type.bulk",
        defaultMessage: "Bulk"
    },
    [SlotBookingOrderType.CONTAINER]: {
        id: "slot_booking_order_type.parking",
        defaultMessage: "Container"
    },
    [SlotBookingOrderType.WRAPPED]: {
        id: "slot_booking_order_type.wrapped",
        defaultMessage: "Verpakt"
    }
});

export const formatSlotbookingOrderType = (orderType: SlotBookingOrderType, intl: IntlShape) => intl.formatMessage(SLOT_BOOKING_ORDER_TYPE_MESSAGES[orderType]);

export const formatCheckInType = (intl: IntlShape, checkInReason: CheckInReason, salesOrder?: ISalesOrder) => {
    let result = (salesOrder && formatSlotbookingOrderType(salesOrder.orderType, intl)) || formatCheckInReason(checkInReason, intl);

    if (salesOrder) {
        result += ` - ${formatOrderAction(salesOrder.orderAction, intl, checkInReason === CheckInReason.CONTAINER)}`;
        if (checkInReason === CheckInReason.CONTAINER && salesOrder.distributionChannel) {
            result += ` - ${formatDistributionChannel(salesOrder.distributionChannel, intl)}`;
        }
    }

    return result;
};

export const roundToTens = (num: number): number => Math.round(num / 10) * 10;
