import { EventInput } from "@fullcalendar/react";
import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { IBookedSlot, IBookedSlotListFetchParams } from "../../types/bookedSlot.types";
import { IScheduledMaintenance, IScheduledMaintenancesFetchParams } from "../../types/scheduledMaintenance.types";
import { bookedSlotToCalendarEvent, scheduledMaintenanceToCalendarEvent } from "../../utils/mapping";
import { bookedSlotListReceivedCreator } from "../actions";
import { calendarEventsFetchActionCreator, calendarEventsFetchFailedActionCreator } from "../actions/calendar";
import { tankScheduledMaintenancesReceivedActionCreator } from "../actions/tank";
import { bookedSlotApi } from "../api/bookedSlot";
import { tankApi } from "../api/tank";
import { errorHandler } from "./errorHandler";
import { IScheduledMaintenanceResponse } from "./slot";
import { AxiosResponse } from "axios";
import dayjs from "dayjs";


function* fetchEvents(action: ReturnType<typeof calendarEventsFetchActionCreator>) {
    const params: IBookedSlotListFetchParams = {
        from: dayjs(action.payload.eventParams.info.start).toISOString(),
        until: dayjs(action.payload.eventParams.info.end).toISOString(),
        tank: action.payload.tankIds,
    };

    const response: AxiosResponse<IBookedSlot[]> = yield call(bookedSlotApi.getBookedSlotList, params);
    const bookedSlots = response.data;

    const scheduledMaintenanceResponses: IScheduledMaintenanceResponse[] = yield all(action.payload.tankIds.map(tankId => {
        const fetchParams: IScheduledMaintenancesFetchParams = {
            from: dayjs(action.payload.eventParams.info.start).toISOString(),
            until: dayjs(action.payload.eventParams.info.end).toISOString()
        };

        return call(tankApi.getScheduledMaintenances, tankId, fetchParams);
    }));
    const scheduledMaintenances: IScheduledMaintenance[] = scheduledMaintenanceResponses
        .flatMap((scheduledMaintenanceResponse) => scheduledMaintenanceResponse.data);

    yield put(bookedSlotListReceivedCreator(bookedSlots));
    yield put(tankScheduledMaintenancesReceivedActionCreator(scheduledMaintenances));

    const bookedSlotEvents = bookedSlots.map(bookedSlotToCalendarEvent);
    const scheduledMaintenanceEvents = scheduledMaintenances.map(scheduledMaintenanceToCalendarEvent);

    action.payload.eventParams.successCallback([...bookedSlotEvents, ...scheduledMaintenanceEvents] as EventInput[]);
}

export function* handleTimeLineEventError(action: ReturnType<typeof calendarEventsFetchFailedActionCreator>) {
    const failedAction = action.payload.action;

    yield call(failedAction.payload.eventParams.failureCallback, action.payload.e);
}

export function* calendarSaga() {
    yield takeLatest(calendarEventsFetchActionCreator.type, errorHandler(fetchEvents, calendarEventsFetchFailedActionCreator));
    yield takeEvery(calendarEventsFetchFailedActionCreator.type, errorHandler(handleTimeLineEventError));
}
