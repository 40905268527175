import { SearchOutlined } from "@ant-design/icons/lib";
import { PayloadAction } from "@reduxjs/toolkit";
import { Table } from "antd";
//eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { TableEventListeners } from "antd/es/table";
import * as React from "react";
import { defineMessages, useIntl } from "react-intl";

import { IOrderFilter, IPage, IPageRequest, ISort } from "../../../../types/api.types";
import { IOrder } from "../../../../types/order.types";
import { BoolFilter } from "../filters/BoolFilter";
import { MainOrderTable } from "../MainOrderTable";
import { csActionRenderer, itactRenderer } from "../renderers";
import {
    clientOrderRefColumn,
    commentColumn,
    customerNameColumn,
    dateAvailableColumn,
    dateCleanedColumn,
    dateParkingInColumn,
    dateParkingOutColumn,
    deliveryIdColumn,
    productColumn,
    sapIdColumn,
    stateColumn,
    stateGroupColumn,
    storageDaysColumn
} from "../ordertable/OrderTableColumns";

interface IProps {
    csvDownloadAllSelectedOrders?: (pageRequest: IPageRequest<IOrderFilter>) => void;
    csvDownloadOnlyFinishedOrders?: () => void;
    page?: IPage<IOrder>;
    fetchingPage: boolean;
    sorting?: ISort;
    filter?: IOrderFilter;
    fetchPage: (pageRequest: IPageRequest<IOrderFilter>) => PayloadAction<IPageRequest<IOrderFilter>>;
    onRow: (record: IOrder, index: number) => TableEventListeners;
}

const MESSAGES = defineMessages({
    itactNeededFilterYes: {
        id: "customer_service_table.filter.itact_needed.yes",
        defaultMessage: "Langs ITACT",
    },
    itactNeededFilterNo: {
        id: "customer_service_table.filter.itact_needed.no",
        defaultMessage: "Niet langs ITACT",
    },
    csActionNeededFilterYes: {
        id: "customer_service_table.filter.cs_action_needed.yes",
        defaultMessage: "Actie nodig",
    },
    csActionNeededFilterNo: {
        id: "customer_service_table.filter.cs_action_needed.no",
        defaultMessage: "Actie niet nodig",
    },
    itactWorkRequiredTitle: {
        id: "customer_service_table.title.itact_work_required",
        defaultMessage: "Langs ITACT"
    },
    csActionRequiredTitle: {
        id: "customer_service_table.title.cs_action_required",
        defaultMessage: "Actie nodig"
    },
});

export const CustomerServiceTable = (props: IProps) => {
    const intl = useIntl();

    const filter: IOrderFilter = { ...props.filter };

    const columnFactory = (onFilter: (filter: IOrderFilter) => any) => {
        return (
            [
                customerNameColumn(intl, true, onFilter, filter),

                clientOrderRefColumn(intl, true, onFilter, filter),

                deliveryIdColumn(intl, true, onFilter, filter),

                sapIdColumn(intl, onFilter, filter),

                productColumn(intl, true, onFilter, filter),

                stateGroupColumn(intl, true, onFilter, filter),

                stateColumn(intl, true, onFilter, filter),

                dateParkingInColumn(intl, true, onFilter, filter),

                dateCleanedColumn(intl, true, onFilter, filter),

                dateAvailableColumn(intl, true, onFilter, filter),

                storageDaysColumn(intl, true, onFilter, filter),

                dateParkingOutColumn(intl, true, onFilter, filter),

                <Table.Column<IOrder>
                    key="itactWorkRequired"
                    dataIndex="itactWorkRequired"
                    title={intl.formatMessage(MESSAGES.itactWorkRequiredTitle)}
                    render={itactRenderer}
                    align="center"
                    sorter
                    filterDropdown={({ confirm }) => (
                        <BoolFilter
                            closeFilter={confirm}
                            onFilter={value => onFilter({ ...filter, itactRequired: value })}
                            trueText={intl.formatMessage(MESSAGES.itactNeededFilterYes)}
                            falseText={intl.formatMessage(MESSAGES.itactNeededFilterNo)}
                        />
                    )}
                    filterIcon={<SearchOutlined className={`${filter.itactRequired ? "icon-filtered" : ""}`}/>}
                />,

                <Table.Column<IOrder>
                    key="csActionRequired"
                    dataIndex="csActionRequired"
                    title={intl.formatMessage(MESSAGES.csActionRequiredTitle)}
                    render={csActionRenderer}
                    className="no-padding"
                    align="center"
                    sorter
                    filterDropdown={({ confirm }) => (
                        <BoolFilter
                            closeFilter={confirm}
                            onFilter={value => onFilter({ ...filter, csActionRequired: value })}
                            trueText={intl.formatMessage(MESSAGES.csActionNeededFilterYes)}
                            falseText={intl.formatMessage(MESSAGES.csActionNeededFilterNo)}
                        />
                    )}
                    filterIcon={<SearchOutlined className={`${filter.csActionRequired ? "icon-filtered" : ""}`}/>}
                />,

                commentColumn(intl)
            ]
        );
    };

    return (
        <MainOrderTable
            csvDownloadAllSelectedOrders={props.csvDownloadAllSelectedOrders}
            csvDownloadOnlyFinishedOrders={props.csvDownloadOnlyFinishedOrders}
            page={props.page}
            filter={props.filter}
            sorting={props.sorting}
            fetchingPage={props.fetchingPage}
            fetchPage={props.fetchPage}
            columnFactory={columnFactory}
            onRow={props.onRow}
        />
    );
};
