import * as React from "react";
import { defineMessages, useIntl } from "react-intl";

import { ReactComponent as GHS01Icon } from "../../../../../../../assets/GHS01.svg";
import { ReactComponent as GHS02Icon } from "../../../../../../../assets/GHS02.svg";
import { ReactComponent as GHS03Icon } from "../../../../../../../assets/GHS03.svg";
import { ReactComponent as GHS04Icon } from "../../../../../../../assets/GHS04.svg";
import { ReactComponent as GHS05Icon } from "../../../../../../../assets/GHS05.svg";
import { ReactComponent as GHS06Icon } from "../../../../../../../assets/GHS06.svg";
import { ReactComponent as GHS07Icon } from "../../../../../../../assets/GHS07.svg";
import { ReactComponent as GHS08Icon } from "../../../../../../../assets/GHS08.svg";
import { ReactComponent as GHS09Icon } from "../../../../../../../assets/GHS09.svg";
import { IProduct } from "../../../../../../../types/order.types";
import { Box } from "../../../../../Box";
import { InfoField } from "../../../../../modals/customerservicemodal/panes/detailinfopane/sections/infofields/InfoField";
import { InfoLabel } from "../../../../../modals/customerservicemodal/panes/detailinfopane/sections/infofields/InfoLabel";
import Icon from "@ant-design/icons";

interface IProps {
    product: IProduct;
}

const MESSAGES = defineMessages({
    title: {
        id: "danger_codes.title",
        defaultMessage: "Gevarencodes"
    },
    instruction: {
        id: "danger_codes.instruction",
        defaultMessage: "Instructie",
    },
    labelNumber: {
        id: "danger_codes.label_number",
        defaultMessage: "Label"
    },
    danger: {
        id: "danger_codes.danger",
        defaultMessage: "Gevaar"
    },
    main: {
        id: "danger_codes.main",
        defaultMessage: "MainVlarem"
    },
    side: {
        id: "danger_codes.side",
        defaultMessage: "SideVlarem",
    },
    un: {
        id: "danger_codes.un",
        defaultMessage: "UN"
    },
    kemmler: {
        id: "danger_codes.kemmler",
        defaultMessage: "Kemmler"
    },
    flammable: {
        id: "danger_codes.flammable",
        defaultMessage: "Brandbaar"
    },
    adrInstructions: {
        id: "danger_codes.adr_instructions",
        defaultMessage: "ADR instructies"
    }
});

// TODO: think about a better way of doing this
// feels ugly
export const DangerCodes = (props: IProps) => {
    const intl = useIntl();

    const dangerCodesToArray = () => {
        const dangerCodes = [];

        if (props.product.instructionId) {
            dangerCodes.push(
                <>
                    <InfoField value={intl.formatMessage(MESSAGES.instruction)}/>
                    <InfoLabel text={props.product.instructionId}/>
                </>
            );
        }

        if (props.product.labelNumber) {
            dangerCodes.push(
                <>
                    <InfoField value={intl.formatMessage(MESSAGES.labelNumber)}/>
                    <InfoLabel text={props.product.labelNumber}/>
                </>
            );
        }

        if (props.product.hazardId) {
            dangerCodes.push(
                <>
                    <InfoField value={intl.formatMessage(MESSAGES.danger)}/>
                    <InfoLabel text={props.product.hazardId}/>
                </>
            );
        }

        if (props.product.pbmUnNumber) {
            dangerCodes.push(
                <>
                    <InfoField value={intl.formatMessage(MESSAGES.un)}/>
                    <InfoLabel text={props.product.pbmUnNumber}/>
                </>
            );
        }

        if (props.product.pbmKemmler) {
            dangerCodes.push(
                <>
                    <InfoField value={intl.formatMessage(MESSAGES.kemmler)}/>
                    <InfoLabel text={props.product.pbmKemmler}/>
                </>
            );
        }

        if (props.product.adrInstructions) {
            dangerCodes.push(
                <>
                    <InfoField value={intl.formatMessage(MESSAGES.adrInstructions)}/>
                    <InfoLabel text={props.product.pbmHeadProtection}/>
                </>
            );
        }

        if (props.product.pbmSkinProtectionDescription) {
            dangerCodes.push(
                <InfoField value={props.product.pbmSkinProtectionDescription} />
            );
        }

        return dangerCodes;
    };

    const dangerCodesArray = dangerCodesToArray();

    const renderVlarem = (title: string, vlarems: string[]) => {
        const vlaremIconMap = {
            GHS01: GHS01Icon,
            GHS02: GHS02Icon,
            GHS03: GHS03Icon,
            GHS04: GHS04Icon,
            GHS05: GHS05Icon,
            GHS06: GHS06Icon,
            GHS07: GHS07Icon,
            GHS08: GHS08Icon,
            GHS09: GHS09Icon
        };

        const vlaremElements = vlarems
            .map((vlarem, index) => {
                return (
                    <div key={vlarem} className={`flex flex-column ${index !== vlarems.length - 1 && "margin-right"}`}>
                        <InfoLabel text={vlarem}/>
                        {
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            vlaremIconMap[vlarem] && <Icon component={vlaremIconMap[vlarem]} className="icon-extra-large"/>
                        }
                    </div>
                );
            });

        return vlarems.length !== 0 && (
            <div className="margin-top">
                <InfoLabel text={title}/>
                <div className="flex flex-wrap margin-half-top">
                    {vlaremElements}
                </div>
            </div>
        );
    };

    return (
        <>
            <InfoLabel text={intl.formatMessage(MESSAGES.title)}/>
            <Box className="margin-half-top flex flex-column">
                <div className="flex">
                    <div style={{ width: "50%" }}>
                        {
                            dangerCodesArray.slice(0, dangerCodesArray.length / 2)
                                .map((dc, i) => <div key={i} className={`${i !== 0 && "margin-top"}`}>{dc}</div>)
                        }
                    </div>
                    <div style={{ width: "50%" }}>
                        {
                            dangerCodesArray.slice(dangerCodesArray.length / 2, dangerCodesArray.length)
                                .map((dc, i) => <div key={i} className={`${i !== 0 && "margin-top"}`}>{dc}</div>)
                        }
                    </div>
                </div>
                {
                    renderVlarem(intl.formatMessage(MESSAGES.main), props.product.mainVlarems)
                }
                {
                    renderVlarem(intl.formatMessage(MESSAGES.side), props.product.sideVlarems)
                }
            </Box>
        </>
    );
};
