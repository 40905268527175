import { ModalProps } from "antd/lib/modal";
import * as React from "react";
import { CleaningModal } from "../cleaningmodal/CleaningModal";
import { ConfirmationModalFooter } from "./ConfirmationModalFooter";

interface IProps extends ModalProps {
    children: React.ReactNode;
    closeModal: () => void;
    onConfirm: () => void;
    disabled?: boolean;
}

export const ConfirmationModal = (props: IProps) => {
    const onConfirm = () => {
        props.onConfirm();
        props.closeModal();
    };

    return (
        <CleaningModal
            {...props}
            closeModal={props.closeModal}
            footer={<ConfirmationModalFooter onConfirm={onConfirm} onBack={props.closeModal} disabled={props.disabled}/>}
        >
            {props.children}
        </CleaningModal>
    );
};
