import { PayloadAction } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { orderAllowedTransitionsFetchCreator } from "../../store/actions/order";
import { IApplicationState } from "../../store/state";
import { State } from "../../types/order.types";

export const useAllowedOrderTransitions = (): [State[], boolean, (orderId: number) => PayloadAction<number>] => {
    const dispatch = useDispatch();
    const transitions = useSelector<IApplicationState, State[]>(state => state.order.allowedTransitions);
    const fetchingTransitions = useSelector<IApplicationState, boolean>(state => state.order.fetchingAllowedTransitions);

    const fetch = (orderId: number) => dispatch(orderAllowedTransitionsFetchCreator(orderId));

    return [transitions, fetchingTransitions, fetch];
};
