import { combineReducers, createReducer } from "@reduxjs/toolkit";
import { setLanguageAction } from "../actions/language.actions";
import { ILanguageState, LanguageCode } from "../../types/language.types";

const activeLanguage = createReducer<LanguageCode>(LanguageCode.NL, (builder) => {
    builder.addCase(setLanguageAction, (language, action) => action.payload);
});

export const languageReducer = combineReducers<ILanguageState>({
    activeLanguage
});
