import * as React from "react";
import { useEffect } from "react";
import { IOrderFilter, IPageRequest, IResourceRequest } from "../../../types/api.types";
import { PlanningTable } from "../../components/tables/planningtable/PlanningTable";
import { IPlanningPageLoader, usePlanningPageLoader } from "../../hooks/pageloaders/usePlanningPageLoader";
import { IOrder, State } from "../../../types/order.types";
import { useOrderUpdate } from "../../hooks/useOrderUpdate";
import { planningListPrintCreator, planningUpdateDoneCreator } from "../../../store/actions/planning";
import { useDispatch } from "react-redux";

interface IProps {
    filter: IOrderFilter;
}

export const PlanningTableContainer = (props: IProps) => {
    const planningPageLoader: IPlanningPageLoader = usePlanningPageLoader();
    const dispatch = useDispatch();
    const [update] = useOrderUpdate(planningUpdateDoneCreator);

    useEffect(() => {
        const pageRequest: IPageRequest<IOrderFilter> = {
            pageNumber: 0,
            filter: { ...props.filter }
        };

        planningPageLoader.loadResource(pageRequest);
    }, []);

    const onPrint = (resourceRequest: IResourceRequest<IOrderFilter>) => {
        dispatch(planningListPrintCreator(resourceRequest));
    };

    const onCleanButtonClicked = (record: IOrder) => {
        update(record.id, { state: State.READY_FOR_CLEANING });
    };

    return (
        <PlanningTable
            onPrint={onPrint}
            sorting={planningPageLoader.resource?.sorting}
            filter={planningPageLoader.resource?.filter}
            onCleanButtonClicked={onCleanButtonClicked}
            fetchPage={planningPageLoader.loadResource}
            page={planningPageLoader.resource?.page || undefined}
            fetchingPage={planningPageLoader.loadingResource}
        />
    );
};
