import * as React from "react";
import { IContractor, ISupplier, IVisitor } from "../../../../../../types/checkIn.types";
import { Col, Row } from "antd";
import { defineMessages, useIntl } from "react-intl";
import { InfoLabel } from "../../../customerservicemodal/panes/detailinfopane/sections/infofields/InfoLabel";
import { InfoField } from "../../../customerservicemodal/panes/detailinfopane/sections/infofields/InfoField";
import { formatIso8601Date } from "../../../../../../utils/format";

interface IProps {
    person: ISupplier|IContractor|IVisitor;
}

const MESSAGES = defineMessages({
    firstName: {
        id: "person_info.first_name",
        defaultMessage: "Voornaam"
    },
    lastName: {
        id: "person_info.last_name",
        defaultMessage: "Achternaam"
    },
    dateOfBirth: {
        id: "person_info.date_of_birth",
        defaultMessage: "Geboortedatum"
    },
    company: {
        id: "person_info.company",
        defaultMessage: "Firma"
    },
    phoneNumber: {
        id: "driver_info.phone_number",
        defaultMessage: "GSM nummer"
    }
});

export const PersonInfo = (props: IProps) => {
    const intl = useIntl();

    return (
        <>
            <Row className="margin-bottom">
                <Col span={12}>
                    <InfoLabel text={intl.formatMessage(MESSAGES.firstName)}/>
                    <InfoField value={props.person.firstName}/>
                </Col>
                <Col span={12}>
                    <InfoLabel text={intl.formatMessage("dateOfBirth" in props.person ? MESSAGES.dateOfBirth : MESSAGES.lastName)}/>
                    <InfoField value={"dateOfBirth" in props.person ? formatIso8601Date(props.person.dateOfBirth) : props.person.lastName}/>
                </Col>
            </Row>
            {
                "company" in props.person ?
                    <Row className="margin-bottom">
                        <Col span={12}>
                            <InfoLabel text={intl.formatMessage(MESSAGES.lastName)}/>
                            <InfoField value={props.person.lastName}/>
                        </Col>

                        <Col span={12}>
                            <InfoLabel text={intl.formatMessage(MESSAGES.company)}/>
                            <InfoField value={props.person.company}/>
                        </Col>
                    </Row> :
                    null
            }
            {!!props.person.phoneNumber &&
            <Row className="margin-bottom">
                <Col span={12}>
                    <InfoLabel text={intl.formatMessage(MESSAGES.phoneNumber)}/>
                    <InfoField value={"+" + props.person.countryCallingCode + " - " + props.person.phoneNumber}/>
                </Col>
            </Row>
            }
        </>
    );
};
