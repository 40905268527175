import { useDispatch, useSelector } from "react-redux";
import { orderLoadCreator } from "../../store/actions";
import { loadingOrderSelector, orderSelector } from "../../store/selectors/order.selector";
import { IApplicationState } from "../../store/state";
import { IOrder } from "../../types/order.types";

export const useOrder = (): [IOrder | null, boolean, (orderId: number) => ReturnType<typeof orderLoadCreator>] => {
    const dispatch = useDispatch();
    const order = useSelector<IApplicationState, IOrder | null>(orderSelector);
    const loadingOrder = useSelector<IApplicationState, boolean>(loadingOrderSelector);

    const load = (orderId: number) => dispatch(orderLoadCreator(orderId));

    return [order, loadingOrder, load];
};
