import * as React from "react";
import { useDispatch } from "react-redux";
import { checklistStepSubmitActionCreator } from "../../../store/actions/checklist";
import { IChecklistEntryStepSubmit } from "../../../types/api.types";
import { ChecklistItem } from "../../../types/checklist.types";
import { ManualWorkForm } from "../../components/tabbars/cleaningtabs/cleaningtab/compartmenttab/checklist/forms/ManualWorkForm";

interface IProps {
    prev: () => void;
    checklistId: number;
    compartmentId: number;
}

export const ManualWorkFormContainer = (props: IProps) => {
    const dispatch = useDispatch();

    const submit = (manualWorkNeeded: boolean, msg?: string) => {
        const checklistEntryStepSubmit: IChecklistEntryStepSubmit = {
            errorDetected: false,
            customerMessage: msg,
            stepOrder: 1,
            checklistItem: ChecklistItem.POST_OPERATION_CHECKLIST,
            formFields: manualWorkNeeded.toString(),
            restFound: false,
            manualWorkNeeded: manualWorkNeeded
        };

        dispatch(checklistStepSubmitActionCreator({
            checklistId: props.checklistId,
            checklistEntryStepSubmit,
            compartmentId: props.compartmentId
        }));
    };

    return (
        <ManualWorkForm
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            prev={props.prev}
            onOk={submit}
        />
    );
};
