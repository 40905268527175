import * as React from "react";
import { ICheckIn, IContainer, TruckComposition } from "../../../../../../types/checkIn.types";
import { defineMessages, useIntl } from "react-intl";
import { Alert, Col, Row } from "antd";
import { InfoLabel } from "../../../customerservicemodal/panes/detailinfopane/sections/infofields/InfoLabel";
import { InfoField } from "../../../customerservicemodal/panes/detailinfopane/sections/infofields/InfoField";
import { VehicleInfo } from "./VehicleInfo";
import { OrderAction } from "../../../../../../types/salesOrder.types";
import { BoolInfoField } from "../../../customerservicemodal/panes/detailinfopane/sections/infofields/BoolInfoField";
import { KilogramDisplay } from "../../../../displays/KilogramDisplay";
import { useSelector } from "react-redux";
import { checkInRecordHasExpiredByAdrType } from "../../../../../../store/selectors/checkInOverview.selector";
import { AdrTypes } from "../../../../../../types/adr.types";

interface IProps {
    container: IContainer;
    checkIn: ICheckIn;
    showAdrInfo: boolean;
}

const MESSAGES = defineMessages({
    container: {
        id: "container_info.container",
        defaultMessage: "Container"
    },
    containerNumber: {
        id: "container_info.container_number",
        defaultMessage: "Container nummer"
    },
    numberOfCompartments: {
        id: "container_info.number_of_compartments",
        defaultMessage: "Aantal vakken"
    },
    adrCode: {
        id: "container_info.adr_code",
        defaultMessage: "ADR code"
    },
    adrExpirationDate: {
        id: "container_info.adr_expiration_date",
        defaultMessage: "ADR vervaldatum"
    },
    adrExpiredWarning: {
        id: "container_info.adr_expired_warning",
        defaultMessage: "ADR code is vervallen."
    },
    lastContainerCheck: {
        id: "container_info.last_container_check",
        defaultMessage: "Datum laatste keuring"
    },
    tankCode: {
        id: "container_info.tank_code",
        defaultMessage: "Tankcode"
    },
    tarra: {
        id: "container_info.tarra",
        defaultMessage: "Tarra"
    },
    flexibag: {
        id: "container_info.flexibag",
        defaultMessage: "Flexibag"
    },
    flexibagCapacity: {
        id: "container_info.flexibag_capacity",
        defaultMessage: "Flexibag capaciteit (liter)"
    },
});

export const ContainerInfo = (props: IProps) => {
    const intl = useIntl();
    const hasLoadOrder = !!(props.checkIn.salesOrders || []).find((order) => order.orderAction === OrderAction.LOAD);
    const containerAdrExpired = useSelector(checkInRecordHasExpiredByAdrType(AdrTypes.CONTAINER));

    return (
        <VehicleInfo title={intl.formatMessage(MESSAGES.container)}>
            <Row className="margin-bottom">
                <Col span={12}>
                    <InfoLabel text={intl.formatMessage(MESSAGES.containerNumber)}/>
                    <InfoField value={props.container.containerNumber}/>
                </Col>
                <Col span={12}>
                    <InfoLabel text={intl.formatMessage(MESSAGES.numberOfCompartments)}/>
                    <InfoField value={props.checkIn.compartmentDistributions.length}/>
                </Col>
            </Row>
            {
                props.container.adrCertificate &&
                <>
                    {
                        props.showAdrInfo && (
                            <Row className="margin-bottom">
                                <Col span={12}>
                                    <InfoLabel text={intl.formatMessage(MESSAGES.adrCode)}/>
                                    <InfoField value={props.container.adrCertificate.adrCode}/>
                                </Col>
                                <Col span={12}>
                                    <InfoLabel text={intl.formatMessage(MESSAGES.lastContainerCheck)}/>
                                    <InfoField value={props.container.adrCertificate.adrExpirationDate}/>
                                </Col>
                            </Row>
                        )
                    }
                    {
                        props.showAdrInfo && containerAdrExpired &&
                        <Row className="margin-bottom">
                            <Col span={24}>
                                <Alert message={intl.formatMessage(MESSAGES.adrExpiredWarning)}
                                    type={"error"}
                                />
                            </Col>

                        </Row>
                    }
                </>
            }
            <Row className="no-margin">
                {
                    props.container.adrCertificate && hasLoadOrder && (
                        <Col span={12}>
                            <InfoLabel text={intl.formatMessage(MESSAGES.tankCode)}/>
                            <InfoField value={props.container.tankCode} />
                        </Col>
                    )
                }
                {
                    hasLoadOrder && props.container.tareWeight && (
                        <Col span={12}>
                            <InfoLabel text={intl.formatMessage(MESSAGES.tarra)}/>
                            <KilogramDisplay number={props.container.tareWeight} />
                        </Col>
                    )
                }
            </Row>
            {
                props.checkIn.truckComposition === TruckComposition.TRACTOR_CHASSIS_CONTAINER_FLEXIBAG && (
                    <Row className="margin-bottom">
                        <Col span={12}>
                            <InfoLabel text={intl.formatMessage(MESSAGES.flexibag)}/>
                            <BoolInfoField value={!!props.checkIn.flexibagCapacity} />
                        </Col>
                        <Col span={12}>
                            <InfoLabel text={intl.formatMessage(MESSAGES.flexibagCapacity)}/>
                            <InfoField value={props.checkIn.flexibagCapacity} />
                        </Col>
                    </Row>
                )
            }
        </VehicleInfo>
    );
};
