import FullCalendar, { EventInput } from "@fullcalendar/react";
import { EventApi } from "@fullcalendar/common";
import nlLocale from "@fullcalendar/core/locales/nl";
import interactionPlugin from "@fullcalendar/interaction";
import { ColCellContentArg, Resource } from "@fullcalendar/resource-common";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import { Dayjs } from "dayjs";
import React from "react";
import { defineMessages, injectIntl, IntlShape } from "react-intl";
import { FULL_CALENDAR_KEY } from "../../../config/config";
import { IEventInfo, IFailureCallback, ISuccessCallback } from "../../../types/bookedSlot.types";
import { IPit } from "../../../types/salesOrder.types";
import { TimeLineEventContainer } from "../../containers/timelinecontainers/TimeLineEventContainer";

interface IProps {
    pits: IPit[];
    events: (info: IEventInfo, successCallback: ISuccessCallback, failureCallback: IFailureCallback) => (void | PromiseLike<EventInput[]>);
    resources: any[];
    date: Dayjs;
    intl: IntlShape;
    setCalendarApi: (val: any) => void;
}

interface IState {
    modalVisible: boolean;
    clickedEvent: EventApi | undefined;
}

const MESSAGES = defineMessages({
    resourceTitle: {
        id: "calendar.resources.title",
        defaultMessage: "Putten",
    },
    resourceGroupName: {
        id: "calendar.resources.name",
        defaultMessage: "Put {name}",
    },
});

class TimeLineViewComponent extends React.Component<IProps, IState> {
    timeLineRef = React.createRef<FullCalendar>();

    constructor(props: Readonly<IProps>) {
        super(props);
        this.state = {
            modalVisible: false,
            clickedEvent: undefined
        };
    }

    componentDidMount() {
        if (this.timeLineRef.current) {
            this.props.setCalendarApi(this.timeLineRef.current.getApi());
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>) {
        if (prevProps.date.toISOString() !== this.props.date.toISOString()) {
            this.changeDate();
        }
    }

    changeDate() {
        const { current } = this.timeLineRef;

        if (current) {
            current.getApi().gotoDate(this.props.date.toISOString());
        }
    }

    getGroupLabel = (value: ColCellContentArg) => {
        const pit = this.props.pits.find(pit => pit.id === value.groupValue) || { name: "" };
        return this.props.intl.formatMessage(MESSAGES.resourceGroupName, { name: pit.name });
    };

    getLabel = ({ resource }: { resource: Resource }) => {
        return resource.extendedProps.name;
    };

    getTitleFormat() {
        return {
            month: "long",
            year: "numeric",
            day: "numeric",
            weekday: "long"
        };
    }

    getHeaderToolBar() {
        return {
            left: "",
            right: ""
        };
    }

    onEventMouseEnter = ({ el }: { el: HTMLElement }) => {
        el.classList.add("fc-timeline-event-active");
    };

    onEventMouseLeave = ({ el }: { el: HTMLElement }) => {
        el.classList.remove("fc-timeline-event-active");
    };

    render() {
        return <FullCalendar
            ref={this.timeLineRef}
            locale={nlLocale}
            schedulerLicenseKey={FULL_CALENDAR_KEY}
            plugins={[resourceTimelinePlugin, interactionPlugin]}
            initialView="resourceTimeline"
            contentHeight="auto"
            initialDate={this.props.date.toISOString()}
            weekends={false}
            selectable={true}
            selectOverlap
            resourceGroupField="pitId"
            nowIndicator
            aspectRatio={5}
            slotMinWidth={20}
            resourceAreaWidth={110}
            resourceGroupLabelContent={this.getGroupLabel}
            resourceLabelContent={this.getLabel}
            slotDuration="00:15:00"
            slotMinTime="06:00:00"
            slotMaxTime="20:00:00"
            eventStartEditable={false}
            editable={false}
            headerToolbar={this.getHeaderToolBar()}
            resources={this.props.resources}
            events={this.props.events}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            titleFormat={this.getTitleFormat()}
            resourceAreaHeaderContent={this.props.intl.formatMessage(MESSAGES.resourceTitle)}
            eventContent={(arg) => (
                <TimeLineEventContainer
                    args={arg}
                    clickedEvent={this.state.clickedEvent}
                    selectedBookedSlotId={this.state.clickedEvent ? parseInt(this.state.clickedEvent.id) : undefined}
                    modalVisible={this.state.modalVisible}
                    setModalVisible={(isVisible) => this.setState({ modalVisible: isVisible })}
                />
            )}
            eventMouseEnter={this.onEventMouseEnter}
            eventMouseLeave={this.onEventMouseLeave}
            eventClick={(info) => {
                this.setState({ modalVisible: true, clickedEvent: info.event });
            }}
        />;
    }
}

export const TimeLineView = injectIntl(TimeLineViewComponent);
