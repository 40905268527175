import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import { SlotBookingSlotsContainer } from "../../containers/SlotBookingSlotsContainer";
import { Box } from "../Box";
import { Spin } from "antd";
import { useSlot } from "../../hooks/useSlot";

const MESSAGES = defineMessages({
    slots: {
        id: "sale_order_booking_slots_section.available_slots",
        defaultMessage: "Beschikbare slots",
    }
});

interface IProps {
    initialSlotStartTime?: string;
    initialSlotEndTime?: string;
    initialDate?: string;
    productionOrderReference: string;
}

export const SlotBookingSlots = (props: IProps) => {
    const intl = useIntl();
    const [slots,, loading] = useSlot();

    return loading? (
        <div className={"flex flex-column"} >
            <h3 className="slots-header">{intl.formatMessage(MESSAGES.slots)}</h3>
            <Spin />
        </div>
    ) :
        slots.length !== 0 ? (
            <div className={"flex flex-column"} >
                <h3 className="slots-header">{intl.formatMessage(MESSAGES.slots)}</h3>
                <Box className="margin-half-top margin-right inline-flex">
                    <SlotBookingSlotsContainer
                        initialDate={props.initialDate}
                        initialSlotStartTime={props.initialSlotStartTime}
                        initialSlotEndTime={props.initialSlotEndTime}
                        productionOrderReference={props.productionOrderReference}
                    />
                </Box>
            </div>
        ) : null;
};
