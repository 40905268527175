import { ICheckInFilter } from "../types/api.types";
import {
    AdrVehicleSpecification,
    CheckInReason,
    CheckInState,
    IAdrCertificate,
    IChassis,
    ICheckIn,
    ICompartment,
    IContainer,
    IDriver,
    ITanker,
    ITractor,
    TransportationMethod
} from "../types/checkIn.types";

export const adrCertificateMock: IAdrCertificate = {
    adrCode: "codde",
    adrExpirationDate: "expo",
};

export const driverMock: IDriver = {
    firstName: "first",
    lastName: "last",
    transportCompany: "opm",
    adrCertificate: adrCertificateMock,
    dateOfBirth: "dtbrt",
    countryCallingCode: "32",
    phoneNumber: "497746443",
};

export const compartmentMock: ICompartment = {
    capacity: 1,
    nitrogen: true,
    cleaned: true,
    restProduct: true,
    order: 1,
    loadAmount: 1,
    productionOrderId: 1,
    toleranceLoadAmount: 0,
    toleranceLoadAmountInLiter: 0,
    loadAmountInLiter: 0,
    shouldUnload: false
};

export const containerMock: IContainer = {
    containerNumber: "number",
    adrCertificate: adrCertificateMock,
    tareWeight: 0,
    tankCode: "lol",
};

export const tractorMock: ITractor = {
    id: 1,
    licensePlate: "plate",
    adrCertificate: adrCertificateMock,
    adrVehicleSpecification: AdrVehicleSpecification.FL
};

export const chassisMock: IChassis = {
    id: 1,
    licensePlate: "plate"
};

export const tankerMock: ITanker = {
    id: 1,
    licensePlate: "plate",
    adrCertificate: adrCertificateMock,
};

export const checkInMock: ICheckIn = {
    assignedTo: "John Doe",
    id: 1,
    cmrProductName: "cmr product",
    cancelReason: "test",
    driver: driverMock,
    container: containerMock,
    tractor: tractorMock,
    chassis: chassisMock,
    tanker: tankerMock,
    checkInReason: CheckInReason.BULK,
    checkInState: CheckInState.IN_PROGRESS,
    extendedCheckInState: CheckInState.IN_PROGRESS,
    transportationMethod: TransportationMethod.ROAD,
    borderCrossingLocation: "border",
    cmrWeightDispatcher: 50000,
    cmrWeightDriver: 10000,
    lenelBadge: "123456",
    wiegandBadge: null,
    compartmentDistributions: [compartmentMock],
    weightCalculation: null,
    exemptAdr: false,
    completedOn: null,
    initialWeight: 17000,
    weighingDate: "2021-12-01 14:52:29.0",
    overridden: false,
    maxWeightExceeded: false,
    isEditable: true,
    adrExpired: false,
    temperatureProduct: 20,
    remarks: [],
    requiresWeightBadge: false
};

export const checkInsPageFetchParams: ICheckInFilter = { archived: false };

export const checkInsMock: ICheckIn[] = [
    checkInMock
];
