import { createSelector } from "@reduxjs/toolkit";
import { IChecklistState } from "../reducers/checklist";
import { IApplicationState } from "../state";

const checklistStateSelector = (state: IApplicationState) => state.checklist;

export const checklistsSelector = createSelector(
    checklistStateSelector,
    (checklistState: IChecklistState) => checklistState.checklists
);

export const fetchingChecklistsSelector = createSelector(
    checklistStateSelector,
    (checklistState: IChecklistState) => checklistState.fetching
);

export const submittingChecklistStepSelector = createSelector(
    checklistStateSelector,
    (checklistState: IChecklistState) => checklistState.submitting
);
