import React from "react";
import { useSelector } from "react-redux";
import { externalViewOrdersSelector } from "../../store/selectors/externalView.selector";
import { IOrder } from "../../types/order.types";
import { OrderInfo } from "../components/modals/customerservicemodal/panes/detailinfopane/OrderInfo";
import { useNotification } from "../hooks/useNotification";

interface IProps {
    orderId: number;
}

export const ExternalViewOrderInfoContainer = (props: IProps) => {
    const [, createNotification] = useNotification();
    const externalViewOrders: IOrder[] = useSelector(externalViewOrdersSelector);
    const order: IOrder | undefined = externalViewOrders.find(order => order.id === props.orderId);

    return !order ? null :
        <OrderInfo
            order={order}
            createNotification={createNotification}
            savingDateParkingIn={false}
            savingDateParkingOut={false}
            savingPriority={false}
            isInternalView={false}
        />;
};
