import { PayloadActionCreator } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { orderUpdateCreator } from "../../store/actions";
import { ordersBeingUpdatedSelector } from "../../store/selectors/order.selector";
import { IApplicationState } from "../../store/state";
import { IOrderUpdateActionPayload } from "../../types/action.types";
import { IOrderUpdateDto } from "../../types/api.types";

export const useOrderUpdate = (updateDoneAction: PayloadActionCreator<IOrderUpdateActionPayload>): [(id: number, orderUpdateDto: IOrderUpdateDto) => { payload: IOrderUpdateActionPayload & { doneAction?: PayloadActionCreator<IOrderUpdateActionPayload> }; type: string } & any, { [p: number]: IOrderUpdateDto }] => {
    const dispatch = useDispatch();

    const ordersBeingUpdated = useSelector<IApplicationState, { [orderId: number]: IOrderUpdateDto }>(ordersBeingUpdatedSelector);
    const update = (id: number, orderUpdateDto: IOrderUpdateDto) => dispatch(orderUpdateCreator({ orderId: id, orderUpdateDto, doneAction: updateDoneAction }));

    return [update, ordersBeingUpdated];
};
