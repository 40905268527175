import * as React from "react";
import { AppLayout } from "./AppLayout";
import { Typography } from "antd";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { UploadArea } from "../components/areas/UploadArea";
import { buildUrl, URL_PATHS } from "../../constants/api.constants";

const { Title } = Typography;

const MESSAGES = defineMessages({
    uploadMessage: {
        id: "checkin.blacklists.upload_area.hint",
        defaultMessage: "Selecteer een CSV bestand om te uploaden in de blacklist.",
    },
});

export const DriverBlacklistScreen = () => {
    const intl = useIntl();

    return (
        <AppLayout>
            <Title level={2}>
                <FormattedMessage id="checkin.blacklists.title" defaultMessage="Upload blacklist" />
            </Title>

            <div style={{ minHeight: 250 }}>
                <UploadArea
                    formattedMessage={intl.formatMessage(MESSAGES.uploadMessage)}
                    action={buildUrl(URL_PATHS.CHECKIN, "driver-blacklists")}
                />
            </div>

            <p>
                <strong><FormattedMessage id="checkin.blacklists.explain" defaultMessage="Volgende velden zijn verplicht in de CSV" />:</strong>
                <ul>
                    <li>firstName</li>
                    <li>lastName</li>
                    <li>dateOfBirth (YYYY-MM-DD)</li>
                    <li>blacklistFrom (YYYY-MM-DD)</li>
                    <li>blacklistUntil (YYYY-MM-DD)</li>
                    <li>blacklistRemarks</li>
                </ul>
            </p>
        </AppLayout>
    );
};
