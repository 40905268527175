import * as React from "react";
import { ReactComponent as AnchorIcon } from "../../../assets/anchor.svg";
import { ReactComponent as DrumIcon } from "../../../assets/drum.svg";
import { ReactComponent as ToolIcon } from "../../../assets/tool.svg";
import { ReactComponent as TrainIcon } from "../../../assets/train.svg";
import { IScheduledMaintenance } from "../../../types/scheduledMaintenance.types";
import Icon from "@ant-design/icons";

interface IProps {
    scheduledMaintenance: IScheduledMaintenance;
}

export const ScheduledMaintenanceEvent = (props: IProps) => {
    const iconFactory = () => {
        switch (props.scheduledMaintenance.reason.toLowerCase()) {
        case "ship":
            return <Icon component={AnchorIcon}/>;
        case "maintenance":
            return <Icon component={ToolIcon}/>;
        case "trein":
            return <Icon component={TrainIcon}/>;
        case "drumming":
            // TODO: add font awesome license to project
            return <Icon component={DrumIcon}/>;
        }
    };

    return (
        <div className="fc-timeline-event-maintenance padded-half-left">
            {iconFactory()}
            <span className="padded-half-left">{props.scheduledMaintenance.reason}</span>
        </div>
    );
};
