import * as React from "react";
import { CleaningTabsContainer } from "../containers/CleaningTabsContainer";
import { AppLayout } from "./AppLayout";

export const CleaningScreen = () => {
    return (
        <AppLayout>
            <CleaningTabsContainer/>
        </AppLayout>
    );
};
