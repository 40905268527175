export class MsalLock {
    key = "msal.lock";

    public lock() {
        if (window && window.localStorage) {
            console.debug(`Locking login process with key ${this.key}`);
            localStorage.setItem(this.key, new Date().toString());
        }
    }

    public unlock() {
        if (window && window.localStorage) {
            if (this.hasLock()) {
                console.debug(`Unlocking login process with key ${this.key}`);
                localStorage.removeItem(this.key);
            }
        }
    }

    public hasLock(): boolean {
        if (window && window.localStorage) {
            return localStorage.getItem(this.key) != null;
        }
        return false;
    }
}
