import { getHttpClient } from "./httpClient";
import { ITtatCalculationFetchParams } from "../../types/ttatCalculation.types";
import { URL_PATHS } from "../../constants/api.constants";

const baseUrl = URL_PATHS.BOOKING + "/ttat-calculations";

const getTtatCalculation = (ttatCalculationFetchParams: ITtatCalculationFetchParams) => getHttpClient().get(baseUrl, { params: ttatCalculationFetchParams });

export const ttatCalculationApi = {
    getTtatCalculation,
};
