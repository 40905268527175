import { Button } from "antd";
import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import { CheckInState, ICheckIn } from "../../../../../types/checkIn.types";
import { useSelector } from "react-redux";
import { nameSelector } from "../../../../../store/selectors/authentication.selector";
import { IApplicationState } from "../../../../../store/state";
import { isValidatableCheckIn } from "../../../../../store/selectors/checkInOverview.selector";

interface IProps {
  onClick: () => void;
  checkIn: ICheckIn | null;
}

const MESSAGES = defineMessages({
    validate: {
        id: "check_in_modal_footer.validate",
        defaultMessage: "Bevestig validatie"
    }
});

export const ValidateCheckinButton = (props: IProps) => {
    const intl = useIntl();
    const name = useSelector<IApplicationState, string>(nameSelector);
    const isEnabled = useSelector<IApplicationState, boolean>(isValidatableCheckIn);

    return props.checkIn && props.checkIn.checkInState === CheckInState.PROCESSING && name === props.checkIn.assignedTo ? (
        <Button onClick={props.onClick} type="primary" disabled={!isEnabled}>
            {intl.formatMessage(MESSAGES.validate)}
        </Button>
    ) : null;
};
