import { Button } from "antd";
import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import { CheckInState, ICheckIn } from "../../../../../types/checkIn.types";

interface IProps {
  onClick: () => void;
  checkIn: ICheckIn | null;
}

const MESSAGES = defineMessages({
    unAssign: {
        id: "check_in_modal_footer.un_assign",
        defaultMessage: "Terug in wachtrij",
    }
});

export const UnAssignCheckinButton = (props: IProps) => {
    const intl = useIntl();

    if (props.checkIn) {
        const isProcessing = props.checkIn.checkInState === CheckInState.PROCESSING;
        const isReadyForOperations = props.checkIn.checkInState === CheckInState.READY_FOR_OPERATIONS;

        if (isProcessing || isReadyForOperations) {
            return <Button onClick={props.onClick} type="default">
                {intl.formatMessage(MESSAGES.unAssign)}
            </Button>;
        }

        return null;
    } else {
        return null;
    }
};
