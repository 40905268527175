import { useDispatch, useSelector } from "react-redux";
import {
    salesOrderListFetchCreator,
    salesOrderListReceivedCreator } from "../../store/actions";
import { PayloadAction } from "@reduxjs/toolkit";
import { IApplicationState } from "../../store/state";
import { ISalesOrder, ISalesOrderListFetchParams } from "../../types/salesOrder.types";

export const useSalesOrderList = (): [
    ISalesOrder[],
    (salesOrderListFetch: ISalesOrderListFetchParams) => PayloadAction<{ salesOrderListFetchParams: ISalesOrderListFetchParams }>,
    (salesOrderList: ISalesOrder[]) => void
    ] => {
    const salesOrderList = useSelector<IApplicationState, ISalesOrder[]>(state => state.salesOrderList);
    const dispatch = useDispatch();
    const fetch = (salesOrderListFetchParams: ISalesOrderListFetchParams) => dispatch(salesOrderListFetchCreator({ salesOrderListFetchParams }));
    const assign = (salesOrderList: ISalesOrder[]) => dispatch(salesOrderListReceivedCreator(salesOrderList));

    return [salesOrderList, fetch, assign];
};
