import { Button, Table } from "antd";
import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import { ReactComponent as PrintIcon } from "../../../../assets/print.svg";
import { PRIORITY_COLOR_CLASSES } from "../../../../theme/typescript/colors";
import { IOrderFilter, IPage, IPageRequest, IResourceRequest, ISort } from "../../../../types/api.types";
import { IOrder } from "../../../../types/order.types";
import {
    containerLocationColumn,
    dateParkingInColumn,
    productColumn,
    sapIdColumn,
    stateColumn
} from "../ordertable/OrderTableColumns";
import { tableButtonToCleanRenderer, tableDateTimeRenderer } from "../renderers";
import { ItemTable } from "../ItemTable";
import { PayloadAction } from "@reduxjs/toolkit";
import Icon from "@ant-design/icons";
import dayjs from "dayjs";

interface IProps {
    fetchPage: (pageRequest: IPageRequest<IOrderFilter>) => PayloadAction<IPageRequest<IOrderFilter>>;
    page?: IPage<IOrder>;
    fetchingPage: boolean;
    sorting?: ISort;
    filter?: IOrderFilter;
    onPrint: (resourceRequest: IResourceRequest<IOrderFilter>) => void;
    onCleanButtonClicked: (record: IOrder) => void;
}

const MESSAGES = defineMessages({
    plannedBy: {
        id: "planning_table.planned_by",
        defaultMessage: "Gepland tegen",
    },
    cleaned: {
        id: "planning_table.leaned",
        defaultMessage: "Plaats in cleaning",
    },
    containerNumber: {
        id: "planning_table.container_number",
        defaultMessage: "Container nr."
    },
    product: {
        id: "planning_table.product",
        defaultMessage: "Product",
    },
    status: {
        id: "planning_table.status",
        defaultMessage: "Status"
    },
    containerFilterPlaceholder: {
        id: "customer_service_table.filter.container.placeholder",
        defaultMessage: "Container nummer"
    },
    print: {
        id: "planning_table.print",
        defaultMessage: "Afdrukken"
    }
});

export const PlanningTable = (props: IProps) => {
    const intl = useIntl();

    const onCell = (record: IOrder) => {
        const cellProps: { className?: string } = { className: undefined };

        if (!record.priority) {
            return cellProps;
        }

        // We truncate to day because otherwise time might cause two separate days to be within a 24h hour timespan
        const priority = dayjs(record.priority).startOf("day");
        const now = dayjs().startOf("day");

        if (!now.diff(priority, "days")) { // priority is today
            cellProps.className = PRIORITY_COLOR_CLASSES.TODAY;
        } else if (now.diff(priority, "days") === -1 && now.isBefore(priority)) { // priority is tomorrow
            cellProps.className = PRIORITY_COLOR_CLASSES.TOMORROW;
        }

        return cellProps;
    };

    const columnFactory = (onFilter: (filter: IOrderFilter) => any) => [
        <Table.Column
            key="priority"
            dataIndex="priority"
            title={intl.formatMessage(MESSAGES.plannedBy)}
            sorter
            render={tableDateTimeRenderer}
            onCell={onCell}
        />,

        sapIdColumn(intl, onFilter, props.filter),

        productColumn(intl, false),

        stateColumn(intl, false),

        containerLocationColumn(intl),

        dateParkingInColumn(intl, false),

        <Table.Column
            key="clean"
            dataIndex="clean"
            title={intl.formatMessage(MESSAGES.cleaned)}
            render={(text: string, record: IOrder) => tableButtonToCleanRenderer(text, record, intl, props.onCleanButtonClicked)}
            align="center"
            onCell={onCell}
        />,
    ];

    const tableId = "planning-table-id";

    const onPrint = () => {
        if (props.filter) {
            const resourceRequest: IResourceRequest<IOrderFilter> = {
                sorting: props.sorting,
                filter: props.filter
            };

            props.onPrint(resourceRequest);
        }
    };

    return (
        <>
            <Button onClick={onPrint}>
                <Icon component={PrintIcon}/>
                {intl.formatMessage(MESSAGES.print)}
            </Button>
            <div id={tableId} className="margin-half-top">
                <ItemTable
                    fetching={props.fetchingPage}
                    fetch={props.fetchPage}
                    data={props.page?.content || []}
                    pageMeta={props.page?.meta}
                    sorting={props.sorting}
                    filter={props.filter}
                    columnFactory={columnFactory}
                />
            </div>
        </>
    );
};
