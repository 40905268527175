import { getHttpClient } from "./httpClient";
import { URL_PATHS } from "../../constants/api.constants";

const baseUrl = URL_PATHS.CLEANING + "/transitions/";

const getAllowedTransitions = (orderId: number) => getHttpClient().get(`${baseUrl}${orderId}`);

export const stateApi = {
    getAllowedTransitions
};
