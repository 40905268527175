import * as React from "react";
import { AppLayout } from "./AppLayout";
import { defineMessages, useIntl } from "react-intl";

const MESSAGES = defineMessages({
    forbidden: {
        id: "forbidden_screen.forbidden",
        defaultMessage: "U heeft niet de juiste rechten om deze pagina te bekijken. Gelieve uw administrator te contacteren.",
    }
});

export const ForbiddenScreen = () => {
    const intl = useIntl();

    return (
        <AppLayout>
            <h4>{intl.formatMessage(MESSAGES.forbidden)}</h4>
        </AppLayout>
    );
};
