import { defineMessages, IntlShape } from "react-intl";
import { ICleaningMessagePermissions, ICleaningMessageTitles } from "../types/message.types";

const customerServiceMessagePermissions: ICleaningMessagePermissions = {
    fromCleaningVisible: true,
    fromCleaningEditable: false,

    toCleaningVisible: true,
    toCleaningEditable: true,

    fromItactVisible: true,
    fromItactEditable: false,

    toItactVisible: true,
    toItactEditable: true,

    beforeLeaveMessageVisible: true,
    beforeLeaveMessageEditable: true
};

const itactMessagePermissions: ICleaningMessagePermissions = {
    fromCleaningVisible: false,
    fromCleaningEditable: false,

    toCleaningVisible: false,
    toCleaningEditable: false,

    fromItactVisible: true,
    fromItactEditable: true,

    toItactVisible: true,
    toItactEditable: false,

    beforeLeaveMessageVisible: false,
    beforeLeaveMessageEditable: false
};

const ITACT_MESSAGE_TITLES = defineMessages({
    fromItactMessage: {
        id: "itact_messages.from_itact",
        defaultMessage: "Voor Customer Service",
    },
    toItactMessage: {
        id: "itact_messages.to_itact",
        defaultMessage: "Van Customer Service",
    },
});

const itactMessageTitleFactory = (intl: IntlShape): ICleaningMessageTitles => ({
    fromItactTitle: intl.formatMessage(ITACT_MESSAGE_TITLES.fromItactMessage),
    toItactTitle: intl.formatMessage(ITACT_MESSAGE_TITLES.toItactMessage)
});

const CUSTOMER_SERVICE_MESSAGE_TITLES = defineMessages({
    fromCleaningMessage: {
        id: "messages.from_cleaning",
        defaultMessage: "Van cleaning",
    },
    toCleaningMessage: {
        id: "messages.to_cleaning",
        defaultMessage: "Voor cleaning",
    },
    fromItactMessage: {
        id: "messages.from_itact",
        defaultMessage: "Van ITACT",
    },
    toItactMessage: {
        id: "messages.to_itact",
        defaultMessage: "Voor ITACT",
    },
    beforeLeaveMessage: {
        id: "messages.before_leave_message",
        defaultMessage: "Voor vertrek",
    }
});

const customerServiceTitleFactory = (intl: IntlShape): ICleaningMessageTitles => ({
    fromCleaningTitle: intl.formatMessage(CUSTOMER_SERVICE_MESSAGE_TITLES.fromCleaningMessage),
    toCleaningTitle: intl.formatMessage(CUSTOMER_SERVICE_MESSAGE_TITLES.toCleaningMessage),
    fromItactTitle: intl.formatMessage(CUSTOMER_SERVICE_MESSAGE_TITLES.fromItactMessage),
    toItactTitle: intl.formatMessage(CUSTOMER_SERVICE_MESSAGE_TITLES.toItactMessage),
    beforeLeaveMessageTitle: intl.formatMessage(CUSTOMER_SERVICE_MESSAGE_TITLES.beforeLeaveMessage)
});

export const MESSAGE_PERMISSIONS = {
    itact: itactMessagePermissions,
    customerService: customerServiceMessagePermissions
};

export const MESSAGE_TITLES_FACTORIES = {
    itact: itactMessageTitleFactory,
    customerService: customerServiceTitleFactory
};
