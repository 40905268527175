import * as React from "react";
import { useDispatch } from "react-redux";
import { checklistStepSubmitActionCreator } from "../../../store/actions/checklist";
import { IChecklistEntryStepSubmit } from "../../../types/api.types";
import { ChecklistItem } from "../../../types/checklist.types";
import { CleaningDoneForm } from "../../components/tabbars/cleaningtabs/cleaningtab/compartmenttab/checklist/forms/CleaningDoneForm";

interface IProps {
    checklistId: number;
    compartmentId: number;
}

export const CleaningDoneFormContainer = (props: IProps) => {
    const dispatch = useDispatch();

    const onOk = (submittedBy: string) => {
        const checklistEntryStepSubmit: IChecklistEntryStepSubmit = {
            errorDetected: false,
            stepOrder: 0,
            checklistItem: ChecklistItem.CONTAINER_DONE,
            restFound: false,
            manualWorkNeeded: false,
            submittedBy
        };

        dispatch(checklistStepSubmitActionCreator({
            checklistId: props.checklistId,
            checklistEntryStepSubmit,
            compartmentId: props.compartmentId
        }));
    };

    return <CleaningDoneForm onOk={onOk}/>;
};
