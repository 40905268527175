import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { errorHandler } from "./errorHandler";
import {
    activateBadgeActionCreator,
    checkInAddLinkSalesOrderToManualOrderActionCreator,
    checkInAssignActionCreator,
    checkInCancelActionCreator,
    checkInDeleteActionCreator,
    checkInFinishActionCreator,
    checkInGetSalesOrderIdForLinkActionCreator,
    checkInPageFetchActionCreator,
    checkInPageFetchFailedActionCreator,
    checkInPageLoadActionCreator,
    checkInPageReceivedActionCreator,
    checkInRecordLoadActionCreator,
    checkInRecordReceivedActionCreator,
    checkInReopenActionCreator,
    checkInReturnToKioskActionCreator,
    checkInSetSalesOrderIdForLinkActionCreator,
    checkInUnAssignActionCreator,
    checkInUpdateActionCreator,
    checkInUpdateLinkSalesOrderToManualOrderActionCreator,
    checkInValidateActionCreator,
    linkBadgeActionCreator,
    printCompartmentsActionCreator
} from "../actions/checkIn";
import { ICheckInFilter, IPage, IPageResponse } from "../../types/api.types";
import { mapSpringPageToIPage } from "../../utils/mapping";
import { ICheckIn } from "../../types/checkIn.types";
import {
    checkInApi,
    deleteCheckInApi,
    getCheckInPage,
    getLinkedSalesOrder,
    linkBadge,
    postBadge,
    postLinkSalesOrderToManualOrder,
    postReturnToKiosk,
    putCheckin,
    putLinkSalesOrderToManualOrder
} from "../api/checkIn.api";
import axios, { AxiosResponse } from "axios";
import { printPage } from "../../utils/views.utils";
import { pickBy } from "lodash";
import { ISalesOrderDTO } from "../../types/salesOrder.types";
import { checkInRecordSelector } from "../selectors/checkInOverview.selector";


export function* loadPage(action: ReturnType<typeof checkInPageLoadActionCreator>) {
    yield put(checkInPageFetchActionCreator(action.payload));
}

export function* fetchPage(action: ReturnType<typeof checkInPageFetchActionCreator>) {
    const filtersWithValue = pickBy(action.payload.filter, (f) => f !== undefined && f!== null) as ICheckInFilter;
    const response: AxiosResponse<any> = yield call(getCheckInPage, { ...action.payload, filter: filtersWithValue });
    const page: IPage<ICheckIn> = mapSpringPageToIPage(response.data);
    const pageResponse: IPageResponse<ICheckIn, ICheckInFilter> = {
        page,
        sorting: action.payload.sorting,
        filter: filtersWithValue,
    };

    yield put(checkInPageReceivedActionCreator(pageResponse));
}

export function* fetchRecord(action: ReturnType<typeof checkInRecordLoadActionCreator>) {
    try {
        const response: AxiosResponse<any> = yield call(checkInApi.getRecord, action.payload.checkinId);
        yield put(checkInRecordReceivedActionCreator(response.data));

        action.payload.callback(true);
    } catch (e) {
        action.payload.callback(false);
    }
}

export function* cancel(action: ReturnType<typeof checkInCancelActionCreator>) {
    yield call(checkInApi.cancel, action.payload.checkinId, action.payload.reason);

    const response: AxiosResponse<any> = yield call(checkInApi.getRecord, action.payload.checkinId);
    yield put(checkInRecordReceivedActionCreator(response.data));
}

export function* assign(action: ReturnType<typeof checkInValidateActionCreator>) {
    yield call(checkInApi.assign, action.payload.checkinId);

    const response: AxiosResponse<any> = yield call(checkInApi.getRecord, action.payload.checkinId);
    yield put(checkInRecordReceivedActionCreator(response.data));
}

export function* unAssign(action: ReturnType<typeof checkInUnAssignActionCreator>) {
    yield call(checkInApi.unAssign, action.payload.checkinId);

    const response: AxiosResponse<any> = yield call(checkInApi.getRecord, action.payload.checkinId);
    yield put(checkInRecordReceivedActionCreator(response.data));

    action.payload.callback();
}

export function* returnToKiosk(action: ReturnType<typeof checkInReturnToKioskActionCreator>) {
    yield call(postReturnToKiosk, action.payload.checkinId);

    const response: AxiosResponse<any> = yield call(checkInApi.getRecord, action.payload.checkinId);
    yield put(checkInRecordReceivedActionCreator(response.data));
}

export function* validate(action: ReturnType<typeof checkInValidateActionCreator>) {
    try {
        yield call(checkInApi.validate, action.payload.checkinId, action.payload.cmrWeight);

        const response: AxiosResponse<any> = yield call(checkInApi.getRecord, action.payload.checkinId);
        yield put(checkInRecordReceivedActionCreator(response.data));
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            action.payload.onFailure(error.response.status, error.response);
        }
    }
}

export function* finish(action: ReturnType<typeof checkInRecordLoadActionCreator>) {
    yield call(checkInApi.finish, action.payload.checkinId);

    const response: AxiosResponse<any> = yield call(checkInApi.getRecord, action.payload.checkinId);
    yield put(checkInRecordReceivedActionCreator(response.data));
}

export function* reopen(action: ReturnType<typeof checkInReopenActionCreator>) {
    yield call(checkInApi.reopen, action.payload.checkinId);

    const response: AxiosResponse<any> = yield call(checkInApi.getRecord, action.payload.checkinId);
    yield put(checkInRecordReceivedActionCreator(response.data));
}

export function* deleteCheckIn(action: ReturnType<typeof checkInDeleteActionCreator>) {
    yield call(deleteCheckInApi, action.payload.checkinId);
}

export function* updateCheckin(action: ReturnType<typeof checkInUpdateActionCreator>) {
    const setIsLoading = action.payload.setIsLoading;
    setIsLoading && setIsLoading(true);
    const response: AxiosResponse<any> = yield call(putCheckin, action.payload.checkinId, action.payload.data);
    yield put(checkInRecordReceivedActionCreator(response.data));
    yield delay(200);
    setIsLoading && setIsLoading(false);
}

export function* activateBadge(action: ReturnType<typeof activateBadgeActionCreator>) {
    action.payload.setIsLoading(true);

    try {
        yield call(postBadge, action.payload.checkinId, action.payload.data);

        const checkInResponse: AxiosResponse<ICheckIn> = yield call(checkInApi.getRecord, action.payload.checkinId);
        yield put(checkInRecordReceivedActionCreator(checkInResponse.data));
        yield delay(200);
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            action.payload.onFailure(error.response);
        }
    }
    action.payload.setIsLoading(false);
}

export function* linkWiegandBadge(action: ReturnType<typeof linkBadgeActionCreator>) {
    action.payload.setIsLoading(true);

    try {
        yield call(linkBadge, action.payload.checkinId, action.payload.data);

        const checkInResponse: AxiosResponse<ICheckIn> = yield call(checkInApi.getRecord, action.payload.checkinId);
        yield put(checkInRecordReceivedActionCreator(checkInResponse.data));
        yield delay(200);
    } catch (e) {
        if (axios.isAxiosError(e) && e.response) {
            action.payload.onFailure(e.response);
        }
    }

    action.payload.setIsLoading(false);
}

export function* printCompartments(action: ReturnType<typeof printCompartmentsActionCreator>) {
    action.payload.setIsLoading(true);

    try {
        const response: AxiosResponse<string> = yield call(checkInApi.getCompartmentsAsHtml, action.payload.checkinId);
        printPage("Compartimentering", response.data);

    } catch (e) {
        if (axios.isAxiosError(e) && e.response) {
            action.payload.onFailure(e.response);
        }
    }

    action.payload.setIsLoading(false);
}

export function* getSalesOrderLink(action: ReturnType<typeof checkInGetSalesOrderIdForLinkActionCreator>) {
    try {
        const activeCheckIn: ICheckIn | null = yield select(checkInRecordSelector);
        if (activeCheckIn) {
            const response: AxiosResponse<ISalesOrderDTO> = yield call(getLinkedSalesOrder, action.payload.reservationId, activeCheckIn.checkInReason, action.payload.terminal);
            yield put(checkInSetSalesOrderIdForLinkActionCreator({ salesOrderId:response.data.id }));
            action.payload.callBack(null);
        }
    } catch (e) {
        if (axios.isAxiosError(e) && e.response) {
            action.payload.callBack(e.response);
        }
    }
}

export function* addLinkSalesOrderToManualOrder(action: ReturnType<typeof checkInAddLinkSalesOrderToManualOrderActionCreator>) {
    try {
        const response: AxiosResponse<ICheckIn> = yield call(postLinkSalesOrderToManualOrder, action.payload.checkinId, action.payload.manualOrderId, action.payload.salesOrderId);
        yield put(checkInRecordReceivedActionCreator(response.data));
    } catch (e) {
        console.log("addLinkSalesOrderToManualOrder ERROR", e);
    }

}
export function* updateLinkSalesOrderToManualOrder(action: ReturnType<typeof checkInUpdateLinkSalesOrderToManualOrderActionCreator>) {
    try {
        const response: AxiosResponse<ICheckIn> = yield call(putLinkSalesOrderToManualOrder, action.payload.checkinId, action.payload.manualOrderId, action.payload.salesOrderId);
        yield put(checkInRecordReceivedActionCreator(response.data));
    } catch (e) {
        console.log("updateLinkSalesOrderToManualOrder ERROR", e);
    }
}


export function* checkInSaga() {
    yield takeLatest(checkInPageLoadActionCreator.type, errorHandler(loadPage));
    yield takeLatest(checkInPageFetchActionCreator.type, errorHandler(fetchPage, checkInPageFetchFailedActionCreator));
    yield takeLatest(checkInRecordLoadActionCreator.type, errorHandler(fetchRecord));
    yield takeLatest(checkInCancelActionCreator.type, errorHandler(cancel));
    yield takeLatest(checkInAssignActionCreator.type, errorHandler(assign));
    yield takeLatest(checkInUnAssignActionCreator.type, errorHandler(unAssign));
    yield takeLatest(checkInValidateActionCreator.type, errorHandler(validate));
    yield takeLatest(checkInFinishActionCreator.type, errorHandler(finish));
    yield takeLatest(checkInReopenActionCreator.type, errorHandler(reopen));
    yield takeLatest(checkInReturnToKioskActionCreator.type, errorHandler(returnToKiosk));
    yield takeLatest(checkInUpdateActionCreator.type, errorHandler(updateCheckin));
    yield takeLatest(activateBadgeActionCreator.type, errorHandler(activateBadge));
    yield takeLatest(linkBadgeActionCreator.type, errorHandler(linkWiegandBadge));
    yield takeLatest(printCompartmentsActionCreator.type, errorHandler(printCompartments));
    yield takeLatest(checkInGetSalesOrderIdForLinkActionCreator.type, errorHandler(getSalesOrderLink));
    yield takeLatest(checkInAddLinkSalesOrderToManualOrderActionCreator.type, errorHandler(addLinkSalesOrderToManualOrder));
    yield takeLatest(checkInUpdateLinkSalesOrderToManualOrderActionCreator.type, errorHandler(updateLinkSalesOrderToManualOrder));
    yield takeLatest(checkInDeleteActionCreator.type, errorHandler(deleteCheckIn));
}
