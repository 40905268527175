import { put, takeLatest } from "redux-saga/effects";
import { IOrderFilter, IPageResponse } from "../../types/api.types";
import { IOrder } from "../../types/order.types";
import {
    cleaningPageFetchCreator,
    cleaningPageFetchFailedCreator,
    cleaningPageLoadCreator,
    cleaningPageReceivedCreator
} from "../actions/cleaning";
import { errorHandler } from "./errorHandler";
import { fetchPage as orderFetchPage } from "./order";

export function* fetchPage(action: ReturnType<typeof cleaningPageFetchCreator>) {
    const pageResponse: IPageResponse<IOrder, IOrderFilter> = yield orderFetchPage(action.payload);
    yield put(cleaningPageReceivedCreator(pageResponse));
}

export function* loadPage(action: ReturnType<typeof cleaningPageLoadCreator>) {
    yield put(cleaningPageFetchCreator(action.payload));
}

export function* cleaningSaga() {
    yield takeLatest(cleaningPageFetchCreator.type, errorHandler(fetchPage, cleaningPageFetchFailedCreator));
    yield takeLatest(cleaningPageLoadCreator.type, errorHandler(loadPage));
}
