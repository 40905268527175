import { Input } from "antd";
import { ModalProps } from "antd/lib/modal";
import * as React from "react";
import { useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { ConfirmationModal } from "../../../../../../modals/confirmationmodal/ConfirmationModal";
import { InfoLabel } from "../../../../../../modals/customerservicemodal/panes/detailinfopane/sections/infofields/InfoLabel";
import { Message } from "../../../../../../modals/customerservicemodal/panes/messagesPane/message/Message";

interface IProps extends ModalProps {
    closeModal: () => void;
    visible: boolean;
    onConfirm: (submittedBy: string, msg?: string) => void;
    title: string;
}

const MESSAGES = defineMessages({
    optionalMessage: {
        id: "placement_form_modal.optional_message",
        defaultMessage: "Optioneel bericht voor Customer Service:"
    },
    submittedBy: {
        id: "error_modal.submittedBy",
        defaultMessage: "Ingediend door"
    }
});

export const ErrorModal = (props: IProps) => {
    const [msg, setMsg] = useState<string | undefined>();
    const [submittedBy, setSubmittedBy] = useState<string | undefined>();
    const intl = useIntl();

    const onConfirm = () => {
        submittedBy && props.onConfirm(submittedBy, msg);
    };

    return (
        <ConfirmationModal
            {...props}
            title={null}
            onConfirm={onConfirm}
            disabled={!submittedBy}
        >
            <h2>{props.title}</h2>
            <Message title={intl.formatMessage(MESSAGES.optionalMessage)} editable onChange={setMsg}/>
            <InfoLabel text={intl.formatMessage(MESSAGES.submittedBy)} className="margin-top inline-block"/>
            <Input value={submittedBy} onChange={(e) => setSubmittedBy(e.target.value)} className="margin-half-top"/>
        </ConfirmationModal>
    );
};
