import { createAction, PayloadActionCreator } from "@reduxjs/toolkit";
import { IFailedActionPayload, IOrderUpdateActionPayload } from "../../types/action.types";
import { IOrderFilter } from "../../types/api.types";
import { IOrder, IStateTransition, State } from "../../types/order.types";

enum OrderActionTypes {
    ORDER_TRANSITIONS_FETCH = "[Order] TRANSITIONS_FETCH",
    ORDER_TRANSITIONS_RECEIVED= "[Order] TRANSITIONS_RECEIVED",
    ORDER_TRANSITIONS_FETCH_FAILED = "[Order] TRANSITIONS_FETCH_FAILED",

    ORDER_ALLOWED_TRANSITIONS_FETCH = "[Order] ALLOWED_TRANSITIONS_FETCH",
    ORDER_ALLOWED_TRANSITIONS_RECEIVED = "[Order] ALLOWED_TRANSTITIONS_RECEIVED",
    ORDER_ALLOWED_TRANSITIONS_FETCH_FAILED = "[Order] ALLOWED_TRANSITIONS_FETCH_FAILED",

    ORDER_FETCH = "[Order] FETCH",
    ORDER_RECEIVED = "[Order] RECEIVED",
    ORDER_FETCH_FAILED = "[Order] FETCH_FAILED",

    ORDER_UPDATE = "[Order] UPDATE",
    ORDER_UPDATE_DONE = "[Order] UPDATE_DONE",
    ORDER_UPDATE_FAILED = "[Order] UPDATE_FAILED",

    ORDER_LOAD = "[Order] LOAD",

    ORDER_TOTAL_ELEMENTS_FETCH = "[Order] TOTAL_ELEMENTS_FETCH",
    ORDER_TOTAL_ELEMENTS_RECEIVED = "[Order] TOTAL_ELEMENTS_RECEIVED",
    ORDER_TOTAL_ELEMENTS_FETCH_FAILED = "[Order] TOTAL_ELEMENTS_FAILED"
}

export const orderTransitionsFetchCreator = createAction<number>(OrderActionTypes.ORDER_TRANSITIONS_FETCH);
export const orderTransitionsReceivedCreator = createAction<IStateTransition[]>(OrderActionTypes.ORDER_TRANSITIONS_RECEIVED);
export const orderTransitionsFetchFailedCreator = createAction<IFailedActionPayload>(OrderActionTypes.ORDER_TRANSITIONS_FETCH_FAILED);

export const orderAllowedTransitionsFetchCreator = createAction<number>(OrderActionTypes.ORDER_ALLOWED_TRANSITIONS_FETCH);
export const orderAllowedTransitionsReceivedCreator = createAction<State[]>(OrderActionTypes.ORDER_ALLOWED_TRANSITIONS_RECEIVED);
export const orderAllowedTransitionsFetchFailedCreator = createAction<IFailedActionPayload>(OrderActionTypes.ORDER_ALLOWED_TRANSITIONS_FETCH_FAILED);

export const orderFetchCreator = createAction<number>(OrderActionTypes.ORDER_FETCH);
export const orderReceivedCreator = createAction<IOrder>(OrderActionTypes.ORDER_RECEIVED);
export const orderFetchFailedCreator = createAction<IFailedActionPayload>(OrderActionTypes.ORDER_FETCH_FAILED);

export const orderUpdateCreator = createAction<IOrderUpdateActionPayload & {doneAction?: PayloadActionCreator<IOrderUpdateActionPayload>}>(OrderActionTypes.ORDER_UPDATE);
export const orderUpdateDoneCreator = createAction<IOrderUpdateActionPayload & {doneAction?: PayloadActionCreator<IOrderUpdateActionPayload>}>(OrderActionTypes.ORDER_UPDATE_DONE);
export const orderUpdateFailedCreator = createAction<IFailedActionPayload>(OrderActionTypes.ORDER_UPDATE_FAILED);

export const orderLoadCreator = createAction<number>(OrderActionTypes.ORDER_LOAD);

export const orderTotalElementsFetchCreator = createAction<IOrderFilter>(OrderActionTypes.ORDER_TOTAL_ELEMENTS_FETCH);
export const orderTotalElementsReceivedCreator = createAction<{ filter: IOrderFilter; count: number }>(OrderActionTypes.ORDER_TOTAL_ELEMENTS_RECEIVED);
export const orderTotalElementsFetchFailedCreator = createAction<IFailedActionPayload>(OrderActionTypes.ORDER_TOTAL_ELEMENTS_FETCH_FAILED);
