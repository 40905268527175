import { Col, Row } from "antd";
import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import { BoolInfoField } from "./infofields/BoolInfoField";
import { InfoField } from "./infofields/InfoField";
import { InfoLabel } from "./infofields/InfoLabel";

interface IProps {
    paidStorageDays: number | undefined;
    toplosser: boolean | undefined;
    baffles: boolean | undefined;
}

const MESSAGES = defineMessages({
    storageDays: {
        id: "paid_storage_section.paid_storage_days",
        defaultMessage: "Betaalde opslag dagen",
    },
    toplosser: {
        id: "paid_storage_section.toplosser",
        defaultMessage: "Toplosser"
    },
    baffles: {
        id: "paid_storage_section.baffles",
        defaultMessage: "Keerschotten"
    }
});

export const PaidStorageSection = (props: IProps) => {
    const intl = useIntl();

    return (
        <Row>
            <Col span={8}>
                <InfoLabel text={intl.formatMessage(MESSAGES.storageDays)}/>
                <InfoField value={props.paidStorageDays}/>
            </Col>
            <Col span={8}>
                <InfoLabel text={intl.formatMessage(MESSAGES.toplosser)}/>
                <BoolInfoField value={props.toplosser} nillable />
            </Col>
            <Col span={8}>
                <InfoLabel text={intl.formatMessage(MESSAGES.baffles)}/>
                <BoolInfoField value={props.baffles} nillable />
            </Col>
        </Row>
    );
};
