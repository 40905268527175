import * as React from "react";
import { Col, Divider, Row } from "antd";
import { defineMessages, IntlShape } from "react-intl";
import { KilogramDisplay } from "./KilogramDisplay";
import { ICompartment, IWeightCalculation } from "../../../types/checkIn.types";
import { roundToTens } from "../../../utils/format";

interface IProps {
  dataWeight: IWeightCalculation;
  dataCompartments: ICompartment[];
  corrected: boolean;
  intl: IntlShape;
}

const MESSAGES = defineMessages({
    original: {
        id: "margin_overview_form.original",
        defaultMessage: "Origineel",
    },
    adjustment: {
        id: "margin_overview_form.adjustment",
        defaultMessage: "Aanpassing",
    },
    weightBridge: {
        id: "margin_overview_form.weight_bridge",
        defaultMessage: "Weegbrug",
    },
    orderWeight: {
        id: "margin_overview_form.order_weight",
        defaultMessage: "Initiële order gewicht",
    },
    totalWeight: {
        id: "margin_overview_form.total_weight",
        defaultMessage: "Totaalgewicht",
    },
    maxAllowedWeight: {
        id: "margin_overview_form.max_allowed_weight",
        defaultMessage: "Maximum toegelaten gewicht",
    },
    correctionMtbg: {
        id: "margin_overview_form.correction_mtbg",
        defaultMessage: "Correctie MTBG",
    },
    correctionCompartment: {
        id: "margin_overview_form.correction_compartment",
        defaultMessage: "Correctie compartimentering",
    },
    correctedOrderWeight: {
        id: "margin_overview_form.corrected_weight",
        defaultMessage: "Nieuw order gewicht",
    },
});
export const MarginCalculationDisplay = (props: IProps) => {
    const { dataWeight, dataCompartments, corrected, intl } = props;
    const compartmentTolerance = dataCompartments[0].toleranceLoadAmount || 0;
    return (
        <>
            <h2>{intl.formatMessage(corrected ? MESSAGES.adjustment : MESSAGES.original)}</h2>
            <Row>
                <Col span={12}>{intl.formatMessage(MESSAGES.weightBridge)}</Col>
                <Col span={12} className="text-right"><KilogramDisplay intl={intl} number={roundToTens(dataWeight.weightBridge)}/></Col>
            </Row>
            <Row>
                <Col span={12}>{intl.formatMessage(MESSAGES.orderWeight)}</Col>
                <Col span={12} className="text-right"><KilogramDisplay intl={intl} number={roundToTens(dataWeight.totalOrderWeight)}/></Col>
            </Row>

            {corrected ? (
                <>
                    {dataWeight.correction > 0 &&
                        <Row>
                            <Col span={12} className="text-info">{intl.formatMessage(MESSAGES.correctionMtbg)}</Col>
                            <Col span={12} className="text-right text-info">- <KilogramDisplay intl={intl}
                                number={roundToTens(dataWeight.correction)}
                                className="div-inline"/></Col>
                        </Row>
                    }

                    {compartmentTolerance != 0 &&
                        <Row>
                            <Col span={12} className="text-info">{intl.formatMessage(MESSAGES.correctionCompartment)}</Col>
                            <Col span={12} className="text-right text-info"><KilogramDisplay intl={intl} number={roundToTens(compartmentTolerance)} className="div-inline"/></Col>
                        </Row>
                    }

                    <Row>
                        <Col span={12} className="text-info">{intl.formatMessage(MESSAGES.correctedOrderWeight)}</Col>
                        <Col span={12} className="text-right text-info"><KilogramDisplay intl={intl} number={roundToTens(dataWeight.totalOrderWeightWithCorrection) + roundToTens(compartmentTolerance)}/></Col>
                    </Row>

                    <Row>
                        <Col span={12}>{intl.formatMessage(MESSAGES.totalWeight)}</Col>
                        <Col span={12} className="text-right text-success"><KilogramDisplay intl={intl} number={roundToTens(dataWeight.totalWeight) + roundToTens(compartmentTolerance)}/></Col>
                    </Row>
                </>
            ) : (
                <Row>
                    <Col span={12} className="text-error">{intl.formatMessage(MESSAGES.totalWeight)}</Col>
                    <Col span={12} className="text-right text-error"><KilogramDisplay intl={intl} number={roundToTens(dataWeight.totalWeightBeforeCorrection)}/></Col>
                </Row>
            )}

            <Divider />

            <Row>
                <Col span={12}>{intl.formatMessage(MESSAGES.maxAllowedWeight)}</Col>
                <Col span={12} className="text-right text-success"><KilogramDisplay intl={intl} number={roundToTens(dataWeight.maxAllowedWeight)}/></Col>
            </Row>
        </>
    );
};
