import { CalendarApi } from "@fullcalendar/common";
import { Button, Spin, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { TIME_FORMATS } from "../../../constants/time";
import { calendarEventsFetchActionCreator } from "../../../store/actions/calendar";
import { pitListFetchCreator } from "../../../store/actions/pit";
import { loadingSelector, pitListSelector } from "../../../store/selectors/pit.selector";
import { tankIdListSelector, tankResourceSelector } from "../../../store/selectors/tank.selector";
import { IEventInfo, IEventParams, IFailureCallback, ISuccessCallback } from "../../../types/bookedSlot.types";
import { TimeLineDatePicker } from "./TimeLineDatePicker";
import { TimeLineView } from "./TimeLineView";
import dayjs, { Dayjs } from "dayjs";

const { Title } = Typography;

export const TimeLineContainer = () => {
    const location = useLocation();
    const [selectedDate, setSelectedDate] = useState(dayjs());
    const [calendarApi, setCalendarApi] = useState<CalendarApi | null>(null);

    const pits = useSelector(pitListSelector);
    const loading = useSelector(loadingSelector);
    const tankResources = useSelector(tankResourceSelector);
    const tankIds = useSelector(tankIdListSelector);
    const dispatch = useDispatch();

    const setCalendarApiWrapper = (calendarApi: any) => {
        const params = new URLSearchParams(location.search.slice(1)) as {date?: string};

        if (calendarApi != null && params.date) {
            const mom = dayjs(params.date, TIME_FORMATS.DATE);
            const date = mom.toDate();
            calendarApi.gotoDate(date);
            setSelectedDate(mom);
        }

        setCalendarApi(calendarApi);
    };

    const events = (info: IEventInfo, successCallback: ISuccessCallback, failureCallback: IFailureCallback) => {
        const eventParams: IEventParams = {
            info,
            successCallback,
            failureCallback
        };

        dispatch(calendarEventsFetchActionCreator({ eventParams, tankIds }));
    };

    const onChange = (date: Dayjs | null) => {
        setSelectedDate(date ? date : dayjs());
    };

    const onNextDay = () => {
        if (calendarApi !== null) {
            calendarApi.next();
            setSelectedDate(dayjs(calendarApi.getDate()));
        }
    };

    const onPreviousDay = () => {
        if (calendarApi !== null) {
            calendarApi.prev();
            setSelectedDate(dayjs(calendarApi.getDate()));
        }
    };

    useEffect(() => {
        dispatch(pitListFetchCreator());
    }, []);

    return loading? <Spin/> : (
        <div className="time-line-wrapper">
            <div className="fc-timeline-header">
                <div className="fc-timeline-header-column">
                    <Title level={2}>{selectedDate.format("dddd D MMMM YYYY")}</Title>
                </div>
                <div className="fc-timeline-header-column fc-timeline-header-column-middle">
                    <TimeLineDatePicker onDateChange={onChange} date={selectedDate} />
                </div>
                <div className="fc-timeline-header-column fc-timeline-header-column-right">
                    <Button size="large" onClick={onPreviousDay} icon="left" type="primary" />
                    {" "}
                    <Button size="large" onClick={onNextDay} icon="right" type="primary" />
                </div>
            </div>
            <div>
                <TimeLineView pits={pits} resources={tankResources} events={events} date={selectedDate.clone()} setCalendarApi={setCalendarApiWrapper} />
            </div>
        </div>
    );
};
