import { IBooking } from "../../types/salesOrder.types";
import { useDispatch } from "react-redux";
import { IBookingResourceLoader } from "../../types/hook.types";
import { bookingReceivedCreator } from "../../store/actions/booking";

export type IBookingLoader = IBookingResourceLoader;

export const useBooking = (): IBookingLoader => {
    const dispatch = useDispatch();

    // const resource = useSelector<IApplicationState, IBooking | null>(bookingSelector);

    const setResource = (booking: IBooking) => {
        return dispatch(bookingReceivedCreator(booking));
    };

    return {
        resource: null,
        setResource
    };
};
