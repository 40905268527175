import * as React from "react";
import { ICheckIn } from "../../../../../../types/checkIn.types";
import { Tabs } from "antd";
import { SalesOrderInfo } from "./SalesOrderInfo";
import { SlotBookingOrderType } from "../../../../../../types/salesOrder.types";

interface IProps {
    checkIn: ICheckIn;
    MESSAGES: any;
}

export const OrderInfoTabs = (props: IProps) => {

    const tabs = props.checkIn.salesOrders &&
        props.checkIn.salesOrders?.flatMap((s) => s.productionOrders.map((p) => (
            <Tabs.TabPane key={p.id.toString()} tab={s.orderType === SlotBookingOrderType.CONTAINER ? s.containerNumber : p.sapReference}>
                <SalesOrderInfo MESSAGES={props.MESSAGES} productionOrder={p} salesOrder={s} checkIn={props.checkIn}/>
            </Tabs.TabPane>
        )));

    return (
        <Tabs destroyInactiveTabPane animated={false} >
            {tabs}
        </Tabs>
    );
};
