import { Spin } from "antd";
import React, { useEffect } from "react";
import { IOrderFilter, IPageRequest } from "../../types/api.types";
import { CleaningList } from "../components/CleaningList";
import { ICleaningPageLoader, useCleaningPageLoader } from "../hooks/pageloaders/useCleaningPageLoader";

export const CleaningListContainer = () => {
    const cleaningPageLoader: ICleaningPageLoader = useCleaningPageLoader();

    useEffect(() => {
        const pageRequest: IPageRequest<IOrderFilter> = {
            pageNumber: 0,
            filter: {}
        };

        cleaningPageLoader.loadResource(pageRequest);
    }, []);

    return (
        !cleaningPageLoader.resource || cleaningPageLoader.loadingResource ?
            <Spin/> :
            <CleaningList orders={cleaningPageLoader.resource.page.content}/>
    );
};
