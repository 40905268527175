import { createAction } from "@reduxjs/toolkit";
import { IFailedActionPayload } from "../../types/action.types";
import { ICustomer } from "../../types/salesOrder.types";

enum CustomerActionTypes {
    CUSTOMER_FETCH = "[Customer] FETCH",
    CUSTOMER_RECEIVED = "[Customer] RECEIVED",
    CUSTOMER_FETCH_FAILED = "[Customer] FETCH_FAILED",
}

export const customerFetchCreator = createAction<{ id: number }>(CustomerActionTypes.CUSTOMER_FETCH);
export const customerReceivedCreator = createAction<ICustomer>(CustomerActionTypes.CUSTOMER_RECEIVED);
export const customerFetchFailedCreator = createAction<IFailedActionPayload>(CustomerActionTypes.CUSTOMER_FETCH_FAILED);
