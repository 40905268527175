import { StopOutlined, WarningOutlined } from "@ant-design/icons/lib";
import { Alert } from "antd";
import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import { ISelectedSlot, ISlotBookingConfig } from "../../../types/slot.types";

interface IProps {
    selectedSlot?: ISelectedSlot;
    config: ISlotBookingConfig;
}

const MESSAGES = defineMessages({
    warning: {
        id: "selected_slot_warning.warning",
        defaultMessage: "Opgelet",
    },
    bookedSlotsExist: {
        id: "selected_slot_warning.booked-slots-exist",
        defaultMessage: "Deze tank is reeds geblokkeerd door een ander geboekt slot.",
    },
    scheduledMaintenancesExist: {
        id: "selected_slot_warning.scheduled-maintenances-exist",
        defaultMessage: "Er is een scheduled maintenance voor deze tank.",
    },
    warningReached: {
        id: "selected_slot_warning.warning-reached",
        defaultMessage: "Er zijn reeds {bookedSlots} slots geboekt voor deze zone.",
    },
    maximumReached: {
        id: "selected_slot_warning.maximum-reached",
        defaultMessage: "Het maximale aantal slots is geboekt voor deze zone.",
    },
    blockedDependsOn: {
        id: "selected_slot_warning.blocked-depends-on",
        defaultMessage: "Dit slot is al geboekt door een vorige booking"
    }
});

export const SelectedSlotWarning = (props: IProps) => {
    const intl = useIntl();

    if (!props.selectedSlot) {
        return <></>;
    }

    const tankTimeOverlap = props.selectedSlot.tankTimeOverlap;
    const blockedDependsOn = props.selectedSlot.blockedDependsOn;
    const maximumBookingsExceeded = props.selectedSlot.zoneBookings && props.selectedSlot.zoneBookings >= props.config.maximumValue;
    const warningBookingsExceeded = props.selectedSlot.zoneBookings && props.selectedSlot.zoneBookings >= props.config.warningValue;
    const scheduledMaintenances = props.selectedSlot.constraints && props.selectedSlot.constraints
        .filter(constraint => constraint.message == "REASON" && constraint.reason == "MAINTENANCE").length;

    if (blockedDependsOn) {
        return <Alert
            icon={<StopOutlined/>}
            message={intl.formatMessage(MESSAGES.warning)}
            description={<div className="selected-slots-alert">{intl.formatMessage(MESSAGES.blockedDependsOn)}</div>}
            type="error"
            showIcon
        />;
    }

    if (tankTimeOverlap && maximumBookingsExceeded) {
        return <Alert
            icon={<StopOutlined/>}
            message={intl.formatMessage(MESSAGES.warning)}
            description={<div className="selected-slots-alert">{intl.formatMessage(MESSAGES.maximumReached)}</div>}
            type="error"
            showIcon
        />;
    }

    if (tankTimeOverlap && warningBookingsExceeded) {
        return <Alert
            icon={<StopOutlined/>}
            message={intl.formatMessage(MESSAGES.warning)}
            description={<div
                className="selected-slots-alert">{intl.formatMessage(MESSAGES.warningReached, { bookedSlots: props.selectedSlot.zoneBookings })}</div>}
            type="warning"
            showIcon
        />;
    }

    if (tankTimeOverlap) {
        return <Alert
            icon={<StopOutlined/>}
            message={intl.formatMessage(MESSAGES.warning)}
            description={<div className="selected-slots-alert">{intl.formatMessage(MESSAGES.bookedSlotsExist)}</div>}
            type="error"
            showIcon
        />;
    }

    if (scheduledMaintenances) {
        return <Alert
            icon={<WarningOutlined/>}
            message={intl.formatMessage(MESSAGES.warning)}
            description={<div className="selected-slots-alert">{intl.formatMessage(MESSAGES.scheduledMaintenancesExist)}</div>}
            type="error"
            showIcon
        />;
    }

    return <></>;
};
