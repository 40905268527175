import * as React from "react";
import { IChassis } from "../../../../../../types/checkIn.types";
import { defineMessages, useIntl } from "react-intl";
import { Alert, Col, Row } from "antd";
import { InfoLabel } from "../../../customerservicemodal/panes/detailinfopane/sections/infofields/InfoLabel";
import { InfoField } from "../../../customerservicemodal/panes/detailinfopane/sections/infofields/InfoField";
import { VehicleInfo } from "./VehicleInfo";
import { useSelector } from "react-redux";
import { checkInRecordHasExpiredByAdrType } from "../../../../../../store/selectors/checkInOverview.selector";
import { AdrTypes } from "../../../../../../types/adr.types";

interface IProps {
    chassis: IChassis;
    showAdrInfo: boolean;
}

const MESSAGES = defineMessages({
    chassis: {
        id: "chassis_info.chassis",
        defaultMessage: "Chassis"
    },
    licensePlate: {
        id: "chassis_info.license_plate",
        defaultMessage: "Nummerplaat"
    },
    adrCode: {
        id: "chassis_info.adr_code",
        defaultMessage: "ADR code"
    },
    adrExpirationDate: {
        id: "chassis_info.adr_expiration_date",
        defaultMessage: "ADR vervaldatum"
    },
    adrExpiredWarning: {
        id: "chassis_info.adr_expired_warning",
        defaultMessage: "ADR code is vervallen."
    },
});

export const ChassisInfo = (props: IProps) => {
    const intl = useIntl();
    const chassisAdrExpired = useSelector(checkInRecordHasExpiredByAdrType(AdrTypes.CHASSIS));

    return (
        <VehicleInfo title={intl.formatMessage(MESSAGES.chassis)}>
            <Row className="margin-bottom">
                <Col span={12}>
                    <InfoLabel text={intl.formatMessage(MESSAGES.licensePlate)}/>
                    <InfoField value={props.chassis.licensePlate}/>
                </Col>
            </Row>
            {
                props.chassis.adrCertificate &&
                <>
                    {
                        props.showAdrInfo && (
                            <Row className="margin-bottom">
                                <Col span={12}>
                                    <InfoLabel text={intl.formatMessage(MESSAGES.adrCode)}/>
                                    <InfoField value={props.chassis.adrCertificate.adrCode}/>
                                </Col>
                                <Col span={12}>
                                    <InfoLabel text={intl.formatMessage(MESSAGES.adrExpirationDate)}/>
                                    <InfoField value={props.chassis.adrCertificate.adrExpirationDate}/>
                                </Col>
                            </Row>
                        )
                    }
                    {
                        props.showAdrInfo && chassisAdrExpired &&
                        <Row className="margin-bottom">
                            <Col span={24}>
                                <Alert message={intl.formatMessage(MESSAGES.adrExpiredWarning)}
                                    type={"error"}
                                />
                            </Col>
                        </Row>
                    }
                </>
            }
        </VehicleInfo>
    );
};
