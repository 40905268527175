/**
 * Renders a page with html content and shows the print dialog.
 * @param title
 * @param content
 */
export const printPage = (title: string, content: string) => {
    const printPage = window.open("", title);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    printPage.document.write(content);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    printPage.document.close();

    setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        printPage.print();
    }, 100);
};
