import { EventApi } from "@fullcalendar/common";
import { EventContentArg } from "@fullcalendar/react";
import * as React from "react";
import { BookedSlotEventContainer } from "./BookedSlotEventContainer";
import { ScheduledMaintenanceEventContainer } from "./ScheduledMaintenanceEventContainer";


interface IProps {
    args: EventContentArg;
    selectedBookedSlotId: number | undefined;
    modalVisible: boolean;
    setModalVisible: (isVisible: boolean) => void;
    clickedEvent: EventApi | undefined;
}

export const TimeLineEventContainer = (props: IProps) => {
    const isBookedSlotEvent = props.args.event.extendedProps.isBookedSlotEvent;

    return (
        isBookedSlotEvent ?
            <BookedSlotEventContainer
                args={props.args}
                clickedEvent={props.clickedEvent}
                selectedBookedSlotId={props.selectedBookedSlotId}
                modalVisible={props.modalVisible}
                setModalVisible={props.setModalVisible}
            />
            :
            <ScheduledMaintenanceEventContainer maintenanceID={Number(props.args.event.id)}/>
    );
};
