import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import { IProduct } from "../../../../../../../types/order.types";
import { Box } from "../../../../../Box";
import { InfoField } from "../../../../../modals/customerservicemodal/panes/detailinfopane/sections/infofields/InfoField";
import { InfoLabel } from "../../../../../modals/customerservicemodal/panes/detailinfopane/sections/infofields/InfoLabel";

interface IProps {
    product: IProduct;
    className?: string;
}

const MESSAGES = defineMessages({
    skinProtection: {
        id: "pbm_instruction.skin_protection",
        defaultMessage: "Gelaatsbescherming"
    },
    gloves: {
        id: "pbm_instruction.gloves",
        defaultMessage: "Handschoenen"
    },
    breathProtection: {
        id: "pbm_instruction.breathe_protection",
        defaultMessage: "Adembescherming"
    },
    clothing: {
        id: "pbm_instruction.clothing",
        defaultMessage: "Kledij"
    },
    shoes: {
        id: "pbm_instruction.shoes",
        defaultMessage: "Schoenen"
    },
    pbmInstructions: {
        id: "pbm_instruction.pbm_codes",
        defaultMessage: "PBM-instructie afwijkingen"
    },
    headProtection: {
        id: "pbm_instruction.head_protection",
        defaultMessage: "Hoofd bescherming"
    },
});

export const PbmInstructions = (props: IProps) => {
    const intl = useIntl();

    const pbmToArray = () => {
        const pbmInstructions = [];

        if (props.product.pbmGlovesDescription) {
            pbmInstructions.push(
                <InfoField value={props.product.pbmGlovesDescription} />
            );
        }

        if (props.product.pbmBreatheProtectionDescription) {
            pbmInstructions.push(
                <InfoField value={props.product.pbmBreatheProtectionDescription} />
            );
        }

        if (props.product.pbmClothingDescription) {
            pbmInstructions.push(
                <InfoField value={props.product.pbmClothingDescription} />
            );
        }

        if (props.product.pbmShoesDescription) {
            pbmInstructions.push(
                <InfoField value={props.product.pbmShoesDescription} />
            );
        }

        if (props.product.pbmHeadProtection) {
            pbmInstructions.push(
                <InfoField value={props.product.pbmHeadProtectionDescription} />
            );
        }

        return pbmInstructions;
    };

    const pbmInstructionsArray = pbmToArray();

    return (
        <div className={props.className}>
            <InfoLabel text={intl.formatMessage(MESSAGES.pbmInstructions)} />
            <Box className="margin-half-top flex">
                <div style={{ width: "50%" }}>
                    {
                        pbmInstructionsArray.slice(0, pbmInstructionsArray.length / 2)
                            .map((p, i) => <div key={i} className={`${i !== 0 && "margin-top"}`}>{p}</div>)
                    }
                </div>
                <div style={{ width: "50%" }}>
                    {
                        pbmInstructionsArray.slice(pbmInstructionsArray.length / 2, pbmInstructionsArray.length)
                            .map((p, i) => <div key={i} className={`${i !== 0 && "margin-top"}`}>{p}</div>)
                    }
                </div>
            </Box>
        </div>
    );
};
