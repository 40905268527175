import * as React from "react";
import { useIntl } from "react-intl";
import { TAB_STATE_COLOR_CLASSES } from "../../../../../theme/typescript/colors";
import { State } from "../../../../../types/order.types";
import { formatState } from "../../../../../utils/format";

interface IProps {
    slot?: string;
    containerNumber: string;
    state: State;
    active: boolean;
}

export const CleaningTabPane = (props: IProps) => {
    const intl = useIntl();

    const stateToColorClass = (state: State) => {
        switch (state) {
        case State.CLEANING:
            return TAB_STATE_COLOR_CLASSES.YELLOW;
        case State.CLEANED_READY_FOR_PICKUP:
        case State.ITACT_REPAIR:
        case State.DONE_PICKED_UP:
            return TAB_STATE_COLOR_CLASSES.GREEN;
        default:
            return TAB_STATE_COLOR_CLASSES.GRAY;
        }
    };

    const colorClass = stateToColorClass(props.state);

    return (
        <div className={`tabpane ${props.active && "text-large"}`}>
            <div className="flex flex-row justify-between">
                {
                    props.slot &&
                    <div className={`slot-tag ${colorClass} ${colorClass && "padded"} margin-half-right`}>
                        {props.slot}
                    </div>
                }
                <span className="align-self-center text-secondary text-small">
                    {formatState(props.state, intl)}
                </span>
            </div>
            <span>
                {props.containerNumber}
            </span>
        </div>
    );
};
