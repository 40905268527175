import { CopyOutlined } from "@ant-design/icons/lib";
import { Button, Col, Row } from "antd";
import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import { NOTIFICATION_TYPES } from "../../../../../../../constants/notification";
import { Paths } from "../../../../../../../types/api.types";
import { ICleaningCustomer, OrderType } from "../../../../../../../types/order.types";
import { formatOrderType } from "../../../../../../../utils/format";
import { disableDateBefore, disableTimeBefore } from "../../../../../../../utils/time";
import { DateTimePicker } from "../../../../../forms/inputs/DateTimePicker";
import { DateTimeInfoField } from "./infofields/DateTimeInfoField";
import { InfoField } from "./infofields/InfoField";
import { InfoLabel } from "./infofields/InfoLabel";
import dayjs, { Dayjs } from "dayjs";

interface IProps {
    customer: ICleaningCustomer;
    containerNumber: string;
    product: string;
    type: OrderType;
    priorityPickerEnabled: boolean;
    onPrioritySave?: (m: Dayjs | null) => void;
    priority?: string;
    savingPriority: boolean;
    clientOrderRef: string;
    deliveryId: string;
    sapId: string;
    createNotification?: (notificationType: string) => void;
    externalViewLinkEnabled: boolean;
}

const MESSAGES = defineMessages({
    customer: {
        id: "info_section.customer",
        defaultMessage: "Klant",
    },
    containerNumber: {
        id: "info_section.container_number",
        defaultMessage: "Container nr.",
    },
    product: {
        id: "info_section.product",
        defaultMessage: "Product",
    },
    type: {
        id: "info_section.type",
        defaultMessage: "Type",
    },
    priority: {
        id: "info_section.priority",
        defaultMessage: "Prioriteit",
    },
    deliveryId: {
        id: "info_section.order_number",
        defaultMessage: "Order nr."
    },
    clientOrderRef: {
        id: "info_section.client_order_ref",
        defaultMessage: "Klant order ref."
    },
    sapId: {
        id: "info_section.sap_id",
        defaultMessage: "Sales nr."
    },
    externalView: {
        id: "info_section.external_view",
        defaultMessage: "External view"
    },
    externalViewLink: {
        id: "info_section.external_view_link",
        defaultMessage: "Kopieer link"
    }
});

export const InfoSection = (props: IProps) => {
    const intl = useIntl();

    const copyToClipBoardAndNotifyUser = (linkText: string) => {
        navigator.clipboard.writeText(linkText);
        props.createNotification && props.createNotification(NOTIFICATION_TYPES.WRITTEN_TO_CLIPBOARD);
    };

    const externalViewLink = () => {
        const link = `${window.location.origin}${Paths.EXTERNAL_VIEW}/${props.customer.uuid}?language=en`;

        return (
            <Button
                type="dashed"
                onClick={() => copyToClipBoardAndNotifyUser(link)}
            >
                <CopyOutlined />
                {intl.formatMessage(MESSAGES.externalViewLink)}
            </Button>);
    };

    return (
        <div>
            <Row className="margin-bottom">
                <Col span={12}>
                    <InfoLabel text={intl.formatMessage(MESSAGES.customer)}/>
                    <InfoField value={props.customer.name}/>
                </Col>
                <Col span={12}>
                    <InfoLabel text={intl.formatMessage(MESSAGES.containerNumber)}/>
                    <InfoField value={props.containerNumber}/>
                </Col>
            </Row>
            <Row className="margin-bottom">
                <Col span={12}>
                    <InfoLabel text={intl.formatMessage(MESSAGES.product)}/>
                    <InfoField value={props.product}/>
                </Col>
                <Col span={12}>
                    <InfoLabel text={intl.formatMessage(MESSAGES.type)}/>
                    <InfoField value={formatOrderType(props.type, intl)}/>
                </Col>
            </Row>
            <Row className="margin-bottom">
                <Col span={12}>
                    <InfoLabel text={intl.formatMessage(MESSAGES.deliveryId)}/>
                    <InfoField value={props.deliveryId}/>
                </Col>
                <Col span={12}>
                    <InfoLabel text={intl.formatMessage(MESSAGES.clientOrderRef)}/>
                    <InfoField value={props.clientOrderRef}/>
                </Col>
            </Row>
            <Row className="margin-bottom">
                <Col span={12}>
                    <InfoLabel text={intl.formatMessage(MESSAGES.sapId)}/>
                    <InfoField value={props.sapId}/>
                </Col>
                {props.externalViewLinkEnabled && <Col span={12}>
                    <InfoLabel text={intl.formatMessage(MESSAGES.externalView)}/>
                    <InfoField value={externalViewLink()}/>
                </Col>}
            </Row>
            <Row>
                <Col>
                    <InfoLabel text={intl.formatMessage(MESSAGES.priority)}/>
                    {
                        props.onPrioritySave && props.priorityPickerEnabled ?
                            <DateTimePicker
                                onSave={props.onPrioritySave}
                                disabled={!props.priorityPickerEnabled}
                                disableDateFn={(m) => disableDateBefore(m, dayjs())}
                                disableTimeFn={(m: any) => disableTimeBefore(dayjs(), m)}
                                defaultValue={props.priority}
                                saving={props.savingPriority}
                            />
                            :
                            <DateTimeInfoField value={props.priority}/>
                    }
                </Col>
            </Row>
        </div>
    );
};
