import { combineReducers, createReducer } from "@reduxjs/toolkit";
import { IOrderFilter, IPageResponse } from "../../types/api.types";
import { IOrder } from "../../types/order.types";
import {
    customerServicePageFetchFailedCreator,
    customerServicePageLoadCreator,
    customerServicePageReceivedCreator
} from "../actions/customerservice";

export interface ICustomerServiceState {
    page: IPageResponse<IOrder, IOrderFilter> | null;
    loadingPage: boolean;
}

const pageReducer = createReducer<IPageResponse<IOrder, IOrderFilter> | null>(null, {
    [customerServicePageReceivedCreator.type]: (state, action) => action.payload,
});

const loadingPageReducer = createReducer<boolean>(false, {
    [customerServicePageReceivedCreator.type]: () => false,
    [customerServicePageLoadCreator.type]: () => true,
    [customerServicePageFetchFailedCreator.type]: () => false,
});

export const customerServiceReducer = combineReducers<ICustomerServiceState>({
    page: pageReducer,
    loadingPage: loadingPageReducer
});
