import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Paths } from "../../../types/api.types";
import { formatFilterQueryParams } from "../../../utils/format";
import { extractTimeSlotString } from "../../../utils/mapping";
import { ConfirmDeleteModal } from "../../components/modals/confirmationmodal/ConfirmDeleteModal";
import { TimeLinePopover } from "../../components/timeline/TimeLinePopover";
import { useBookedSlot } from "../../hooks/useBookedSlot";
import { EventApi } from "@fullcalendar/common";
import { Duration } from "dayjs/plugin/duration";

interface IProps {
    selectedBookedSlotId?: number;
    modalVisible: boolean;
    setModalVisible: (isVisible: boolean) => void;
    bookedSlotId: number;
    arrivalDuration: Duration;
    departureDuration: Duration;
    clickedEvent: EventApi | undefined;
}

export const TimeLineInformationContainer = (props: React.PropsWithChildren<IProps>) => {
    const [, , fetchBookedSlot, selectedBookedSlot, deleteBookedSlot] = useBookedSlot();
    const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        (props.selectedBookedSlotId !== undefined) && props.selectedBookedSlotId === props.bookedSlotId && fetchBookedSlot(props.selectedBookedSlotId);
    }, [props.selectedBookedSlotId]);

    const onConfirmDelete = () => {
        props.selectedBookedSlotId && deleteBookedSlot(props.selectedBookedSlotId);
        props.setModalVisible(false);
        props.clickedEvent && props.clickedEvent.remove();
    };

    const onCancelDelete = () => {
        setConfirmationModalVisible(false);
        props.setModalVisible(false);
    };

    const onConfirmDeleteModal = () => {
        props.setModalVisible(false);
        setConfirmationModalVisible(true);
    };

    const onConfirmUpdateModal = () => {
        props.setModalVisible(false);
    };

    const goToEditPage = () => {
        const formatQuery = selectedBookedSlot && formatFilterQueryParams({
            id: selectedBookedSlot.id,
            salesOrderReference: selectedBookedSlot.orderReference,
            date: selectedBookedSlot.fromDate,
            start: extractTimeSlotString(selectedBookedSlot.fromDate),
            end: extractTimeSlotString(selectedBookedSlot.untilDate),
            tankId: selectedBookedSlot.tank.id,
        });

        selectedBookedSlot && navigate(Paths.SLOTBOOKING + "?" + formatQuery);
    };

    return selectedBookedSlot? (
        <>
            <ConfirmDeleteModal
                open={confirmationModalVisible}
                onConfirm={onConfirmDelete}
                closeModal={onCancelDelete}
            />
            <TimeLinePopover
                visible={props.modalVisible && props.selectedBookedSlotId === props.bookedSlotId}
                bookedSlot={selectedBookedSlot}
                onConfirm={onConfirmUpdateModal}
                onCancel={onConfirmDeleteModal}
                onEdit={goToEditPage}
                arrivalDuration={props.arrivalDuration}
                departureDuration={props.departureDuration}
            >
                {props.children}
            </TimeLinePopover>
        </>) : <> {props.children} </>;
};
