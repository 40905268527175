import { useDispatch, useSelector } from "react-redux";
import { checklistsFetchActionCreator } from "../../store/actions/checklist";
import { checklistsSelector, fetchingChecklistsSelector } from "../../store/selectors/compartment.selector";
import { IApplicationState } from "../../store/state";
import { IChecklist } from "../../types/checklist.types";

export const useChecklist = (): [IChecklist[], boolean, (compartmentId: number) => ReturnType<typeof checklistsFetchActionCreator>] => {
    const dispatch = useDispatch();
    const checklists = useSelector<IApplicationState, IChecklist[]>(checklistsSelector);
    const fetchingChecklists = useSelector<IApplicationState, boolean>(fetchingChecklistsSelector);

    const fetchChecklists = (compartmentId: number) => dispatch(checklistsFetchActionCreator(compartmentId));

    return [checklists, fetchingChecklists, fetchChecklists];
};
