import { IPageRequest } from "../../types/api.types";
import {
    IBookedSlotBodyParams,
    IBookedSlotListFetchParams,
    IBookedSlotPageFetchParams
} from "../../types/bookedSlot.types";
import { formatFilterQueryParams, formatPageQueryParams } from "../../utils/format";
import { getHttpClient } from "./httpClient";
import { URL_PATHS } from "../../constants/api.constants";

const baseUrl = URL_PATHS.BOOKING + "/bookings";

const postBookedSlot = (bookedSlotBodyParams: IBookedSlotBodyParams) => getHttpClient().post(`${baseUrl}`, bookedSlotBodyParams);
const putBookedSlot = (slotId: number, bookedSlotBodyParams: IBookedSlotBodyParams) => getHttpClient().put(`${baseUrl}/${slotId}`, bookedSlotBodyParams);
const getBookedSlotList = (bookedSlotListFetchParams: IBookedSlotListFetchParams) => getHttpClient().get(`${baseUrl}?${formatFilterQueryParams(bookedSlotListFetchParams)}`);
const getBookedSlot = (bookedSlotId: number) => getHttpClient().get(`${baseUrl}/${bookedSlotId}`);
const deleteBookedSlot = (bookedSlotId: number) => getHttpClient().delete(`${baseUrl}/${bookedSlotId}`);
const getBookedSlotPage = (pageRequest: IPageRequest<IBookedSlotPageFetchParams>) => getHttpClient().get(`${baseUrl}/page/${formatPageQueryParams(pageRequest)}`);

export const bookedSlotApi = {
    postBookedSlot,
    putBookedSlot,
    getBookedSlot,
    getBookedSlotList,
    deleteBookedSlot,
    getBookedSlotPage
};
