import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { Initialized } from "./react/components/app/Initialized";
import * as serviceWorker from "./serviceWorker";
import { store } from "./store";
import "antd/dist/reset.css";
import "./theme/index.scss";
import { ConfigProvider } from "antd";


const CleaningApp: React.FC = () => {
    return (
        <ConfigProvider theme={{
            token: {
                colorPrimary: "#e8bd00",
                colorLink: "#017db5",
                colorSuccess: "#52c41a",
                colorError: "#f5222d",
                colorText:"#000000DD",
                colorTextSecondary: "#00000072",
                fontSize: 14
            },
        }}>
            <Provider store={store}>
                <Initialized/>
            </Provider>
        </ConfigProvider>
    );
};


// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(window.document.getElementById("root")!);
root.render(<CleaningApp/>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
