import * as React from "react";
import { CheckInReason, ICheckIn } from "../../../../types/checkIn.types";
import { VisitCheckInDetail } from "./VisitCheckInDetail";
import { NonVisitCheckInDetail } from "./NonVisitCheckInDetail";
import { SupplierCheckInDetail } from "./SupplierCheckInDetail";
import { ContractorCheckInDetail } from "./ContractorCheckInDetail";

interface IProps {
    visible: boolean;
    checkIn: ICheckIn;
}

export const CheckInDetailModal = (props: IProps) => {
    if (props.checkIn.checkInReason === CheckInReason.VISIT && props.checkIn.visitor) {
        return <VisitCheckInDetail visitor={props.checkIn.visitor}/>;
    } else if (props.checkIn.checkInReason === CheckInReason.SUPPLIER && props.checkIn.supplier) {
        return <SupplierCheckInDetail supplier={props.checkIn.supplier}/>;
    } else if (props.checkIn.checkInReason === CheckInReason.CONTRACTOR && props.checkIn.contractor) {
        return <ContractorCheckInDetail contractor={props.checkIn.contractor}/>;
    } else {
        return <NonVisitCheckInDetail checkIn={props.checkIn}/>;
    }
};
