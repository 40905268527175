import { useDispatch, useSelector } from "react-redux";
import { PayloadAction } from "@reduxjs/toolkit";
import { IBookedSlot, IBookedSlotBodyParams, IBookedSlotListFetchParams } from "../../types/bookedSlot.types";
import {
    bookedSlotCreateCreator,
    bookedSlotDeleteCreator,
    bookedSlotFetchCreator,
    bookedSlotListFetchCreator,
    bookedSlotReceivedCreator,
    bookedSlotUpdateCreator
} from "../../store/actions";
import { IApplicationState } from "../../store/state";
import { bookedSlotListSelector } from "../../store/selectors/bookedSlot.selector";

export const useBookedSlot = (): [
    (bookedSlotBodyParams: IBookedSlotBodyParams) => PayloadAction<{ bookedSlotBodyParams: IBookedSlotBodyParams }>,
    (id: number, bookedSlotBodyParams: IBookedSlotBodyParams) => PayloadAction<{ slotId: number; bookedSlotBodyParams: IBookedSlotBodyParams }>,
    (bookedSlotId: number) => PayloadAction<{ id: number }>,
    IBookedSlot | null,
    (bookedSlotId: number) => void,
    IBookedSlot[] | null,
    (params: IBookedSlotListFetchParams) => void,
    (bookedSlot: IBookedSlot) => void,
] => {
    const dispatch = useDispatch();

    const create = (bookedSlotBodyParams: IBookedSlotBodyParams) => dispatch(bookedSlotCreateCreator({ bookedSlotBodyParams: bookedSlotBodyParams }));
    const update = (id: number, bookedSlotBodyParams: IBookedSlotBodyParams) => dispatch(bookedSlotUpdateCreator({
        slotId: id,
        bookedSlotBodyParams: bookedSlotBodyParams
    }));
    const fetch = (bookedSlotId: number) => dispatch(bookedSlotFetchCreator({ id: bookedSlotId }));
    const cancel = (bookedSlotId: number) => dispatch(bookedSlotDeleteCreator({ id: bookedSlotId }));
    const fetchList = (params: IBookedSlotListFetchParams) => dispatch(bookedSlotListFetchCreator(params));

    const selectedBookedSlot = useSelector<IApplicationState, IBookedSlot | null>(state => state.bookedSlot.selected);
    const list = useSelector(bookedSlotListSelector);

    const assign = (bookedSlot: IBookedSlot) => dispatch(bookedSlotReceivedCreator(bookedSlot));

    return [create, update, fetch, selectedBookedSlot, cancel, list, fetchList, assign];
};
