import { ENV } from "./environments";
import { BLACKLISTED, CheckInState, ExtendedCheckInState } from "../types/checkIn.types";

export const FULL_CALENDAR_KEY = ENV.REACT_APP_FULL_CALENDAR_KEY;
export const BASE_URL_API = ENV.REACT_APP_BASEURL || "http://localhost:8081";


export const CONFIG = {
    /**
     * Enables the default behaviour of the checkin list. If true it keeps refreshing every interval.
     */
    refreshCheckIns: true,

    /**
      * Refreshes the checkin page every x seconds. Where x is defined in the variable.
      */
    refreshIntervalCheckIns: 15,

    /**
     * Sets the default states on what gets filtered in the backend for the check-in overview.
     */
    defaultCheckinFilters: {
        checkInState: [CheckInState.IN_PROGRESS, CheckInState.QUEUED, CheckInState.PROCESSING, CheckInState.READY_FOR_OPERATIONS, CheckInState.BACK_TO_KIOSK],
        archived: false
    },

    /**
     * Sets the default states on what gets filtered in the backend for the archived check-in overview.
     */
    defaultArchivedCheckinFilters: {
        extendedCheckInState: [CheckInState.CANCELLED, CheckInState.FINISHED, BLACKLISTED] as ExtendedCheckInState[],
        archived: true
    },
    /**
     * Sets the default states on what gets filtered in the backend for the check-in overview.
     */
    defaultExtendedCheckinFilters: {
        extendedCheckInState: [CheckInState.IN_PROGRESS, CheckInState.QUEUED, CheckInState.PROCESSING, CheckInState.READY_FOR_OPERATIONS, CheckInState.BACK_TO_KIOSK, BLACKLISTED] as ExtendedCheckInState[],
        archived: false
    },
};
