import { Form, Input } from "antd";
import * as React from "react";
import { useEffect, useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { ICheckIn } from "../../../../types/checkIn.types";
import { ConfirmableButton } from "../inputs/ConfirmableButton";
import { ICmrWeightForm } from "../../../../types/forms.types";

interface IProps {
    isLoading: boolean;
    onSubmit: (values: ICmrWeightForm) => void;
    checkIn: ICheckIn;
}

const MESSAGES = defineMessages({
    requiredError: {
        id: "cmr_weight_form.validations.required",
        defaultMessage: "Een waarde is verplicht"
    },
    noNumberError: {
        id: "cmr_weight_form.validations.number",
        defaultMessage: "Een numerieke waarde is verplicht"
    },
    placeholder: {
        id: "cmr_weight_form.placeholders.weight",
        defaultMessage: "Gewicht"
    }
});

export const CmrWeightForm = (props: IProps) => {
    const intl = useIntl();
    const [form] = Form.useForm();
    const [isButtonEnabled, setIsButtonEnabled] = useState<boolean>(false);
    const [value, setValue] = useState<string | null>(null);

    const isEnabled = () => {
        if (props.isLoading) {
            return false;
        }

        if (props.checkIn.cmrWeightDriver === form.getFieldValue("cmrWeightDispatcher") && !form.isFieldsTouched() && props.checkIn.cmrWeightDispatcher === null) {
            return true;
        }

        if (!form.isFieldsTouched()) {
            return false;
        }

        if (form.getFieldValue("cmrWeightDispatcher") === "") {
            return false;
        }

        if (props.checkIn.cmrWeightDispatcher+"" === form.getFieldValue("cmrWeightDispatcher")) {
            return false;
        }

        return true;
    };

    useEffect(() => {
        setIsButtonEnabled(isEnabled());
    }, [value, props.checkIn.cmrWeightDispatcher]);

    const handleSubmit = (values: any) => {
        props.onSubmit(values);
    };

    return (
        <Form layout="inline" onFinish={handleSubmit} form={form}>
            <Form.Item
                name="cmrWeightDispatcher"
                rules={[
                    {
                        required: true,
                        message: intl.formatMessage(MESSAGES.requiredError),
                    },
                    {
                        pattern: /^(?:\d*)$/,
                        message: intl.formatMessage(MESSAGES.noNumberError),
                    }
                ]}
                initialValue={props.checkIn.cmrWeightDispatcher || props.checkIn.cmrWeightDriver}
            >
                <Input
                    placeholder={intl.formatMessage(MESSAGES.placeholder)}
                    disabled={props.isLoading}
                    maxLength={15}
                    onChange={(e) => {
                        setValue(e.target.value);
                    }}
                    onKeyPress={(e) => {
                        const specialCharRegex = new RegExp("[0-9]");

                        if (!specialCharRegex.test(e.key)) {
                            e.preventDefault();
                            return false;
                        }
                    }}
                />
            </Form.Item>

            <Form.Item>
                <ConfirmableButton
                    label={<FormattedMessage id="cmr_weight_form.inputs.submit.label" defaultMessage="Verifiëren" />}
                    confirmLabel={<FormattedMessage id="cmr_weight_form.inputs.submit.confirm" defaultMessage="Bent u zeker dat u het CMR gewicht nog wilt aanpassen?" />}
                    disabled={!isButtonEnabled}
                    shouldConfirm={props.checkIn.cmrWeightDispatcher !== null}
                    onConfirm={() => form.submit()}
                />
            </Form.Item>
        </Form>
    );
};
