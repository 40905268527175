import { Button, Input } from "antd";
import * as React from "react";
import { useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { InfoLabel } from "../../../../../../modals/customerservicemodal/panes/detailinfopane/sections/infofields/InfoLabel";
import { WarningModal } from "./WarningModal";

interface IProps {
    containerLocation?: string;
    containerNumber: string;
    onOk: (fields: string) => void;
    prev: () => void;
}

const MESSAGES = defineMessages({
    containerNumberPromptLocation: {
        id: "container_number_form.container_number_prompt_location",
        defaultMessage: "Geef het container nummer in op locatie {location}:"
    },
    containerNumberPromptNoLocation: {
        id: "container_number_form.container_number_prompt_no_location",
        defaultMessage: "Geef het container nummer in:"
    },
    modalTitle: {
        id: "container_number_form.modal_title",
        defaultMessage: "Het ingegeven container nummer ''{actual}'' komt niet overeen met het geplaatste container nummer ''{expected}''."
    },
    next: {
        id: "container_number_form.next",
        defaultMessage: "Volgende"
    },
    prev: {
        id: "container_number_form.prev",
        defaultMessage: "Vorige"
    }
});

export const ContainerNumberForm = (props: IProps) => {
    const intl = useIntl();
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [input, setInput] = useState<string | undefined>();

    const onNext = () => {
        const lowerCaseInput = input && input.toLowerCase();

        if (lowerCaseInput !== props.containerNumber.toLowerCase()) {
            setModalVisible(true);
            return;
        }

        props.onOk(lowerCaseInput);
    };

    const containerNumberPrompt = props.containerLocation ?
        intl.formatMessage(MESSAGES.containerNumberPromptLocation, { location: props.containerLocation }) :
        intl.formatMessage(MESSAGES.containerNumberPromptNoLocation);

    return (
        <div>
            <InfoLabel text={containerNumberPrompt}/>
            <Input onChange={(e) => setInput(e.target.value)} className="margin-half-top"/>
            <div className="margin-top">
                <Button onClick={props.prev}>
                    {intl.formatMessage(MESSAGES.prev)}
                </Button>
                <Button type="primary" onClick={onNext} disabled={!input} className="margin-half-left">
                    {intl.formatMessage(MESSAGES.next)}
                </Button>
            </div>
            <WarningModal
                width="50%"
                visible={modalVisible}
                message={intl.formatMessage(MESSAGES.modalTitle, {
                    actual: input,
                    expected: props.containerNumber
                })}
                onOk={() => setModalVisible(false)}
            />
        </div>
    );
};
