import { notification } from "antd";
import { defineMessages, IntlShape } from "react-intl";

interface IProps {
    intl: IntlShape;
    reset: () => void;
}

const MESSAGES = defineMessages({
    slotNotFound: {
        id: "slotnot_found_notification.message",
        defaultMessage: "Slot niet gevonden"
    }
});

export const SlotNotFoundNotification = (props: IProps) => {
    notification.error({
        message: props.intl.formatMessage(MESSAGES.slotNotFound),
        onClose: () => props.reset(),
        placement: "bottomRight",
    });
    return null;
};
