import { useDispatch } from "react-redux";
import { itactCsvDownloadActionCreator } from "../../store/actions/itact";
import { IOrderFilter, IPageRequest } from "../../types/api.types";
import { IResourceRequester } from "../../types/hook.types";
import { IOrder } from "../../types/order.types";

export type ICsvDownloadItactPageLoader = IResourceRequester<IOrder, IOrderFilter>;

export const useCsvDownloadItact = () => {
    const dispatch = useDispatch();

    const requestResource = (pageRequest: IPageRequest<IOrderFilter>) => dispatch(itactCsvDownloadActionCreator(pageRequest));

    return {
        resource: null,
        requestResource: requestResource
    };
};
