import * as React from "react";
import { Col, Row } from "antd";
import { IVisitor } from "../../../../types/checkIn.types";
import { useSelector } from "react-redux";
import { IApplicationState } from "../../../../store/state";
import { isAssignedToMeSelector } from "../../../../store/selectors/authentication.selector";
import { checkInRecordSelector } from "../../../../store/selectors/checkInOverview.selector";
import { DocumentsPane } from "./panes/documentsPane/DocumentsPane";
import { ActionsPane } from "./panes/actionsPane/ActionsPane";
import { PersonPane } from "./panes/personPane/PersonPane";
import { defineMessages, useIntl } from "react-intl";
import { RemarksPane } from "./panes/remarksPanes/RemarksPane";

interface IProps {
    visitor: IVisitor;
}

const MESSAGES = defineMessages({
    title: {
        id: "check_in_modal.visit.title",
        defaultMessage: "Bezoeker info"
    }
});

export const VisitCheckInDetail = (props: IProps) => {
    const intl = useIntl();
    const checkIn = useSelector(checkInRecordSelector);
    const isAssignedToMe = useSelector<IApplicationState, boolean>(isAssignedToMeSelector(checkIn?.assignedTo || null));

    return (
        <Row gutter={10}>
            <Col span={12}>
                <PersonPane person={props.visitor} title={intl.formatMessage(MESSAGES.title)}/>
            </Col>
            <Col span={12}>
                {isAssignedToMe && <ActionsPane/>}
                <DocumentsPane checkIn={checkIn}/>
                <RemarksPane checkIn={checkIn}/>
            </Col>
        </Row>
    );
};
