import { SearchOutlined } from "@ant-design/icons/lib";
import { PayloadAction } from "@reduxjs/toolkit";
import { Table } from "antd";
import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { TIME_FORMATS } from "../../../constants/time";
import { IPage, IPageRequest, ISort, Paths } from "../../../types/api.types";
import { IBookedSlot, IBookedSlotPageFetchParams } from "../../../types/bookedSlot.types";
import { DateRangeFilter } from "./filters/DateRangeFilter";
import { SearchFilter, SearchFilterResult } from "./filters/SearchFilter";
import { tableDateRenderer, tableTimeRenderer, tankNameTableRenderer } from "./renderers";
import { ItemTable } from "./ItemTable";
import dayjs from "dayjs";

interface IProps {
    page?: IPage<IBookedSlot>;
    fetchingPage: boolean;
    sorting?: ISort;
    filter?: IBookedSlotPageFetchParams;
    fetchPage: (pageRequest: IPageRequest<IBookedSlotPageFetchParams>) => PayloadAction<IPageRequest<IBookedSlotPageFetchParams>>;
}

export const MESSAGES = defineMessages({
    fromDateTitle: {
        id: "booked_slots_table.from_date_title",
        defaultMessage: "Datum"
    },
    arrivalTimeTitle: {
        id: "booked_slots_table.arrival_time_title",
        defaultMessage: "Start slot"
    },
    departureTimeTitle: {
        id: "booked_slots_table.departure_time_title",
        defaultMessage: "Eind slot"
    },
    tankNrTitle: {
        id: "booked_slots_table.tank_nr_title",
        defaultMessage: "Tank nr"
    },
    customerTitle: {
        id: "booked_slots_table.customer_title",
        defaultMessage: "Product eigenaar"
    },
    productTitle: {
        id: "booked_slots_table.product_title",
        defaultMessage: "Product"
    },
    dateFilterPlaceholder: {
        id: "booked_slots_table.date_filter_placeholder",
        defaultMessage: "Selecteer datum"
    },
    tankNameFilterPlaceholder: {
        id: "booked_slots_table.tank_name_filter_placeholder",
        defaultMessage: "Selecteer tank nr"
    },
    customerFilterPlaceholder: {
        id: "booked_slots_table.customer_filter_placeholder",
        defaultMessage: "Selecteer product eigenaar"
    },
    salesOrderReferenceTitle: {
        id: "booked_slots_table.sales_order_reference_title",
        defaultMessage: "Order nr."
    },
    salesOrderReferencePlaceholder: {
        id: "booked_slots_table.sales_order_reference_placeholder",
        defaultMessage: "Selecteer order nr."
    }
});

export const BookedSlotsTable = (props: IProps) => {
    const intl = useIntl();
    const navigate = useNavigate();

    const onRow = (record: IBookedSlot) => {
        return ({
            onClick: () => {
                const param = new URLSearchParams({ date: dayjs(record.fromDate).format(TIME_FORMATS.DATE) }).toString();
                navigate(Paths.SLOTBOOKING_PLANNER + "?" + param);
            }
        });
    };

    const columnFactory = (onFilter: (filter: IBookedSlotPageFetchParams) => any) => {
        return [
            <Table.Column<IBookedSlot>
                key="fromDate"
                dataIndex="fromDate"
                title={intl.formatMessage(MESSAGES.fromDateTitle)}
                render={(val, record: IBookedSlot) => tableDateRenderer(record.fromDate)}
                sorter
                filterDropdown={onFilter ? ({ confirm }) => (
                    <DateRangeFilter
                        closeFilter={confirm}
                        onFilter={(startDate, endDate) => onFilter({ ...props.filter, from: startDate, until: endDate })}
                        placeholder={intl.formatMessage(MESSAGES.dateFilterPlaceholder)}
                        from={props.filter && props.filter.from}
                        until={props.filter && props.filter.until}
                        format={TIME_FORMATS.DATE}
                    />
                ) : undefined}
                filterIcon={props.filter && <SearchOutlined className={`${props.filter.from ? "icon-filtered" : ""}`}/>}
            />,
            <Table.Column<IBookedSlot>
                key="arrivalTime"
                dataIndex="fromDate"
                title={intl.formatMessage(MESSAGES.arrivalTimeTitle)}
                render={(val, record: IBookedSlot) => tableTimeRenderer(record.fromDate)}
            />,
            <Table.Column<IBookedSlot>
                key="departureTime"
                dataIndex="untilDate"
                title={intl.formatMessage(MESSAGES.departureTimeTitle)}
                render={(val, record: IBookedSlot) => tableTimeRenderer(record.untilDate)}
            />,
            <Table.Column<IBookedSlot>
                key="tankNr"
                dataIndex="tank.name"
                title={intl.formatMessage(MESSAGES.tankNrTitle)}
                render={(val, record: IBookedSlot) => tankNameTableRenderer(record.tank.name)}
                filterDropdown={onFilter ? ({ confirm }) => (
                    <SearchFilter
                        closeFilter={confirm}
                        onFilter={(val: SearchFilterResult) => onFilter({ ...props.filter, tankName: val as string })}
                        placeholder={intl.formatMessage(MESSAGES.tankNameFilterPlaceholder)}
                    />
                ) : undefined}
                filterIcon={<SearchOutlined className={`${props.filter && props.filter.tankName ? "icon-filtered" : ""}`}/>}
            />,
            <Table.Column<IBookedSlot>
                key="customerName"
                dataIndex="tank.customer.name"
                title={intl.formatMessage(MESSAGES.customerTitle)}
                render={(val, record: IBookedSlot) => record.tank.customer ? record.tank.customer.name : "-"}
                filterDropdown={onFilter ? ({ confirm }) => (
                    <SearchFilter
                        closeFilter={confirm}
                        onFilter={(val: SearchFilterResult) => onFilter({ ...props.filter, customer: val as string })}
                        placeholder={intl.formatMessage(MESSAGES.customerFilterPlaceholder)}
                    />
                ) : undefined}
                filterIcon={<SearchOutlined className={`${props.filter && props.filter.customer ? "icon-filtered" : ""}`}/>}
            />,
            <Table.Column<IBookedSlot>
                key="product"
                dataIndex="tank.product"
                title={intl.formatMessage(MESSAGES.productTitle)}
            />,
            <Table.Column<IBookedSlot>
                key="salesOrderReference"
                dataIndex="salesOrderReference"
                title={intl.formatMessage(MESSAGES.salesOrderReferenceTitle)}
                filterDropdown={onFilter ? ({ confirm }) => (
                    <SearchFilter
                        closeFilter={confirm}
                        onFilter={(val: SearchFilterResult) => onFilter({ ...props.filter, salesOrderReference: val as string })}
                        placeholder={intl.formatMessage(MESSAGES.salesOrderReferencePlaceholder)}
                    />
                ) : undefined}
            />
        ];
    };

    return (
        <ItemTable
            data={props.page?.content || []}
            pageMeta={props.page?.meta}
            fetching={props.fetchingPage}
            fetch={props.fetchPage}
            columnFactory={columnFactory}
            onRow={onRow}
            filter={props.filter}
        />
    );
};
