import { createAction } from "@reduxjs/toolkit";
import { IFailedActionPayload, IOrderUpdateActionPayload } from "../../types/action.types";
import { IOrderFilter, IPageRequest, IPageResponse } from "../../types/api.types";
import { IOrder } from "../../types/order.types";

enum ItactActionTypes {
    ITACT_PAGE_LOAD = "[Itact] PAGE_LOAD",
    ITACT_PAGE_FETCH = "[Itact] PAGE_FETCH",
    ITACT_PAGE_RECEIVED = "[Itact] PAGE_RECEIVED",
    ITACT_PAGE_FETCH_FAILED = "[Itact] PAGE_FETCH_FAILED",

    ITACT_PAGE_ORDER_UPDATE_DONE = "[Itact] PAGE_ORDER_UPDATE_DONE",

    ITACT_ORDER_UPDATE_ITACT_NEEDED_DONE = "[Itact] ORDER_UPDATE_ITACT_NEEDED_DONE",
    ITACT_ORDER_UPDATE_ITACT_DONE_DONE = "[Itact] ORDER_UPDATE_ITACT_DONE_DONE",
    ITACT_CSV_DOWNLOAD = "[Itact] CSV_DOWNLOAD"
}

export const itactPageLoadActionCreator = createAction<IPageRequest<IOrderFilter>>(ItactActionTypes.ITACT_PAGE_LOAD);
export const itactPageFetchActionCreator = createAction<IPageRequest<IOrderFilter>>(ItactActionTypes.ITACT_PAGE_FETCH);
export const itactPageReceivedActionCreator = createAction<IPageResponse<IOrder, IOrderFilter>>(ItactActionTypes.ITACT_PAGE_RECEIVED);
export const itactPageFetchFailedActionCreator = createAction<IFailedActionPayload>(ItactActionTypes.ITACT_PAGE_FETCH_FAILED);

export const itactPageOrderUpdateDoneActionCreator = createAction<IOrderUpdateActionPayload>(ItactActionTypes.ITACT_PAGE_ORDER_UPDATE_DONE);

export const itactOrderUpdateItactNeededDoneActionCreator = createAction<IOrderUpdateActionPayload>(ItactActionTypes.ITACT_ORDER_UPDATE_ITACT_NEEDED_DONE);

export const itactOrderUpdateItactDoneDoneActionCreator = createAction<IOrderUpdateActionPayload>(ItactActionTypes.ITACT_ORDER_UPDATE_ITACT_DONE_DONE);
export const itactCsvDownloadActionCreator = createAction<IPageRequest<IOrderFilter>>(ItactActionTypes.ITACT_CSV_DOWNLOAD);
