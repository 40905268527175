
export const countDown = (start: number, callback: (counter: number) => void) => {
    let counter = start;

    return setInterval(() => {
        if (counter === 0) {
            callback(counter);
            counter = start;
        } else {
            callback(counter);
            counter--;
        }
    }, 1000);
};
