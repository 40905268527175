import * as React from "react";
import { FormattedMessage } from "react-intl";
import { ExternalViewOrderInfoContainer } from "../../../../../containers/ExternalViewOrderInfoContainer";
import { Box } from "../../../../Box";
import { Pane } from "../../../customerservicemodal/panes/Pane";

interface IProps {
    orderId: number;
}

export const ExternalViewOrderInfoPane = (props: IProps) => {
    return (
        <Pane title={<FormattedMessage id="detail_info_section.title" defaultMessage="Detail info"/>}>
            <Box>
                <ExternalViewOrderInfoContainer orderId={props.orderId}/>
            </Box>
        </Pane>
    );
};
