import { Button } from "antd";
import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import { CheckInState, ICheckIn } from "../../../../../types/checkIn.types";
import { useSelector } from "react-redux";
import { IApplicationState } from "../../../../../store/state";
import { nameSelector } from "../../../../../store/selectors/authentication.selector";

interface IProps {
  onClick: () => void;
  checkIn: ICheckIn | null;
}

const MESSAGES = defineMessages({
    finish: {
        id: "check_in_modal_footer.finish",
        defaultMessage: "Check-in afwerken",
    }
});

export const FinishCheckinButton = (props: IProps) => {
    const intl = useIntl();
    const myName = useSelector<IApplicationState, string>(nameSelector);

    return props.checkIn && props.checkIn.checkInState === CheckInState.READY_FOR_OPERATIONS && props.checkIn.assignedTo === myName ? (
        <Button onClick={props.onClick} type="primary">
            {intl.formatMessage(MESSAGES.finish)}
        </Button>
    ) : null;
};
