import { Form, Input } from "antd";
import * as React from "react";
import { useEffect, useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { ConfirmableButton } from "../inputs/ConfirmableButton";
import { IBadgeSerialForm } from "../../../../types/forms.types";

interface IProps {
    isLoading: boolean;
    onSubmit: (values: IBadgeSerialForm) => void;
    wiegandBadge: string | null;
    lenelBadge: string | null;
    badgeName: "lenel" | "wiegand";
    wiegandBadgeIsApplicable: boolean;
}

const MESSAGES = defineMessages({
    requiredError: {
        id: "badge_serial_form.validations.required",
        defaultMessage: "Een waarde is verplicht"
    },
    noNumberError: {
        id: "badge_serial_form.validations.number",
        defaultMessage: "Een numerieke waarde is verplicht"
    },
    placeholder: {
        id: "badge_serial_form.placeholders.badge",
        defaultMessage: "123456"
    },
    enterWiegandBeforeLenelError: {
        id: "badge_serial_form.validations.enter_wiegand_before_lenel",
        defaultMessage: "Please enter a wiegand badge first."
    }
});

export const BadgeSerialForm = (props: IProps) => {
    const intl = useIntl();
    const [form] = Form.useForm();
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);
    const [isWiegandInputEnabled] = useState(true);
    // if there is no wiegand badge, lenel badge should be enabled by default (since some records only have one type of badge instead of 2)
    const [isLenelInputEnabled, setIsLenelInputEnabled] = useState(!props.wiegandBadgeIsApplicable);
    const [value, setValue] = useState<string | null>(null);

    useEffect(() => {
        if (props.wiegandBadge) {
            setIsLenelInputEnabled(true);
            if (props.badgeName === "wiegand") {
                form.setFieldValue("badgeSerial", props.wiegandBadge);
            }
        }
    }, [props.wiegandBadge]);

    useEffect(() => {
        if (props.badgeName === "lenel") {
            form.setFieldValue("badgeSerial", props.lenelBadge);
        }
    }, [props.lenelBadge]);

    const isEnabled = () => {
        if (props.isLoading) {
            return false;
        }

        if (!form.isFieldsTouched()) {
            return false;
        }

        if (form.getFieldValue("badgeSerial") === "") {
            return false;
        }

        return !(props.wiegandBadge === form.getFieldValue("badgeSerial") || props.lenelBadge === form.getFieldValue("badgeSerial"));


    };

    useEffect(() => {
        setIsButtonEnabled(isEnabled());
    }, [value, props.wiegandBadge, props.lenelBadge]);

    const handleSubmit = (values: any) => {
        props.onSubmit(values);
    };

    return (
        <>
            <Form layout="inline" onFinish={handleSubmit} form={form}>
                <Form.Item
                    name="badgeSerial"
                    rules={[
                        {
                            required: true,
                            message: intl.formatMessage(MESSAGES.requiredError),
                        },
                        {
                            pattern: /^\d*$/,
                            message: intl.formatMessage(MESSAGES.noNumberError),
                        },
                    ]}
                >
                    <Input
                        placeholder={intl.formatMessage(MESSAGES.placeholder)}
                        disabled={props.badgeName === "wiegand" ? !isWiegandInputEnabled : !isLenelInputEnabled}
                        maxLength={15}
                        onChange={(e) => {
                            setValue(e.target.value);
                        }}
                        onKeyPress={(e) => {
                            const specialCharRegex = new RegExp("[0-9]");

                            if (!specialCharRegex.test(e.key)) {
                                e.preventDefault();
                                return false;
                            }
                        }}
                    />
                </Form.Item>

                <Form.Item>
                    <ConfirmableButton
                        label={<FormattedMessage id="badge_serial_form.inputs.submit.label" defaultMessage="Koppelen" />}
                        confirmLabel={<FormattedMessage id="badge_serial_form.inputs.submit.confirm" defaultMessage="Bent u zeker dat u de badge wilt aanpassen?" />}
                        disabled={!isButtonEnabled}
                        shouldConfirm={props.badgeName === "wiegand" ? props.wiegandBadge !== null : props.lenelBadge !== null}
                        onConfirm={() => form.submit()}
                    />
                </Form.Item>
            </Form>
            {props.badgeName === "lenel" && !isLenelInputEnabled && <span>{intl.formatMessage(MESSAGES.enterWiegandBeforeLenelError)}</span>}

        </>
    );
};
