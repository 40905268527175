import { useDispatch, useSelector } from "react-redux";
import { IApplicationState } from "../../store/state";
import { dependsOnBookedSlotReceivedCreator } from "../../store/actions";
import { IBookedSlot } from "../../types/bookedSlot.types";
import { IBookedSlotResourceLoader } from "../../types/hook.types";
import { dependsOnBookedSlotSelector } from "../../store/selectors/bookedSlot.selector";

export type IBookedSlotLoader = IBookedSlotResourceLoader;

export const useDependsOnBookedSlot = (): IBookedSlotLoader => {
    const dispatch = useDispatch();

    const resource = useSelector<IApplicationState, IBookedSlot | null>(dependsOnBookedSlotSelector);

    const setResource = (dependsOnBookedSlot: IBookedSlot) => {
        return dispatch(dependsOnBookedSlotReceivedCreator(dependsOnBookedSlot));
    };

    return {
        resource,
        setResource
    };
};
