import * as React from "react";
import { formatField } from "../../../../../../../../utils/format";

interface IProps<T> {
    value?: T;
    formatter?: (value?: T) => string;
    className?: string;
    styles?: React.CSSProperties;
}

export const InfoField = <T,>(props: IProps<T>) => {
    const val = props.formatter ? props.formatter(props.value) : props.value;

    return (
        <div className={props.className} style={props.styles}>
            {formatField(val)}
        </div>
    );
};
