import { Button, Form, Radio } from "antd";
import * as React from "react";
import { useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { Message } from "../../../../../../modals/customerservicemodal/panes/messagesPane/message/Message";


interface IProps {
    prev: () => void;
    onOk: (manualWorkNeeded: boolean, msg?: string) => void;
}

const MESSAGES = defineMessages({
    manualWorkNeeded: {
        id: "manual_work_form.manual_work_needed",
        defaultMessage: "Manueel werk door ITACT vereist?"
    },
    yes: {
        id: "manual_work_form.yes",
        defaultMessage: "Ja"
    },
    no: {
        id: "manual_work_form.no",
        defaultMessage: "Nee"
    },
    optionalMessage: {
        id: "manual_work_form.optional_message",
        defaultMessage: "Optioneel bericht voor Customer Service:"
    },
    prev: {
        id: "manual_work_form.prev",
        defaultMessage: "Vorige"
    },
    confirm: {
        id: "manual_work_form.confirm",
        defaultMessage: "Bevestig"
    },
    fieldRequired: {
        defaultMessage: "Alle velden moeten ingevuld zijn.",
        id: "manual_work_form.field_required",
    }
});

export const ManualWorkForm = (props: IProps) => {
    const intl = useIntl();
    const [msg, setMsg] = useState<string | undefined>();
    const [form] = Form.useForm();

    const onSubmit = (values: any) => {
        props.onOk(values.manualWorkNeeded, msg);
    };

    return (
        <div>
            <Form onFinish={onSubmit} form={form} layout="vertical">
                <Form.Item
                    name="manualWorkNeeded"
                    label={intl.formatMessage(MESSAGES.manualWorkNeeded)}
                    rules={[
                        {
                            required: true,
                            message: intl.formatMessage(MESSAGES.fieldRequired)
                        }
                    ]}
                >
                    <Radio.Group>
                        <Radio.Button value={true}>{intl.formatMessage(MESSAGES.yes)}</Radio.Button>
                        <Radio.Button value={false}>{intl.formatMessage(MESSAGES.no)}</Radio.Button>
                    </Radio.Group>
                </Form.Item>
                <Message title={intl.formatMessage(MESSAGES.optionalMessage)} editable onChange={setMsg} className="margin-top"/>
                <div className="margin-top">
                    <Form.Item>
                        <Button onClick={props.prev}>
                            {intl.formatMessage(MESSAGES.prev)}
                        </Button>
                        <Button type="primary" htmlType="submit" className="margin-half-left">
                            {intl.formatMessage(MESSAGES.confirm)}
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        </div>
    );
};
