import * as React from "react";
import { defineMessages, IntlShape, useIntl } from "react-intl";

interface IProps {
  number: number;
  className?: string;
  intl?: IntlShape;
}

const MESSAGES = defineMessages({
    kg: {
        id: "kilogram_display.kg",
        defaultMessage: "KG",
    }
});
export const KilogramDisplay = (props: IProps) => {
    // dirty hack for components without a language provider. Some modals of ant design do not have this injected.
    // eslint-disable-next-line
    const intl = props.intl || useIntl();
    return <div className={props.className}>{new Intl.NumberFormat("de-DE").format(props.number)} {intl.formatMessage(MESSAGES.kg)}</div>;
};
