import React from "react";
import { PaneTitle } from "../PaneTitle";

interface IProps {
    title: string;
    children: React.ReactNode;
}

export const VehicleInfo = (props: IProps) => {
    return (
        <React.Fragment>
            <PaneTitle title={props.title}/>
            {
                props.children
            }
        </React.Fragment>
    );
};
