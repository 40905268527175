import { combineReducers, createReducer } from "@reduxjs/toolkit";
import { pitListFetchCreator, pitListFetchFailedCreator, pitListReceivedCreator } from "../actions/pit";
import { IPit } from "../../types/salesOrder.types";

export interface IPitState {
    pitList: IPit[];
    loading: boolean;
}

const pitList = createReducer<IPit[]>([], {
    [pitListReceivedCreator.type]: (tankList, action) => action.payload,
});

const loading = createReducer<boolean>(false, {
    [pitListFetchCreator.type]: () => true,
    [pitListReceivedCreator.type]: () => false,
    [pitListFetchFailedCreator.type]: () => false,
});

export const pitReducer = combineReducers<IPitState>({
    pitList: pitList,
    loading: loading,
});
