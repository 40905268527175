import * as React from "react";
import { useEffect } from "react";
import { AppLayout } from "./AppLayout";
import { CheckInOverviewTableContainer } from "../containers/tablecontainers/CheckInOverviewTableContainer";
import { useDispatch } from "react-redux";
import { setLanguageAction } from "../../store/actions/language.actions";
import { LanguageCode } from "../../types/language.types";

export const CheckInOverviewReadOnlyScreen = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setLanguageAction(LanguageCode.EN));

        return () => {
            dispatch(setLanguageAction(LanguageCode.NL));
        };
    }, []);

    return (
        <AppLayout>
            <CheckInOverviewTableContainer readonly/>
        </AppLayout>
    );
};
