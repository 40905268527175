import { URL_PATHS } from "../../constants/api.constants";
import { getHttpClient } from "./httpClient";

const baseUrl = URL_PATHS.CHECKIN + "/application-version";

const getApplicationVersion = () => {
    return getHttpClient().get(baseUrl);
};

export const applicationVersionApi = {
    getApplicationVersion
};