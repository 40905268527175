import { Divider } from "antd";
import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import { SlotBookingBookSelectedSlotContainer } from "../../containers/SlotBookingBookSelectedSlotContainer";
import { SlotBookingSelectedSlotContainer } from "../../containers/SlotBookingSelectedSlotContainer";
import { Box } from "../Box";

const MESSAGES = defineMessages({
    slots: {
        id: "sale_order_booking_selected_slot_section.selected_slot",
        defaultMessage: "Geselecteerd slot",
    }
});

export const SlotBookingSelectedSlots = () => {
    const intl = useIntl();

    return (
        <div className={"flex flex-column margin-left"} >
            <h3 className="slots-header">{intl.formatMessage(MESSAGES.slots)}</h3>
            <Box className="margin-half-top margin-right block">
                <SlotBookingSelectedSlotContainer/>
                <Divider/>
                <SlotBookingBookSelectedSlotContainer/>
            </Box>
        </div>
    );
};
