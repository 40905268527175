import { getHttpClient } from "./httpClient";
import { URL_PATHS } from "../../constants/api.constants";

const baseUrl = URL_PATHS.BOOKING + "/slot-booking-configs";

const getConfig = (zoneId: number) => getHttpClient()
    .get(`${baseUrl}/${zoneId}`);

export const slotBookingConfigApi = {
    getConfig,
};
