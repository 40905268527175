import { createAction } from "@reduxjs/toolkit";
import { IFailedActionPayload } from "../../types/action.types";
import { ITtatCalculation, ITtatCalculationFetchParams, } from "../../types/ttatCalculation.types";

enum TtatCalculationTypes {
    TTAT_CALCULATION_FETCH = "[TtatCalculation] FETCH",
    TTAT_CALCULATION_RECEIVED = "[TtatCalculation] RECEIVED",
    TTAT_CALCULATION_FETCH_FAILED = "[TtatCalculation] FETCH_FAILED",
}

export const ttatCalculationFetchCreator = createAction<{ ttatCalculationFetchParams: ITtatCalculationFetchParams }>(TtatCalculationTypes.TTAT_CALCULATION_FETCH);
export const ttatCalculationReceivedCreator = createAction<ITtatCalculation | null>(TtatCalculationTypes.TTAT_CALCULATION_RECEIVED);
export const ttatCalculationFetchFailedCreator = createAction<IFailedActionPayload>(TtatCalculationTypes.TTAT_CALCULATION_FETCH_FAILED);
