import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import { IChassis, ICheckIn, IContainer, ITanker, ITractor } from "../../../../../../types/checkIn.types";
import { Box } from "../../../../Box";
import { Pane } from "../../../customerservicemodal/panes/Pane";
import { VehiclesInfo } from "./VehiclesInfo";

interface IProps {
    tractor?: ITractor;
    chassis?: IChassis;
    tanker?: ITanker;
    container?: IContainer;
    checkIn: ICheckIn;
}

const MESSAGES = defineMessages({
    title: {
        id: "vehicle_info_pane.title",
        defaultMessage: "Voertuig info"
    }
});

export const VehiclesInfoPane = (props: IProps) => {
    const intl = useIntl();

    return (
        <Pane title={intl.formatMessage(MESSAGES.title)}>
            <Box>
                <VehiclesInfo
                    tanker={props.tanker}
                    tractor={props.tractor}
                    chassis={props.chassis}
                    container={props.container}
                    checkIn={props.checkIn}
                />
            </Box>
        </Pane>
    );
};
