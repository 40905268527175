import { ReactComponent as scheduleOutlined } from "@ant-design/icons-svg/inline-svg/outlined/schedule.svg";
import { ReactComponent as checkinOutlined } from "@ant-design/icons-svg/inline-svg/outlined/ant-design.svg";
import { ReactComponent as settingOutlined } from "@ant-design/icons-svg/inline-svg/outlined/setting.svg";
import { ReactComponent as userOutlined } from "@ant-design/icons-svg/inline-svg/outlined/user.svg";
import { Menu } from "antd";
import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as DropIcon } from "../../../assets/drop-24px.svg";
import { isAuthorized } from "../../../security/authorization";
import { Paths, ROLES } from "../../../types/api.types";
import { useAuthentication } from "../../hooks/useAuthentication";
import { MenuItemTitle } from "./MenuItemTitle";
import { useDispatch } from "react-redux";
import { logoutAction } from "../../../store/actions";

interface IProps {
    className?: string;
}

const MESSAGES = defineMessages({
    archivedCheckins: {
        id: "sidemenu.checkins.archivedCheckins.title",
        defaultMessage: "Archive"
    },
    mapTitle: {
        id: "sidemenu.map.title",
        defaultMessage: "Map"
    },
    cleaningTitle: {
        id: "sidemenu.cleaning.title",
        defaultMessage: "Cleaning"
    },
    customerServiceTitle: {
        id: "sidemenu.customer_service.title",
        defaultMessage: "Customer service"
    },
    itactTitle: {
        id: "sidemenu.itact.title",
        defaultMessage: "ITACT",
    },
    cleanersTitle: {
        id: "sidemenu.cleaners.title",
        defaultMessage: "Cleaners"
    },
    customerTitle: {
        id: "sidemenu.customer.title",
        defaultMessage: "Klant"
    },
    slotBookings: {
        id: "sidemenu.slot_booking.main_title",
        defaultMessage: "Slot bookings"
    },
    slotBookingTitle: {
        id: "sidemenu.slot_booking.title",
        defaultMessage: "Boeken"
    },
    slotBookingPlanner: {
        id: "sidemenu.slot_booking_planner.title",
        defaultMessage: "Planner"
    },
    slotBookingList: {
        id: "sidemenu.slot_booking_list.title",
        defaultMessage: "Lijst"
    },
    checkinTitle: {
        id: "sidemenu.checkins.title",
        defaultMessage: "Check-ins"
    },
    settingsTitle : {
        id: "sidemenu.settings.title",
        defaultMessage: "Settings"
    },
    blacklistCheckin: {
        id: "sidemenu.checkins.blacklist.title",
        defaultMessage: "Blacklists"
    },
    overviewReadOnly: {
        id: "sidemenu.checkins.overviewReadOnly.title",
        defaultMessage: "Check-ins voor chauffeurs"
    },
    checkinOverview: {
        id: "sidemenu.checkins.overview.title",
        defaultMessage: "Lijst"
    },
    itactNeeded: {
        id: "sidemenu.settings.itact_needed.title",
        defaultMessage: "Itact nodig"
    },
    tolerances: {
        id: "sidemenu.settings.tolerances.title",
        defaultMessage: "Toleranties"
    },
    archiveSalesOrders: {
        id: "sidemenu.settings.archive_salesorders.title",
        defaultMessage: "Archiveer sales orders"
    },
    weightBadgeTank: {
        id: "sidemenu.settings.weight_badge_tank.title",
        defaultMessage: "Tanks met weegbadge"
    },
    certificate: {
        id: "sidemenu.settings.certificate.title",
        defaultMessage: "Certificaten"
    },
    freeStorageDays: {
        id: "sidemenu.settings.free_storage_days.title",
        defaultMessage: "Vrije opslag dagen"
    },
    selfCheckIn: {
        id: "sidemenu.settings.self_check_in.title",
        defaultMessage: "Self check in"
    },
    logOut: {
        id: "sidemenu.settings.logout.title",
        defaultMessage: "Afmelden"
    }
});

export const SideMenu = (props: IProps) => {
    const { SubMenu } = Menu;
    const intl = useIntl();
    const navigate = useNavigate();
    const location = useLocation();
    const [ authentication ] = useAuthentication();
    const dispatch = useDispatch();

    const roles = authentication.roles || [];

    const onClick = (e: { key: string }) => {
        navigate(e.key);
    };

    // https://github.com/ant-design/ant-design/issues/4853
    return (
        <Menu className={props.className} mode="horizontal" selectedKeys={[location.pathname]} onClick={onClick}>
            {
                isAuthorized(roles, [ROLES.CLEANING]) &&
                <SubMenu title={<MenuItemTitle text={intl.formatMessage(MESSAGES.cleaningTitle)} icon={DropIcon}/>} key={Paths.CUSTOMER_SERVICE + "-menu"}>
                    <Menu.Item key={Paths.CUSTOMER_SERVICE}>
                        <MenuItemTitle text={intl.formatMessage(MESSAGES.customerServiceTitle)}/>
                    </Menu.Item>
                    <Menu.Item key={Paths.ITACT}>
                        <MenuItemTitle text={intl.formatMessage(MESSAGES.itactTitle)}/>
                    </Menu.Item>
                    <Menu.Item key={Paths.CLEANING}>
                        <MenuItemTitle text={intl.formatMessage(MESSAGES.cleanersTitle)}/>
                    </Menu.Item>
                </SubMenu>
            }
            {
                isAuthorized(roles, [ROLES.SLOTBOOKING]) &&
                <SubMenu title={<MenuItemTitle text={intl.formatMessage(MESSAGES.slotBookings)} icon={scheduleOutlined}/>} key={Paths.SLOTBOOKING + "-menu"}>
                    <Menu.Item key={Paths.SLOTBOOKING}>
                        <MenuItemTitle text={intl.formatMessage(MESSAGES.slotBookingTitle)}/>
                    </Menu.Item>
                    <Menu.Item key={Paths.SLOTBOOKING_PLANNER}>
                        <MenuItemTitle text={intl.formatMessage(MESSAGES.slotBookingPlanner)}/>
                    </Menu.Item>
                    <Menu.Item key={Paths.SLOTBOOKING_LIST}>
                        <MenuItemTitle text={intl.formatMessage(MESSAGES.slotBookingList)}/>
                    </Menu.Item>
                </SubMenu>
            }
            {
                isAuthorized(roles, [ROLES.CHECKIN]) &&
                <SubMenu title={<MenuItemTitle text={intl.formatMessage(MESSAGES.checkinTitle)} icon={checkinOutlined}/>} key={Paths.CHECKIN + "-menu"}>
                    <Menu.Item key={Paths.CHECKIN_OVERVIEW}>
                        <MenuItemTitle text={intl.formatMessage(MESSAGES.checkinOverview)}/>
                    </Menu.Item>
                    <Menu.Item key={Paths.CHECKIN_ARCHIVED}>
                        <MenuItemTitle text={intl.formatMessage(MESSAGES.archivedCheckins)}/>
                    </Menu.Item>
                    <Menu.Item key={Paths.CHECKIN_DRIVERS}>
                        <MenuItemTitle text={intl.formatMessage(MESSAGES.blacklistCheckin)}/>
                    </Menu.Item>
                    <Menu.Item key={Paths.CHECKIN_OVERVIEW_READ_ONLY}>
                        <MenuItemTitle text={intl.formatMessage(MESSAGES.overviewReadOnly)}/>
                    </Menu.Item>
                </SubMenu>
            }
            {
                isAuthorized(roles, [ROLES.ADMIN]) &&
                <SubMenu title={<MenuItemTitle text={intl.formatMessage(MESSAGES.settingsTitle)} icon={settingOutlined}/>} key={Paths.SETTINGS + "-menu"}>
                    <Menu.Item key={Paths.ITACT_NEEDED}>
                        <MenuItemTitle text={intl.formatMessage(MESSAGES.itactNeeded)}/>
                    </Menu.Item>
                    <Menu.Item key={Paths.FREE_STORAGE_DAYS}>
                        <MenuItemTitle text={intl.formatMessage(MESSAGES.freeStorageDays)}/>
                    </Menu.Item>
                    <Menu.Item key={Paths.SELF_CHECK_IN}>
                        <MenuItemTitle text={intl.formatMessage(MESSAGES.selfCheckIn)}/>
                    </Menu.Item>
                    <Menu.Item key={Paths.TOLERANCES}>
                        <MenuItemTitle text={intl.formatMessage(MESSAGES.tolerances)}/>
                    </Menu.Item>
                    <Menu.Item key={Paths.ARCHIVE_SALESORDERS}>
                        <MenuItemTitle text={intl.formatMessage(MESSAGES.archiveSalesOrders)}/>
                    </Menu.Item>
                    <Menu.Item key={Paths.WEIGHT_BADGE_TANK}>
                        <MenuItemTitle text={intl.formatMessage(MESSAGES.weightBadgeTank)}/>
                    </Menu.Item>
                    <Menu.Item key={Paths.CERTIFICATE}>
                        <MenuItemTitle text={intl.formatMessage(MESSAGES.certificate)}/>
                    </Menu.Item>
                </SubMenu>
            }

            {
                authentication.authenticated ? (
                    <SubMenu title={<MenuItemTitle text={authentication.name || ""} icon={userOutlined}/>} className="menu-right-item" key="user">
                        <Menu.Item key={Paths.LOGOUT} onClick={() => dispatch(logoutAction())}>
                            <MenuItemTitle text={intl.formatMessage(MESSAGES.logOut)}/>
                        </Menu.Item>
                    </SubMenu>
                ) : null
            }
        </Menu>
    );
};
