import * as React from "react";
import { defineMessages, IntlShape } from "react-intl";
import { ICheckInFilter } from "../../../../types/api.types";
import { Table, Tooltip } from "antd";
import { SearchFilter, SearchFilterResult } from "../filters/SearchFilter";
import Icon, { SearchOutlined } from "@ant-design/icons";
import { InfoField } from "../../modals/customerservicemodal/panes/detailinfopane/sections/infofields/InfoField";
import {
    CHECK_IN_REASON_MESSAGES,
    CHECK_IN_STATE_MESSAGES,
    EXTENDED_CHECK_IN_STATE_MESSAGES,
    formatCheckInState,
    formatExtendedCheckInState,
    optionalFormatIso8601DateTime
} from "../../../../utils/format";
import { CheckInState, EXTENDED_CHECK_IN_STATE, ExtendedCheckInState, ICheckIn } from "../../../../types/checkIn.types";
import { getEarliestTimeSlot } from "../../../../utils/order";
import { getCompany, getFirstName, getLastName } from "../../../../utils/checkIn";
import { ReactComponent as RemarkIcon } from "../../../../assets/remark-yellow.svg";
import { TIME_FORMATS } from "../../../../constants/time";
import dayjs from "dayjs";

export const MESSAGES = defineMessages({
    transportCompany: {
        id: "check_overview_table.transport_company",
        defaultMessage: "Transportbedrijf"
    },
    transportCompanyFilterPlaceholder: {
        id: "transport_company_filter.placeholder",
        defaultMessage: "Transportbedrijf"
    },
    firstNameDriver: {
        id: "check_overview_table.first_name_driver",
        defaultMessage: "Voornaam"
    },
    firstNameFilterPlaceholder: {
        id: "first_name_filter.placeholder",
        defaultMessage: "Voornaam"
    },
    lastNameDriver: {
        id: "check_overview_table.last_name_driver",
        defaultMessage: "Achternaam"
    },
    lastNameFilterPlaceholder: {
        id: "last_name_filter.placeholder",
        defaultMessage: "Achternaam"
    },
    customerReference: {
        id: "check_overview_table.customer_reference",
        defaultMessage: "Klant referentie"
    },
    operation: {
        id: "checkin_overview_table.operation",
        defaultMessage: "Operatie"
    },
    tankNumber: {
        id: "check_overview_table.tank_number",
        defaultMessage: "Tank nr."
    },
    productName: {
        id: "check_overview_table.product_name",
        defaultMessage: "Product naam"
    },
    timeSlot: {
        id: "check_overview_table.time_slot",
        defaultMessage: "Tijdslot"
    },
    terminal: {
        id: "check_overview_table.terminal",
        defaultMessage: "Terminal"
    },
    status: {
        id: "check_overview_table.status",
        defaultMessage: "Status"
    },
    handler: {
        id: "check_overview_table.handler",
        defaultMessage: "Behandelaar"
    },
    completedOn: {
        id: "check_overview_table.completed_on",
        defaultMessage: "SCI klaar"
    },
});

export const expandableMarginLeft = { marginLeft: 54 };

export const transportCompanyColumn = (intl: IntlShape, sort: boolean, onFilter?: (filter: ICheckInFilter) => any, filter?: ICheckInFilter) => (
    <Table.Column
        key="transportCompany"
        width={100}
        sorter={sort}
        align="left"
        title={intl.formatMessage(MESSAGES.transportCompany)}
        render={(record: ICheckIn) => <InfoField className="truncated-text" styles={{ width: 80 }} value={getCompany(record)} />}
        filterDropdown={onFilter ? ({ confirm }) => (
            <SearchFilter
                onFilter={(val: SearchFilterResult) => onFilter({ ...filter, transportCompany: val as string } as ICheckInFilter)}
                closeFilter={confirm}
                placeholder={intl.formatMessage(MESSAGES.transportCompanyFilterPlaceholder)}
                clearFilter={() => onFilter({ ...filter, transportCompany: "" } as ICheckInFilter)}
            />) : undefined}
        filterIcon={<SearchOutlined className={`${filter && filter.transportCompany ? "icon-filtered" : ""}`}/>}
    />
);

export const remarkColumn = () => (
    <Table.Column
        key="checkinRemark"
        width={20}
        className="check-in-table-remark"
        title={""}
        render={(record: ICheckIn) => {
            const firstRemark = !!record.remarks.length && record.remarks[0];

            return firstRemark ?
                <Tooltip
                    overlayStyle={{ maxWidth: 500, marginLeft: 16 }}
                    placement="topLeft"
                    title={() => (
                        <div className="flex-column margin-half">
                            <div className="flex-row bold">
                                <span>{firstRemark.createdByUser} -&nbsp;</span>
                                <span>{dayjs(firstRemark.createdDate).format(TIME_FORMATS.DATE_TIME)}</span>
                            </div>
                            <span className="text-disabled">{firstRemark.remark}</span>
                        </div>
                    )}
                >
                    <Icon component={RemarkIcon} />
                </Tooltip>:
                <></>;
        }}
    />
);

export const firstNameColumn = (intl: IntlShape, sort: boolean, onFilter?: (filter: ICheckInFilter) => any, filter?: ICheckInFilter) => (
    <Table.Column
        key="firstName"
        width={112}
        sorter
        align="left"
        title={intl.formatMessage(MESSAGES.firstNameDriver)}
        render={(record: ICheckIn) => <InfoField className="truncated-text" styles={{ width: 98 }} value={getFirstName(record)} />}
        filterDropdown={onFilter ? ({ confirm }) => (
            <SearchFilter
                onFilter={(val: SearchFilterResult) => onFilter({ ...filter, firstName: val as string } as ICheckInFilter)}
                closeFilter={confirm}
                placeholder={intl.formatMessage(MESSAGES.firstNameFilterPlaceholder)}
                clearFilter={() => onFilter({ ...filter, firstName: "" } as ICheckInFilter)}
            />) : undefined}
        filterIcon={<SearchOutlined className={`${filter && filter.firstName ? "icon-filtered" : ""}`}/>}
    />
);

export const lastNameColumn = (intl: IntlShape, sort: boolean, onFilter?: (filter: ICheckInFilter) => any, filter?: ICheckInFilter) => (
    <Table.Column
        key="lastName"
        width={160}
        sorter
        align="left"
        title={intl.formatMessage(MESSAGES.lastNameDriver)}
        render={(record: ICheckIn) => <InfoField className="truncated-text" styles={{ width: 140 }} value={getLastName(record)} />}
        filterDropdown={onFilter ? ({ confirm }) => (
            <SearchFilter
                onFilter={(val: SearchFilterResult) => onFilter({ ...filter, lastName: val as string } as ICheckInFilter)}
                closeFilter={confirm}
                placeholder={intl.formatMessage(MESSAGES.lastNameFilterPlaceholder)}
                clearFilter={() => onFilter({ ...filter, lastName: "" } as ICheckInFilter)}
            />) : undefined}
        filterIcon={<SearchOutlined className={`${filter && filter.lastName ? "icon-filtered" : ""}`}/>}
    />
);

export const timeSlotColumn = (intl: IntlShape) => (
    <Table.Column
        key="deliveryAt"
        dataIndex={["salesOrders", "productionOrders", "deliveryAt"]}
        width={150}
        sorter
        align="left"
        title={intl.formatMessage(MESSAGES.timeSlot)}
        render={(text: string, record: ICheckIn) => {
            const timeSlot = getEarliestTimeSlot(record.salesOrders || []);
            return <InfoField className={record.salesOrders?.find((order) => order.overdue) ? "text-red" : ""} value={optionalFormatIso8601DateTime(timeSlot)} />;
        }}
    />
);

export function customerReferenceColumn<T>(intl: IntlShape, renderer: (val: string, record: T, multiRecord?: boolean) => any, onFilter?: (filter: ICheckInFilter) => any, filter?: ICheckInFilter, multiRecord?: boolean) {
    return (
        <Table.Column
            key="customerReference"
            dataIndex="customerReference"
            width={multiRecord ? "9%" : 190}
            title={<span style={multiRecord ? expandableMarginLeft : undefined}>{intl.formatMessage(MESSAGES.customerReference)}</span>}
            render={(val, record) => renderer(val, record as T, multiRecord)}
            sorter
            align="left"
            filterDropdown={onFilter ? ({ confirm }) => (
                <SearchFilter
                    onFilter={(val: SearchFilterResult) => onFilter({ ...filter, customerReference: val as string } as ICheckInFilter)}
                    closeFilter={confirm}
                    placeholder={intl.formatMessage(MESSAGES.customerReference)}
                    clearFilter={() => onFilter({ ...filter, customerReference: "" } as ICheckInFilter)}
                />) : undefined}
            filterIcon={<SearchOutlined className={`${filter && filter.customerReference ? "icon-filtered" : ""}`}/>}
        />
    );
}

export function operationColumn<T>(intl: IntlShape, renderer: (val: string, record: T) => any , multiRecord?: boolean) {
    return <Table.Column
        key="checkInReason"
        dataIndex="checkInReason"
        width={multiRecord ? "6%" : 78}
        title={intl.formatMessage(MESSAGES.operation)}
        render={renderer}
        sorter={(a, b) => intl.formatMessage(CHECK_IN_REASON_MESSAGES[(a as unknown as ICheckIn).checkInReason]).localeCompare(intl.formatMessage(CHECK_IN_REASON_MESSAGES[(b as unknown as ICheckIn).checkInReason]))}
        align="left"
    />;
}

export function tankColumn<T>(intl: IntlShape, renderer: (val: string, record: T) => any, onFilter?: (filter: ICheckInFilter) => any, filter?: ICheckInFilter, multiRecord?: boolean) {
    return (
        <Table.Column
            key="tank"
            dataIndex="tank"
            width={multiRecord ? "6%" : 102}
            title={intl.formatMessage(MESSAGES.tankNumber)}
            render={renderer}
            sorter
            align="left"
            filterDropdown={onFilter ? ({ confirm }) => (
                <SearchFilter
                    onFilter={(val: SearchFilterResult) => onFilter({ ...filter, tank: val as string } as ICheckInFilter)}
                    closeFilter={confirm}
                    placeholder={intl.formatMessage(MESSAGES.tankNumber)}
                    clearFilter={() => onFilter({ ...filter, tank: "" } as ICheckInFilter)}
                />) : undefined}
            filterIcon={<SearchOutlined className={`${filter && filter.tank ? "icon-filtered" : ""}`}/>}
        />
    );
}

export function productName<T>(intl: IntlShape, renderer: (val: string, record: T) => any, onFilter?: (filter: ICheckInFilter) => any, filter?: ICheckInFilter, multiRecord?: boolean) {
    return (
        <Table.Column
            key="productName"
            width={multiRecord ? "6%" : undefined}
            dataIndex="productName"
            title={intl.formatMessage(MESSAGES.productName)}
            render={renderer}
            sorter
            align="left"
            filterDropdown={onFilter ? ({ confirm }) => (
                <SearchFilter
                    onFilter={(val: SearchFilterResult) => onFilter({ ...filter, productName: val as string } as ICheckInFilter)}
                    closeFilter={confirm}
                    placeholder={intl.formatMessage(MESSAGES.productName)}
                    clearFilter={() => onFilter({ ...filter, productName: "" } as ICheckInFilter)}
                />) : undefined}
            filterIcon={<SearchOutlined className={`${filter && filter.productName ? "icon-filtered" : ""}`}/>}
        />
    );
}

export const checkInStatusColumn = (intl: IntlShape, onFilter?: (filter: ICheckInFilter) => any, filter?: ICheckInFilter, defaultFilteredValue?: ExtendedCheckInState[]) => (
    <Table.Column
        key="checkInState"
        dataIndex="checkInState"
        width={147}
        sorter={(a, b) => intl.formatMessage(CHECK_IN_STATE_MESSAGES[(a as ICheckIn).checkInState]).localeCompare(intl.formatMessage(CHECK_IN_STATE_MESSAGES[(b as ICheckIn).checkInState]))}
        align="left"
        title={intl.formatMessage(MESSAGES.status)}
        render={(val) => <InfoField value={formatCheckInState(val, intl)} />}
        defaultFilteredValue={defaultFilteredValue}
        filters={Object.values(CheckInState).map((checkInState) => (
            {
                text: intl.formatMessage(CHECK_IN_STATE_MESSAGES[checkInState]),
                value: checkInState,
            }
        ))}
        filterIcon={<SearchOutlined className={`${filter && filter.checkInState && filter.checkInState.length > 0 ? "icon-filtered" : ""}`}/>}
    />
);

export const extendedCheckInStatusColumn = (intl: IntlShape, onFilter?: (filter: ICheckInFilter) => any, filter?: ICheckInFilter, defaultFilteredValue?: ExtendedCheckInState[]) => (
    <Table.Column
        key="extendedCheckInState"
        dataIndex="extendedCheckInState"
        width={147}
        sorter={(a, b) => intl.formatMessage(EXTENDED_CHECK_IN_STATE_MESSAGES[(a as ICheckIn).extendedCheckInState]).localeCompare(intl.formatMessage(EXTENDED_CHECK_IN_STATE_MESSAGES[(b as ICheckIn).extendedCheckInState]))}
        align="left"
        title={intl.formatMessage(MESSAGES.status)}
        render={(val) => <InfoField value={formatExtendedCheckInState(val, intl)} />}
        defaultFilteredValue={defaultFilteredValue}
        filters={Object.values(EXTENDED_CHECK_IN_STATE).map((extendedCheckInState) => (
            {
                text: intl.formatMessage(EXTENDED_CHECK_IN_STATE_MESSAGES[extendedCheckInState]),
                value: extendedCheckInState,
            }
        ))}
        filterIcon={<SearchOutlined className={`${filter && filter.extendedCheckInState && filter.extendedCheckInState.length > 0 ? "icon-filtered" : ""}`}/>}
    />
);

export const terminalColumn = (intl: IntlShape) => (
    <Table.Column
        key="terminal"
        dataIndex={["salesOrders", "productionOrders", "storageLocation"]}
        width={55}
        sorter
        align="left"
        title={intl.formatMessage(MESSAGES.terminal)}
        render={(text: string, record: ICheckIn) => {
            if (record.visitor) {
                return record.visitor.terminal;
            }
            if (record.contractor) {
                return record.contractor.terminal;
            }
            if (record.supplier) {
                return record.supplier.terminal;
            }
            if (record.salesOrders && record.salesOrders[0] && record.salesOrders[0].productionOrders[0] && record.salesOrders[0].productionOrders[0].storageLocation) {
                return record.salesOrders && record.salesOrders[0] && record.salesOrders[0].productionOrders[0] && record.salesOrders[0].productionOrders[0].storageLocation;
            }
            return record.manualOrders && record.manualOrders[0] && record.manualOrders[0].storageLocation;
        }}
    />
);

export const assignedToColumn = (intl: IntlShape, onFilter?: (filter: ICheckInFilter) => any, filter?: ICheckInFilter) => (
    <Table.Column<ICheckIn>
        key="assignedTo"
        dataIndex="assignedTo"
        width={160}
        title={intl.formatMessage(MESSAGES.handler)}
        render={(text: string) => <InfoField className="truncated-text" styles={{ width: 140 }} value={text} />}
        sorter
        align="left"
        filterDropdown={onFilter ? ({ confirm }) => (
            <SearchFilter
                onFilter={(val: SearchFilterResult) => onFilter({ ...filter, assignedTo: val as string } as ICheckInFilter)}
                closeFilter={confirm}
                clearFilter={() => onFilter({ ...filter, assignedTo: "" } as ICheckInFilter)}
                placeholder={intl.formatMessage(MESSAGES.handler)}
            />) : undefined}
        filterIcon={<SearchOutlined className={`${filter && filter.assignedTo ? "icon-filtered" : ""}`}/>}
    />
);

export const completedOnColumn = (intl: IntlShape) => (
    <Table.Column
        key="completedOn"
        dataIndex="completedOn"
        width={150}
        sorter
        align="left"
        title={intl.formatMessage(MESSAGES.completedOn)}
        render={(text: string,record: ICheckIn) => {
            return <InfoField value={optionalFormatIso8601DateTime(record.completedOn)} />;
        }}
    />
);
