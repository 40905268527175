import { IApplicationState } from "../state";
import { createSelector } from "@reduxjs/toolkit";

const filterStateSelector = (state: IApplicationState) => state.filter;

export const filterTerminalsSelector = createSelector(
    filterStateSelector,
    (filtersState) => filtersState?.terminals
);

export const filterAllTerminalsSelector = createSelector(
    filterStateSelector,
    (filtersState) => filtersState?.allTerminals
);

export const typeValuesSelector = createSelector(
    filterStateSelector,
    (filtersState) => filtersState?.typeValues
);

export const filterAllTypeValuesSelector = createSelector(
    filterStateSelector,
    (filtersState) => filtersState?.allTypeValues
);
