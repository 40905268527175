import { createSelector } from "@reduxjs/toolkit";
import { ICustomerServiceState } from "../reducers/customerservice";
import { IApplicationState } from "../state";

const customerServiceStateSelector = (state: IApplicationState) => state.customerService;

export const customerServicePageSelector = createSelector(
    customerServiceStateSelector,
    (customerServiceState: ICustomerServiceState) => customerServiceState.page
);

export const customerServiceLoadingPageSelector = createSelector(
    customerServiceStateSelector,
    (customerServiceState: ICustomerServiceState) => customerServiceState.loadingPage
);
