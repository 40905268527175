import * as React from "react";
import { useDispatch } from "react-redux";
import { checklistStepSubmitActionCreator } from "../../../store/actions/checklist";
import { IChecklistEntryStepSubmit } from "../../../types/api.types";
import { ChecklistItem } from "../../../types/checklist.types";
import { PostRadioForm } from "../../components/tabbars/cleaningtabs/cleaningtab/compartmenttab/checklist/forms/PostRadioForm";

interface IProps {
    prev: () => void;
    checklistId: number;
    compartmentId: number;
}

export const PostRadioFormContainer = (props: IProps) => {
    const dispatch = useDispatch();

    const submit = (error: boolean, fields: string) => {
        const checklistEntryStepSubmit: IChecklistEntryStepSubmit = {
            errorDetected: error,
            stepOrder: 0,
            checklistItem: ChecklistItem.POST_OPERATION_CHECKLIST,
            formFields: fields,
            restFound: false,
            manualWorkNeeded: false
        };

        dispatch(checklistStepSubmitActionCreator({
            checklistId: props.checklistId,
            checklistEntryStepSubmit,
            compartmentId: props.compartmentId
        }));
    };

    const onOk = (fields: any) => {
        submit(false, JSON.stringify(fields));
    };

    return (
        <PostRadioForm
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onOk={onOk}
            prev={props.prev}
        />
    );
};
