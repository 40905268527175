import * as React from "react";
import { useEffect, useState } from "react";
import { CHECKLIST_ITEM_STEPS_AMOUNT } from "../../../../../../../constants/checklist";
import { ChecklistItem, IChecklist } from "../../../../../../../types/checklist.types";
import { ICompartment, IOrder } from "../../../../../../../types/order.types";
import { sortEntries, sortSteps } from "../../../../../../../utils/checklist";
import { CleaningDoneFormContainer } from "../../../../../../containers/checklistformcontainers/CleaningDoneFormContainer";
import { ContainerNumberFormContainer } from "../../../../../../containers/checklistformcontainers/ContainerNumberFormContainer";
import { ManualWorkFormContainer } from "../../../../../../containers/checklistformcontainers/ManualWorkFormContainer";
import { PostRadioFormContainer } from "../../../../../../containers/checklistformcontainers/PostRadioFormContainer";
import { PreRadioFormContainer } from "../../../../../../containers/checklistformcontainers/PreRadioFormContainer";
import { ProcedureFormContainer } from "../../../../../../containers/checklistformcontainers/ProcedureFormContainer";
import { UNNumberFormContainer } from "../../../../../../containers/checklistformcontainers/UNNumberFormContainer";

interface IProps {
    compartment: ICompartment;
    order: IOrder;
    checklist: IChecklist;
}

export const ChecklistForm = (props: IProps) => {
    const [stepOrder, setStepOrder] = useState<number>(0);
    const [checklistItem, setChecklistItem] = useState<ChecklistItem>(ChecklistItem.PRE_OPERATION_CHECKLIST);

    const prev = () => {
        if (checklistItem === ChecklistItem.PRE_OPERATION_CHECKLIST && stepOrder === 0) {
            return;
        }

        const checklistItemKeys = Object.keys(ChecklistItem);
        const checklistItemIndex = checklistItemKeys.findIndex(val => val === checklistItem);

        if (stepOrder === 0) {
            const prevChecklistItem = checklistItemKeys[checklistItemIndex - 1] as ChecklistItem;

            setChecklistItem(prevChecklistItem);
            setStepOrder(CHECKLIST_ITEM_STEPS_AMOUNT[prevChecklistItem] - 1);

            return;
        }

        setStepOrder(stepOrder - 1);
    };

    const preOperationForms = [
        <ContainerNumberFormContainer
            containerNumber={props.order.container.sapId}
            checklistId={props.checklist.id}
            prev={prev}
            key={0}
            compartmentId={props.compartment.id}
            containerLocation={props.order.container.location && props.order.container.location.location}
        />,
        <UNNumberFormContainer
            unNumber={props.compartment.product.pbmUnNumber}
            prev={prev}
            checklistId={props.checklist.id}
            key={1}
            compartmentId={props.compartment.id}
        />,
        <PreRadioFormContainer
            prev={prev}
            checklistId={props.checklist.id}
            key={2}
            compartment={props.compartment}
            containerLocation={props.order.container.location && props.order.container.location.location}
        />
    ];

    const postOperationForms = [
        <PostRadioFormContainer prev={prev} checklistId={props.checklist.id} key={0} compartmentId={props.compartment.id}/>,
        <ManualWorkFormContainer prev={prev} checklistId={props.checklist.id} key={1} compartmentId={props.compartment.id}/>
    ];

    const containerDoneForms = [
        <CleaningDoneFormContainer checklistId={props.checklist.id} key={0} compartmentId={props.compartment.id}/>
    ];

    const cleaningProcedureForms = [
        <ProcedureFormContainer product={props.compartment.product} checklistId={props.checklist.id} prev={prev} key={0} compartmentId={props.compartment.id}/>,
    ];

    const checklistItemFormsTable = {
        [ChecklistItem.PRE_OPERATION_CHECKLIST]: preOperationForms,
        [ChecklistItem.POST_OPERATION_CHECKLIST]: postOperationForms,
        [ChecklistItem.CONTAINER_DONE]: containerDoneForms,
        [ChecklistItem.CLEANING_PROCEDURE]: cleaningProcedureForms,
    };

    useEffect(() => {
        // set current form
        const checklistItemKeys = Object.keys(ChecklistItem);
        const sortedChecklist = sortEntries(props.checklist.entries);
        const lastEntry = sortedChecklist[sortedChecklist.length - 1];

        if (!lastEntry) {
            return;
        }

        const sortedSteps = sortSteps(lastEntry.steps);
        const lastStep = sortedSteps[sortedSteps.length - 1];
        const lastChecklistItemIndex = checklistItemKeys.findIndex(val => val === lastEntry.checklistItem);

        // if last step
        if (lastStep.stepOrder === CHECKLIST_ITEM_STEPS_AMOUNT[lastEntry.checklistItem] - 1) {
            setStepOrder(0);
            setChecklistItem(checklistItemKeys[lastChecklistItemIndex + 1] as ChecklistItem);
            return;
        }

        setStepOrder(lastStep.stepOrder + 1);
        setChecklistItem(lastEntry.checklistItem);
    }, []);

    return checklistItemFormsTable[checklistItem][stepOrder];
};
