import { call, put, select, takeLatest } from "redux-saga/effects";
import { IOrderFilter, IPageRequest, IPageResponse } from "../../types/api.types";
import { IOrder } from "../../types/order.types";
import {
    planningListPrintCreator,
    planningPageFetchCreator,
    planningPageFetchFailedCreator,
    planningPageLoadCreator,
    planningPageReceivedCreator,
    planningUpdateDoneCreator
} from "../actions/planning";
import { errorHandler } from "./errorHandler";
import { fetchPage as orderFetchPage } from "./order";
import { formatQueryParams } from "../../utils/format";
import { orderApi } from "../api/order";
import { mapPageResponseToPageRequest } from "../../utils/mapping";
import { planningPageSelector } from "../selectors/planning.selector";
import { AxiosResponse } from "axios";
import { printPage } from "../../utils/views.utils";

export function* printList(action: ReturnType<typeof planningListPrintCreator>) {
    const queryParams = formatQueryParams(action.payload);
    const response: AxiosResponse<string> = yield call(orderApi.getListAsHtml, queryParams);

    printPage("Print cleaning planning", response.data);
}

export function* fetchPage(action: ReturnType<typeof planningPageFetchCreator>) {
    const pageResponse: IPageResponse<IOrder, IOrderFilter> = yield orderFetchPage(action.payload);
    yield put(planningPageReceivedCreator(pageResponse));
}

export function* loadPage(action: ReturnType<typeof planningPageLoadCreator>) {
    yield put(planningPageFetchCreator(action.payload));
}

export function* pageUpdateDone() {
    const page: IPageResponse<IOrder, IOrderFilter> = yield select(planningPageSelector);
    const pageRequest: IPageRequest<IOrderFilter> = mapPageResponseToPageRequest(page);
    yield put(planningPageFetchCreator(pageRequest));
}

export function* planningSaga() {
    yield takeLatest(planningPageLoadCreator.type, errorHandler(loadPage));
    yield takeLatest(planningListPrintCreator.type, errorHandler(printList));
    yield takeLatest(planningUpdateDoneCreator.type, errorHandler(pageUpdateDone));
    yield takeLatest(planningPageFetchCreator.type, errorHandler(fetchPage, planningPageFetchFailedCreator));
}
