import { createAction } from "@reduxjs/toolkit";
import { IFailedActionPayload } from "../../types/action.types";
import { ITank } from "../../types/salesOrder.types";
import { IScheduledMaintenance } from "../../types/scheduledMaintenance.types";
import { ITankBlockParams, ITankListFetchParams, } from "../../types/tank.types";

enum TankActionTypes {
    TANK_FETCH = "[Tank] FETCH",
    TANK_RECEIVED = "[Tank] RECEIVED",
    TANK_FETCH_FAILED = "[Tank] FETCH_FAILED",

    TANK_LIST_FETCH = "[Tank] LIST_FETCH",
    TANK_LIST_RECEIVED = "[Tank] LIST_RECEIVED",
    TANK_LIST_FETCH_FAILED = "[Tank] LIST_FETCH_FAILED",

    TANK_BLOCK = "[Tank} BLOCK",
    TANK_BLOCK_SUCCEEDED = "[Tank} BLOCK_SUCCEEDED",
    TANK_BLOCK_FAILED = "[Tank} BLOCK_FAILED",

    TANK_SCHEDULED_MAINTENANCES_RECEIVED = "[Tank] SCHEDULED_MAINTENANCES_RECEIVED"
}

export const tankFetchCreator = createAction<{id: number}>(TankActionTypes.TANK_FETCH);
export const tankReceivedCreator = createAction<ITank>(TankActionTypes.TANK_RECEIVED);
export const tankFetchFailedCreator = createAction<IFailedActionPayload>(TankActionTypes.TANK_FETCH_FAILED);

export const tankListFetchCreator = createAction<{tankListFetchParams: ITankListFetchParams}>(TankActionTypes.TANK_LIST_FETCH);
export const tankListReceivedCreator = createAction<ITank[]>(TankActionTypes.TANK_LIST_RECEIVED);
export const tankListFetchFailedCreator = createAction<IFailedActionPayload>(TankActionTypes.TANK_LIST_FETCH_FAILED);

export const tankBlockCreator = createAction<{id: number; tankBlockParams: ITankBlockParams}>(TankActionTypes.TANK_BLOCK);
export const tankBlockSucceededCreator = createAction<{tankBlockParams: ITankBlockParams}>(TankActionTypes.TANK_BLOCK_SUCCEEDED);
export const tankBlockFailedCreator = createAction<IFailedActionPayload>(TankActionTypes.TANK_BLOCK_FAILED);

export const tankScheduledMaintenancesReceivedActionCreator = createAction<IScheduledMaintenance[]>(TankActionTypes.TANK_SCHEDULED_MAINTENANCES_RECEIVED);
