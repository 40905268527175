import * as React from "react";
import { useState } from "react";
import { List, message } from "antd";
import { defineMessages, useIntl } from "react-intl";
import { CheckCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { BadgeSerialForm } from "./BadgeSerialForm";
import { useDispatch, useSelector } from "react-redux";
import { activateBadgeActionCreator, linkBadgeActionCreator } from "../../../../store/actions/checkIn";
import {
    checkInIsBulkFlowSelector,
    checkInRecordSelector,
    checkInRowHasStorageLocationSelector
} from "../../../../store/selectors/checkInOverview.selector";
import { IBadgeSerialForm } from "../../../../types/forms.types";
import { StorageLocation } from "../../../../types/salesOrder.types";

const MESSAGES = defineMessages({
    lenel: {
        id: "cmr_weight_form.inputs.badge.label",
        defaultMessage: "Toegangsbadge"
    },
    wiegand: {
        id: "cmr_weight_form.inputs.badge.wiegandLabel",
        defaultMessage: "Weegbadge"
    },
    onFailure: {
        id: "cmr_weight_form.inputs.badge.on_error",
        defaultMessage: "Er is iets misgegaan. Het lijkt dat deze badge niet kan gekoppeld worden."
    }
});

export const BadgeSerialFormContainer = () => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);
    const checkIn = useSelector(checkInRecordSelector);
    const isADPOSalesOrder = useSelector(checkInRowHasStorageLocationSelector(StorageLocation.ADPO));
    const isBulkFlow = useSelector(checkInIsBulkFlowSelector);

    const wiegandBadgeIsApplicable = !!checkIn?.requiresWeightBadge && isADPOSalesOrder && isBulkFlow;


    const onFailure = () => {
        message.error(intl.formatMessage(MESSAGES.onFailure));
    };

    const onSubmitLenel = (data: IBadgeSerialForm) => {
        if (checkIn) {
            dispatch(activateBadgeActionCreator({ checkinId: checkIn.id, data, setIsLoading, onFailure }));
        }
    };

    const onSubmitWiegand = (data: IBadgeSerialForm) => {
        if (checkIn) {
            dispatch(linkBadgeActionCreator({ checkinId: checkIn.id, data, setIsLoading, onFailure }));
        }
    };

    return checkIn && (
        <div className="badge-serial-form-container">
            {wiegandBadgeIsApplicable &&
            <List.Item title={intl.formatMessage(MESSAGES.wiegand)}>
                <List.Item.Meta
                    avatar={checkIn.wiegandBadge ? <CheckCircleOutlined className="text-success" /> : <ExclamationCircleOutlined className="text-error" />}
                    title={intl.formatMessage(MESSAGES.wiegand)}
                    description={<BadgeSerialForm isLoading={isLoading} onSubmit={onSubmitWiegand} lenelBadge={checkIn.lenelBadge} wiegandBadge={checkIn.wiegandBadge} badgeName="wiegand" wiegandBadgeIsApplicable={wiegandBadgeIsApplicable}/>}
                    style={{ alignItems: "center" }}
                />
            </List.Item>
            }
            <List.Item title={intl.formatMessage(MESSAGES.lenel)}>
                <List.Item.Meta
                    avatar={checkIn.lenelBadge ? <CheckCircleOutlined className="text-success" /> : <ExclamationCircleOutlined className="text-error" />}
                    title={intl.formatMessage(MESSAGES.lenel)}
                    description={<BadgeSerialForm isLoading={isLoading} onSubmit={onSubmitLenel} lenelBadge={checkIn.lenelBadge} wiegandBadge={checkIn.wiegandBadge} badgeName="lenel" wiegandBadgeIsApplicable={wiegandBadgeIsApplicable} />}
                    style={{ alignItems: "center" }}
                />
            </List.Item>
        </div>
    );
};
