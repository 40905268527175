import * as React from "react";
import { useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { Box } from "../../../../Box";
import { Pane } from "../../../customerservicemodal/panes/Pane";
import { CompartmentsInfo } from "./CompartmentsInfo";
import { ICompartment } from "../../../../../../types/checkIn.types";
import { Button, Spin } from "antd";
import { PrinterFilled } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { checkInRecordSelector } from "../../../../../../store/selectors/checkInOverview.selector";
import { printCompartmentsActionCreator } from "../../../../../../store/actions/checkIn";
import { salesOrdersWithUnknownTemperatureSelector } from "../../../../../../store/selectors/order.selector";

interface IProps {
    compartments: ICompartment[];
    initialWeight: number;
    weighingDate: string;
    overridden: boolean;
}

const MESSAGES = defineMessages({
    title: {
        id: "compartments_pane.title",
        defaultMessage: "Compartimentering"
    },
    print: {
        id: "compartments_pane.print_button",
        defaultMessage: "Afdrukken"
    },
    printError: {
        id: "compartments_pane.print_error",
        defaultMessage: "Er is iets misgelopen. Probeer het later opnieuw."
    }
});

export const CompartmentsPane = (props: IProps) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const checkIn = useSelector(checkInRecordSelector);
    const salesOrdersWithUnknownTemperature = useSelector(salesOrdersWithUnknownTemperatureSelector);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const printCompartments = () => {
        dispatch(
            printCompartmentsActionCreator(
                {
                    checkinId: checkIn?.id || 0,
                    setIsLoading,
                    onFailure: () => {
                        alert(intl.formatMessage(MESSAGES.printError));
                    }
                }
            )
        );
    };

    return (
        <Pane title={
            <React.Fragment>
                {intl.formatMessage(MESSAGES.title)}
                <Button onClick={printCompartments} icon={isLoading ? <Spin /> : <PrinterFilled />} className="margin-left">
                    {intl.formatMessage(MESSAGES.print)}
                </Button>
            </React.Fragment>
        }
        >
            <Box>
                <CompartmentsInfo
                    compartments={props.compartments}
                    initialWeight={props.initialWeight}
                    weighingDateTime={props.weighingDate}
                    overridden={props.overridden}
                    maxWeightExceeded={!!checkIn?.maxWeightExceeded}
                    temperatureUnknown={salesOrdersWithUnknownTemperature.length > 0}
                />
            </Box>
        </Pane>
    );
};
