import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import { TransportationMethod } from "../../../../../../types/checkIn.types";
import { Col, Row } from "antd";
import { InfoLabel } from "../../../customerservicemodal/panes/detailinfopane/sections/infofields/InfoLabel";
import { InfoField } from "../../../customerservicemodal/panes/detailinfopane/sections/infofields/InfoField";
import { formatTransportationMethod } from "../../../../../../utils/format";

interface IProps {
    borderCrossing?: string;
    transportationMethod?: TransportationMethod;
}

const MESSAGES = defineMessages({
    borderCrossing: {
        id: "transport_details.border_crossing",
        defaultMessage: "Grensovergang"
    },
    transportationMethod: {
        id: "transport_details.transportation_method",
        defaultMessage: "Weg/Spoor/Zee"
    }
});

export const TransportDetails = (props: IProps) => {
    const intl = useIntl();

    return (
        <Row>
            <Col span={12}>
                <InfoLabel text={intl.formatMessage(MESSAGES.borderCrossing)}/>
                <InfoField value={props.borderCrossing}/>
            </Col>
            <Col span={12}>
                <InfoLabel text={intl.formatMessage(MESSAGES.transportationMethod)}/>
                <InfoField value={props.transportationMethod && formatTransportationMethod(props.transportationMethod, intl)}/>
            </Col>
        </Row>
    );
};
