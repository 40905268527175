import { Table } from "antd";
import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import { IBookedSlot } from "../../../../types/bookedSlot.types";
import { ITank } from "../../../../types/salesOrder.types";
import { IScheduledMaintenance } from "../../../../types/scheduledMaintenance.types";
import { IConstraint, IHybridSlot, ISelectedSlot, ISlot, ISlotBookingConfig } from "../../../../types/slot.types";
import { concatDateTime } from "../../../../utils/mapping";
import { slotStartRenderer, slotStatusRenderer } from "./renderers";

interface IProps {
    slots: IHybridSlot[];
    selectedSlot: ISelectedSlot | null;
    dependsOnBookedSlot: IBookedSlot | null;
    assignSelectedSlot: (selectedSlot: ISelectedSlot | null) => void;
    tank: ITank;
    productionOrderReference: string;
    config: ISlotBookingConfig;
    salesOrderReference: string;
}

const MESSAGES = defineMessages({
    hour: {
        id: "slots_table.hour",
        defaultMessage: "Uur"
    },
    status: {
        id: "slots_table.status",
        defaultMessage: "Status"
    }
});

export const SlotsTable = (props: IProps) => {
    const intl = useIntl();

    const isSelectedSlot = (slot: ISlot): boolean => {
        return props.selectedSlot !== null &&
            slot.start === props.selectedSlot.startTime;
    };

    const setRowClassName = (slot: IHybridSlot) => {
        if (isSelectedSlot(slot.slot)) {
            return "selected";
        }

        if (slot.blockedDependsOn) {
            return "disabled";
        }

        if (slot.slot.constraints.length) {
            // priority 1 is highest priority
            const topConstraint: IConstraint = slot.slot.constraints.slice()
                .sort((c1, c2) => (c1.priority > c2.priority) ? 1 : ((c2.priority > c1.priority) ? -1 : 0))[0];
            return topConstraint.status.toString().toLowerCase();
        }
        return "";
    };

    const columns = [
        {
            title: intl.formatMessage(MESSAGES.hour),
            className: "available-slots",
            dataIndex: "slot.start",
            key: "start",
            align: "left" as const,
            width: "177px",
            render: (start: string) => slotStartRenderer(start)
        },
        {
            title: intl.formatMessage(MESSAGES.status),
            dataIndex: "scheduledMaintenances",
            render: (scheduledMaintenances: IScheduledMaintenance[], slot: IHybridSlot) => slotStatusRenderer(slot, props.config, intl.formatMessage),
            key: "reasons",
            align: "left" as const,
        }
    ];

    return (
        <Table
            scroll={{ y: 550, x: 463 }}
            onRow={(slot: IHybridSlot) => {
                return {
                    onClick: () => {
                        const selectedSlot: ISelectedSlot = {
                            startDate: concatDateTime(slot.slot.date, slot.slot.start).format(),
                            endDate: concatDateTime(slot.slot.date, slot.slot.end).format(),
                            endTime: slot.slot.end,
                            startTime: slot.slot.start,
                            tank: props.tank,
                            productionOrderReference: props.productionOrderReference,
                            tankBookings: slot.slot.tankBookings,
                            zoneBookings: slot.slot.zoneBookings,
                            constraints: slot.slot.constraints,
                            tankTimeOverlap: slot.slot.tankTimeOverlap,
                            dependsOnBooking: props.dependsOnBookedSlot ? props.dependsOnBookedSlot.id : undefined,
                            blockedDependsOn: slot.blockedDependsOn,
                            salesOrderReference: props.salesOrderReference
                        };
                        props.assignSelectedSlot(selectedSlot);
                    }
                };
            }}
            rowClassName={(slot: IHybridSlot) => `available-slots ${setRowClassName(slot)}`}
            title={() => <h4>Tank {props.tank.name}</h4>}
            dataSource={props.slots}
            columns={columns}
            pagination={false}/>
    );
};
