import React from "react";
import { Checkbox, Col, Row } from "antd";
import { defineMessages, useIntl } from "react-intl";
import { CHECK_IN_REASON_MESSAGES } from "../../../../utils/format";
import { CheckInReason } from "../../../../types/checkIn.types";
import { StorageLocation } from "../../../../types/salesOrder.types";
import { useDispatch, useSelector } from "react-redux";
import { hideHeaderSelector } from "../../../../store/selectors/ui.selector";
import {
    filterAllTerminalsSelector, filterAllTypeValuesSelector,
    filterTerminalsSelector,
    typeValuesSelector
} from "../../../../store/selectors/filter.selector";
import {
    filterSetAllTerminalsAction, filterSetAllTypeValuesAction,
    filterSetTerminalAction,
    filterSetTypeValuesAction
} from "../../../../store/actions/filter.actions";

const MESSAGES = defineMessages({
    all: {
        id: "checkinTableQuickFilters.all",
        defaultMessage: "Alle",
    },
    terminalFilter: {
        id: "checkinTableQuickFilters.terminalFilter",
        defaultMessage: "Terminal filter",
    },
    typeFilter: {
        id: "checkinTableQuickFilters.typeFilter",
        defaultMessage: "Type filter",
    },
});

export const CheckInTableQuickFilters = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const hideHeader = useSelector(hideHeaderSelector);
    const terminalValues = useSelector(filterTerminalsSelector);
    const allTerminalValues = useSelector(filterAllTerminalsSelector);
    const typeValues = useSelector(typeValuesSelector);
    const allTypeValues = useSelector(filterAllTypeValuesSelector);

    const terminalOptions = Object.values(StorageLocation).map((value) => {
        return { label: value, value };
    });
    const typeOptions = Object.values(CheckInReason).map((value) => {
        return { label: intl.formatMessage(CHECK_IN_REASON_MESSAGES[value]), value };
    });

    return (
        <div className={`checkin-table-quick-filters margin-left-auto padded-half ${hideHeader ? "display-none" : ""}`}>
            <Row className="margin-third-bottom">
                <Col span={3} className="flex-row margin-half-right">
                    <span className="margin-left-auto">{intl.formatMessage(MESSAGES.terminalFilter)}:</span>
                </Col>
                <Checkbox
                    className="margin-right"
                    checked={allTerminalValues}
                    onChange={(e) => {
                        dispatch(filterSetAllTerminalsAction(e.target.checked));
                        dispatch(filterSetTerminalAction(e.target.checked ? Object.values(StorageLocation) : []));
                    }}
                >{intl.formatMessage(MESSAGES.all)}</Checkbox>
                <Checkbox.Group
                    options={terminalOptions}
                    value={terminalValues}
                    onChange={(e) => {
                        dispatch(filterSetAllTerminalsAction(false));
                        dispatch(filterSetTerminalAction(e as StorageLocation[]));
                    }}
                />
            </Row>
            <Row>
                <Col span={3} className="flex-row margin-half-right">
                    <span className="margin-left-auto">{intl.formatMessage(MESSAGES.typeFilter)}:</span>
                </Col>
                <Checkbox
                    className="margin-right"
                    checked={allTypeValues}
                    onChange={(e) => {
                        dispatch(filterSetAllTypeValuesAction(e.target.checked));
                        dispatch(filterSetTypeValuesAction(e.target.checked ? Object.values(CheckInReason) : []));
                    }}
                >{intl.formatMessage(MESSAGES.all)}</Checkbox>
                <Checkbox.Group
                    options={typeOptions}
                    value={typeValues}
                    onChange={(e) => {
                        dispatch(filterSetAllTypeValuesAction(false));
                        dispatch(filterSetTypeValuesAction(e as CheckInReason[]));
                    }}
                />
            </Row>
        </div>
    );
};
