import { createAction } from "@reduxjs/toolkit";
import { StorageLocation } from "../../types/salesOrder.types";
import { CheckInReason } from "../../types/checkIn.types";

enum FilterActionTypes {
  SET_TERMINAL = "[FILTER] SET_TERMINAL",
  SET_ALL_TERMINALS = "[FILTER] SET_ALL_TERMINALS",
  SET_TYPE_VALUES = "[FILTER] SET_TYPE_VALUES",
  SET_ALL_TYPE_VALUES = "[FILTER] SET_ALL_TYPE_VALUES",
}

export const filterSetTerminalAction = createAction<StorageLocation[]>(FilterActionTypes.SET_TERMINAL);
export const filterSetAllTerminalsAction = createAction<boolean>(FilterActionTypes.SET_ALL_TERMINALS);
export const filterSetTypeValuesAction = createAction<CheckInReason[]>(FilterActionTypes.SET_TYPE_VALUES);
export const filterSetAllTypeValuesAction = createAction<boolean>(FilterActionTypes.SET_ALL_TYPE_VALUES);
