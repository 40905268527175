import { useDispatch } from "react-redux";
import { customerServiceCsvDownloadAllSelectedOrdersActionCreator } from "../../../store/actions/customerservice";
import { IOrderFilter, IPageRequest } from "../../../types/api.types";
import { IResourceRequester } from "../../../types/hook.types";
import { IOrder } from "../../../types/order.types";

export type ICsvDownloadPageLoader = IResourceRequester<IOrder, IOrderFilter>;

export const useCsvDownloadAllSelectedOrdersCustomerService = (): ICsvDownloadPageLoader => {
    const dispatch = useDispatch();

    const requestResource = (pageRequest: IPageRequest<IOrderFilter>) => dispatch(customerServiceCsvDownloadAllSelectedOrdersActionCreator(pageRequest));

    return {
        resource: null,
        requestResource: requestResource
    };
};
