import { PayloadAction } from "@reduxjs/toolkit";
import { ITank } from "../../types/salesOrder.types";
import { useDispatch, useSelector } from "react-redux";
import { IApplicationState } from "../../store/state";
import {
    tankFetchCreator,
    tankReceivedCreator
} from "../../store/actions/tank";

export const useTank = (): [
    ITank | null,
    (id: number) => PayloadAction<{ id: number }>,
    (tank: ITank) => void
    ] => {
    const tank = useSelector<IApplicationState, ITank | null>(state => state.tank.tank);
    const dispatch = useDispatch();
    const fetch = (id: number) => dispatch(tankFetchCreator({ id }));
    const assign = (tank: ITank) => dispatch(tankReceivedCreator(tank));

    return [tank, fetch, assign];
};
