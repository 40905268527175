import { Spin } from "antd";
import * as React from "react";
import { useEffect } from "react";
import { IOrderFilter, IPageRequest } from "../../types/api.types";
import { CleaningTabs } from "../components/tabbars/cleaningtabs/CleaningTabs";
import { ICleaningPageLoader, useCleaningPageLoader } from "../hooks/pageloaders/useCleaningPageLoader";

export const CleaningTabsContainer = () => {
    const cleaningPageLoader: ICleaningPageLoader = useCleaningPageLoader();

    useEffect(() => {
        const pageRequest: IPageRequest<IOrderFilter> = {
            pageNumber: 0,
            filter: {}
        };

        cleaningPageLoader.loadResource(pageRequest);
    }, []);

    return (
        !cleaningPageLoader.resource || cleaningPageLoader.loadingResource ?
            <Spin/> :
            <CleaningTabs orders={cleaningPageLoader.resource.page.content} loadingOrders={cleaningPageLoader.loadingResource}/>
    );
};
