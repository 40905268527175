import { useDispatch, useSelector } from "react-redux";
import { externalViewTransitionsFetchActionCreator } from "../../store/actions/externalView";
import { IApplicationState } from "../../store/state";
import { IResourceLoader } from "../../types/hook.types";
import { IStateTransition } from "../../types/order.types";

export interface IExternalViewTransitionsLoader extends IResourceLoader<IStateTransition[]> {
    loadResource: (uuid: string, orderId: number) => any;
}

export const useExternalViewTransitionsLoader = (): IExternalViewTransitionsLoader => {
    const dispatch = useDispatch();

    const resource = useSelector<IApplicationState, IStateTransition[]>(state => state.order.transitions);
    const loadResource = (uuid: string, id: number) => dispatch(externalViewTransitionsFetchActionCreator({ id, uuid }));
    const loadingResource = useSelector<IApplicationState, boolean>(state => state.order.fetchingTransitions);

    return {
        resource,
        loadingResource,
        loadResource
    };
};
