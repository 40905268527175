import { Button } from "antd";
import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import { ICheckIn } from "../../../../../types/checkIn.types";

interface IProps {
  onClick: () => void;
  checkIn: ICheckIn | null;
}

const MESSAGES = defineMessages({
    close: {
        id: "check_in_modal_footer.close",
        defaultMessage: "Scherm sluiten"
    }
});

export const CloseCheckinButton = (props: IProps) => {
    const intl = useIntl();

    return (
        <Button onClick={props.onClick} type="default">
            {intl.formatMessage(MESSAGES.close)}
        </Button>
    );
};
