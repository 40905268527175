import * as React from "react";
import { useState } from "react";
import { IProductionOrder } from "../../types/salesOrder.types";
import { parseSearchQuery } from "../../utils/format";
import { Box } from "../components/Box";
import { SlotBookingSelectedSlots } from "../components/wrappers/SlotBookingSelectedSlots";
import { SlotBookingSlots } from "../components/wrappers/SlotBookingSlots";
import { useSalesOrder } from "../hooks/useSalesOrder";
import { useSelectedSlot } from "../hooks/useSelectedSlot";
import { SlotBookingInformationContainer } from "./bookingcontainers/SlotBookingInformationContainer";
import { SlotBookingOrderContainer } from "./SlotBookingOrderContainer";
import { RightOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";

export const SlotBookingContainer = () => {
    const [salesOrder] = useSalesOrder();
    const [selectedSlot] = useSelectedSlot();
    const location = useLocation();
    const params = parseSearchQuery(location.search) as {tankId?: string; date?: string; start?: string; end?: string; salesOrderReference?: string};
    const [selectedProductionOrder, setSelectedProductionOrder] = useState<IProductionOrder | null>(null);

    return (
        <>
            <div className={"flex"}>
                <Box className="margin-half-top margin-right margin-bottom inline-table">
                    <SlotBookingOrderContainer salesOrderReference={params.salesOrderReference}/>
                </Box>
                {
                    salesOrder &&
                        <>
                            <RightOutlined className="flex align-self-center" />
                            <Box className="margin-half-top margin-left margin-bottom">
                                <SlotBookingInformationContainer
                                    setSelectedProductionOrder={setSelectedProductionOrder}
                                    selectedProductionOrder={selectedProductionOrder}
                                    salesOrder={salesOrder}
                                    tankId={params.tankId}
                                    date={params.date}
                                />
                            </Box>
                        </>
                }
            </div>
            <div className={"flex"}>
                <SlotBookingSlots
                    initialDate={params.date}
                    initialSlotStartTime={params.start}
                    initialSlotEndTime={params.end}
                    productionOrderReference={(selectedProductionOrder && selectedProductionOrder.sapReference) || ""}
                />
                {selectedSlot && <SlotBookingSelectedSlots/>}
            </div>
        </>
    );
};
