import { SearchOutlined } from "@ant-design/icons/lib";
import { Table } from "antd";
import * as React from "react";
import { defineMessages, IntlShape } from "react-intl";
import { IOrderFilter } from "../../../../types/api.types";
import { IOrder, State, StateGroup } from "../../../../types/order.types";
import { formatState, formatStateGroup } from "../../../../utils/format";
import { DateRangeFilter } from "../filters/DateRangeFilter";
import { DropdownFilter } from "../filters/DropdownFilter";
import { SearchFilter, SearchFilterResult } from "../filters/SearchFilter";
import { commentRenderer, containerLocationRenderer, storageDaysRenderer, tableDateTimeRenderer } from "../renderers";

const MESSAGES = defineMessages({
    customerFilterPlaceholder: {
        id: "customer_service_table.filter.customer.placeholder",
        defaultMessage: "Klantnaam"
    },
    orderFilterPlaceholder: {
        id: "customer_service_table.filter.order.placeholder",
        defaultMessage: "Order nummer"
    },
    deliveryIdFilterPlaceholder: {
        id: "customer_service_table.filter.delivery_id.placeholder",
        defaultMessage: "Order nr."
    },
    containerFilterPlaceholder: {
        id: "customer_service_table.filter.container.placeholder",
        defaultMessage: "Container nummer"
    },
    productFilterPlaceholder: {
        id: "customer_service_table.filter.product.placeholder",
        defaultMessage: "Product naam"
    },
    statusFilterPlaceholder: {
        id: "customer_service_table.filter.status.placeholder",
        defaultMessage: "Status"
    },
    subStatusFilterPlaceholder: {
        id: "customer_service_table.filter.sub_status.placeholder",
        defaultMessage: "Substatus"
    },
    dateParkingInFilterPlaceholder: {
        id: "customer_service_table.filter.date_parking_in.placeholder",
        defaultMessage: "Selecteer datum"
    },
    storageDaysFilterPlaceholder: {
        id: "customer_service_table.filter.storage_days.placeholder",
        defaultMessage: "Aantal opslag dagen"
    },
    clientNameTitle: {
        id: "customer_service_table.title.client_name",
        defaultMessage: "Klant"
    },
    clientOrderRefTitle: {
        id: "customer_service_table.title.client_ref",
        defaultMessage: "Klant order ref."
    },
    deliveryIdTitle: {
        id: "customer_service_table.title.delivery_id",
        defaultMessage: "Order nr."
    },
    containerSapIdTitle: {
        id: "customer_service_table.title.container_sap_id",
        defaultMessage: "Container nr."
    },
    productsTitle: {
        id: "customer_service_table.title.products",
        defaultMessage: "Product"
    },
    stateGroupTitle: {
        id: "customer_service_table.title.state_group",
        defaultMessage: "Status"
    },
    stateTitle: {
        id: "customer_service_table.title.state",
        defaultMessage: "Substatus"
    },
    dateParkingInTitle: {
        id: "customer_service_table.title.date_parking_in",
        defaultMessage: "Datum parking in"
    },
    dateCleanedTitle: {
        id: "customer_service_table.title.date_cleaned",
        defaultMessage: "Datum gereinigd"
    },
    dateAvailableTitle: {
        id: "customer_service_table.title.date_available",
        defaultMessage: "Datum beschikbaar"
    },
    dateParkingOutTitle: {
        id: "customer_service_table.title.date_parking_out",
        defaultMessage: "Datum parking uit"
    },
    storageDaysTitle: {
        id: "customer_service_table.title.storage_days",
        defaultMessage: "Opslag dagen"
    },
    containerLocationTitle: {
        id: "table.title.container_location",
        defaultMessage: "Locatie"
    }
});

export const sapIdColumn = (intl: IntlShape, onFilter: (filter: IOrderFilter) => any, filter?: IOrderFilter) => (
    <Table.Column<IOrder>
        key="container.sapId"
        dataIndex="container"
        title={intl.formatMessage(MESSAGES.containerSapIdTitle)}
        sorter
        filterDropdown={({ confirm }) => (
            <SearchFilter
                onFilter={(val: SearchFilterResult) => onFilter({ ...filter, containerNumber: val as string })}
                closeFilter={confirm}
                placeholder={intl.formatMessage(MESSAGES.containerFilterPlaceholder)}
            />
        )}
        filterIcon={<SearchOutlined className={`${filter && filter.containerNumber ? "icon-filtered" : ""}`}/>}
        render={(_, record: IOrder) => record.container.sapId}
    />
);

export const productColumn = (intl: IntlShape, sort: boolean, onFilter?: (filter: IOrderFilter) => any, filter?: IOrderFilter) => {
    return (
        <Table.Column<IOrder>
            key="compartments.product.name"
            dataIndex="products"
            title={intl.formatMessage(MESSAGES.productsTitle)}
            render={(_, record: IOrder) => record.compartments.map(c => c.product.name).join(", ")}
            sorter={sort}
            filterDropdown={onFilter ? ({ confirm }) => (
                <SearchFilter
                    onFilter={(val: SearchFilterResult) => onFilter({ ...filter, productName: val as string })}
                    closeFilter={confirm}
                    placeholder={intl.formatMessage(MESSAGES.productFilterPlaceholder)}
                />
            ) : undefined}
            filterIcon={filter && <SearchOutlined className={`${filter.productName ? "icon-filtered" : ""}`}/>}
        />
    );
};

export const containerLocationColumn = (intl: IntlShape) => {
    return (
        <Table.Column<IOrder>
            key="containerLocation"
            dataIndex="containerLocation"
            title={intl.formatMessage(MESSAGES.containerLocationTitle)}
            render={(_, record: IOrder) => containerLocationRenderer(record.container)}
        />
    );
};

export const stateColumn = (intl: IntlShape, sort: boolean, onFilter?: (filter: IOrderFilter) => any, filter?: IOrderFilter) => {
    return (
        <Table.Column<IOrder>
            key="state"
            dataIndex="state"
            title={intl.formatMessage(MESSAGES.stateTitle)}
            render={(val) => formatState(val, intl)}
            sorter={sort}
            filterDropdown={onFilter ? ({ confirm }) => (
                <DropdownFilter
                    closeFilter={confirm}
                    onFilter={(val: string | undefined) => onFilter({ ...filter, state: val ? [val] as State[] : [] })}
                    options={Object.keys(State).filter((k) => k !== "CLEANED")}
                    placeholder={intl.formatMessage(MESSAGES.statusFilterPlaceholder)}
                    optionRenderer={(option: string) => formatState(State[option as State], intl)}
                />
            ) : undefined}
            filterIcon={filter && <SearchOutlined className={`${filter.state?.length ? "icon-filtered" : ""}`}/>}
        />
    );
};

export const deliveryIdColumn = (intl: IntlShape, sort: boolean, onFilter?: (filter: IOrderFilter) => any, filter?: IOrderFilter) => {
    return (
        <Table.Column<IOrder>
            key="deliveryId"
            dataIndex="deliveryId"
            title={intl.formatMessage(MESSAGES.deliveryIdTitle)}
            sorter={sort}
            filterDropdown={onFilter ? ({ confirm }) => (
                <SearchFilter
                    onFilter={(val: SearchFilterResult) => onFilter({ ...filter, deliveryId: val as string })}
                    closeFilter={confirm}
                    placeholder={intl.formatMessage(MESSAGES.deliveryIdFilterPlaceholder)}
                />
            ) : undefined}
            filterIcon={<SearchOutlined className={`${filter && filter.deliveryId ? "icon-filtered" : ""}`}/>}
        />
    );
};

export const dateParkingInColumn = (intl: IntlShape, sort: boolean, onFilter?: (filter: IOrderFilter) => any, filter?: IOrderFilter) => {
    return (
        <Table.Column<IOrder>
            key="dateParkingIn"
            dataIndex="dateParkingIn"
            title={intl.formatMessage(MESSAGES.dateParkingInTitle)}
            render={tableDateTimeRenderer}
            sorter={sort}
            filterDropdown={onFilter ? ({ confirm }) => (
                <DateRangeFilter
                    closeFilter={confirm}
                    onFilter={((startDate, endDate) => onFilter({ ...filter, dateParkingInAfter: startDate, dateParkingInBefore: endDate }))}
                    placeholder={intl.formatMessage(MESSAGES.dateParkingInFilterPlaceholder)}
                />
            ) : undefined}
            filterIcon={<SearchOutlined className={`${filter && filter.dateParkingInAfter ? "icon-filtered" : ""}`}/>}
        />
    );
};

export const dateCleanedColumn = (intl: IntlShape, sort: boolean, onFilter?: (filter: IOrderFilter) => any, filter?: IOrderFilter) => {
    return (
        <Table.Column<IOrder>
            key="dateCleaned"
            dataIndex="dateCleaned"
            title={intl.formatMessage(MESSAGES.dateCleanedTitle)}
            render={tableDateTimeRenderer}
            sorter={sort}
            filterDropdown={onFilter ? ({ confirm }) => (
                <DateRangeFilter
                    closeFilter={confirm}
                    onFilter={((startDate, endDate) => onFilter({ ...filter, dateCleanedAfter: startDate, dateCleanedBefore: endDate }))}
                    placeholder={intl.formatMessage(MESSAGES.dateParkingInFilterPlaceholder)}
                />
            ) : undefined}
            filterIcon={<SearchOutlined className={`${filter && filter.dateCleanedAfter ? "icon-filtered" : ""}`}/>}
        />
    );
};

export const dateAvailableColumn = (intl: IntlShape, sort: boolean, onFilter?: (filter: IOrderFilter) => any, filter?: IOrderFilter) => {
    return (
        <Table.Column<IOrder>
            key="dateAvailable"
            dataIndex="dateAvailable"
            title={intl.formatMessage(MESSAGES.dateAvailableTitle)}
            render={tableDateTimeRenderer}
            sorter={sort}
            filterDropdown={onFilter ? ({ confirm }) => (
                <DateRangeFilter
                    closeFilter={confirm}
                    onFilter={((startDate, endDate) => onFilter({ ...filter, dateAvailableAfter: startDate, dateAvailableBefore: endDate }))}
                    placeholder={intl.formatMessage(MESSAGES.dateParkingInFilterPlaceholder)}
                />
            ) : undefined}
            filterIcon={<SearchOutlined className={`${filter && filter.dateAvailableAfter ? "icon-filtered" : ""}`}/>}
        />
    );
};

export const dateParkingOutColumn = (intl: IntlShape, sort: boolean, onFilter?: (filter: IOrderFilter) => any, filter?: IOrderFilter) => {
    return (
        <Table.Column<IOrder>
            key="dateParkingOut"
            dataIndex="dateParkingOut"
            title={intl.formatMessage(MESSAGES.dateParkingOutTitle)}
            render={tableDateTimeRenderer}
            sorter={sort}
            filterDropdown={onFilter ? ({ confirm }) => (
                <DateRangeFilter
                    closeFilter={confirm}
                    onFilter={((startDate, endDate) => onFilter({ ...filter, dateParkingOutAfter: startDate, dateParkingOutBefore: endDate }))}
                    placeholder={intl.formatMessage(MESSAGES.dateParkingInFilterPlaceholder)}
                />
            ) : undefined}
            filterIcon={<SearchOutlined className={`${filter && filter.dateParkingOutAfter ? "icon-filtered" : ""}`}/>}
        />
    );
};

export const clientOrderRefColumn = (intl: IntlShape, sort: boolean, onFilter?: (filter: IOrderFilter) => any, filter?: IOrderFilter) => {
    return (
        <Table.Column<IOrder>
            key="clientOrderRef"
            dataIndex="clientOrderRef"
            title={intl.formatMessage(MESSAGES.clientOrderRefTitle)}
            sorter={sort}
            filterDropdown={onFilter ? ({ confirm }) => (
                <SearchFilter
                    onFilter={(val: SearchFilterResult) => onFilter({ ...filter, orderNumber: val as number })}
                    closeFilter={confirm}
                    placeholder={intl.formatMessage(MESSAGES.orderFilterPlaceholder)}
                />
            ) : undefined}
            filterIcon={<SearchOutlined className={`${filter && filter.orderNumber ? "icon-filtered" : ""}`}/>}
        />
    );
};

export const stateGroupColumn = (intl: IntlShape, sort: boolean, onFilter?: (filter: IOrderFilter) => any, filter?: IOrderFilter) => {
    return (
        <Table.Column<IOrder>
            key="stateGroup"
            dataIndex="stateGroup"
            title={intl.formatMessage(MESSAGES.stateGroupTitle)}
            render={(val) => formatStateGroup(val, intl)}
            sorter={sort}
            filterDropdown={onFilter ? ({ confirm }) => (
                <DropdownFilter
                    closeFilter={confirm}
                    onFilter={(val: string | undefined) => onFilter({ ...filter, stateGroup: val as StateGroup })}
                    options={Object.keys(StateGroup)}
                    placeholder={intl.formatMessage(MESSAGES.statusFilterPlaceholder)}
                    optionRenderer={(option: string) => formatStateGroup(StateGroup[option as StateGroup], intl)}
                />
            ) : undefined}
            filterIcon={<SearchOutlined className={`${filter && filter.stateGroup ? "icon-filtered" : ""}`}/>}
        />
    );
};

export const storageDaysColumn = (intl: IntlShape, sort: boolean, onFilter?: (filter: IOrderFilter) => any, filter?: IOrderFilter) => {
    return (
        <Table.Column<IOrder>
            key="storageDays"
            dataIndex="storageDays"
            title={intl.formatMessage(MESSAGES.storageDaysTitle)}
            render={storageDaysRenderer}
            sorter={sort}
            filterDropdown={onFilter ? ({ confirm }) => (
                <SearchFilter
                    onFilter={(val: SearchFilterResult) => onFilter({ ...filter, storageDays: val as number })}
                    closeFilter={confirm}
                    placeholder={intl.formatMessage(MESSAGES.storageDaysFilterPlaceholder)}
                    type="number"
                />
            ) : undefined}
            filterIcon={<SearchOutlined className={`${filter && filter.storageDays ? "icon-filtered" : ""}`}/>}
        />
    );
};

export const customerNameColumn = (intl: IntlShape, sort: boolean, onFilter?: (filter: IOrderFilter) => any, filter?: IOrderFilter) => {
    return (
        <Table.Column<IOrder>
            key="cleaningCustomer.name"
            dataIndex={["cleaningCustomer", "name"]}
            title={intl.formatMessage(MESSAGES.clientNameTitle)}
            sorter={sort}
            filterDropdown={onFilter ? ({ confirm }) => (
                <SearchFilter
                    onFilter={(val: SearchFilterResult) => onFilter({ ...filter, customer: val ? val as string : undefined })}
                    closeFilter={confirm}
                    placeholder={intl.formatMessage(MESSAGES.customerFilterPlaceholder)}
                />
            ) : undefined}
            filterIcon={<SearchOutlined className={`${filter && filter.customer ? "icon-filtered" : ""}`} />}
        />
    );
};

export const commentColumn = (intl: IntlShape) => {
    return (
        <Table.Column<IOrder>
            key="comments"
            dataIndex="commentPresent"
            render={(text: string, record: IOrder) => commentRenderer(text, record, intl)}
            className="table-column-icons"
        />
    );
};
