import * as React from "react";
import { Col, Row } from "antd";
import { ISupplier } from "../../../../types/checkIn.types";
import { useSelector } from "react-redux";
import { isAssignedToMeSelector } from "../../../../store/selectors/authentication.selector";
import { checkInRecordSelector } from "../../../../store/selectors/checkInOverview.selector";
import { DocumentsPane } from "./panes/documentsPane/DocumentsPane";
import { VehiclePane } from "./panes/vehiclePane/VehiclePane";
import { ActionsPane } from "./panes/actionsPane/ActionsPane";
import { PersonPane } from "./panes/personPane/PersonPane";
import { defineMessages, useIntl } from "react-intl";
import { RemarksPane } from "./panes/remarksPanes/RemarksPane";

interface IProps {
    supplier: ISupplier;
}

const MESSAGES = defineMessages({
    title: {
        id: "check_in_modal.supplier.title",
        defaultMessage: "Leverancier info"
    }
});

export const SupplierCheckInDetail = (props: IProps) => {
    const intl = useIntl();
    const checkIn = useSelector(checkInRecordSelector);
    const isAssignedToMe = useSelector(isAssignedToMeSelector(checkIn?.assignedTo || null));

    return (
        <Row gutter={10}>
            <Col span={8}>
                <PersonPane person={props.supplier} title={intl.formatMessage(MESSAGES.title)}/>
            </Col>
            <Col span={8}>
                <VehiclePane checkin={checkIn} person={props.supplier}/>
            </Col>

            <Col span={8}>
                {isAssignedToMe && <ActionsPane/>}
                <DocumentsPane checkIn={checkIn}/>
                <RemarksPane checkIn={checkIn}/>
            </Col>

        </Row>
    );
};
