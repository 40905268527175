import { call, put, takeLatest } from "redux-saga/effects";
import { IOrderFilter, IPageResponse } from "../../types/api.types";
import { IOrder } from "../../types/order.types";
import { orderAllowedTransitionsFetchCreator, orderFetchCreator, orderTransitionsFetchCreator } from "../actions";
import {
    customerServiceCsvDownloadAllSelectedOrdersActionCreator,
    customerServiceCsvDownloadOnlyFinishedOrdersActionCreator,
    customerServiceOrderUpdateDoneActionCreator,
    customerServicePageFetchCreator,
    customerServicePageFetchFailedCreator,
    customerServicePageLoadCreator,
    customerServicePageReceivedCreator
} from "../actions/customerservice";
import { errorHandler } from "./errorHandler";
import { fetchPage as orderFetchPage } from "./order";
import { orderApi } from "../api/order";
import fileDownload from "js-file-download";
import { AxiosResponse } from "axios";

export function* fetchPage(action: ReturnType<typeof customerServicePageFetchCreator>) {
    const pageResponse: IPageResponse<IOrder, IOrderFilter> = yield orderFetchPage(action.payload);
    yield put(customerServicePageReceivedCreator(pageResponse));
}

export function* downloadCsvAllSelectedOrders(action: ReturnType<typeof customerServiceCsvDownloadAllSelectedOrdersActionCreator>) {
    const response: AxiosResponse<any> = yield call(orderApi.getCsvDownloadAllSelectedOrders, action.payload);
    if (response.headers && response.headers["content-disposition"]) {
        const fileName = response.headers["content-disposition"].split("filename=")[1];
        fileDownload(response.data, fileName);
    }
}

export function* downloadCsvOnlyFinishedOrders() {
    const response: AxiosResponse<any> = yield call(orderApi.getCsvDownloadOnlyFinishedOrders);
    if (response.headers && response.headers["content-disposition"]) {
        const fileName = response.headers["content-disposition"].split("filename=")[1];
        fileDownload(response.data, fileName);
    }
}

export function* loadPage(action: ReturnType<typeof customerServicePageLoadCreator>) {
    yield put(customerServicePageFetchCreator(action.payload));
}

export function* updateDone(action: ReturnType<typeof customerServiceOrderUpdateDoneActionCreator>) {
    yield put(orderFetchCreator(action.payload.orderId));

    if (action.payload.orderUpdateDto.state || action.payload.orderUpdateDto.priority) {
        yield put(orderTransitionsFetchCreator(action.payload.orderId));
        yield put(orderAllowedTransitionsFetchCreator(action.payload.orderId));
    }
}

export function* customerServiceSaga() {
    yield takeLatest(customerServicePageFetchCreator.type, errorHandler(fetchPage, customerServicePageFetchFailedCreator));
    yield takeLatest(customerServicePageLoadCreator.type, errorHandler(loadPage));
    yield takeLatest(customerServiceOrderUpdateDoneActionCreator.type, errorHandler(updateDone));
    yield takeLatest(customerServiceCsvDownloadAllSelectedOrdersActionCreator.type, errorHandler(downloadCsvAllSelectedOrders));
    yield takeLatest(customerServiceCsvDownloadOnlyFinishedOrdersActionCreator.type, errorHandler(downloadCsvOnlyFinishedOrders));
}
