import * as React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Paths, ROLES } from "../types/api.types";
import { DefaultPageRoleViewer, RoleViewer } from "./components/app/RoleViewer";
import { BookedSlotsScreen } from "./screens/BookedSlotsScreen";
import { CalendarScreen } from "./screens/CalendarScreen";
import { CleaningScreen } from "./screens/CleaningScreen";
import { CustomerServiceScreen } from "./screens/CustomerServiceScreen";
import { ItactScreen } from "./screens/ItactScreen";
import { SlotBookingScreen } from "./screens/SlotBookingScreen";
import { DriverBlacklistScreen } from "./screens/DriverBlacklistScreen";
import { CheckInOverviewScreen } from "./screens/CheckInOverviewScreen";
import { SelfCheckInScreen } from "./screens/SelfCheckInScreen";
import { CheckInOverviewReadOnlyScreen } from "./screens/CheckInOverviewReadOnlyScreen";
import { UploadConfigScreen } from "./screens/UploadConfigScreen";
import { ExternalViewScreen } from "./screens/ExternalViewScreen";
import { CheckinArchivedOverviewScreen } from "./screens/CheckinArchivedOverviewScreen";

export const Routing = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path={`${Paths.EXTERNAL_VIEW}/:uuid`} element={<ExternalViewScreen />}/>
                <Route path={Paths.ITACT} element={
                    <RoleViewer roles={[ROLES.CLEANING]}>
                        <ItactScreen/>
                    </RoleViewer>
                }/>
                <Route path={Paths.CUSTOMER_SERVICE} element={
                    <RoleViewer roles={[ROLES.CLEANING]}>
                        <CustomerServiceScreen/>
                    </RoleViewer>
                }/>
                <Route path={Paths.CLEANING} element={
                    <RoleViewer roles={[ROLES.CLEANING]}>
                        <CleaningScreen/>
                    </RoleViewer>
                }/>
                <Route path={Paths.SLOTBOOKING} element={
                    <RoleViewer roles={[ROLES.SLOTBOOKING]}>
                        <SlotBookingScreen/>
                    </RoleViewer>
                }/>
                <Route path={Paths.SLOTBOOKING_PLANNER} element={
                    <RoleViewer roles={[ROLES.SLOTBOOKING]}>
                        <CalendarScreen/>
                    </RoleViewer>
                }/>
                <Route path={Paths.SLOTBOOKING_LIST} element={
                    <RoleViewer roles={[ROLES.SLOTBOOKING]}>
                        <BookedSlotsScreen/>
                    </RoleViewer>
                }/>
                <Route path={Paths.CHECKIN_DRIVERS} element={
                    <RoleViewer roles={[ROLES.CHECKIN]}>
                        <DriverBlacklistScreen/>
                    </RoleViewer>
                }/>
                <Route path={Paths.ITACT_NEEDED} element={
                    <RoleViewer roles={[ROLES.ADMIN]}>
                        <UploadConfigScreen type={"itact"}/>
                    </RoleViewer>
                }/>
                <Route path={Paths.TOLERANCES} element={
                    <RoleViewer roles={[ROLES.ADMIN]}>
                        <UploadConfigScreen type={"tolerances"}/>
                    </RoleViewer>
                }/>
                <Route path={Paths.ARCHIVE_SALESORDERS} element={
                    <RoleViewer roles={[ROLES.ADMIN]}>
                        <UploadConfigScreen type={"archiveSalesOrders"}/>
                    </RoleViewer>
                }/>
                <Route path={Paths.WEIGHT_BADGE_TANK} element={
                    <RoleViewer roles={[ROLES.ADMIN]}>
                        <UploadConfigScreen type={"weightBadgeTank"}/>
                    </RoleViewer>
                }/>
                <Route path={Paths.CERTIFICATE} element={
                    <RoleViewer roles={[ROLES.ADMIN]}>
                        <UploadConfigScreen type={"certificate"}/>
                    </RoleViewer>
                }/>
                <Route path={Paths.FREE_STORAGE_DAYS} element={
                    <RoleViewer roles={[ROLES.ADMIN]}>
                        <UploadConfigScreen type={"freeStorage"}/>
                    </RoleViewer>
                }/>
                <Route path={Paths.CHECKIN_OVERVIEW} element={
                    <RoleViewer roles={[ROLES.CHECKIN]}>
                        <CheckInOverviewScreen/>
                    </RoleViewer>
                }/>
                <Route path={Paths.CHECKIN_ARCHIVED} element={
                    <RoleViewer roles={[ROLES.CHECKIN]}>
                        <CheckinArchivedOverviewScreen/>
                    </RoleViewer>
                }/>
                <Route path={Paths.CHECKIN_OVERVIEW_READ_ONLY} element={
                    <RoleViewer roles={[ROLES.CHECKIN]}>
                        <CheckInOverviewReadOnlyScreen/>
                    </RoleViewer>
                }/>
                <Route path={Paths.SELF_CHECK_IN} element={
                    <RoleViewer roles={[ROLES.ADMIN]}>
                        <SelfCheckInScreen />
                    </RoleViewer>
                }/>
                <Route path={Paths.ROOT} element={<DefaultPageRoleViewer/>}/>
                <Route path={Paths.LOGOUT} />
            </Routes>
        </BrowserRouter>
    );
};
