import { range } from "lodash";
import type { Dayjs } from "dayjs";
import { IDisableTimeFunctionsMap } from "../types/ant.types";

export const disableDateAfter = (m: Dayjs | null, boundary: Dayjs) => {
    return !!(m && boundary.isBefore(m, "day"));
};

export const disableDateBefore = (m: Dayjs | null, boundary: Dayjs) => {
    return !!(m && boundary.isAfter(m, "day"));
};

export const disableTimeBefore = (boundary: Dayjs, m?: Dayjs | null) => {
    const boundaryHour = boundary.hour();
    const boundaryMinute = boundary.minute();
    const boundarySeconds = boundary.second();

    if (!m || m.isAfter(boundary, "day")) {
        return {};
    }

    if (m.isBefore(boundary, "day")) {
        return {
            disabledHours: () => range(0, 24),
            disabledMinutes: () => range(0, 60),
            disabledSeconds: () => range(0, 60),
        };
    }

    if (m.isSame(boundary, "day")) {
        const blockFns: IDisableTimeFunctionsMap = {
            disabledHours: () => range(0, boundaryHour)
        };

        if (m.isSame(boundary, "hour") || m.isBefore(boundary, "hour")) {
            blockFns.disabledMinutes = () => range(0, boundaryMinute);

            if (m.isSame(boundary, "minute") || m.isBefore(boundary, "minute")) {
                blockFns.disabledSeconds = () => range(0, boundarySeconds);
            }
        }

        return blockFns;
    }

    return {};
};

export const disableTimeAfter = (boundary: Dayjs, m?: Dayjs | null) => {
    const boundaryHour = boundary.hour();
    const boundaryMinute = boundary.minute();
    const boundarySeconds = boundary.second();

    if (!m || m.isBefore(boundary, "day")) {
        return {};
    }

    if (m.isAfter(boundary, "day")) {
        return {
            disabledHours: () => range(0, 24),
            disabledMinutes: () => range(0, 60),
            disabledSeconds: () => range(0, 60),
        };
    }

    if (m.isSame(boundary, "day")) {
        const blockFns: IDisableTimeFunctionsMap = {
            disabledHours: () => range(boundaryHour + 1, 25)
        };

        if (m.isSame(boundary, "hour") || m.isAfter(boundary, "hour")) {
            blockFns.disabledMinutes = () => range(boundaryMinute + 1, 61);

            if (m.isSame(boundary, "minute") || m.isAfter(boundary, "minute")) {
                blockFns.disabledSeconds = () => range(boundarySeconds + 1, 61);
            }
        }

        return blockFns;
    }

    return {};
};
