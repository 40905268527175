import { Spin } from "antd";
import React, { useEffect } from "react";
import { StatusTimeline } from "../components/modals/customerservicemodal/panes/statuspane/StatusTimeline";
import { useOrderTransitions } from "../hooks/useOrderTransitions";

interface IProps {
    orderId: number;
    className: string;
}

export const StateTimelineContainer = (props: IProps) => {
    const [transitions, fetchingTransitions, fetchTransitions] = useOrderTransitions();

    useEffect(() => {
        fetchTransitions(props.orderId);
    }, []);

    return (
        <div className={props.className}>
            {
                fetchingTransitions ? <Spin/> : <StatusTimeline statusHistory={transitions}/>
            }
        </div>
    );
};
