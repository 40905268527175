import { Button } from "antd";
import * as React from "react";
import { useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { IProduct } from "../../../../../../../../types/order.types";
import { mapCleaningProcedureObjToArr } from "../../../../../../../../utils/mapping";
import { CleaningProcedureList } from "../../compartmentinfo/CleaningProcedureList";
import { PbmInstructions } from "../../compartmentinfo/PbmInstructions";
import { ErrorModal } from "./ErrorModal";

interface IProps {
    product: IProduct;
    prev: () => void;
    onError: (submittedBy: string, msg?: string) => void;
    onOk: () => void;
}

const MESSAGES = defineMessages({
    unNumberFormPrompt: {
        id: "un_number_form.prompt",
        defaultMessage: "Geef het UN nummer in:"
    },
    prev: {
        id: "procedure_form.prev",
        defaultMessage: "Vorige"
    },
    modalTitle: {
        id: "procedure_form.modal_title",
        defaultMessage: "De cleaning werd stop gezet."
    },
    confirm: {
        id: "procedure_form.confirm",
        defaultMessage: "Procedure werd correct uitgevoerd"
    },
    cancel: {
        id: "procedure_form.cancel",
        defaultMessage: "Procedure werd niet correct uitgevoerd"
    }
});


export const ProcedureForm = (props: IProps) => {
    const intl = useIntl();
    const [modalVisible, setModalVisible] = useState<boolean>(false);

    return (
        <div className="fill-width">
            <div className="flex flex-row flex-start">
                <PbmInstructions product={props.product}/>
                <CleaningProcedureList steps={mapCleaningProcedureObjToArr(props.product.cleaningProcedure)} className="margin-left"/>
            </div>
            <div className="margin-top">
                <Button onClick={props.prev}>
                    {intl.formatMessage(MESSAGES.prev)}
                </Button>
                <Button type="primary" onClick={props.onOk} className="margin-half-left">
                    {intl.formatMessage(MESSAGES.confirm)}
                </Button>
                <Button type="default" onClick={() => setModalVisible(true)} className="margin-half-left">
                    {intl.formatMessage(MESSAGES.cancel)}
                </Button>
            </div>
            <ErrorModal
                closeModal={() => setModalVisible(false)}
                visible={modalVisible}
                onConfirm={props.onError}
                title={intl.formatMessage(MESSAGES.modalTitle)}/>
        </div>
    );
};
