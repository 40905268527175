import { BASE_URL_API } from "../config/config";

export const URL_PATHS = {
    BOOKING: "/slot-booking",
    CLEANING: "/cleaning",
    CHECKIN: "/check-in",
    CLEANING_CUSTOMERS: "/cleaning/customers",
    ADMIN: "/check-in/admin",
};

export const buildUrl = (...pathComponents: string[]) => {
    const path = pathComponents.join("/");
    return `${BASE_URL_API}${path}`;
};
