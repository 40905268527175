import { Tabs } from "antd";
import * as React from "react";
import { IOrder } from "../../../../../types/order.types";
import { CompartmentTab } from "./compartmenttab/CompartmentTab";

interface IProps {
    order: IOrder;
    loadingOrders: boolean;
}

export const CleaningTab = (props: IProps) => {
    const tabs = props.order.compartments.map((compartment, index) => (
        <Tabs.TabPane tab={index + 1} key={`${props.order.id}-${index}`}>
            <CompartmentTab order={props.order} compartment={compartment} loadingOrders={props.loadingOrders}/>
        </Tabs.TabPane>
    ));

    return (
        <Tabs destroyInactiveTabPane tabPosition="left" animated={false}>
            {tabs}
        </Tabs>
    );
};
