import * as React from "react";
import { ICheckIn } from "../../../../types/checkIn.types";
import { useDispatch } from "react-redux";
import { CleaningModal } from "../cleaningmodal/CleaningModal";
import { checkInDeleteActionCreator } from "../../../../store/actions/checkIn";
import { ModalFooter } from "./ModalFooter";
import { defineMessages, useIntl } from "react-intl";
import { CheckInDeleteModal } from "./CheckInDetailDeleteModal";

interface IProps {
    visible: boolean;
    closeModal: () => void;
    checkIn: ICheckIn;
}

const MESSAGES = defineMessages({
    cancel: {
        id: "check_in_delete_modal_footer.close",
        defaultMessage: "Cancel"
    },
    submit: {
        id: "check_in_delete_modal_footer.submit",
        defaultMessage: "Ok"
    }
});

export const CheckInDetailDeleteModalContainer = (props: IProps) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const closeModal = () => {
        props.closeModal();
    };

    const handleSubmit = () => {
        dispatch(checkInDeleteActionCreator({ checkinId: props.checkIn.id }));
        closeModal();
    };

    const renderFooter = () => (
        <ModalFooter
            disabled={false}
            onClose={closeModal}
            onSubmit={handleSubmit}
            cancelLabel={intl.formatMessage(MESSAGES.cancel)}
            confirmLabel={intl.formatMessage(MESSAGES.submit)}
        />
    );

    return (
        <CleaningModal open={props.visible} closeModal={closeModal} width="20%" footer={renderFooter()}>
            <CheckInDeleteModal handleSubmit={handleSubmit} />
        </CleaningModal>
    );
};
