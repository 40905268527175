import { createReducer } from "@reduxjs/toolkit";
import { StringDict } from "msal/lib-commonjs/MsalTypes";

import { loginFailedAction, loginOkAction, logoutAction } from "../actions";

type TokenClaims = StringDict & { groups: string[] } & { roles: string[] };

export interface IAuthenticationState {
    authenticated: boolean;
    name?: string;
    email?: string;
    groups?: string[];
    roles?: string[];
    expires?: Date;
}

const INITIAL_STATE: IAuthenticationState = {
    authenticated: false,
};

/**
 * Reducer: authentication
 */
export const authentication = createReducer(INITIAL_STATE, (builder) => {

    builder.addCase(loginOkAction, (state, action) => ({
        ...state,
        authenticated: true,
        name: action.payload.idToken.name,
        email: action.payload.idToken.preferredName,
        groups: (action.payload.idTokenClaims as TokenClaims).groups,
        roles: (action.payload.idTokenClaims as TokenClaims).roles,
        expires: action.payload.expiresOn,
    }));

    builder.addCase(logoutAction, () => INITIAL_STATE);
    builder.addCase(loginFailedAction, () => INITIAL_STATE);

});
