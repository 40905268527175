import { notification } from "antd";
import { defineMessages, IntlShape } from "react-intl";

interface IProps {
    intl: IntlShape;
    reset: () => void;
}

const MESSAGES = defineMessages({
    slotBookingUpdateSucceededMessage: {
        id: "slot_booking_update_succeeded.message",
        defaultMessage: "Slot succesvol geupdate"
    },
});

export const SlotBookingUpdateSucceededNotification = (props: IProps) => {
    notification.success({
        message: props.intl.formatMessage(MESSAGES.slotBookingUpdateSucceededMessage),
        onClose: () => props.reset(),
        placement: "bottomRight",
    });
    return null;
};
