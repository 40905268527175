import { useDispatch, useSelector } from "react-redux";
import { checkInPageFetchActionCreator, checkInPageLoadActionCreator } from "../../../store/actions/checkIn";
import { checkInLoadingPageSelector, checkInPageSelector } from "../../../store/selectors/checkInOverview.selector";
import { IApplicationState } from "../../../store/state";
import { ICheckInFilter, IPageRequest, IPageResponse } from "../../../types/api.types";
import { ICheckIn } from "../../../types/checkIn.types";
import { ICheckInPageResourceLoader } from "../../../types/hook.types";
import { mapPageResponseToPageRequest } from "../../../utils/mapping";

export type ICheckInOverviewPageLoader = ICheckInPageResourceLoader<ICheckIn, ICheckInFilter>;

export const useCheckInOverviewPageLoader = (): ICheckInOverviewPageLoader => {
    const dispatch = useDispatch();

    const page = useSelector<IApplicationState, IPageResponse<ICheckIn, ICheckInFilter> | null>(checkInPageSelector);
    const loading = useSelector<IApplicationState, boolean>(checkInLoadingPageSelector);

    const loadResource = (pageRequest: IPageRequest<ICheckInFilter>) => {
        return dispatch(checkInPageLoadActionCreator(pageRequest));
    };

    const refresh = () => {
        const pageRequest = page ? mapPageResponseToPageRequest(page) : {
            pageNumber: 0,
            filter: {} as ICheckInFilter
        };

        return dispatch(checkInPageFetchActionCreator(pageRequest));
    };

    return {
        resource: page,
        loadingResource: loading,
        loadResource,
        refresh
    };
};
