import { IApplicationState } from "../state";
import { createSelector } from "@reduxjs/toolkit";

const documentStateSelector = (state: IApplicationState) => state.documents;

export const documentsPerCheckInSelector = (checkInId: number) => createSelector(
    documentStateSelector,
    (documentsState) => documentsState?.documentsPerCheckIn && documentsState.documentsPerCheckIn[checkInId]
);

export const fetchDocumentsFailedSelector = createSelector(
    documentStateSelector,
    (documentsState) => documentsState?.fetchDocumentsFailed
);

