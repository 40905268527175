import { Button, Input } from "antd";
import * as React from "react";
import { useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { InfoLabel } from "../../../../../../modals/customerservicemodal/panes/detailinfopane/sections/infofields/InfoLabel";

interface IProps {
    onOk: (submittedBy: string) => void;
}

const MESSAGES = defineMessages({
    done: {
        id: "cleaning_done_form.done",
        defaultMessage: "Compartiment succesvol afgewerkt!"
    },
    confirm: {
        id: "cleaning_done_form.confirm",
        defaultMessage: "Reiniging succesvol afgewerkt"
    },
    submittedBy: {
        id: "cleaning_done_form.submittedBy",
        defaultMessage: "Ingediend door"
    }
});

export const CleaningDoneForm = (props: IProps) => {
    const intl = useIntl();
    const [submittedBy, setSubmittedBy] = useState<string | undefined>();

    return (
        <div>
            <h1>
                {intl.formatMessage(MESSAGES.done)}
            </h1>
            <InfoLabel text={intl.formatMessage(MESSAGES.submittedBy)} className="margin-top inline-block"/>
            <Input value={submittedBy} onChange={(e) => setSubmittedBy(e.target.value)} className="margin-half-top"/>
            <Button type="primary" className="margin-top" onClick={() => submittedBy && props.onOk(submittedBy)} disabled={!submittedBy}>
                {intl.formatMessage(MESSAGES.confirm)}
            </Button>
        </div>
    );
};
