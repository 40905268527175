import { createSelector } from "@reduxjs/toolkit";
import { IBookedSlotState } from "../reducers/bookedSlot";
import { IApplicationState } from "../state";

const bookedSlotStateSelector = (state: IApplicationState) => state.bookedSlot;

export const bookedSlotListSelector = createSelector(
    bookedSlotStateSelector,
    (bookedSlotState: IBookedSlotState) => bookedSlotState.bookedSlotList
);

export const dependsOnBookedSlotSelector = createSelector(
    bookedSlotStateSelector,
    (bookedSlotState: IBookedSlotState) => bookedSlotState.dependsOn
);

export const bookedSlotPageSelector = createSelector(
    bookedSlotStateSelector,
    (bookedSlotState: IBookedSlotState) => bookedSlotState.page
);

export const bookedSlotLoadingPageSelector = createSelector(
    bookedSlotStateSelector,
    (bookedSlotState: IBookedSlotState) => bookedSlotState.loadingPage
);
