import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import { ItactOrderInfoContainer } from "../../../../containers/ItactOrderInfoContainer";
import { Box } from "../../../Box";
import { Pane } from "../../customerservicemodal/panes/Pane";

interface IProps {
    orderId: number;
}

const MESSAGES = defineMessages({
    title: {
        id: "itact_order_info_pane.title",
        defaultMessage: "Detail info"
    }
});

export const ItactOrderInfoPane = (props: IProps) => {
    const intl = useIntl();

    return (
        <Pane title={intl.formatMessage(MESSAGES.title)}>
            <Box>
                <ItactOrderInfoContainer orderId={props.orderId}/>
            </Box>
        </Pane>
    );
};
