import * as React from "react";
import { useEffect } from "react";
import { IPageRequest } from "../../../types/api.types";
import { IBookedSlotPageFetchParams } from "../../../types/bookedSlot.types";
import { BookedSlotsTable } from "../../components/tables/BookedSlotsTable";
import { useBookedSlotPageLoader } from "../../hooks/pageloaders/useBookedSlotPageLoader";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

export const BookedSlotsTableContainer = () => {
    const bookedSlotsPageLoader = useBookedSlotPageLoader();

    useEffect(() => {
        const pageRequest: IPageRequest<IBookedSlotPageFetchParams> = {
            pageNumber: bookedSlotsPageLoader.resource?.page.meta.number || 0,
            filter: {
                from: dayjs.utc().subtract(1, "days").format(),
                until: dayjs.utc().add(30, "days").format(),
            },
            sorting: {
                property: "fromDate",
                order: "asc"
            }
        };

        bookedSlotsPageLoader.loadResource(pageRequest);
    }, []);

    return (
        <BookedSlotsTable
            page={bookedSlotsPageLoader.resource ? bookedSlotsPageLoader.resource.page : undefined}
            fetchingPage={bookedSlotsPageLoader.loadingResource}
            fetchPage={bookedSlotsPageLoader.loadResource}
            sorting={bookedSlotsPageLoader.resource ? bookedSlotsPageLoader.resource.sorting : undefined}
            filter={bookedSlotsPageLoader.resource ? bookedSlotsPageLoader.resource.filter : undefined}
        />
    );
};

