import { notification } from "antd";
import { defineMessages, IntlShape } from "react-intl";

interface IProps {
    intl: IntlShape;
    reset: () => void;
}

const MESSAGES = defineMessages({
    clipBoardWrittenMessage: {
        id: "clip_board_written_notification.message",
        defaultMessage: "Link gekopieerd naar klembord"
    },
});

export const ClipBoardWrittenNotification = (props: IProps) => {
    notification.success({
        message: props.intl.formatMessage(MESSAGES.clipBoardWrittenMessage),
        onClose: () => props.reset(),
        placement: "bottomRight"
    });
    return null;
};
