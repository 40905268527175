import { PayloadAction } from "@reduxjs/toolkit";
import { Button, Checkbox } from "antd";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { TableEventListeners } from "antd/es/table";
import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import { IOrderFilter, IPage, IPageRequest, ISort } from "../../../types/api.types";
import { IOrder } from "../../../types/order.types";
import { ItemTable } from "./ItemTable";
import Text from "antd/es/typography/Text";

interface IProps {
    csvDownloadAllSelectedOrders?: (pageRequest: IPageRequest<IOrderFilter>) => void;
    csvDownloadOnlyFinishedOrders?: () => void;
    uuid?: string;
    page?: IPage<IOrder>;
    fetchingPage: boolean;
    filter?: IOrderFilter;
    sorting?: ISort;
    fetchPage: (pageRequest: IPageRequest<IOrderFilter>) => PayloadAction<IPageRequest<IOrderFilter>>;
    onRow?: (record: IOrder, index: number) => TableEventListeners;
    columnFactory: (onFilter: (filter: IOrderFilter) => any) => React.ReactElement[];
}

const MESSAGES = defineMessages({
    showDoneOrders: {
        id: "customer_service_table.show_done_orders",
        defaultMessage: "Toon afgewerkte & ontvangen orders"
    },
    downloadAllSelectedOrders: {
        id: "customer_service_table.download_all_selected_orders",
        defaultMessage: "Download alle geselecteerde orders"
    },
    downloadOnlyFinishedOrders: {
        id: "customer_service_table.download_only_finished_orders",
        defaultMessage: "Download enkel afgewerkte orders",
    },
});

export const MainOrderTable = (props: IProps) => {
    const intl = useIntl();

    const onCheckBoxChange = (e: { target: { checked: boolean } }) => {
        const newFilter: IOrderFilter = { ...props.filter };
        // Undefined filter values are not sent to the backend. This also equates never having filtered and turning it off
        newFilter.allStates = e.target.checked ? true : undefined;

        const pageRequest: IPageRequest<IOrderFilter> = {
            uuid: props.uuid,
            pageNumber: 0,
            sorting: props.sorting,
            filter: newFilter
        };

        props.fetchPage(pageRequest);
    };

    const onDownloadAllSelectedOrders = () => {
        const newFilter: IOrderFilter = { ...props.filter };

        const pageRequest: IPageRequest<IOrderFilter> = {
            pageNumber: 0,
            uuid: props.uuid,
            sorting: props.sorting,
            filter: newFilter
        };

        props.csvDownloadAllSelectedOrders && props.csvDownloadAllSelectedOrders(pageRequest);
    };

    const onDownloadAllFinishedOrders = () => {
        props.csvDownloadOnlyFinishedOrders && props.csvDownloadOnlyFinishedOrders();
    };

    const filter: IOrderFilter = { ...props.filter };

    return (
        <>
            <Checkbox checked={filter.allStates} onChange={onCheckBoxChange}>
                {intl.formatMessage(MESSAGES.showDoneOrders)}
            </Checkbox>
            {
                props.csvDownloadAllSelectedOrders &&
                <Button onClick={onDownloadAllSelectedOrders} className={"margin-left"}>
                    <Text>{intl.formatMessage(MESSAGES.downloadAllSelectedOrders)}</Text>
                </Button>
            }
            {
                props.csvDownloadOnlyFinishedOrders &&
                <Button onClick={onDownloadAllFinishedOrders} className={"margin-left"}>
                    <Text>{intl.formatMessage(MESSAGES.downloadOnlyFinishedOrders)}</Text>
                </Button>
            }
            <ItemTable
                uuid={props.uuid}
                data={props.page?.content || []}
                pageMeta={props.page?.meta}
                fetching={props.fetchingPage}
                fetch={props.fetchPage}
                columnFactory={props.columnFactory}
                className="margin-half-top block"
                onRow={props.onRow}
                filter={filter}
            />
        </>
    );
};
