import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { IOrderFilter, IPage, IPageRequest, IPageResponse } from "../../types/api.types";
import { IOrder, IStateTransition, State } from "../../types/order.types";
import { formatFilterQueryParams, formatPageQueryParams } from "../../utils/format";
import { mapSpringPageToIPage } from "../../utils/mapping";
import {
    orderAllowedTransitionsFetchCreator,
    orderAllowedTransitionsFetchFailedCreator,
    orderAllowedTransitionsReceivedCreator,
    orderFetchCreator,
    orderFetchFailedCreator,
    orderLoadCreator,
    orderReceivedCreator,
    orderTotalElementsFetchCreator,
    orderTotalElementsFetchFailedCreator,
    orderTotalElementsReceivedCreator,
    orderTransitionsFetchCreator,
    orderTransitionsFetchFailedCreator,
    orderTransitionsReceivedCreator,
    orderUpdateCreator,
    orderUpdateDoneCreator,
    orderUpdateFailedCreator
} from "../actions";
import { orderApi } from "../api/order";
import { stateApi } from "../api/state";
import { errorHandler } from "./errorHandler";
import { AxiosResponse } from "axios";
import { isNil, omitBy } from "lodash";

export function* fetchTransitions(action: ReturnType<typeof orderTransitionsFetchCreator>) {
    const response: AxiosResponse<IStateTransition[]> = yield call(orderApi.getTransitions, action.payload);
    yield put(orderTransitionsReceivedCreator(response.data));
}

export function* fetchAllowedTransitions(action: ReturnType<typeof orderAllowedTransitionsFetchCreator>) {
    const response: AxiosResponse<State[]> = yield call(stateApi.getAllowedTransitions, action.payload);
    yield put(orderAllowedTransitionsReceivedCreator(response.data));
}

export function* fetchPage(pageRequest: IPageRequest<IOrderFilter>) {
    const filtersWithValue = omitBy(pageRequest.filter, isNil);
    const queryParams = formatPageQueryParams({ ...pageRequest, filter: filtersWithValue });

    const response: AxiosResponse<any> = yield call(orderApi.getPage, queryParams);
    const page: IPage<IOrder> = mapSpringPageToIPage(response.data);

    const pageResponse: IPageResponse<IOrder, IOrderFilter> = {
        page,
        sorting: pageRequest.sorting,
        filter: pageRequest.filter,
    };

    return pageResponse;
}

export function* fetch(action: ReturnType<typeof orderFetchCreator>) {
    const response: AxiosResponse<IOrder> = yield call(orderApi.getOrder, action.payload);
    yield put(orderReceivedCreator(response.data));
}

export function* update(action: ReturnType<typeof orderUpdateCreator>) {
    yield call(orderApi.update, action.payload.orderId, action.payload.orderUpdateDto);
    yield put(orderUpdateDoneCreator(action.payload));
}

export function* updateDone(action: ReturnType<typeof orderUpdateDoneCreator>) {
    if (action.payload.doneAction) {
        yield put(action.payload.doneAction({ orderId: action.payload.orderId, orderUpdateDto: action.payload.orderUpdateDto }));
    }
}

export function* load(action: ReturnType<typeof orderLoadCreator>) {
    yield put(orderFetchCreator(action.payload));
}

export function* fetchTotalElements(action: ReturnType<typeof orderTotalElementsFetchCreator>) {
    const filterString = formatFilterQueryParams(action.payload);
    const queryParams = `?size=1&${filterString}`;
    const response: AxiosResponse<any> = yield call(orderApi.getPage, queryParams);
    yield put(orderTotalElementsReceivedCreator({ filter: action.payload, count: response.data.totalElements }));
}

export function* orderSaga() {
    yield takeLatest(orderTransitionsFetchCreator.type, errorHandler(fetchTransitions, orderTransitionsFetchFailedCreator));
    yield takeLatest(orderAllowedTransitionsFetchCreator.type, errorHandler(fetchAllowedTransitions, orderAllowedTransitionsFetchFailedCreator));
    yield takeLatest(orderFetchCreator.type, errorHandler(fetch, orderFetchFailedCreator));
    yield takeEvery(orderUpdateCreator.type, errorHandler(update, orderUpdateFailedCreator));
    yield takeLatest(orderUpdateDoneCreator.type, errorHandler(updateDone));
    yield takeLatest(orderLoadCreator.type, errorHandler(load));
    yield takeEvery(orderTotalElementsFetchCreator.type, errorHandler(fetchTotalElements, orderTotalElementsFetchFailedCreator));
}
