import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import { StateTimelineContainer } from "../../../../../containers/StateTimelineContainer";
import { TransitionDropdownContainer } from "../../../../../containers/TransitionDropdownContainer";
import { Box } from "../../../../Box";
import { Pane } from "../Pane";

interface IProps {
    orderId: number;
}

const MESSAGES = defineMessages({
    title: {
        defaultMessage: "Status info",
        id: "status_pane.title"
    }
});

export const StatusPane = (props: IProps) => {
    const intl = useIntl();

    return (
        <Pane title={intl.formatMessage(MESSAGES.title)}>
            <Box>
                <StateTimelineContainer orderId={props.orderId} className="margin-bottom"/>
                <TransitionDropdownContainer orderId={props.orderId}/>
            </Box>
        </Pane>
    );
};
