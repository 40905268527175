import * as React from "react";
import { Form } from "antd";
import { defineMessages, useIntl } from "react-intl";

interface IProps {
  handleSubmit: (values: any) => void;
}

const MESSAGES = defineMessages({
    reason: {
        id: "check_in_detail_delete.reason",
        defaultMessage: "Bent u zeker dat u de check-in definitief wil verwijderen?"
    },
});

export const CheckInDeleteModal = (props: IProps) => {
    const intl = useIntl();
    const [form] = Form.useForm();

    return (
        <Form onFinish={props.handleSubmit} form={form}>
            <Form.Item
                name="reason"
                label={intl.formatMessage(MESSAGES.reason)}
            >
            </Form.Item>
        </Form>
    );
};
