import * as React from "react";
import { useSelectedSlot } from "../hooks/useSelectedSlot";
import { SlotBookingSelectedSlotForm } from "../components/forms/SlotBookingSelectedSlotForm";
import { useTtatCalculation } from "../hooks/useTtatCalculation";

export const SlotBookingSelectedSlotContainer = () => {
    const [selectedSlot, ] = useSelectedSlot();
    const [, , ttatCalculation] = useTtatCalculation();

    return (
        selectedSlot && <SlotBookingSelectedSlotForm
            selectedSlot={selectedSlot}
            ttatCalculation={ttatCalculation}/>
    );
};
