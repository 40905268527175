import { useDispatch, useSelector } from "react-redux";
import { cleaningPageFetchCreator, cleaningPageLoadCreator } from "../../../store/actions/cleaning";
import { cleaningLoadingPageSelector, cleaningPageSelector } from "../../../store/selectors/cleaning.selector";
import { IApplicationState } from "../../../store/state";
import { IOrderFilter, IPageRequest, IPageResponse, ISort } from "../../../types/api.types";
import { IPageResourceLoader } from "../../../types/hook.types";
import { IOrder } from "../../../types/order.types";
import { mapPageResponseToPageRequest } from "../../../utils/mapping";

export type ICleaningPageLoader = IPageResourceLoader<IOrder, IOrderFilter>;

export const useCleaningPageLoader = (): ICleaningPageLoader => {
    const dispatch = useDispatch();

    const page = useSelector<IApplicationState, IPageResponse<IOrder, IOrderFilter> | null>(cleaningPageSelector);
    const loading = useSelector<IApplicationState, boolean>(cleaningLoadingPageSelector);

    const loadResource = (pageRequest: IPageRequest<IOrderFilter>) => {
        const sorting: ISort = {
            property: "container.location.location",
            order: "asc"
        };

        const filter: IOrderFilter = {
            ...pageRequest.filter,
            cleaningOrder: true
        };

        const pr: IPageRequest<IOrderFilter> = {
            pageNumber: pageRequest.pageNumber,
            sorting: pageRequest.sorting || sorting,
            filter
        };

        // TODO: should use cleaningList instead of page
        return dispatch(cleaningPageLoadCreator(pr));
    };

    const refresh = () => {
        const pageRequest = page ? mapPageResponseToPageRequest(page) : {
            pageNumber: 0,
            filter: {}
        };

        return dispatch(cleaningPageFetchCreator(pageRequest));
    };

    return {
        resource: page,
        loadingResource: loading,
        loadResource,
        refresh
    };
};

