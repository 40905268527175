import * as React from "react";
import { ITractor } from "../../../../../../types/checkIn.types";
import { defineMessages, useIntl } from "react-intl";
import { Alert, Col, Row } from "antd";
import { InfoLabel } from "../../../customerservicemodal/panes/detailinfopane/sections/infofields/InfoLabel";
import { InfoField } from "../../../customerservicemodal/panes/detailinfopane/sections/infofields/InfoField";
import { formatAdrVehicleSpecification, formatIso8601Date } from "../../../../../../utils/format";
import { VehicleInfo } from "./VehicleInfo";
import { useSelector } from "react-redux";
import { checkInRecordHasExpiredByAdrType } from "../../../../../../store/selectors/checkInOverview.selector";
import { AdrTypes } from "../../../../../../types/adr.types";

interface IProps {
    tractor: ITractor;
    isTruck: boolean;
    showAdrInfo: boolean;
}

const MESSAGES = defineMessages({
    tractorLicensePlate: {
        id: "tractor_info.tractor_licenseplate",
        defaultMessage: "Nummerplaat trekker"
    },
    adrCode: {
        id: "tractor_info.adr_code",
        defaultMessage: "ADR code"
    },
    adrExpirationDate: {
        id: "tractor_info.adr_expiration_date",
        defaultMessage: "ADR vervaldatum"
    },
    adrExpiredWarning: {
        id: "tractor_info.adr_expired_warning",
        defaultMessage: "ADR code is vervallen."
    },
    tractor: {
        id: "tractor_info.tractor",
        defaultMessage: "Trekker"
    },
    flat: {
        id: "tractor_info.flat",
        defaultMessage: "FL/AT"
    },
    truck: {
        id: "tractor_info.truck",
        defaultMessage: "Vrachtwagen"
    }
});

export const TractorInfo = (props: IProps) => {
    const intl = useIntl();
    const tractorAdrExpired = useSelector(checkInRecordHasExpiredByAdrType(AdrTypes.TRACTOR));


    return (
        <VehicleInfo title={intl.formatMessage(props.isTruck ? MESSAGES.truck : MESSAGES.tractor)}>
            <Row className="margin-bottom">
                <Col span={12}>
                    <InfoLabel text={intl.formatMessage(MESSAGES.tractorLicensePlate)}/>
                    <InfoField value={props.tractor.licensePlate}/>
                </Col>
            </Row>
            {
                props.tractor.adrCertificate &&
                <>
                    {
                        props.showAdrInfo && (
                            <Row className="margin-bottom">
                                <Col span={12}>
                                    <InfoLabel text={intl.formatMessage(MESSAGES.adrCode)}/>
                                    <InfoField value={props.tractor.adrCertificate.adrCode}/>
                                </Col>
                                <Col span={12}>
                                    <InfoLabel text={intl.formatMessage(MESSAGES.adrExpirationDate)}/>
                                    <InfoField value={formatIso8601Date(props.tractor.adrCertificate.adrExpirationDate)}/>
                                </Col>
                            </Row>
                        )
                    }
                    {
                        props.showAdrInfo && tractorAdrExpired &&
                        <Row className="margin-bottom">
                            <Col span={24}>
                                <Alert message={intl.formatMessage(MESSAGES.adrExpiredWarning)}
                                    type={"error"}
                                />
                            </Col>

                        </Row>
                    }
                    <Row>
                        <Col span={12}>
                            <InfoLabel text={intl.formatMessage(MESSAGES.flat)}/>
                            <InfoField value={props.tractor.adrVehicleSpecification && formatAdrVehicleSpecification(props.tractor.adrVehicleSpecification, intl)}/>
                        </Col>
                    </Row>
                </>
            }
        </VehicleInfo>
    );
};
