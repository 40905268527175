import * as React from "react";
import { AppLayout } from "./AppLayout";
import { CheckInOverviewTableContainer } from "../containers/tablecontainers/CheckInOverviewTableContainer";

export const CheckInOverviewScreen = () => {
    return (
        <AppLayout>
            <CheckInOverviewTableContainer/>
        </AppLayout>
    );
};
