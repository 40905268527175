import { PayloadAction, PayloadActionCreator } from "@reduxjs/toolkit";
import { call, put } from "redux-saga-test-plan/matchers";
import { IFailedActionPayload } from "../../types/action.types";
import { unauthorizedAction } from "../actions";

function* handleError(e: Error, action: PayloadAction<void> , errorActionCreator?: PayloadActionCreator<IFailedActionPayload>) {
    const failedAction: IFailedActionPayload = {
        e,
        action,
    };

    // Axios errors have this property and we check if the property is present.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (e.response && e.response.status === 403) {
        yield put(unauthorizedAction(failedAction));
    }

    if (errorActionCreator) {
        yield put(errorActionCreator(failedAction));
    }
}

export const errorHandler = (saga: any, errorActionCreator?: PayloadActionCreator<IFailedActionPayload>) => (
    function* handler(action: PayloadAction<void>) {
        try {
            yield call(saga, action);
        } catch (e) {
            yield call(handleError, e, action, errorActionCreator);
        }
    }
);
