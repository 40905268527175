import { ITank } from "./salesOrder.types";

type TimeString = string;
type DateString = string;

export interface ISelectedSlot{
    tank: ITank;
    startDate: DateString;
    endDate: DateString;
    startTime: TimeString;
    endTime: TimeString;
    productionOrderReference: string;
    tankBookings?: number;
    zoneBookings?: number;
    tankTimeOverlap?: boolean;
    constraints?: IConstraint[];
    dependsOnBooking?: number;
    blockedDependsOn: boolean;
    salesOrderReference: string;
}

export interface ISlot {
    start: TimeString;
    end: TimeString;
    date: DateString;
    tankBookings: number;
    zoneBookings: number;
    tankTimeOverlap?: boolean;
    constraints: IConstraint[];
}

export interface ISlotResponse {
    start: TimeString;
    end: TimeString;
    tankBookings: number;
    zoneBookings: number;
    tankTimeOverlap: boolean;
    constraints: IConstraint[];
}

export interface IConstraint {
    priority: number;
    reason: string;
    message: string;
    status: IConstraintStatus;
}

export enum IConstraintStatus {
    BLOCKED = "blocked",
    WARNING = "warning",
    DISABLED = "disabled",
}

export interface IHybridSlot {
    slot: ISlot;
    blockedDependsOn: boolean;
}

export interface ISlotFetchParams {
    from: string;
    until: string;
    productionOrderReference: string;
    bookingType: string;
}

export interface ISlotBookingConfig {
    zoneId: number;
    warningValue: number;
    maximumValue: number;
}
