import { Timeline } from "antd";
import * as React from "react";

interface IProps {
    title: string;
    active: boolean;
    children?: React.ReactElement;
}

export const TimelineItem: React.FC<IProps> = (props) => {
    return (
        <Timeline.Item color={`${props.active ? "blue" : "gray"}`}>
            <h3>{props.title}</h3>
            {props.children}
        </Timeline.Item>);
};
