import { call, put, takeLatest } from "redux-saga/effects";
import { errorHandler } from "./errorHandler";
import { pitListFetchCreator, pitListFetchFailedCreator, pitListReceivedCreator } from "../actions/pit";
import { pitsApi } from "../api/pit";
import { AxiosResponse } from "axios";
import { IPit } from "../../types/salesOrder.types";

export function* fetchPitList() {
    const response: AxiosResponse<IPit[]> = yield call(pitsApi.getPitList);
    yield put(pitListReceivedCreator(response.data));
}

export function* pitSaga() {
    yield takeLatest(pitListFetchCreator.type, errorHandler(fetchPitList, pitListFetchFailedCreator));
}
