import React, { useState } from "react";
import { ICheckIn } from "../../../../../../types/checkIn.types";
import { Pane } from "../../../customerservicemodal/panes/Pane";
import { Box } from "../../../../Box";
import { defineMessages, useIntl } from "react-intl";
import TextArea from "antd/lib/input/TextArea";
import { useDispatch } from "react-redux";
import {
    deleteRemarkForCheckinAction,
    postRemarkForCheckinAction
} from "../../../../../../store/actions/remarks.actions";
import { TIME_FORMATS } from "../../../../../../constants/time";
import { CloseOutlined } from "@ant-design/icons";
import { IRemark } from "../../../../../../types/remarks.types";
import { Button, Popconfirm } from "antd";
import dayjs from "dayjs";

interface IProps {
  checkIn: ICheckIn | null;
  className?: string;
}

const MESSAGES = defineMessages({
    title: {
        id: "remarksPane.title",
        defaultMessage: "Opmerkingen"
    },
    maxLength: {
        id: "remarksPane.maxLength",
        defaultMessage: "Max. 280 tekens"
    },
    buttonLabel: {
        id: "remarksPane.buttonLabel",
        defaultMessage: "Toevoegen"
    },
    deleteConfirm: {
        id: "remarksPane.deleteConfirm",
        defaultMessage: "Weet u zeker dat u deze comment wilt verwijderen?"
    },
});

export const RemarksPane = (props: IProps) => {
    if (!props.checkIn) {return (<></>);}
    const checkIn = props.checkIn as ICheckIn;

    const intl = useIntl();
    const dispatch = useDispatch();
    const [remark, setRemark] = useState("");

    const onClickAdd = () => {
        remark && dispatch(postRemarkForCheckinAction({ checkinId: checkIn.id, remark }));
        setRemark("");
    };

    const renderRemark = (remark: IRemark) => (
        <div key={remark.id} className="flex-column margin-half-bottom">
            <div className="flex-row bold">
                <span>{remark.createdByUser} -&nbsp;</span>
                <span>{dayjs(remark.createdDate).format(TIME_FORMATS.DATE_TIME)}</span>
                <Popconfirm
                    title={intl.formatMessage(MESSAGES.deleteConfirm)}
                    onConfirm={() => dispatch(deleteRemarkForCheckinAction({ remarkId: remark.id, checkinId: checkIn.id }))}
                >
                    <CloseOutlined className="margin-left-auto flex-column justify-center"/>
                </Popconfirm>
            </div>
            <span className="text-disabled">{remark.remark}</span>
        </div>
    );

    return (
        <Pane className={props.className} title={intl.formatMessage(MESSAGES.title)}>
            <Box className="flex-column">
                {!!props.checkIn.remarks.length &&
                    <div
                        className="box-border"
                        style={{ height: 126, overflow: "auto", marginBottom: 8, padding: 6 }}
                    >
                        {props.checkIn.remarks.map((remark) =>
                            renderRemark(remark)
                        )}
                    </div>
                }
                <TextArea
                    value={remark}
                    onChange={(e) => setRemark(e.target.value)}
                    autoSize={{ minRows: 3, maxRows: 6 }}
                    placeholder={intl.formatMessage(MESSAGES.maxLength)}
                    maxLength={280}
                />
                <Button
                    className="margin-left-auto margin-half-top"
                    type="primary"
                    onClick={onClickAdd}
                    disabled={!remark}
                >
                    {intl.formatMessage(MESSAGES.buttonLabel)}
                </Button>
            </Box>
        </Pane>
    );
};
