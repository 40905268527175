declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface Window {
        ENV?: unknown;
    }
}

function createEnv<T>() {
    return (window.ENV || process.env) as T;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ENV = createEnv<any>();
