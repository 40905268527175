import { Tabs } from "antd";
import * as React from "react";
import { useState } from "react";
import { IOrder } from "../../../../types/order.types";
import { CleaningTab } from "./cleaningtab/CleaningTab";

import { CleaningTabPane } from "./cleaningtabpane/CleaningTabPane";

interface IProps {
    orders: IOrder[];
    loadingOrders: boolean;
}

export const CleaningTabs = (props: IProps) => {
    const defaultKey = `${props.orders.length ? props.orders[0].id : ""}`;
    const [activeTab, setActiveTab] = useState<string>(defaultKey);

    const tabs = props.orders
        .map(order => {
            const location = order.container.location && order.container.location.location;
            const keyStr = `${order.id}`;
            const tabPane = <CleaningTabPane slot={location} containerNumber={order.container.sapId} state={order.state} active={activeTab === keyStr}/>;

            return (
                <Tabs.TabPane tab={tabPane} key={keyStr}>
                    <CleaningTab order={order} loadingOrders={props.loadingOrders}/>
                </Tabs.TabPane>
            );
        });

    return (
        <div className="cleaningtabs">
            <Tabs destroyInactiveTabPane animated={false} onChange={setActiveTab} defaultActiveKey={defaultKey}>
                {tabs}
            </Tabs>
        </div>
    );
};
