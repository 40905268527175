import * as React from "react";
import { TankBlockingForm } from "../components/forms/TankBlockingForm";
import { useBlockTank } from "../hooks/useBlockTank";
import { useTankList } from "../hooks/useTankList";

export const TankBlockingContainer = () => {
    const [tankList, fetchTankList, ] = useTankList();
    const [blockTank] = useBlockTank();

    return <TankBlockingForm
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        tankList={tankList}
        blockTank={blockTank}
        fetchTankList={fetchTankList}/>;
};
