import { Col, Row } from "antd";
import * as React from "react";
import { PADDING } from "../../../../theme/typescript";
import { CleaningModal } from "../cleaningmodal/CleaningModal";
import { ExternalViewOrderInfoPane } from "./panes/detailinfopane/ExternalViewOrderInfoPane";
import { ExternalViewStatusPane } from "./panes/statuspane/ExternalViewStatusPane";

interface IProps {
    visible: boolean;
    closeModal: () => void;
    orderId?: number;
    uuid: string;
}

export const ExternalViewModal = (props: IProps) => {
    return !props.orderId ? null : (
        <CleaningModal
            visible={props.visible}
            closeModal={props.closeModal}
            width="60%"
        >
            <Row gutter={PADDING}>
                <Col span={12}>
                    <ExternalViewOrderInfoPane orderId={props.orderId}/>
                </Col>
                <Col span={12}>
                    <ExternalViewStatusPane id={props.orderId} uuid={props.uuid}/>
                </Col>
            </Row>
        </CleaningModal>
    );
};
