import { useDispatch, useSelector } from "react-redux";
import { IApplicationState } from "../../store/state";
import { ISelectedSlot } from "../../types/slot.types";
import { selectedSlotReceivedCreator } from "../../store/actions";

export const useSelectedSlot = (): [
    ISelectedSlot | null,
    (selectedSlot: ISelectedSlot | null) => void
] => {
    const selectedSlot = useSelector<IApplicationState, ISelectedSlot | null>(state => state.slot.selected);

    const dispatch = useDispatch();
    const assign = (selectedSlot: ISelectedSlot | null) => dispatch(selectedSlotReceivedCreator(selectedSlot));

    return [selectedSlot, assign];
};
