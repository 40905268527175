import { Col, Row } from "antd";
import * as React from "react";
import { MESSAGE_PERMISSIONS, MESSAGE_TITLES_FACTORIES } from "../../../../constants/messages";
import { PADDING } from "../../../../theme/typescript";
import { CleaningModal } from "../cleaningmodal/CleaningModal";
import { OrderInfoPane } from "./panes/detailinfopane/OrderInfoPane";
import { MessagesPane } from "./panes/messagesPane/MessagesPane";
import { StatusPane } from "./panes/statuspane/StatusPane";

interface IProps {
    visible: boolean;
    closeModal: () => void;
    orderId?: number;
}

export const CustomerServiceModal = (props: IProps) => {
    if (!props.orderId) {
        return <></>;
    }

    return (
        <CleaningModal
            open={props.visible}
            closeModal={props.closeModal}
            width="90%"
        >
            <Row gutter={PADDING}>
                <Col span={8}>
                    <OrderInfoPane orderId={props.orderId}/>
                </Col>
                <Col span={8}>
                    <StatusPane orderId={props.orderId}/>
                </Col>
                <Col span={8}>
                    <MessagesPane
                        orderId={props.orderId}
                        permissions={MESSAGE_PERMISSIONS.customerService}
                        titleFactory={MESSAGE_TITLES_FACTORIES.customerService}
                    />
                </Col>
            </Row>
        </CleaningModal>
    );
};
