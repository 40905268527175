import React from "react";
import { TimeOutSettingsPane } from "./self-check-in/TimeOutSettingsPane";
import { TerminalSettingsPane } from "./self-check-in/TerminalSettingsPane";
import { Col, Row } from "antd";
import { StorageLocation } from "../../../types/salesOrder.types";
import { ContractorUploadPane } from "./self-check-in/ContractorUploadPane";

export const SelfCheckInForm = () => {
    return (
        <>
            <TimeOutSettingsPane/>
            <Row gutter={64}>
                <Col span={8}>
                    <TerminalSettingsPane terminal={StorageLocation.ADPO}/>
                </Col>
                <Col span={8}>
                    <TerminalSettingsPane terminal={StorageLocation.LLH}/>
                </Col>
                <Col span={8}>
                    <TerminalSettingsPane terminal={StorageLocation.ALP}/>
                </Col>
            </Row>
            <ContractorUploadPane />
        </>
    );
};
