import * as React from "react";
import { defineMessages, IntlShape, useIntl } from "react-intl";
import { ICleaningMessagePermissions, ICleaningMessageTitles } from "../../../../../../types/message.types";
import { MessagesContainer } from "../../../../../containers/MessagesContainer";
import { Pane } from "../Pane";

interface IProps {
    orderId: number;
    permissions: ICleaningMessagePermissions;
    titleFactory: (intl: IntlShape) => ICleaningMessageTitles;
}

const MESSAGES = defineMessages({
    title: {
        id: "messages_pane.title",
        defaultMessage: "Berichten",
    }
});

export const MessagesPane = (props: IProps) => {
    const intl = useIntl();

    return (
        <Pane title={intl.formatMessage(MESSAGES.title)}>
            <MessagesContainer
                orderId={props.orderId}
                permissions={props.permissions}
                titleFactory={props.titleFactory}
            />
        </Pane>
    );
};
