import { useDispatch, useSelector } from "react-redux";
import { salesOrderFetchCreator, salesOrderReceivedCreator } from "../../store/actions";
import { PayloadAction } from "@reduxjs/toolkit";
import { IApplicationState } from "../../store/state";
import { BookingType, ISalesOrder } from "../../types/salesOrder.types";

export const useSalesOrder = (): [
    ISalesOrder | null,
    (reference: string) => PayloadAction<{ reference: string }>,
    (salesOrder: ISalesOrder | null) => void
] => {
    const salesOrder = useSelector<IApplicationState, ISalesOrder | null>(state => state.salesOrder);
    const dispatch = useDispatch();
    const fetch = (reference: string, bookingType?: BookingType) => dispatch(salesOrderFetchCreator({ reference, bookingType }));
    const assign = (salesOrder: ISalesOrder | null) => dispatch(salesOrderReceivedCreator(salesOrder));

    return [salesOrder, fetch, assign];
};
