import { combineReducers } from "redux";

import { IApplicationState } from "../state";
import { authentication } from "./authentication";
import { bookedSlotReducer } from "./bookedSlot";
import { checklistStateReducer } from "./checklist";
import { cleaningReducer } from "./cleaning";
import { customerReducer } from "./customer";
import { customerServiceReducer } from "./customerservice";
import { init } from "./init";
import { itactReducer } from "./itact";
import { notificationReducer } from "./notification";
import { orderReducer } from "./order";
import { pitReducer } from "./pit";
import { planningReducer } from "./planning";
import { salesOrderListReducer, salesOrderReducer } from "./salesOrder";
import { slotReducer } from "./slot";
import { tankReducer } from "./tank";
import { externalViewReducer } from "./externalview";
import { checkInReducer } from "./checkIn.reducers";
import { settingsReducer } from "./selfCheckInSettings.reducers";
import { documentsReducer } from "./documents.reducers";
import { uiReducer } from "./ui.reducers";
import { languageReducer } from "./language.reducers";
import { filterReducer } from "./filter.reducers";

// Setup the root reducer
export const rootReducer = combineReducers<IApplicationState>({
    ui: uiReducer,
    language: languageReducer,
    authentication,
    init,
    slot: slotReducer,
    customer: customerReducer,
    order: orderReducer,
    tank: tankReducer,
    checklist: checklistStateReducer,
    salesOrder: salesOrderReducer,
    salesOrderList: salesOrderListReducer,
    pit: pitReducer,
    cleaning: cleaningReducer,
    customerService: customerServiceReducer,
    planning: planningReducer,
    bookedSlot: bookedSlotReducer,
    notification: notificationReducer,
    itact: itactReducer,
    externalView: externalViewReducer,
    // booking: bookingReducer,
    checkIn: checkInReducer,
    settings: settingsReducer,
    documents: documentsReducer,
    filter: filterReducer
});
