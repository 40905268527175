import * as React from "react";
import { ReactComponent as LogoIcon } from "../../../assets/logo.svg";

type Props = {
  className?: string;
  onClick: () => void;
};

export const Logo = (props: Props) => (
    <LogoIcon onClick={props.onClick} className={props.className}/>
);
