import * as React from "react";

interface IProps {
    title: string | React.ReactNode;
}

export const PaneTitle = (props: IProps) => {
    return (
        <div>
            <h2>{props.title}</h2>
        </div>
    );
};
