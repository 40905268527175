import { combineReducers, createReducer } from "@reduxjs/toolkit";
import { ICheckInFilter, IPageResponse } from "../../types/api.types";
import { ICheckIn } from "../../types/checkIn.types";
import {
    checkInPageClearFiltersActionCreator,
    checkInPageFetchFailedActionCreator,
    checkInPageLoadActionCreator,
    checkInPageReceivedActionCreator,
    checkInRecordReceivedActionCreator,
    checkInSetSalesOrderIdForLinkActionCreator,
    checkInSetViewSalesOrderLinkModalSetManualOrderIdActionCreator
} from "../actions/checkIn";
import { setRemarksForCheckinAction } from "../actions/remarks.actions";

export interface ICheckInState {
    page: IPageResponse<ICheckIn, ICheckInFilter> | null;
    loadingPage: boolean;
    record: ICheckIn | null;
    approvedSalesOrderId: number | null;
    linkSalesOrderModalManualOrderId: number | null;
}

export const recordReducer = createReducer<ICheckIn | null>(null, (builder) => {
    builder.addCase(checkInRecordReceivedActionCreator, (state, action) => {
        const nonEditableSalesOrders = action.payload?.salesOrders?.some(order => order.cancelled);

        if (action.payload) {
            action.payload.isEditable = !nonEditableSalesOrders;
        }
        return action.payload;
    });
    builder.addCase(setRemarksForCheckinAction, (state, action) => {
        if (state && state.id === action.payload.checkinId) {
            return { ...state, remarks: action.payload.remarks };
        }
    });
});

export const pageReducer = createReducer<IPageResponse<ICheckIn, ICheckInFilter> | null>(null, (builder) => {
    builder.addCase(checkInPageReceivedActionCreator, (state, action) => action.payload);
    builder.addCase(checkInPageClearFiltersActionCreator, (state) => {
        if (state) {
            return {
                ...state,
                page: {
                    ...state.page,
                    meta: {
                        ...state.page.meta,
                        number: 0
                    }
                } ,
                filter: {
                    ...state.filter,
                    checkInState: []
                }
            };
        }
    });
});

export const pageLoadingReducer = createReducer<boolean>(false, (builder) => {
    builder.addCase(checkInPageLoadActionCreator, () => true);
    builder.addCase(checkInPageReceivedActionCreator, () => false);
    builder.addCase(checkInPageFetchFailedActionCreator, () => false);
});

export const showLinkSalesOrderModal = createReducer<number | null>(null, (builder) => {
    builder.addCase(checkInSetViewSalesOrderLinkModalSetManualOrderIdActionCreator, (state, action) => action.payload);
});

export const approvedSalesOrderId = createReducer<number | null>(null, (builder) => {
    builder.addCase(checkInSetSalesOrderIdForLinkActionCreator, (state, action) => action.payload.salesOrderId);
});

export const checkInReducer = combineReducers<ICheckInState>({
    page: pageReducer,
    loadingPage: pageLoadingReducer,
    record: recordReducer,
    approvedSalesOrderId,
    linkSalesOrderModalManualOrderId: showLinkSalesOrderModal
});
