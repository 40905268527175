import { createAction } from "@reduxjs/toolkit";
import { IFailedActionPayload } from "../../types/action.types";
import { IChecklistEntryStepSubmit } from "../../types/api.types";
import { IChecklist } from "../../types/checklist.types";

enum ChecklistActionTypes {
    CHECKLISTS_FETCH = "[Checklist] FETCH",
    CHECKLISTS_RECEIVED = "[Checklist] RECEIVED",
    CHECKLISTS_FETCH_FAILED = "[Checklist] FETCH_FAILED",

    CHECKLIST_STEP_SUBMIT = "[Checklist] STEP_SUBMIT",
    CHECKLIST_STEP_SUBMITTED = "[Checklist] STEP_SUBMITTED",
    CHECKLIST_STEP_SUBMIT_FAILED = "[Checklist] STEP_SUBMIT_FAILED",
}

export const checklistsFetchActionCreator = createAction<number>(ChecklistActionTypes.CHECKLISTS_FETCH);
export const checklistsReceivedActionCreator = createAction<IChecklist[]>(ChecklistActionTypes.CHECKLISTS_RECEIVED);
export const checklistsFetchFailedActionCreator = createAction<IFailedActionPayload>(ChecklistActionTypes.CHECKLISTS_FETCH_FAILED);

export const checklistStepSubmitActionCreator = createAction<{compartmentId: number; checklistId: number; checklistEntryStepSubmit: IChecklistEntryStepSubmit}>(ChecklistActionTypes.CHECKLIST_STEP_SUBMIT);
export const checklistStepSubmittedActionCreator = createAction<number>(ChecklistActionTypes.CHECKLIST_STEP_SUBMITTED);
export const checklistStepSubmitFailedActionCreator = createAction<IFailedActionPayload>(ChecklistActionTypes.CHECKLIST_STEP_SUBMIT_FAILED);
