import { useDispatch, useSelector } from "react-redux";
import { IApplicationState } from "../../store/state";
import { ISlot, ISlotBookingConfig, ISlotFetchParams } from "../../types/slot.types";
import { PayloadAction } from "@reduxjs/toolkit";
import { slotsFetchCreator, slotsReceivedCreator } from "../../store/actions";

export const useSlot = (): [
    ISlot[],
    ISlotBookingConfig | null,
    boolean,
    (tankId: number, slotsFetch: ISlotFetchParams) => PayloadAction<{ tankId: number; slotsFetchParams: ISlotFetchParams }>,
    (slots: ISlot[]) => PayloadAction<ISlot[]>
] => {
    const slots = useSelector<IApplicationState, ISlot[]>(state => state.slot.available);
    const config = useSelector<IApplicationState, ISlotBookingConfig | null>(state => state.slot.config);
    const loading = useSelector<IApplicationState, boolean>(state => state.slot.loading);
    const dispatch = useDispatch();
    const fetch = (tankId: number, slotsFetchParams: ISlotFetchParams) => dispatch(slotsFetchCreator({ tankId, slotsFetchParams }));
    const assign = (slots: ISlot[]) => dispatch(slotsReceivedCreator(slots));

    return [slots, config, loading, fetch, assign];
};
