import { useDispatch, useSelector } from "react-redux";
import { IApplicationState } from "../../store/state";
import { resetNotificationActionCreator, showNotificationActionCreator } from "../../store/actions/notifications";

export const useNotification = (): [
    string | null,
    (notificationType: string) => void,
    () => void
    ] => {
    const notification = useSelector<IApplicationState, string | null>(state => state.notification);
    const dispatch = useDispatch();
    const create = (notificationType: string) => dispatch(showNotificationActionCreator(notificationType));
    const reset = () => dispatch(resetNotificationActionCreator());

    return [notification, create, reset];
};
