import { Button, Checkbox, Tag, Tooltip } from "antd";
import * as React from "react";
import { ReactComponent as BlueBalloonIcon } from "../../../assets/blueballoon.svg";
import { ReactComponent as DashIcon } from "../../../assets/dash-rounded-24px.svg";
import { ReactComponent as WarningIcon } from "../../../assets/error_outline-24px.svg";
import { ReactComponent as RedBalloonIcon } from "../../../assets/redballoon.svg";
import { ReactComponent as RestIcon } from "../../../assets/waves-24px.svg";
import { ReactComponent as ToplosserIcon } from "../../../assets/toplosser.svg";
import { IContainer, IOrder, OrderType, State } from "../../../types/order.types";
import {
    formatField,
    formatIso8601Date,
    formatIso8601Time,
    optionalFormatIso8601DateTime,
    valOrInfoFieldDefaultVal
} from "../../../utils/format";
import { commentPresent, restFound } from "../../../utils/order";
import Icon, { BorderHorizontalOutlined, ToolOutlined } from "@ant-design/icons";
import { defineMessages, IntlShape } from "react-intl";

export const storageDaysRenderer = formatField;

export const itactRenderer = (actionRequired: boolean) => {
    return (
        <Checkbox checked={actionRequired} disabled/>
    );
};

export const csActionRenderer = (actionRequired: any) => {
    return actionRequired ? <Icon component={WarningIcon}/> : <Icon component={DashIcon}/>;
};

const COMMENT_MESSAGES = defineMessages({
    baffles: {
        id: "comment_renderer.baffles",
        defaultMessage: "Keerschotten",
    },
    restFound: {
        id: "comment_renderer.rest_found",
        defaultMessage: "Rest gevonden",
    },
    commentPresent: {
        id: "comment_renderer.comment_present",
        defaultMessage: "Commentaar aanwezig",
    },
    beforeLeaveMessage: {
        id: "comment_renderer.before_leave_message",
        defaultMessage: "Bericht voor vertrek",
    },
    manualWork: {
        id: "comment_renderer.manual_work",
        defaultMessage: "Manueel werk",
    },
    toplosser: {
        id: "comment_renderer.toplosser",
        defaultMessage: "Toplosser",
    }
});
export const commentRenderer = (text: string, record: IOrder, intl: IntlShape) => {
    return (
        <>
            {record.toplosser && <Tooltip className="icon-tooltip-table" title={intl.formatMessage(COMMENT_MESSAGES.toplosser)} placement="left"><Icon className="table-icon" component={ToplosserIcon}/></Tooltip>}
            {record.baffles && <Tooltip className="icon-tooltip-table" title={intl.formatMessage(COMMENT_MESSAGES.baffles)} placement="left"><BorderHorizontalOutlined className="table-icon table-icon-baffles" /></Tooltip>}
            {restFound(record) && <Tooltip className="icon-tooltip-table" title={intl.formatMessage(COMMENT_MESSAGES.restFound)} placement="left"><Icon className="table-icon" component={RestIcon} /></Tooltip>}
            {commentPresent(record) && <Tooltip className="icon-tooltip-table" title={intl.formatMessage(COMMENT_MESSAGES.commentPresent)} placement="left"><Icon className="table-icon" component={BlueBalloonIcon} /></Tooltip>}
            {record.beforeLeaveMessage && <Tooltip className="icon-tooltip-table" title={intl.formatMessage(COMMENT_MESSAGES.beforeLeaveMessage)} placement="left"><Icon className="table-icon" component={RedBalloonIcon} /></Tooltip>}
            {record.manualWorkNeeded && <Tooltip className="icon-tooltip-table" title={intl.formatMessage(COMMENT_MESSAGES.manualWork)} placement="left"><ToolOutlined className="table-icon table-icon-manual-work" /></Tooltip>}
        </>
    );
};

export const tableDateTimeRenderer = (instantStr?: string) => valOrInfoFieldDefaultVal(optionalFormatIso8601DateTime(instantStr));

const CLEAN_BUTTON_MESSAGES = defineMessages({
    cleanButton: {
        id: "button_renderer.clean_button",
        defaultMessage: "Reinig",
    }
});
export const tableButtonToCleanRenderer = (text: string, record: IOrder, intl: IntlShape, onClick: (record: any) => void) => {
    const isSpecificType = record.orderType === OrderType.MANNED_CLEANING || record.orderType === OrderType.WAGON || record.orderType === OrderType.IBC || record.orderType === OrderType.HOSE;
    const isCorrectState = record.state === State.PLANNED;

    return <>{isCorrectState && isSpecificType ? <Button onClick={() => onClick(record)}>{intl.formatMessage(CLEAN_BUTTON_MESSAGES.cleanButton)}</Button> : "-"}</>;
};

export const tableDateRenderer = (instantStr?: string) => valOrInfoFieldDefaultVal(formatIso8601Date(instantStr));
export const tableTimeRenderer = (instantStr?: string) => valOrInfoFieldDefaultVal(formatIso8601Time(instantStr));

export const containerLocationRenderer = (container: IContainer) => valOrInfoFieldDefaultVal(container.location && container.location.location);
export const tankNameTableRenderer = (tankName: string) => <Tag color="blue" style={{ fontSize: "0.8rem", backgroundColor: "#e2e1e1" }}>{tankName}</Tag>;
