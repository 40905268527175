import * as React from "react";
import { useEffect } from "react";
import { Button, Form, Select } from "antd";
import { defineMessages, useIntl } from "react-intl";
import { ISalesOrder, ISalesOrderListFetchParams } from "../../../types/salesOrder.types";
import { ISlot } from "../../../types/slot.types";
import { SelectValue } from "antd/lib/select";
import { ALL_ORDER_TYPES } from "../../../constants/orderTypes";

interface IProps {
    form: any;
    salesOrderList: ISalesOrder[];
    // fetchSalesOrder: (reference: string, bookingType: BookingType) => void;
    assignSelectedSlot: (selectedSlot: ISlot | null) => void;
    fetchSalesOrderList: (salesOrderListFetch: ISalesOrderListFetchParams) => void;
    assignSlots: (slots: ISlot[]) => void;
    assignSalesOrder: (salesOrder: ISalesOrder | null) => void;
    // assignTank: (tank: ITank | null) => void;
    // assignTankList: (tank: ITank[]) => void;
    // tank: ITank | null;
    fetchTank: (id: number) => void;
    initialSalesOrder: ISalesOrder | null;
    resetUrl: () => void;
    // assignBooking: (booking: IBooking) => void;
    // assignDependsOnBooking: (booking: IBooking | null) => void;
}

const MESSAGES = defineMessages({
    reference: {
        id: "sale_order_section.number",
        defaultMessage: "Order nummer",
    },
    referenceRequired: {
        id: "sale_order_section.number_required",
        defaultMessage: "Zoek een order",
    },
    orderTypeRequired: {
        id: "sale_order_section.order_type_required",
        defaultMessage: "Vul een order type in",
    },
    orderType: {
        id: "sale_order_section.order_type",
        defaultMessage: "Order type",
    },
    search: {
        id: "sale_order_section.search",
        defaultMessage: "Zoek order",
    },
});

export const SlotBookingSalesOrderForm = (props: IProps) => {
    const { getFieldDecorator } = props.form;
    const intl = useIntl();

    const clearState = () => {
        props.assignSelectedSlot(null);
        // props.assignTank(null);
        // props.assignTankList([]);
        props.assignSlots([]);
        // props.assignDependsOnBooking(null);
        props.assignSalesOrder(null);
    };

    const onSubmit = () => {
        // when searching a new order, make sure the URL doesn't contain edit parameters anymore
        props.resetUrl();
    };

    // const onChange = (value: SelectValue) => {
    //     if (value.toString().length >= AUTOCOMPLETE_MINIMUM_LENGTH.SALES_ORDER) {
    //         const fetchParams: ISalesOrderListFetchParams = { reference: value.toString() };
    //         props.fetchSalesOrderList(fetchParams);
    //     }
    // };

    const onChangeBookingType = (value: SelectValue) => {
        if (value) {
            // const bookingType = formatEnumString(value.toString());
            clearState();
            // props.assignBooking({ type: BookingType[bookingType as keyof typeof BookingType] });
        }
    };

    // const renderOption = (item: ISalesOrder) => {
    //     return (
    //         <AutoComplete.Option key={item.reference}>
    //             {item.reference}
    //         </AutoComplete.Option>
    //     );
    // };

    useEffect(() => {
        // props.assignBooking({ type: BookingType.SINGLE });
    }, []);

    return (
        <Form onFinish={onSubmit} layout="vertical">
            {/*<Form.Item label={intl.formatMessage(MESSAGES.reference)}>*/}
            {/*    {getFieldDecorator("reference", {*/}
            {/*        initialValue: props.initialSalesOrder?.reference,*/}
            {/*        rules: [{*/}
            {/*            required: true,*/}
            {/*            message: intl.formatMessage(MESSAGES.referenceRequired)*/}
            {/*        }],*/}
            {/*    })(<AutoComplete*/}
            {/*        dataSource={props.salesOrderList.map(renderOption)}*/}
            {/*        placeholder={intl.formatMessage(MESSAGES.referenceRequired)}*/}
            {/*        onChange={onChange}*/}
            {/*    />)}*/}
            {/*</Form.Item>*/}
            <Form.Item label={intl.formatMessage(MESSAGES.orderType)}>
                {getFieldDecorator("orderType", {
                    // initialValue: BookingType.SINGLE,
                    rules: [{
                        required: true,
                        message: intl.formatMessage(MESSAGES.orderTypeRequired)
                    }],
                })(
                    <Select allowClear onChange={onChangeBookingType}>
                        {ALL_ORDER_TYPES.map(type =>
                            <Select.Option key={type} value={type}>
                                {type}
                            </Select.Option>)}
                    </Select>
                )}
            </Form.Item>
            <Form.Item>
                <Button htmlType="submit">
                    {intl.formatMessage(MESSAGES.search)}
                </Button>
            </Form.Item>
        </Form>
    );
};
