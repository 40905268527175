import { createAction } from "@reduxjs/toolkit";
import { IDocumentsPerCheckIn } from "../../types/document.types";

enum DocumentsActionTypes {
  FETCH_DOCUMENTS_FOR_CHECKIN = "[DOCUMENTS] FETCH DOCUMENTS FOR CHECKIN",
  FETCH_DOCUMENTS_FOR_CHECKIN_FAILED = "[DOCUMENTS] FETCH DOCUMENTS FOR CHECKIN_FAILED",
  SET_DOCUMENTS_FOR_CHECKIN = "[DOCUMENTS] SET DOCUMENTS FOR CHECKIN"
}

export const documentsForCheckinFetchActionCreator = createAction<number>(DocumentsActionTypes.FETCH_DOCUMENTS_FOR_CHECKIN);
export const documentsForCheckinFetchFailedActionCreator = createAction<boolean>(DocumentsActionTypes.FETCH_DOCUMENTS_FOR_CHECKIN_FAILED);
export const documentsForCheckinSetActionCreator = createAction<IDocumentsPerCheckIn>(DocumentsActionTypes.SET_DOCUMENTS_FOR_CHECKIN);
