import { PayloadAction } from "@reduxjs/toolkit";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { TableEventListeners } from "antd/es/table";
import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import { ICheckInFilter, IPage, IPageRequest, ISort, Paths } from "../../../../types/api.types";
import { CheckInReason, CheckInState, ExtendedCheckInState, ICheckIn } from "../../../../types/checkIn.types";
import { CHECK_IN_REASON_MESSAGES, formatCheckInType } from "../../../../utils/format";
import { ItemTable } from "../ItemTable";
import { InfoField } from "../../modals/customerservicemodal/panes/detailinfopane/sections/infofields/InfoField";
import {
    assignedToColumn,
    checkInStatusColumn,
    completedOnColumn,
    customerReferenceColumn,
    expandableMarginLeft,
    extendedCheckInStatusColumn,
    firstNameColumn,
    lastNameColumn,
    operationColumn,
    productName,
    remarkColumn,
    tankColumn,
    terminalColumn,
    timeSlotColumn,
    transportCompanyColumn
} from "./CheckInColumns";
import { Table } from "antd";
import { IProductionOrder, ISalesOrder } from "../../../../types/salesOrder.types";
import { filterOutBlendComponentsInProductionOrder } from "../../../../utils/order";
import { CONFIG } from "../../../../config/config";
import { useLocation } from "react-router-dom";

interface IProps {
    page?: IPage<ICheckIn>;
    fetchingPage: boolean;
    sorting?: ISort;
    filter?: ICheckInFilter;
    fetchPage: (pageRequest: IPageRequest<ICheckInFilter>) => PayloadAction<IPageRequest<ICheckInFilter>>;
    onRow: (record: ICheckIn, index: number) => TableEventListeners;
    readonly: boolean;
}

export const MESSAGES = defineMessages({
    multiOrder: {
        id: "check_in_table.multiple",
        defaultMessage: "Multi order"
    },
    multipleProducts: {
        id: "check_in_table.multiple_products",
        defaultMessage: "Meerdere producten"
    }
});

export const CheckInOverviewTable = (props: IProps) => {
    const intl = useIntl();
    const { pathname } = useLocation();

    const getMultiProductionOrders = (checkIn: ICheckIn) => {
        if (checkIn.checkInReason === CheckInReason.WRAPPED) {
            return checkIn.salesOrders && checkIn.salesOrders.length > 1 && checkIn.salesOrders.flatMap(s => s.productionOrders.filter(filterOutBlendComponentsInProductionOrder)) || [];
        } else {
            return checkIn.salesOrders && checkIn.salesOrders.flatMap(s => s.productionOrders.filter(filterOutBlendComponentsInProductionOrder)) || [];
        }
    };

    const ciRenderer = (renderer: (v: string, r: ICheckIn) => any) => {
        return (val: string, record: ICheckIn) => {
            if (getMultiProductionOrders(record).length > 1) {
                return intl.formatMessage(MESSAGES.multiOrder);
            }

            return renderer(val, record);
        };
    };

    const ciCustomerReferenceRenderer = (val: string, record: ICheckIn) => {
        const customerReference = record.salesOrders &&
            record.salesOrders[0] &&
            (record.salesOrders[0].customerReference || record?.salesOrders[0].containerNumber);

        return <InfoField className="truncated-text" value={customerReference}/>;
    };

    const ciOperationRenderer = (val: string) => {
        if (val && val !== "" && Object.values(CheckInReason).includes(val as CheckInReason)) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return <InfoField value={intl.formatMessage(CHECK_IN_REASON_MESSAGES[val])} />;
        }

        return <InfoField value="-" />;
    };

    const ciTankRenderer = (val: string, record: ICheckIn) => {
        const tank = (record.salesOrders &&
            record.salesOrders[0] &&
            record.salesOrders[0].productionOrders[0]?.tank) ?? undefined;

        return <InfoField value={tank}/>;
    };

    const ciProductRenderer = (val: string, record: ICheckIn) => {
        let productName;
        if (record.checkInReason === CheckInReason.WRAPPED && record.salesOrders && record.salesOrders[0] && record.salesOrders[0].productionOrders.length > 1) {
            productName = intl.formatMessage(MESSAGES.multipleProducts);
        } else {
            productName = (record.salesOrders &&
                record.salesOrders[0] &&
                record.salesOrders[0].productionOrders[0]?.product?.materialDescription) ?? undefined;
        }
        return <InfoField className="truncated-text" value={productName}/>;
    };

    const findSalesOrder = (productionOrder: IProductionOrder): ISalesOrder | undefined => {
        if (!props.page) {
            return;
        }

        return props.page.content
            .flatMap((c) => c.salesOrders)
            .find(s => s?.productionOrders.find(p => p.id === productionOrder.id) !== undefined);
    };

    const findCheckIn = (productionOrder: IProductionOrder): ICheckIn | undefined => {
        if (!props.page) {
            return;
        }

        return props.page.content
            .find((c) => c.salesOrders?.flatMap((s) => s.productionOrders).find((p) => p.id === productionOrder.id));
    };

    const poCustomerReferenceRenderer = (val: string, record: IProductionOrder, multiRecord?: boolean) => {
        const salesOrder: ISalesOrder | undefined = findSalesOrder(record);
        const customerReference = salesOrder?.customerReference || salesOrder?.containerNumber;

        return <InfoField styles={multiRecord ? expandableMarginLeft : undefined} value={customerReference}/>;
    };

    const poOperationRenderer = (val: string, record: IProductionOrder) => {
        const checkIn: ICheckIn | undefined = findCheckIn(record);
        const salesOrder: ISalesOrder | undefined = findSalesOrder(record);

        const result = checkIn?.checkInReason && formatCheckInType(intl, checkIn.checkInReason, salesOrder);

        return <InfoField value={result}/>;
    };

    const poTankRenderer = (val: string, record: IProductionOrder) => {
        return <InfoField value={record.tank}/>;
    };

    const poProductNameRenderer = (val: string, record: IProductionOrder) => {
        return <InfoField value={record.product.materialDescription}/>;
    };

    const columnFactory = (onFilter: (filter: ICheckInFilter) => any) => {
        const defaultStatusFilterValue = () : CheckInState[] => {
            return CONFIG.defaultCheckinFilters.checkInState;
        };

        const defaultExtendedStatusFilterValue = () : ExtendedCheckInState[] => {
            if (pathname.includes(Paths.CHECKIN_ARCHIVED)) {
                return CONFIG.defaultArchivedCheckinFilters.extendedCheckInState;
            }
            return CONFIG.defaultExtendedCheckinFilters.extendedCheckInState;
        };

        return props.readonly ? [
            transportCompanyColumn(intl, true, onFilter, props.filter),
            firstNameColumn(intl, true, onFilter, props.filter),
            lastNameColumn(intl, true, onFilter, props.filter),
            timeSlotColumn(intl),
            operationColumn<ICheckIn>(intl, ciOperationRenderer),
            checkInStatusColumn(intl, onFilter, props.filter, defaultStatusFilterValue()),
        ] : [
            remarkColumn(),
            transportCompanyColumn(intl, true, onFilter, props.filter),
            firstNameColumn(intl, true, onFilter, props.filter),
            lastNameColumn(intl, true, onFilter, props.filter),
            timeSlotColumn(intl),
            completedOnColumn(intl),
            customerReferenceColumn<ICheckIn>(intl, ciRenderer(ciCustomerReferenceRenderer), onFilter, props.filter),
            operationColumn<ICheckIn>(intl, ciOperationRenderer),
            tankColumn(intl, ciRenderer(ciTankRenderer), onFilter, props.filter),
            productName(intl, ciRenderer(ciProductRenderer), onFilter, props.filter),
            terminalColumn(intl),
            extendedCheckInStatusColumn(intl, onFilter, props.filter, defaultExtendedStatusFilterValue()),
            assignedToColumn(intl, onFilter, props.filter),
        ];
    };

    const expandedRowRender = (record: ICheckIn) => {
        return (
            <div className="padding">
                <Table
                    dataSource={getMultiProductionOrders(record)}
                    pagination={false}
                    size="small"
                >
                    {
                        [
                            customerReferenceColumn<IProductionOrder>(intl, poCustomerReferenceRenderer, undefined,undefined, true),
                            operationColumn<IProductionOrder>(intl, poOperationRenderer, true),
                            tankColumn<IProductionOrder>(intl, poTankRenderer, undefined,undefined,true),
                            productName<IProductionOrder>(intl, poProductNameRenderer, undefined,undefined,true)
                        ]
                    }
                </Table>
            </div>
        );
    };

    const shouldHighlight = (checkIn: ICheckIn) =>
        !props.readonly && (checkIn.overridden || checkIn.maxWeightExceeded || checkIn.salesOrders?.some(salesOrder => salesOrder.temperatureNotKnown) || checkIn.adrExpired);

    const rowExpandable = (checkIn: ICheckIn) => (getMultiProductionOrders(checkIn).length || 0) > 1;

    return (
        <ItemTable
            data={props.page?.content || []}
            pageMeta={props.page?.meta}
            fetching={false}
            fetch={props.fetchPage}
            columnFactory={columnFactory}
            onRow={props.onRow}
            filter={props.filter}
            expandable={{
                expandedRowRender,
                rowExpandable,
            }}
            rowClassName={(record: ICheckIn) => shouldHighlight(record) ? "check-in-table-row-highlighted" : ""}
        />
    );
};
