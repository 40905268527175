import { Modal } from "antd";
import { ModalProps } from "antd/lib/modal";
import * as React from "react";
import { CleaningModalFooter } from "./CleaningModalFooter";

interface IProps extends ModalProps {
    closeModal: () => void;
    footer?: React.ReactNode;
    closable?: boolean;
}

export const CleaningModal = (props: React.PropsWithChildren<IProps>) => {
    return (
        <Modal
            {...props}
            className="cleaningmodal"
            onCancel={props.closeModal}
            destroyOnClose
            centered
            closable={!!props.closable}
            footer={props.footer || <CleaningModalFooter onOk={props.closeModal}/>}
        >
            {props.children}
        </Modal>
    );
};
