import { Button, Col, Row, Tooltip } from "antd";
import * as React from "react";
import { useIntl } from "react-intl";
import {
    formatBoolField,
    formatCheckInState,
    formatOrderAction,
    optionalFormatIso8601DateTime
} from "../../../../../../utils/format";
import { InfoField } from "../../../customerservicemodal/panes/detailinfopane/sections/infofields/InfoField";
import { InfoLabel } from "../../../customerservicemodal/panes/detailinfopane/sections/infofields/InfoLabel";
import { InfoHover } from "./InfoHover";
import { IManualOrder, IProductionOrder } from "../../../../../../types/salesOrder.types";
import { ICheckIn } from "../../../../../../types/checkIn.types";
import { useDispatch } from "react-redux";
import { checkInSetViewSalesOrderLinkModalSetManualOrderIdActionCreator } from "../../../../../../store/actions/checkIn";
import { WeightCalculationWarning } from "../../WeightCalculationWarning";
import { ExemptAdrDisplay } from "../../../../displays/ExemptAdrDisplay";
import { getFirstTimeSlot } from "../../../../../../utils/order";
import { ReactComponent as Warning } from "../../../../../../assets/warning-yellow.svg";
import { LinkOutlined } from "@ant-design/icons";

interface IProps {
  cancelReason?: string;
  productionOrder?: IProductionOrder;
  manualOrder: IManualOrder;
  checkIn: ICheckIn;
  MESSAGES: any;
}

export const ManualOrderInfo = (props: IProps) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const linkedSalesOrder = props.checkIn.salesOrders?.find((salesOrder) => salesOrder.manualOrderId === props.manualOrder.id);

    // modal itself is rendered in CheckInDetailModalContainer
    const onLinkOrEditSalesOrder = () => dispatch(checkInSetViewSalesOrderLinkModalSetManualOrderIdActionCreator(props.manualOrder.id));

    const ifNotSameReturnStrikethrough = (value: string | undefined | null, originalValue: string) => {
        if (!value || value === originalValue) {
            return <InfoField value={originalValue}/>;
        }
        return (
            <div className="flex-column">
                <del>{originalValue}</del>
                <InfoField value={value}/>
            </div>
        );
    };

    const getQuantityFormat = (quantity: number) => new Intl.NumberFormat("de-DE").format(quantity);

    return (
        <div>
            <Row className="margin-bottom">
                <Col span={12}>
                    <InfoLabel text={intl.formatMessage(props.MESSAGES.orderType)}/>
                    {ifNotSameReturnStrikethrough(linkedSalesOrder && formatOrderAction(linkedSalesOrder.orderAction, intl), formatOrderAction(props.manualOrder.manualOrderAction, intl))}
                </Col>
                <Col span={12}>
                    <InfoLabel text={intl.formatMessage(props.MESSAGES.state)}/>
                    <div>
                        {formatCheckInState(props.checkIn.checkInState, intl)}
                        <InfoHover information={props.checkIn.cancelReason} />
                    </div>
                </Col>
            </Row>
            <Row className="margin-bottom">
                <Col span={12}>
                    <InfoLabel text={intl.formatMessage(props.MESSAGES.reference)}/>
                    {ifNotSameReturnStrikethrough(linkedSalesOrder?.reservationId, props.manualOrder.orderNumber)}
                    <div className="margin-half-top">
                        <Button onClick={onLinkOrEditSalesOrder} type={!linkedSalesOrder ? "primary" : "default"}>
                            <span><LinkOutlined className="margin-half-right" />
                                {intl.formatMessage(!linkedSalesOrder ? props.MESSAGES.linkSalesOrder : props.MESSAGES.editSalesOrder)}
                            </span>
                        </Button>
                    </div>
                </Col>
                {linkedSalesOrder &&
                <Col span={12}>
                    <>
                        <InfoLabel text={intl.formatMessage(props.MESSAGES.assignmentNumber)}/>
                        {linkedSalesOrder.productionOrders[0] &&
                          <InfoField value={linkedSalesOrder.productionOrders[0].sapReference}/>
                        }
                    </>
                </Col>
                }
            </Row>
            <Row className="margin-bottom">
                <Col span={12}>
                    <InfoLabel text={intl.formatMessage(props.MESSAGES.customer)}/>
                    {ifNotSameReturnStrikethrough(linkedSalesOrder?.customer.name, props.manualOrder.customer)}
                </Col>
                {linkedSalesOrder &&
                <Col span={12}>
                    <InfoLabel text={intl.formatMessage(props.MESSAGES.unNumber)}/>
                    {linkedSalesOrder.productionOrders[0] &&
                     <InfoField value={linkedSalesOrder.productionOrders[0].product.unNumber}/>
                    }
                </Col>
                }
            </Row>
            <Row className="margin-bottom">
                <Col span={12}>
                    <InfoLabel text={intl.formatMessage(props.MESSAGES.product)}/>
                    {
                        ifNotSameReturnStrikethrough(linkedSalesOrder?.productionOrders[0].product.materialDescription, props.manualOrder.product)
                    }
                </Col>
                <Col span={12}>
                    <InfoLabel text={intl.formatMessage(props.MESSAGES.customerReference)}/>
                    {ifNotSameReturnStrikethrough(linkedSalesOrder?.customerReference, props.manualOrder.orderNumber)}
                </Col>
            </Row>
            <Row className="margin-bottom">
                {linkedSalesOrder &&
                <Col span={12}>
                    <InfoLabel text={intl.formatMessage(props.MESSAGES.tank)}/>
                    {linkedSalesOrder.productionOrders[0] &&
                    <InfoField value={linkedSalesOrder.productionOrders[0].tank}/>
                    }
                </Col>
                }
                {linkedSalesOrder &&
                <Col span={12}>
                    <InfoLabel text={intl.formatMessage(props.MESSAGES.timeslot)}/>
                    <Row>
                        <InfoField value={optionalFormatIso8601DateTime(getFirstTimeSlot([linkedSalesOrder]))}/>
                        {linkedSalesOrder.overdue &&
                        <Tooltip title={intl.formatMessage(linkedSalesOrder.mailSent? props.MESSAGES.mailSent : props.MESSAGES.overDue)} placement="top">
                            <Warning className="margin-third-left" />
                        </Tooltip>
                        }
                    </Row>
                </Col>
                }
            </Row>
            <Row className="margin-bottom">
                <Col span={12}>
                    <InfoLabel text={intl.formatMessage(props.MESSAGES.amount)}/>
                    {linkedSalesOrder?.productionOrders[0] ?
                        <div className="flex-row align-items-end">
                            <div>
                                {ifNotSameReturnStrikethrough(getQuantityFormat(linkedSalesOrder.productionOrders[0].quantity), getQuantityFormat(props.manualOrder.quantity))}
                            </div>
                            <span className="margin-third-left">{linkedSalesOrder.productionOrders[0].quantityUnit}</span>
                            <WeightCalculationWarning/>
                        </div> :
                        <div>{getQuantityFormat(props.manualOrder.quantity)}</div>
                    }
                </Col>
                <Col span={12}>
                    <InfoLabel text="ADR"/>
                    {linkedSalesOrder?.productionOrders[0] ?
                        <>
                            {props.checkIn.exemptAdr ?
                                <ExemptAdrDisplay /> :
                                ifNotSameReturnStrikethrough(formatBoolField(linkedSalesOrder?.productionOrders[0].adr, intl), formatBoolField(props.manualOrder.adr, intl))
                            }
                        </> :
                        <>
                            {props.checkIn.exemptAdr ?
                                <ExemptAdrDisplay /> :
                                <InfoField value={formatBoolField(linkedSalesOrder?.productionOrders[0].adr, intl)}/>
                            }
                        </>
                    }
                </Col>
            </Row>
            <Row className="margin-bottom">
                <Col span={12}>
                    <InfoLabel text={intl.formatMessage(props.MESSAGES.destinationEu)}/>
                    <InfoField value={formatBoolField(props.manualOrder.destinationEu, intl)}/>
                </Col>
                {linkedSalesOrder &&
                    <Col span={12}>
                        <InfoLabel text={intl.formatMessage(props.MESSAGES.destination)}/>
                        <InfoField value={linkedSalesOrder.destination.countryCode}/>
                    </Col>
                }
            </Row>
            {typeof props.checkIn.temperatureProduct === "number" &&
                <Row className="margin-bottom">
                    <Col span={12}>
                        <InfoLabel text={intl.formatMessage(props.MESSAGES.temperatureProduct)}/>
                        <InfoField value={props.checkIn.temperatureProduct + intl.formatMessage(props.MESSAGES.degreesCelsius)}/>
                    </Col>
                </Row>
            }
        </div>
    );
};
