import * as React from "react";
import { Alert, Col, Modal, Row, Tooltip } from "antd";
import { defineMessages, useIntl } from "react-intl";
import { formatThousands, roundToTens } from "../../../../utils/format";
import { MarginCalculationDisplay } from "../../displays/MarginCalculationDisplay";
import { useSelector } from "react-redux";
import { ReactComponent as Warning } from "../../../../assets/warning-yellow.svg";
import {
    compartmentsRecordSelector,
    weightCalculationRecordSelector
} from "../../../../store/selectors/checkInOverview.selector";
import { WeightCalculationDecision } from "../../../../types/checkIn.types";

const MESSAGES = defineMessages({
    title: {
        id: "weight_calculation_modal.title",
        defaultMessage: "Gewicht berekening",
    },
    watchOut: {
        id: "margin_overview_form.watch_out",
        defaultMessage: "Uw order hoeveelheid zorgt ervoor dat u het maximum toegelaten gewicht overschrijdt en/of de compartimentering niet correct kan ingegeven worden.",
    },
    watchOutWithAdjustment: {
        id: "margin_overview_form.watch_out_width_adjustment",
        defaultMessage: "Uw order hoeveelheid zorgt ervoor dat u het maximum toegelaten gewicht overschrijdt en/of de compartimentering niet correct kan ingegeven worden. " +
            "Daarom wordt uw ordergewicht met {correction} KG van {totalBeforeCorrection} KG naar {totalWeight} KG aangepast, zodat " +
            "uw totaal gewicht binnen het maximum toegelaten gewicht valt van {maxAllowedWeight} KG en/of uw compartimentering correct kan ingegeven worden.",
    },
    watchOutTitle: {
        id: "margin_overview_form.watch_out_title",
        defaultMessage: "Opgelet!",
    },
    toleranceAdjusted: {
        id: "checkin.order_info.toleranceAdjusted",
        defaultMessage: "Hoeveelheid aangepast door tolerantie"
    },
    weightExceeded: {
        id: "checkin.order_info.weightExceeded",
        defaultMessage: "Gewicht overschreden"
    }
});
export const WeightCalculationWarning = () => {
    const intl = useIntl();
    const weightCalculation = useSelector(weightCalculationRecordSelector);
    const compartments = useSelector(compartmentsRecordSelector);
    const compartmentTolerance = compartments[0]?.toleranceLoadAmount || 0;
    const dontShowAdjustment = (weightCalculation?.totalOrderWeightWithCorrection || 0) + compartmentTolerance === 0;

    if (weightCalculation && (compartmentTolerance !=0 || weightCalculation.correction > 0)) {
        let totalCorrection = (compartmentTolerance * -1);
        if (weightCalculation.correction> 0) {
            totalCorrection = totalCorrection + weightCalculation.correction;
        }
        totalCorrection = Math.abs(totalCorrection);
        const config = {
            title: intl.formatMessage(MESSAGES.title),
            width: "1000px",
            icon: null,
            content: (
                <div className="margin-top">
                    <Alert
                        message={<span className="normal-text">{intl.formatMessage(MESSAGES.watchOutTitle)}</span>}
                        description={
                            <span className="normal-text">
                                {
                                    intl.formatMessage(dontShowAdjustment ? MESSAGES.watchOut : MESSAGES.watchOutWithAdjustment, {
                                        correction: formatThousands(roundToTens(totalCorrection)),
                                        totalBeforeCorrection: formatThousands(roundToTens(weightCalculation.totalOrderWeight)),
                                        totalWeight: formatThousands(roundToTens(weightCalculation.totalOrderWeightWithCorrection + compartmentTolerance)),
                                        maxAllowedWeight: formatThousands(weightCalculation.maxAllowedWeight)
                                    })
                                }
                            </span>
                        }
                        type="warning"
                        showIcon
                    />

                    <Row className="margin-top">
                        <Col span={12} style={{ paddingRight: 35 }}>
                            <MarginCalculationDisplay intl={intl} dataWeight={weightCalculation} dataCompartments={compartments} corrected={false} />
                        </Col>
                        {!dontShowAdjustment && (
                            <Col span={12}>
                                <MarginCalculationDisplay intl={intl} dataWeight={weightCalculation} dataCompartments={compartments} corrected />
                            </Col>
                        )}
                    </Row>
                </div>
            ),
        };

        return (
            <Tooltip title={intl.formatMessage(weightCalculation.decision === WeightCalculationDecision.OVERLOADED ? MESSAGES.weightExceeded : MESSAGES.toleranceAdjusted)} placement="top">
                <Warning onClick={() => Modal.warning(config)} className="margin-third-left" />
            </Tooltip>
        );
    } else {
        return null;
    }
};
