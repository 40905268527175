import { combineReducers, createReducer } from "@reduxjs/toolkit";
import { IOrderFilter, IPageResponse } from "../../types/api.types";
import { IOrder } from "../../types/order.types";
import {
    planningPageFetchFailedCreator,
    planningPageLoadCreator,
    planningPageReceivedCreator
} from "../actions/planning";

export interface IPlanningState {
    page: IPageResponse<IOrder, IOrderFilter> | null;
    loadingPage: boolean;
}

const pageReducer = createReducer<IPageResponse<IOrder, IOrderFilter> | null>(null, {
    [planningPageReceivedCreator.type]: (state, action) => action.payload,
});

const loadingPageReducer = createReducer<boolean>(false, {
    [planningPageReceivedCreator.type]: () => false,
    [planningPageLoadCreator.type]: () => true,
    [planningPageFetchFailedCreator.type]: () => false
});

export const planningReducer = combineReducers<IPlanningState>({
    page: pageReducer,
    loadingPage: loadingPageReducer
});
