import { getHttpClient } from "./httpClient";
import { URL_PATHS } from "../../constants/api.constants";

const baseUrl = URL_PATHS.CLEANING + "/customers";

const getCustomer = (customerId: number) => getHttpClient().get(`${baseUrl}/${customerId}`);

export const customerApi = {
    getCustomer,
};
