import { createSelector } from "@reduxjs/toolkit";
import { IPit } from "../../types/salesOrder.types";
import { ITankState } from "../reducers/tank";
import { IApplicationState } from "../state";

const tankStateSelector = (state: IApplicationState) => state.tank;
const pitListStateSelector = (state: IApplicationState) => state.pit.pitList;

export const tankIdListSelector = createSelector(
    pitListStateSelector,
    (pitList: IPit[]) => pitList.flatMap(pit => pit.tanks).map(tank => tank.id)
);

export const tankResourceSelector = createSelector(
    pitListStateSelector,
    (pitList: IPit[]) => pitList.map(
        pit => ({
            ...pit,
            tanks: pit.tanks.map(
                tank => ({
                    ...tank,
                    id: ""+tank.id,
                    pitId: pit.id
                }))
        }))
        .flatMap(pit => pit.tanks)
);

export const scheduledMaintenancesSelector = createSelector(
    tankStateSelector,
    (tankState: ITankState) => tankState.scheduledMaintenances
);
