import * as React from "react";
import { useEffect } from "react";
import { ICompartment, IOrder } from "../../types/order.types";
import { Checklist } from "../components/tabbars/cleaningtabs/cleaningtab/compartmenttab/checklist/Checklist";
import { useChecklist } from "../hooks/useChecklist";

interface IProps {
    className?: string;
    order: IOrder;
    compartment: ICompartment;
}

export const ChecklistContainer = (props: IProps) => {
    const [checklists, fetchingChecklists, fetchChecklist] = useChecklist();

    useEffect(() => {
        fetchChecklist(props.compartment.id);
    }, []);

    return (
        <Checklist
            className={props.className}
            compartment={props.compartment}
            order={props.order}
            checklists={checklists}
            fetchingChecklists={fetchingChecklists}
        />
    );
};
