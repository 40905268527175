import { combineReducers, createReducer } from "@reduxjs/toolkit";
import { ITank } from "../../types/salesOrder.types";
import { IScheduledMaintenance } from "../../types/scheduledMaintenance.types";
import {
    tankListReceivedCreator,
    tankReceivedCreator,
    tankScheduledMaintenancesReceivedActionCreator
} from "../actions/tank";

export interface ITankState {
    tank: ITank | null;
    tankList: ITank[];
    scheduledMaintenances: IScheduledMaintenance[];
}

export const tankFetchReducer = createReducer<ITank | null>(null, {
    [tankReceivedCreator.type]: (tank, action) => action.payload,
});

export const tankListReducer = createReducer<ITank[]>([], {
    [tankListReceivedCreator.type]: (tankList, action) => action.payload,
});

export const scheduledMaintenancesReducer = createReducer<IScheduledMaintenance[]>([], (builder) => {
    builder.addCase(tankScheduledMaintenancesReceivedActionCreator, (state, action) => {
        return action.payload;
    });
});

export const tankReducer = combineReducers<ITankState>({
    tank: tankFetchReducer,
    tankList: tankListReducer,
    scheduledMaintenances: scheduledMaintenancesReducer,
});
