import { applyMiddleware, createStore } from "redux";
import createSagaMiddleware from "redux-saga";

import { rootReducer } from "./reducers";
import { rootSaga } from "./sagas";
import { composeWithDevTools } from "@redux-devtools/extension";

// Setup saga middleware
const sagaMiddleware = createSagaMiddleware();

// Central store
export const store = createStore(
    rootReducer,
    composeWithDevTools((applyMiddleware(sagaMiddleware)))
);

// Start sagas
sagaMiddleware.run(rootSaga);
