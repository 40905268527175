import { IOrderFilter } from "../types/api.types";
import { OrderType, State } from "../types/order.types";

const planningFilter: IOrderFilter = {
    state: [State.PLANNED, State.READY_FOR_CLEANING, State.CLEANING]
};

const ctrFilter: IOrderFilter = {
    ...planningFilter,
    orderType: OrderType.UNMANNED_CLEANING,
    restFound: false
};

const restFilter: IOrderFilter = {
    ...planningFilter,
    restFound: true
};

const rtcFilter: IOrderFilter = {
    ...planningFilter,
    orderType: OrderType.WAGON,
    restFound: false
};

const ibcFilter: IOrderFilter = {
    ...planningFilter,
    orderType: OrderType.IBC,
    restFound: false
};

const hoseFilter: IOrderFilter = {
    ...planningFilter,
    orderType: OrderType.HOSE,
    restFound: false
};

const mannedFilter: IOrderFilter = {
    ...planningFilter,
    orderType: OrderType.MANNED_CLEANING,
    restFound: false
};

export const ORDER_FILTERS = {
    CTR: ctrFilter,
    REST: restFilter,
    RTC: rtcFilter,
    IBC: ibcFilter,
    HOSE: hoseFilter,
    MANNED: mannedFilter
};
