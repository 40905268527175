import { Button } from "antd";
import * as React from "react";
import { defineMessages, useIntl } from "react-intl";

interface IProps {
    onOk: () => void;
}

const MESSAGES = defineMessages({
    close: {
        id: "cleaning_modal_footer.close",
        defaultMessage: "Sluiten"
    }
});

export const CleaningModalFooter = (props: IProps) => {
    const intl = useIntl();

    return (
        <div className="text-middle">
            <Button onClick={props.onOk} type="primary">
                {intl.formatMessage(MESSAGES.close)}
            </Button>
        </div>
    );
};
