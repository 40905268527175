import { EventContentArg } from "@fullcalendar/react";
import React from "react";
import { IBookedSlot } from "../../../types/bookedSlot.types";
import { TimeLineInformationContainer } from "../../containers/timelinecontainers/TimeLineInformationContainer";
import { EventApi } from "@fullcalendar/common";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
dayjs.extend(duration);

interface ICalendarEvent {
    args: EventContentArg;
    selectedBookedSlotId: number | undefined;
    bookedSlot?: IBookedSlot;
    modalVisible: boolean;
    setModalVisible: (isVisible: boolean) => void;
    clickedEvent: EventApi | undefined;
}

export const TimeLineEvent = (props: ICalendarEvent) => {
    const arrivalTimeString = props.bookedSlot ? props.bookedSlot.arrivalTime : "";
    const departureTimeString = props.bookedSlot ? props.bookedSlot.departureTime : "";

    const { start, end, id } = props.args.event;

    if (!end || !start) {
        throw new Error("A start and end time should be defined");
    }

    const totalTime = dayjs(end).diff(dayjs(start));
    const totalTimeInMinutes = totalTime / 1000 / 60;

    const arrivalDuration = dayjs.duration(arrivalTimeString);
    const departureDuration = dayjs.duration(departureTimeString);

    const arrivalPercentage = arrivalDuration.asMinutes() / totalTimeInMinutes * 100;
    const departurePercentage = departureDuration.asMinutes() / totalTimeInMinutes * 100;

    const tankTimePercentage = 100 - arrivalPercentage - departurePercentage;

    return (<TimeLineInformationContainer
        arrivalDuration={arrivalDuration}
        departureDuration={departureDuration}
        clickedEvent={props.clickedEvent}
        selectedBookedSlotId={props.selectedBookedSlotId}
        modalVisible={props.modalVisible}
        setModalVisible={props.setModalVisible}
        bookedSlotId={parseInt(id)}>
        <div className={props.bookedSlot && props.bookedSlot.internal ? "fc-timeline-event-internal" : "fc-timeline-event-external"}>
            <span className="fc-timeline-event-arrival-block" style={{ width: `${arrivalPercentage}%` }} />
            <span className="fc-timeline-event-operation-block" style={{ width: `${tankTimePercentage}%` }} />
            <span className="fc-timeline-event-departure-block" style={{ width: `${departurePercentage}%` }} />
        </div>
    </TimeLineInformationContainer>);
};
