import { ITank } from "../../types/salesOrder.types";
import { ITankListFetchParams } from "../../types/tank.types";
import { useDispatch, useSelector } from "react-redux";
import { IApplicationState } from "../../store/state";
import { tankListFetchCreator, tankListReceivedCreator } from "../../store/actions/tank";

export const useTankList = (): [
    ITank[],
    (tankListFetch: ITankListFetchParams) => void,
    (tanks: ITank[]) => void,
] => {
    const tankList = useSelector<IApplicationState, ITank[]>(state => state.tank.tankList);
    const dispatch = useDispatch();
    const fetch = (tankListFetchParams: ITankListFetchParams) => dispatch(tankListFetchCreator({ tankListFetchParams }));
    const assign = (tanks: ITank[]) => dispatch(tankListReceivedCreator(tanks));

    return [tankList, fetch, assign];
};
