import { createAction } from "@reduxjs/toolkit";
import { IFailedActionPayload } from "../../types/action.types";
import { ISlotBookingConfig } from "../../types/slot.types";

enum SlotBookingConfigActionTypes {
    SLOT_BOOKING_CONFIG_FETCH = "[SlotBookingConfig] FETCH",
    SLOT_BOOKING_CONFIG_RECEIVED = "[SlotBookingConfig] RECEIVED",
    SLOT_BOOKING_CONFIG_FETCH_FAILED = "[SlotBookingConfig] FETCH_FAILED",
}

export const slotBookingConfigFetchCreator = createAction<number>(SlotBookingConfigActionTypes.SLOT_BOOKING_CONFIG_FETCH);
export const slotBookingConfigReceivedCreator = createAction<ISlotBookingConfig>(SlotBookingConfigActionTypes.SLOT_BOOKING_CONFIG_RECEIVED);
export const slotBookingConfigFetchFailedCreator = createAction<IFailedActionPayload>(SlotBookingConfigActionTypes.SLOT_BOOKING_CONFIG_FETCH_FAILED);
