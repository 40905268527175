import { notification } from "antd";
import { IntlShape } from "react-intl";
import { defineMessages } from "react-intl";

interface IProps {
    intl: IntlShape;
    reset: () => void;
}

const MESSAGES = defineMessages({
    alreadyBookingSalesOrder: {
        id: "already_booking_sales_order_notification.message",
        defaultMessage: "Er is al een booking voor dit sales order"
    },
});

export const AlreadyBookingSalesOrderNotification = (props: IProps) => {
    notification.error({
        message: props.intl.formatMessage(MESSAGES.alreadyBookingSalesOrder),
        onClose: () => props.reset(),
        placement: "bottomRight"
    });
    return null;
};
