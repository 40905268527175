import React from "react";
import { IBookedSlot } from "../../../types/bookedSlot.types";
import { TimeLineInformationFooter } from "./TimeLineInformationFooter";
import { Popover } from "antd";
import { defineMessages, useIntl } from "react-intl";
import { extractTimeSlotString } from "../../../utils/mapping";
import dayjs from "dayjs";
import { Duration } from "dayjs/plugin/duration";

interface IProps {
    visible: boolean;
    bookedSlot: IBookedSlot;
    onConfirm: () => void;
    onCancel: () => void;
    onEdit: () => void;
    arrivalDuration: Duration;
    departureDuration: Duration;
}

const MESSAGES = defineMessages({
    status: {
        id: "timeline.popover.status",
        defaultMessage: "Status:",
    },
    approved: {
        id: "timeline.popover.approved",
        defaultMessage: "Goedgekeurd",
    },
    startSlot: {
        id: "timeline.popover.start_slot",
        defaultMessage: "Start slot:",
    },
    endSlot: {
        id: "timeline.popover.end_slot",
        defaultMessage: "Einde slot:",
    },
    atTank: {
        id: "timeline.popover.at_tank",
        defaultMessage: "Aan tank:",
    },
    finalCall: {
        id: "timeline.popover.final_call",
        defaultMessage: "Final call:",
    }
});

export const TimeLinePopover = (props: React.PropsWithChildren<IProps>) => {
    const intl = useIntl();

    const fromDate = extractTimeSlotString(props.bookedSlot.fromDate);
    const untilDate = extractTimeSlotString(props.bookedSlot.untilDate);
    const atTankStartTime = extractTimeSlotString(dayjs(props.bookedSlot.fromDate).add(props.arrivalDuration).format());
    const atTankEndTime = extractTimeSlotString(dayjs(props.bookedSlot.untilDate).subtract(props.departureDuration).format());
    const finalCall = extractTimeSlotString(dayjs(props.bookedSlot.fromDate).add(30, "minute").format());

    return (<Popover
        title={
            <>
                <div>{(props.bookedSlot.tank.customer ? props.bookedSlot.tank.customer.name + " - " : "") + props.bookedSlot.tank.name}</div>
                <div className="not-bold">{props.bookedSlot.tank.product}</div>
            </>
        }
        content={<>
            <ul className="list-align">
                {/*TODO: currently status is hard coded, since there is no way to retrieve it yet */}
                <li>{intl.formatMessage(MESSAGES.status)} <span className="align-right">{intl.formatMessage(MESSAGES.approved)}</span></li>
                <li>{intl.formatMessage(MESSAGES.startSlot)} <span className="align-right">{fromDate}</span></li>
                <li>{intl.formatMessage(MESSAGES.endSlot)} <span className="align-right">{untilDate}</span></li>
                <li>{intl.formatMessage(MESSAGES.atTank)} <span className="align-right">{atTankStartTime} - {atTankEndTime}</span></li>
                <li className="margin-bottom">{intl.formatMessage(MESSAGES.finalCall)} <span className="align-right">{finalCall}</span></li>
            </ul>
            <TimeLineInformationFooter
                onEdit={props.onEdit}
                onConfirm={props.onConfirm}
                onCancel={props.onCancel}
            />
        </>}
        visible={props.visible}
    >
        {props.children}
    </Popover>);
};
