import React, { useEffect } from "react";
import { customerServiceOrderUpdateDoneActionCreator } from "../../store/actions/customerservice";
import { IOrderUpdateDto } from "../../types/api.types";
import { OrderInfo } from "../components/modals/customerservicemodal/panes/detailinfopane/OrderInfo";
import { useNotification } from "../hooks/useNotification";
import { useOrder } from "../hooks/useOrder";
import { useOrderUpdate } from "../hooks/useOrderUpdate";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

interface IProps {
    orderId: number;
}

export const OrderInfoContainer = (props: IProps) => {
    const [order,, loadOrder] = useOrder();
    const [update, ordersBeingSaved] = useOrderUpdate(customerServiceOrderUpdateDoneActionCreator);
    const [, createNotification] = useNotification();

    useEffect(() => {
        loadOrder(props.orderId);
    }, []);

    const onDateParkingInSave = (m: Dayjs | null) => {
        if (!m) {
            return;
        }

        const orderUpdateDto: IOrderUpdateDto = {
            dateParkingIn: dayjs.utc(m).format()
        };

        update(props.orderId, orderUpdateDto);
    };

    const onPrioritySave = (m: Dayjs | null) => {
        const orderUpdateDto: IOrderUpdateDto = {
            priority: m && dayjs.utc(m).startOf("minute").format()
        };

        update(props.orderId, orderUpdateDto);
    };

    const onDateParkingOutSave = (m: Dayjs | null) => {
        if (!m) {
            return;
        }
        const orderUpdateDto: IOrderUpdateDto = {
            dateParkingOut: dayjs.utc(m).format()
        };

        update(props.orderId, orderUpdateDto);
    };

    return (
        order &&
        <OrderInfo
            order={order}
            onDateParkingInSave={onDateParkingInSave}
            onPrioritySave={onPrioritySave}
            savingDateParkingIn={ordersBeingSaved[props.orderId] && !!ordersBeingSaved[props.orderId].dateParkingIn}
            savingPriority={ordersBeingSaved[props.orderId] && !!ordersBeingSaved[props.orderId].priority}
            onDateParkingOutSave={onDateParkingOutSave}
            savingDateParkingOut={ordersBeingSaved[props.orderId] && !!ordersBeingSaved[props.orderId].dateParkingOut}
            createNotification={createNotification}
            isInternalView={true}
        />
    );
};
