import * as React from "react";
import { AppLayout } from "./AppLayout";
import { defineMessages, useIntl } from "react-intl";
import { UploadArea } from "../components/areas/UploadArea";
import { buildUrl, URL_PATHS } from "../../constants/api.constants";

const MESSAGES = defineMessages({
    freeStorageUploadArea: {
        id: "settings.free_storage_days.upload_area.hint",
        defaultMessage: "Selecteer een CSV bestand om te uploaden met de vrije opslag dagen.",
    },
    itactUploadArea: {
        id: "settings.itact_needed.upload_area.hint",
        defaultMessage: "Selecteer een CSV bestand om te uploaden met de itact needed.",
    },
    tolerancesUploadArea: {
        id: "settings.itact_needed.upload_area.hint",
        defaultMessage: "Selecteer een CSV bestand om te uploaden met de tolerances.",
    },
    archiveSalesOrdersUploadArea: {
        id: "settings.sales_order.upload_area.hint",
        defaultMessage: "Selecteer een CSV bestand om te uploaden met de te archiveren sales orders.",
    },
    weightBadgeTankUploadArea: {
        id: "settings.weight_badge_tank.upload_area.hint",
        defaultMessage: "Selecteer een CSV bestand om te uploaden met de tanks waarvoor een weegbadge vereist is.",
    },
    certificateUploadArea: {
        id: "settings.certificate.upload_area.hint",
        defaultMessage: "Selecteer een CSV bestand om te uploaden met de materialen waarvoor een certificaat vereist is.",
    },
    requiredFieldsTitle: {
        id: "settings.itact_needed.explain",
        defaultMessage: "Volgende velden zijn verplicht in de CSV",
    },
});

interface IProps {
  type: "itact" | "tolerances" | "freeStorage" | "archiveSalesOrders" | "weightBadgeTank"| "certificate";
}

export const UploadConfigScreen = (props: IProps) => {
    const intl = useIntl();

    const getUploadMessage = () => {
        switch (props.type) {
        case "freeStorage":
            return intl.formatMessage(MESSAGES.freeStorageUploadArea);
        case "itact":
            return intl.formatMessage(MESSAGES.itactUploadArea);
        case "tolerances":
            return intl.formatMessage(MESSAGES.tolerancesUploadArea);
        case "archiveSalesOrders":
            return intl.formatMessage(MESSAGES.archiveSalesOrdersUploadArea);
        case "weightBadgeTank":
            return intl.formatMessage(MESSAGES.weightBadgeTankUploadArea);
        case "certificate":
            return intl.formatMessage(MESSAGES.certificateUploadArea);
        }
    };

    const getBuildUrlParams = () => {
        switch (props.type) {
        case "freeStorage":
            return [URL_PATHS.CLEANING_CUSTOMERS, "free-days-config-import"];
        case "itact":
            return [URL_PATHS.CLEANING_CUSTOMERS, "itact-config-import"];
        case "tolerances":
            return [URL_PATHS.ADMIN, "customer-tolerance", "import"];
        case "archiveSalesOrders":
            return [URL_PATHS.BOOKING, "sales-orders","archive"];
        case "weightBadgeTank":
            return [URL_PATHS.CHECKIN, "weight-badge-tank","tanks"];
        case "certificate":
            return [URL_PATHS.CHECKIN, "certificate","import"];
        }
    };

    const getRequiredFieldsLabels = () :string[] => {
        switch (props.type) {
        case "freeStorage":
            return ["name", "sapId", "freeDaysAmount"];
        case "itact":
            return ["name", "sapId", "itactNeeded"];
        case "tolerances":
            return ["partnerId", "weightTolerance", "compartmentsTolerance"];
        case "archiveSalesOrders":
            return ["sapReference"];
        case "weightBadgeTank":
            return ["tank"];
        case "certificate":
            return ["name","orderType", "orderAction", "materialCode"];
        }
    };

    return (
        <AppLayout>
            <div style={{ minHeight: 250 }}>
                <UploadArea
                    formattedMessage={getUploadMessage()}
                    action={buildUrl(...getBuildUrlParams())}
                />
            </div>

            <p>
                <strong>{intl.formatMessage(MESSAGES.requiredFieldsTitle)}:</strong>
            </p>
            <ul>
                {getRequiredFieldsLabels().map((label) => (<li key={label}>{label}</li>))}
            </ul>
        </AppLayout>
    );
};

