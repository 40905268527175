import { createSelector } from "@reduxjs/toolkit";
import { IApplicationState } from "../state";
import { IAuthenticationState } from "../reducers/authentication";

const authenticationSelector = (state: IApplicationState) => state.authentication;

export const nameSelector = createSelector(
    authenticationSelector,
    (authenticationState: IAuthenticationState) => authenticationState.name || ""
);


export const isAssignedToMeSelector = (assignedTo: string | null) => {
    return createSelector(
        nameSelector,
        (name: string) => name !== null && name === assignedTo
    );
};
