import { IOrderFilter, IOrderUpdateDto, IPageRequest } from "../../types/api.types";
import { getHttpClient } from "./httpClient";
import { formatPageQueryParams } from "../../utils/format";
import { URL_PATHS } from "../../constants/api.constants";

const baseUrl = URL_PATHS.CLEANING + "/orders/";

const getPage = (queryParams: string) => getHttpClient().get(`${baseUrl}${queryParams}`);
const getOrder = (orderId: number) => getHttpClient().get(`${baseUrl}${orderId}`);
const getTransitions = (orderId: number) => getHttpClient().get(`${baseUrl}${orderId}/state-transitions`);
const update = (orderId: number, orderUpdateDto: IOrderUpdateDto) => getHttpClient().put(`${baseUrl}${orderId}`, orderUpdateDto);
const getCsvDownloadAllSelectedOrders = (pageRequest: IPageRequest<IOrderFilter>) => getHttpClient().get(`${baseUrl}csv${formatPageQueryParams(pageRequest)}`, { timeout: 60000 });
const getCsvDownloadOnlyFinishedOrders = () => getHttpClient().get(`${baseUrl}done-cleaning-orders-csv`, { timeout: 60000 });
const getListAsHtml = (queryParams: string) => {
    const definedConfig = { headers: { "Accept": "text/html" } };
    return getHttpClient().get(`${baseUrl}list${queryParams}`, definedConfig);
};
const getItactCsv = (pageRequest: IPageRequest<IOrderFilter>) => getHttpClient().get(`${baseUrl}itact-csv${formatPageQueryParams(pageRequest)}`);

export const orderApi = {
    getPage,
    getOrder,
    getTransitions,
    update,
    getCsvDownloadAllSelectedOrders,
    getCsvDownloadOnlyFinishedOrders,
    getListAsHtml,
    getItactCsv
};
