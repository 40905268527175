const COLOR_CLASSES = {
    YELLOW: "yellow",
    GREEN: "green",
    GRAY: "gray",
    LIGHT_GREEN: "light-green",
    LIGHT_YELLOW: "light-yellow"
};

export const TAB_STATE_COLOR_CLASSES = {
    GREEN: COLOR_CLASSES.GREEN,
    YELLOW: COLOR_CLASSES.YELLOW,
    GRAY: COLOR_CLASSES.GRAY
};

export const PRIORITY_COLOR_CLASSES = {
    TODAY: COLOR_CLASSES.LIGHT_GREEN,
    TOMORROW: COLOR_CLASSES.LIGHT_YELLOW,
};
