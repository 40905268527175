import { CheckInReason, ICheckIn } from "../types/checkIn.types";
import { OrderAction } from "../types/salesOrder.types";
import { AdrTypes } from "../types/adr.types";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
dayjs.extend(isBetween);

const DAYS_CONTAINER_CHECK_VALID = 912;

export const getCompany = (checkIn: ICheckIn) => {
    if (checkIn.checkInReason === CheckInReason.VISIT) {
        return "";
    } else if (checkIn.checkInReason === CheckInReason.SUPPLIER) {
        return checkIn.supplier?.company;
    } else if (checkIn.checkInReason === CheckInReason.CONTRACTOR) {
        return checkIn.contractor?.company;
    } else {
        return checkIn.driver?.transportCompany;
    }
};

export const getFirstName = (checkIn: ICheckIn) => {
    if (checkIn.checkInReason === CheckInReason.VISIT) {
        return checkIn.visitor?.firstName;
    } else if (checkIn.checkInReason === CheckInReason.SUPPLIER) {
        return checkIn.supplier?.firstName;
    } else if (checkIn.checkInReason === CheckInReason.CONTRACTOR) {
        return checkIn.contractor?.firstName;
    } else {
        return checkIn.driver?.firstName;
    }
};

export const getLastName = (checkIn: ICheckIn) => {
    if (checkIn.checkInReason === CheckInReason.VISIT) {
        return checkIn.visitor?.lastName;
    } else if (checkIn.checkInReason === CheckInReason.SUPPLIER) {
        return checkIn.supplier?.lastName;
    } else if (checkIn.checkInReason === CheckInReason.CONTRACTOR) {
        return checkIn.contractor?.lastName;
    } else {
        return checkIn.driver?.lastName;
    }
};


export const checkIfAdrIsExpired = (record: ICheckIn | null, adrCategory: AdrTypes) => {
    const hasLoadOrders = (record?.manualOrders?.find(order => order.manualOrderAction === OrderAction.LOAD)) || (record?.salesOrders?.find(order => order.orderAction === OrderAction.LOAD));

    if (record && record.completedOn && record[adrCategory]?.adrCertificate && hasLoadOrders) {
        if (adrCategory === AdrTypes.CONTAINER) {
            const expirationDate = dayjs().subtract(DAYS_CONTAINER_CHECK_VALID, "days");
            return !dayjs(record[adrCategory]?.adrCertificate?.adrExpirationDate).isBetween(expirationDate, dayjs());
        }
        const checkInCompletionMoment = dayjs(record.completedOn);
        const adrExpirationMoment = dayjs(record[adrCategory]?.adrCertificate?.adrExpirationDate);
        return adrExpirationMoment.isBefore(checkInCompletionMoment);
    }
    return false;
};
