import { Spin } from "antd";
import * as React from "react";
import { useEffect } from "react";
import { IntlShape } from "react-intl";
import { ICleaningMessagePermissions, ICleaningMessageTitles } from "../../types/message.types";
import { Messages } from "../components/modals/customerservicemodal/panes/messagesPane/Messages";
import { useOrder } from "../hooks/useOrder";

interface IProps {
    orderId: number;
    permissions: ICleaningMessagePermissions;
    titleFactory: (intl: IntlShape) => ICleaningMessageTitles;
}

export const MessagesContainer = (props: IProps) => {
    const [order, loadingOrder, loadOrder] = useOrder();

    useEffect(() => {
        loadOrder(props.orderId);
    }, []);

    return (!order || loadingOrder ?
        <Spin/> :
        <Messages
            orderId={order.id}
            fromCleaningMessage={order.fromCleaningMessage}
            toCleaningMessage={order.toCleaningMessage}
            fromItactMessage={order.fromItactMessage}
            toItactMessage={order.toItactMessage}
            beforeLeaveMessage={order.beforeLeaveMessage}
            permissions={props.permissions}
            titleFactory={props.titleFactory}
        />
    );
};
