import { createSelector } from "@reduxjs/toolkit";
import { ICleaningState } from "../reducers/cleaning";
import { IApplicationState } from "../state";

const cleaningStateSelector = (state: IApplicationState) => state.cleaning;

export const cleaningPageSelector = createSelector(
    cleaningStateSelector,
    (cleaningState: ICleaningState) => cleaningState.page
);

export const cleaningLoadingPageSelector = createSelector(
    cleaningStateSelector,
    (cleaningState: ICleaningState) => cleaningState.loadingPage
);
