import { call, takeEvery } from "redux-saga/effects";
import { applicationVersionApi } from "../api/applicationVersion.api";
import { applicationVersionGetActionCreator } from "../actions/applicationVersion.action";
import { errorHandler } from "./errorHandler";
import { AxiosResponse } from "axios";

export function* getApplicationVersion(action: ReturnType<typeof applicationVersionGetActionCreator>) {
    try {
        const response: AxiosResponse<any> = yield call(applicationVersionApi.getApplicationVersion);
        action.payload.callback(response.data);
    } catch (e) {
        action.payload.callback("");
    }
}

export function* applicationVersionSaga() {
    yield takeEvery(applicationVersionGetActionCreator.type, errorHandler(getApplicationVersion));
}

