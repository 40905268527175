import { useDispatch, useSelector } from "react-redux";
import { IApplicationState } from "../../store/state";
import { ICheckInResourceLoader } from "../../types/hook.types";
import { ICheckIn } from "../../types/checkIn.types";
import { checkInRecordSelector } from "../../store/selectors/checkInOverview.selector";
import {
    checkInAssignActionCreator,
    checkInDeleteActionCreator,
    checkInFinishActionCreator,
    checkInRecordLoadActionCreator,
    checkInRecordReceivedActionCreator,
    checkInReopenActionCreator,
    checkInUnAssignActionCreator,
    checkInValidateActionCreator
} from "../../store/actions/checkIn";
import { AxiosResponse } from "axios";

export const useCheckIn = (): ICheckInResourceLoader => {
    const dispatch = useDispatch();
    const resource = useSelector<IApplicationState, ICheckIn | null>(checkInRecordSelector);

    const loadResource = (checkinId: number, callback: (success: boolean) => void) => {
        return dispatch(checkInRecordLoadActionCreator({ checkinId, callback }));
    };

    const clear = () => {
        dispatch(checkInRecordReceivedActionCreator(null));
    };

    const onAssign = () => {
        if (resource) {
            dispatch(checkInAssignActionCreator({ checkinId: resource.id }));
        }
    };

    /**
     * UnAssign event handlers
     */
    const onUnAssign = (callback: () => void) => {
        if (resource) {
            dispatch(checkInUnAssignActionCreator({ checkinId: resource.id, callback }));
        }
    };

    /**
     * Validate event handlers
     */
    const onValidate = (onFailure: (code: number, response: AxiosResponse<any>) => void) => {
        if (resource) {
            dispatch(checkInValidateActionCreator({ checkinId: resource.id, cmrWeight: 1, onFailure }));
        }
    };

    /**
     * Finish event handlers
     */
    const onFinish = () => {
        if (resource) {
            dispatch(checkInFinishActionCreator({ checkinId: resource.id }));
        }
    };

    const onReopen = () => {
        if (resource) {
            dispatch(checkInReopenActionCreator({ checkinId: resource.id }));
        }
    };

    const onDelete = () => {
        if (resource) {
            dispatch(checkInDeleteActionCreator({ checkinId: resource.id }));
        }
    };

    return {
        loadingResource: false,
        resource,
        loadResource,
        clear,
        onAssign,
        onUnAssign,
        onValidate,
        onFinish,
        onReopen,
        onDelete,
    };
};
