import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { ERROR_TRANSLATION_CODES } from "../../constants/errorTranslationCodes";
import { NOTIFICATION_TYPES } from "../../constants/notification";
import { ITank } from "../../types/salesOrder.types";
import { showNotificationActionCreator } from "../actions/notifications";
import { slotBookingConfigFetchCreator } from "../actions/slotBookingConfig";
import {
    tankBlockCreator,
    tankBlockFailedCreator,
    tankBlockSucceededCreator,
    tankFetchCreator,
    tankFetchFailedCreator,
    tankListFetchCreator,
    tankListFetchFailedCreator,
    tankListReceivedCreator,
    tankReceivedCreator
} from "../actions/tank";
import { tankApi } from "../api/tank";
import { errorHandler } from "./errorHandler";
import { AxiosResponse } from "axios";
import { ITankBlockParams } from "../../types/tank.types";

export function* fetchTankList(action: ReturnType<typeof tankListFetchCreator>) {
    const response: AxiosResponse<ITank[]> = yield call(tankApi.getTankList, action.payload.tankListFetchParams);
    yield put(tankListReceivedCreator(response.data));
}

export function* blockTank(action: ReturnType<typeof tankBlockCreator>) {
    const response: AxiosResponse<{tankBlockParams: ITankBlockParams}> = yield call(tankApi.block, action.payload.id, action.payload.tankBlockParams);
    yield put(showNotificationActionCreator(NOTIFICATION_TYPES.BLOCK_SUCCEEDED));
    yield put(tankBlockSucceededCreator(response.data));
}

export function* getTank(action: ReturnType<typeof tankFetchCreator>) {
    const response: AxiosResponse<ITank> = yield call(tankApi.getTank, action.payload.id);
    const receivedTank = response.data;

    if (receivedTank.bay && receivedTank.bay.zone) {
        yield put (slotBookingConfigFetchCreator(receivedTank.bay.zone.id));
    }

    yield put (tankReceivedCreator(receivedTank));
}

export function* showNotification(action: ReturnType<typeof tankBlockFailedCreator>) {
    // Axios errors have this property and we check if the property is present.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const response = action.payload.e.response;

    if (response && response.data) {
        const translationCode = response.data["translationCode"];

        if (translationCode.includes(ERROR_TRANSLATION_CODES.ALREADY_SCHEDULED)) {
            yield put(showNotificationActionCreator(NOTIFICATION_TYPES.BLOCK_ALREADY_SCHEDULED));
        }
        if (translationCode.includes(ERROR_TRANSLATION_CODES.SLOT_BOOKING_EXISTS)) {
            yield put(showNotificationActionCreator(NOTIFICATION_TYPES.BLOCK_SLOT_BOOKINGS_EXIST));
        }
    }
}

export function* tankSaga() {
    yield takeLatest(tankListFetchCreator.type, errorHandler(fetchTankList, tankListFetchFailedCreator));
    yield takeLatest(tankBlockCreator.type, errorHandler(blockTank, tankBlockFailedCreator));
    yield takeLatest(tankFetchCreator.type, errorHandler(getTank, tankFetchFailedCreator));
    yield takeEvery(tankBlockFailedCreator.type, errorHandler(showNotification));
}
