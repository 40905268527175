import { Button } from "antd";
import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import { CheckInState, ICheckIn } from "../../../../../types/checkIn.types";

interface IProps {
  onClick: () => void;
  checkIn: ICheckIn | null;
}

const MESSAGES = defineMessages({
    assign: {
        id: "check_in_modal_footer.assign",
        defaultMessage: "Toewijzen aan mij",
    }
});

export const AssignCheckinButton = (props: IProps) => {
    const intl = useIntl();

    return props.checkIn && (props.checkIn.checkInState === CheckInState.QUEUED || props.checkIn.checkInState === CheckInState.BACK_TO_KIOSK) ? (
        <Button onClick={props.onClick} type="primary">
            {intl.formatMessage(MESSAGES.assign)}
        </Button>
    ) : null;
};
