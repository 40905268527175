import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import { Box } from "../../../../Box";
import { Pane } from "../../../customerservicemodal/panes/Pane";
import { TransportationMethod } from "../../../../../../types/checkIn.types";
import { TransportDetails } from "./TransportDetails";

interface IProps {
    borderCrossing?: string;
    transportationMethod?: TransportationMethod;
    className?: string;
}

const MESSAGES = defineMessages({
    title: {
        id: "transport_details_pane.title",
        defaultMessage: "Transport details"
    }
});

export const TransportDetailsPane = (props: IProps) => {
    const intl = useIntl();

    return (
        <Pane className={props.className} title={intl.formatMessage(MESSAGES.title)}>
            <Box>
                <TransportDetails transportationMethod={props.transportationMethod} borderCrossing={props.borderCrossing}/>
            </Box>
        </Pane>
    );
};
