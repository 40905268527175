import { EventContentArg } from "@fullcalendar/react";
import * as React from "react";
import { useSelector } from "react-redux";
import { bookedSlotListSelector } from "../../../store/selectors/bookedSlot.selector";
import { IBookedSlot } from "../../../types/bookedSlot.types";
import { TimeLineEvent } from "../../components/timeline/TimeLineEvent";
import { EventApi } from "@fullcalendar/common";

interface IProps {
    args: EventContentArg;
    selectedBookedSlotId: number | undefined;
    modalVisible: boolean;
    setModalVisible: (isVisible: boolean) => void;
    clickedEvent: EventApi | undefined;
}

export const BookedSlotEventContainer = (props: IProps) => {
    const bookedSlotList: IBookedSlot[] = useSelector(bookedSlotListSelector);
    const bookedSlotEventId = props.args.event.id;
    const bookedSlot: IBookedSlot | undefined = bookedSlotList.find(bookedSlot => bookedSlot.id.toString() === bookedSlotEventId);

    return (
        <TimeLineEvent
            clickedEvent={props.clickedEvent}
            bookedSlot={bookedSlot}
            selectedBookedSlotId={props.selectedBookedSlotId}
            args={props.args}
            modalVisible={props.modalVisible}
            setModalVisible={props.setModalVisible}
        />
    );
};
