import * as React from "react";
import { IntlShape, useIntl } from "react-intl";
import { customerServiceOrderUpdateDoneActionCreator } from "../../../../../../store/actions/customerservice";
import { IOrderUpdateDto } from "../../../../../../types/api.types";
import { ICleaningMessagePermissions, ICleaningMessageTitles } from "../../../../../../types/message.types";
import { useOrderUpdate } from "../../../../../hooks/useOrderUpdate";
import { Message } from "./message/Message";

interface IProps {
    orderId: number;
    fromCleaningMessage?: string;
    toCleaningMessage?: string;
    fromItactMessage?: string;
    toItactMessage?: string;
    beforeLeaveMessage?: string;

    permissions: ICleaningMessagePermissions;
    titleFactory: (intl: IntlShape) => ICleaningMessageTitles;
}

export const Messages = (props: IProps) => {
    const intl = useIntl();
    const [update, ordersBeingUpdated] = useOrderUpdate(customerServiceOrderUpdateDoneActionCreator);

    const saveMessage = (orderUpdateDto: IOrderUpdateDto) => {
        update(props.orderId, orderUpdateDto);
    };

    const saveToCleaning = (m: string) => {
        const orderUpdateDto: IOrderUpdateDto = {
            toCleaningMessage: m,
        };

        saveMessage(orderUpdateDto);
    };

    const saveToItact = (m: string) => {
        const orderUpdateDto: IOrderUpdateDto = {
            toItactMessage: m,
        };

        saveMessage(orderUpdateDto);
    };

    const saveFromItact = (m: string) => {
        const orderUpdateDto: IOrderUpdateDto = {
            fromItactMessage: m,
        };

        saveMessage(orderUpdateDto);
    };

    const saveBeforeLeaveMessage = (m: string) => {
        const orderUpdateDto: IOrderUpdateDto = {
            beforeLeaveMessage: m,
        };

        saveMessage(orderUpdateDto);
    };

    const titles = props.titleFactory(intl);

    return (
        <>
            {
                props.permissions.toCleaningVisible &&
                <Message
                    title={titles.toCleaningTitle || ""}
                    body={props.toCleaningMessage}
                    editable={props.permissions.toCleaningEditable}
                    onSave={saveToCleaning}
                    saving={ordersBeingUpdated[props.orderId] && !!ordersBeingUpdated[props.orderId].toCleaningMessage}
                />
            }
            {
                props.permissions.fromCleaningVisible &&
                <Message
                    title={titles.fromCleaningTitle || ""}
                    body={props.fromCleaningMessage}
                    editable={props.permissions.fromCleaningEditable}
                    className="margin-top"
                />
            }
            {
                props.permissions.toItactVisible &&
                <Message
                    title={titles.toItactTitle || ""}
                    body={props.toItactMessage}
                    editable={props.permissions.toItactEditable}
                    className="margin-top"
                    onSave={saveToItact}
                    saving={ordersBeingUpdated[props.orderId] && !!ordersBeingUpdated[props.orderId].toItactMessage}
                />
            }
            {
                props.permissions.fromItactVisible &&
                <Message
                    title={titles.fromItactTitle || ""}
                    body={props.fromItactMessage}
                    editable={props.permissions.fromItactEditable}
                    className="margin-top"
                    onSave={saveFromItact}
                    saving={ordersBeingUpdated[props.orderId] && !!ordersBeingUpdated[props.orderId].fromItactMessage}
                />
            }
            {
                props.permissions.beforeLeaveMessageVisible &&
                <Message
                    title={titles.beforeLeaveMessageTitle || ""}
                    body={props.beforeLeaveMessage}
                    editable={props.permissions.beforeLeaveMessageEditable}
                    className="margin-top"
                    onSave={saveBeforeLeaveMessage}
                    saving={ordersBeingUpdated[props.orderId] && !!ordersBeingUpdated[props.orderId].beforeLeaveMessage}
                />
            }
        </>
    );
};
