import { useDispatch } from "react-redux";
import { PayloadAction } from "@reduxjs/toolkit";
import { tankBlockCreator } from "../../store/actions/tank";
import { ITankBlockParams } from "../../types/tank.types";

export const useBlockTank = (): [(id: number, tankBlockParams: ITankBlockParams) => PayloadAction<{ id: number; tankBlockParams: ITankBlockParams }>] => {
    const dispatch = useDispatch();
    const block = (id: number, tankBlockParams: ITankBlockParams) => dispatch(tankBlockCreator({ id: id, tankBlockParams: tankBlockParams }));

    return [block];
};
