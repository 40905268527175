import { useDispatch, useSelector } from "react-redux";
import {
    customerServicePageFetchCreator,
    customerServicePageLoadCreator
} from "../../../store/actions/customerservice";
import {
    customerServiceLoadingPageSelector,
    customerServicePageSelector
} from "../../../store/selectors/customerservice.selector";
import { IOrderFilter, IPageRequest } from "../../../types/api.types";
import { IPageResourceLoader } from "../../../types/hook.types";
import { IOrder } from "../../../types/order.types";
import { mapPageResponseToPageRequest } from "../../../utils/mapping";

export type ICustomerServicePageLoader = IPageResourceLoader<IOrder, IOrderFilter>;

export const useCustomerServicePageLoader = (): ICustomerServicePageLoader => {
    const dispatch = useDispatch();

    const page = useSelector(customerServicePageSelector);
    const loadingPage = useSelector(customerServiceLoadingPageSelector);
    const loadPage = (pageRequest: IPageRequest<IOrderFilter>) => dispatch(customerServicePageLoadCreator(pageRequest));
    const refresh = () => {
        const pageRequest = page ? mapPageResponseToPageRequest(page) : {
            pageNumber: 0,
            filter: {}
        };

        return dispatch(customerServicePageFetchCreator(pageRequest));
    };

    return {
        resource: page,
        loadingResource: loadingPage,
        loadResource: loadPage,
        refresh
    };
};
