import * as React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { Paths } from "../../types/api.types";
import { SlotBookingSalesOrderForm } from "../components/forms/SlotBookingSalesOrderForm";
import { IBookingLoader, useBooking } from "../hooks/useBooking";
import { IBookedSlotLoader, useDependsOnBookedSlot } from "../hooks/useDependsOnBookedSlot";
import { useSalesOrder } from "../hooks/useSalesOrder";
import { useSalesOrderList } from "../hooks/useSalesOrderList";
import { useSelectedSlot } from "../hooks/useSelectedSlot";
import { useSlot } from "../hooks/useSlot";
import { useTank } from "../hooks/useTank";
import { useTankList } from "../hooks/useTankList";

interface IProps {
    salesOrderReference?: string;
}

export const SlotBookingOrderContainer = (props: IProps) => {
    const [salesOrder, fetchSalesOrder, assignSalesOrder] = useSalesOrder();
    const [salesOrderList, fetchSalesOrderList] = useSalesOrderList();
    const [, assignSelectedSlot] = useSelectedSlot();
    const [,,,, assignSlots] = useSlot();
    const [,, assignTank] = useTank();
    const [,, assignTankList] = useTankList();
    const dependsOnBookingHook: IBookedSlotLoader = useDependsOnBookedSlot();
    const bookingHook: IBookingLoader = useBooking();

    const navigate = useNavigate();

    useEffect(() => {
        if (typeof props.salesOrderReference !== "undefined") {
            fetchSalesOrder(props.salesOrderReference);
        }
    }, []);

    const resetUrl = () => {
        navigate(Paths.SLOTBOOKING);
    };

    return <SlotBookingSalesOrderForm
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        fetchSalesOrder={fetchSalesOrder}
        assignSalesOrder={assignSalesOrder}
        salesOrderList={salesOrderList}
        fetchSalesOrderList={fetchSalesOrderList}
        assignSlots={assignSlots}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        assignSelectedSlot={assignSelectedSlot}
        initialSalesOrder={salesOrder}
        assignTank={assignTank}
        assignTankList={assignTankList}
        resetUrl={resetUrl}
        assignBooking={bookingHook.setResource}
        assignDependsOnBooking={dependsOnBookingHook.setResource}
    />;
};
