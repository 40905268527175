import { Tabs } from "antd";
import * as React from "react";
import { useEffect } from "react";
import { defineMessages, useIntl } from "react-intl";
import { Box } from "../components/Box";
import { SlotBookingContainer } from "../containers/SlotBookingContainer";
import { TankBlockingContainer } from "../containers/TankBlockingContainer";
import { useSalesOrder } from "../hooks/useSalesOrder";
import { useSelectedSlot } from "../hooks/useSelectedSlot";
import { useSlot } from "../hooks/useSlot";
import { AppLayout } from "./AppLayout";

const MESSAGES = defineMessages({
    bookSlot: {
        id: "sale_order_screen.book_slot",
        defaultMessage: "Boek een slot",
    },
    blockTank: {
        id: "sale_order_screen.block_tank",
        defaultMessage: "Blokkeer een tank",
    }
});

export const SlotBookingScreen = () => {
    const { TabPane } = Tabs;
    const intl = useIntl();
    const [,, assignSalesOrder] = useSalesOrder();
    const [,,,, assignSlots] = useSlot();
    const [,assignSelectedSlot] = useSelectedSlot();

    useEffect(() => {
        assignSalesOrder(null);
        assignSlots([]);
        assignSelectedSlot(null);
    }, []);

    return (
        <AppLayout>
            <Tabs defaultActiveKey="1">
                <TabPane tab={intl.formatMessage(MESSAGES.bookSlot)} key="1">
                    <SlotBookingContainer />
                </TabPane>
                <TabPane tab={intl.formatMessage(MESSAGES.blockTank)} key="2">
                    <div className={"flex"}>
                        <Box className="margin-half-top margin-right margin-bottom flex">
                            <TankBlockingContainer/>
                        </Box>
                    </div>
                </TabPane>
            </Tabs>
        </AppLayout>
    );
};
