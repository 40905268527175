import { call, put, takeLatest } from "redux-saga/effects";
import {
    documentsForCheckinFetchActionCreator,
    documentsForCheckinFetchFailedActionCreator,
    documentsForCheckinSetActionCreator
} from "../actions/documents.actions";
import { AxiosResponse } from "axios";
import { getDocumentsForCheckIn } from "../api/documents.api";
import { IDocumentsResponse } from "../../types/document.types";
import { errorHandler } from "./errorHandler";

export function* fetchDocuments(action: ReturnType<typeof documentsForCheckinFetchActionCreator>) {
    try {
        yield put(documentsForCheckinFetchFailedActionCreator(false));
        const response: AxiosResponse<IDocumentsResponse[]> = yield call(getDocumentsForCheckIn, action.payload);
        const documents = response.data;

        yield put(documentsForCheckinSetActionCreator({ [action.payload]: documents }));
    } catch (e) {
        yield put(documentsForCheckinFetchFailedActionCreator(true));
    }
}


export function* documentSaga() {
    yield takeLatest(documentsForCheckinFetchActionCreator.type, errorHandler(fetchDocuments));
}
