import { ReactComponent as PicCenterOutlined } from "@ant-design/icons-svg/inline-svg/outlined/pic-center.svg";
import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { ReactComponent as DropIcon } from "../../../../assets/drop-24px.svg";
import { Paths } from "../../../../types/api.types";
import "./PageTitle.scss";
import Icon from "@ant-design/icons";

type Props = {
    className?: string;
};

const MESSAGES = defineMessages({
    [Paths.EXTERNAL_VIEW]: {
        id: "application.title.path.external_view",
        defaultMessage: "Cleaning - External View",
    },
    [Paths.CUSTOMER_SERVICE]: {
        id: "application.title.path.customer_service",
        defaultMessage: "Cleaning - Customer Service",
    },
    [Paths.CLEANING]: {
        id: "application.title.path.cleaning",
        defaultMessage: "Cleaning - Cleaners"
    },
    [Paths.SLOTBOOKING]: {
        id: "application.title.path.slot_booking",
        defaultMessage: "Bookings - Booking maken"
    },
    [Paths.SLOTBOOKING_PLANNER]: {
        id: "application.title.path.slot_booking_planner",
        defaultMessage: "Bookings - Tankplanner"
    },
    [Paths.ROOT]: {
        id: "application.title.path.root",
        defaultMessage: "ADPO Cleaning & Slot Booking"
    },
    [Paths.ITACT]: {
        id: "application.title.path.itact",
        defaultMessage: "Cleaning - ITACT"
    },
    [Paths.SLOTBOOKING_LIST]: {
        id: "application.title.path.slot_booking_list",
        defaultMessage: "Bookings - Geboekte slots"
    },
    [Paths.CHECKIN_ARCHIVED]: {
        id: "application.title.path.check_in_archivedCheckins",
        defaultMessage: "Check-ins - Archived"
    },
    [Paths.CHECKIN_DRIVERS]: {
        id: "application.title.path.check_in_blacklist",
        defaultMessage: "Check-ins - Blacklists"
    },
    [Paths.CHECKIN_OVERVIEW]: {
        id: "application.title.path.check_in_overview",
        defaultMessage: "Check-ins - Overview"
    },
    [Paths.ITACT_NEEDED]: {
        id: "application.title.path.settings_itact_needed",
        defaultMessage: "Settings - Itact nodig"
    },
    [Paths.FREE_STORAGE_DAYS]: {
        id: "application.title.path.settings_free_storage_days",
        defaultMessage: "Settings - Vrije opslag dagen"
    },
    [Paths.SELF_CHECK_IN]: {
        id: "application.title.path.settings_self_check_in",
        defaultMessage: "Settings - Self check in"
    },
    [Paths.LOGOUT]: {
        id: "application.title.path.logout_screen",
        defaultMessage: "Afmelden"
    },
    [Paths.CHECKIN_OVERVIEW_READ_ONLY]: {
        id: "application.title.path.overviewReadOnly",
        defaultMessage: "Overview"
    },
    [Paths.TOLERANCES]: {
        id: "application.title.path.tolerances",
        defaultMessage: "Toleranties"
    },
    [Paths.ARCHIVE_SALESORDERS]: {
        id: "application.title.path.archive_sales_orders",
        defaultMessage: "Archiveer sales orders"
    },
    [Paths.WEIGHT_BADGE_TANK]: {
        id: "application.title.path.weight_badge_tank",
        defaultMessage: "Tanks met weegbadge"
    },
    [Paths.CERTIFICATE]: {
        id: "application.title.path.certificate",
        defaultMessage: "Certificaten"
    }
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SideIcon = (location: any) => {
    switch (location.pathname) {
    case Paths.CLEANING:
        return <Icon component={DropIcon} />;
    case Paths.SLOTBOOKING:
        return <Icon component={PicCenterOutlined} />;
    }
    return null;
};

export const PageTitle = (props: Props) => {
    const location = useLocation();
    const intl = useIntl();

    const isExternalView = location.pathname.includes(Paths.EXTERNAL_VIEW);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const pageTitle = isExternalView ? intl.formatMessage(MESSAGES[Paths.EXTERNAL_VIEW]) : intl.formatMessage(MESSAGES[location.pathname]);
    const title = `${pageTitle}`;

    return (
        <h1 className={props.className}>
            {SideIcon(location)} {title}
        </h1>
    );
};
