import * as React from "react";
import { useEffect } from "react";
import { IBookedSlot } from "../../types/bookedSlot.types";
import { IHybridSlot, ISelectedSlot, ISlot } from "../../types/slot.types";
import { concatDateTime } from "../../utils/mapping";
import { SlotsTable } from "../components/tables/slotstable/SlotsTable";
import { IBookedSlotLoader, useDependsOnBookedSlot } from "../hooks/useDependsOnBookedSlot";
import { useSalesOrder } from "../hooks/useSalesOrder";
import { useSelectedSlot } from "../hooks/useSelectedSlot";
import { useSlot } from "../hooks/useSlot";
import { useTank } from "../hooks/useTank";
import dayjs, { Dayjs } from "dayjs";

interface IProps {
    initialSlotStartTime?: string;
    initialSlotEndTime?: string;
    initialDate?: string;
    productionOrderReference: string;
}

export const SlotBookingSlotsContainer = (props: IProps) => {
    const [salesOrder, ,] = useSalesOrder();
    const [slots, config] = useSlot();
    const [selectedSlot, assignSelectedSlot] = useSelectedSlot();
    const [tank,] = useTank();
    const dependsOnBookingHook: IBookedSlotLoader = useDependsOnBookedSlot();

    const dependsOnBookedSlot: IBookedSlot | null = dependsOnBookingHook.resource;

    const slotsContainInitialValues = (slots: ISlot[]): boolean => {
        return slots.filter(slot =>
            slot.start === props.initialSlotStartTime &&
            props.initialDate &&
            slot.date === concatDateTime(props.initialDate, "00:00").format()).length > 0;
    };

    const calculateDependsOn = (slotFromDate: Dayjs) => {
        return dependsOnBookedSlot ? dayjs(dependsOnBookedSlot.untilDate)
            .subtract(dayjs.duration(dependsOnBookedSlot.departureTime))
            .isAfter(slotFromDate) : false;
    };

    useEffect(() => {
        if (props.initialDate && props.initialSlotStartTime && props.initialSlotEndTime && tank) {
            const selectedSlot: ISelectedSlot = {
                startDate: concatDateTime(props.initialDate, props.initialSlotStartTime).format(),
                endDate: concatDateTime(props.initialDate, props.initialSlotEndTime).format(),
                endTime: props.initialSlotEndTime,
                startTime: props.initialSlotStartTime,
                tank: tank,
                productionOrderReference: props.productionOrderReference,
                dependsOnBooking: dependsOnBookedSlot ? dependsOnBookedSlot.id : undefined,
                blockedDependsOn: calculateDependsOn(concatDateTime(props.initialDate, props.initialSlotStartTime)),
                salesOrderReference: salesOrder && salesOrder.sapReference || ""
            };

            slotsContainInitialValues(slots) && assignSelectedSlot(selectedSlot);
        }
    }, []);

    return (
        <>
            {tank && config &&
            <SlotsTable
                selectedSlot={selectedSlot}
                productionOrderReference={props.productionOrderReference}
                dependsOnBookedSlot={dependsOnBookedSlot}
                slots={slots.map(slot => {
                    const hybridSlot: IHybridSlot = {
                        slot: slot,
                        blockedDependsOn: calculateDependsOn(concatDateTime(slot.date, slot.start))
                    };
                    return hybridSlot;
                })}
                assignSelectedSlot={assignSelectedSlot}
                tank={tank}
                config={config}
                salesOrderReference={salesOrder?.sapReference || ""}
            />}
        </>
    );
};
