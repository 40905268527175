import { PayloadAction } from "@reduxjs/toolkit";
import { Table } from "antd";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { TableEventListeners } from "antd/es/table";
import { isNil, omitBy } from "lodash";
import * as React from "react";
import { useState } from "react";
import {
    IFilter,
    IOrderFilter,
    IPageMeta,
    IPageRequest,
    IResourceRequest,
    ISort,
    Paths
} from "../../../types/api.types";
import { mapAntSortToSort, mapIPageMetaToAntPagination } from "../../../utils/mapping";
import { useMatch } from "react-router-dom";
import { ExpandableConfig } from "rc-table/lib/interface";

interface IProps {
    uuid?: string;
    data: any[];
    pageMeta?: IPageMeta;
    fetching: boolean;
    sorting?: ISort;
    filter?: IFilter;
    fetch: (resourceRequest: any) => PayloadAction<any>;
    onRow?: (record: any, index: number) => TableEventListeners;
    columnFactory: (onFilter: (filter: IFilter) => any) => React.ReactElement[];
    className?: string;
    expandable?: ExpandableConfig<any>;
    rowClassName?: (record: any, index: number) => string;
}

export const ItemTable = (props: IProps) => {
    const isExternalView = useMatch(`${Paths.EXTERNAL_VIEW}/:uuid`);
    const [tableState, setTableState] = useState<{ filters: any, sorter: any, pagination: any }>({ filters: {}, sorter: {}, pagination: {} });
    const onChange = (pagination: any, filters: any, sorter: any) => {
        let sanitizedFilter = {};

        if (filters) {
            // TODO: make backend deal with empty strings?
            // missing values are interpreted as empty strings by the backend if the param is present
            // empty strings still trigger the specifications and prevent us from clearing the filter
            sanitizedFilter = omitBy(filters, isNil);
        }

        // This function only gets triggered for internal filters and sorters.
        const newState = { pagination, sorter, filters: { ...tableState.filters, ...sanitizedFilter } };

        const resourceRequest: IResourceRequest<IOrderFilter> | IPageRequest<IOrderFilter> = {
            filter: { ...props.filter, ...newState.filters } || { ...newState.filters },
        };

        // when sorting is turned off we still receive a sorter object except the order property is missing
        // our backend fails when it receives a 'sort' param with no value so we don't set it if it's missing
        if (newState.sorter.order) {
            resourceRequest.sorting = mapAntSortToSort(newState.sorter);
        }

        if (props.pageMeta) {
            const pageRequest: IPageRequest<IOrderFilter> = {
                ...resourceRequest,
                uuid: props.uuid,
                pageNumber: newState.pagination.current - 1,
                filter: { ...filters, ...resourceRequest.filter }
            };
            if (isExternalView) {
                pageRequest.filter = resourceRequest.filter;
            }
            props.fetch(pageRequest);
        } else {
            props.fetch(resourceRequest);
        }

        setTableState(newState);
    };

    const onFilter = (filter: IOrderFilter) => {
        // TODO: make backend deal with empty strings?
        // missing values are interpreted as empty strings by the backend if the param is present
        // empty strings still trigger the specifications and prevent us from clearing the filter
        const sanitizedFilter = omitBy(filter, isNil);

        // This function only gets triggered for custom filters;
        const newState = { pagination: tableState.pagination, sorter: tableState.sorter, filters: { ...tableState.filters, ...sanitizedFilter } };

        let resourceRequest: IResourceRequest<IOrderFilter> | IPageRequest<IOrderFilter> = {
            sorting: props.sorting,
            filter: sanitizedFilter,
        };

        if (props.pageMeta) {
            resourceRequest = {
                ...resourceRequest,
                uuid: props.uuid,
                pageNumber: 0
            };
        }

        setTableState(newState);
        props.fetch(resourceRequest);
    };

    return (
        <Table
            dataSource={props.data}
            rowKey="id"
            loading={props.fetching}
            pagination={!!props.pageMeta && mapIPageMetaToAntPagination(props.pageMeta)}
            onChange={onChange}
            size="small"
            className={props.className}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onRow={props.onRow}
            expandable={props.expandable}
            rowClassName={props.rowClassName}
        >
            {
                props.columnFactory(onFilter)
            }
        </Table>
    );
};
