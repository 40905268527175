import * as React from "react";
import { ExternalViewTableContainer } from "../containers/tablecontainers/ExternalViewTableContainer";
import { NoSidebarLayout } from "./NoSidebarAppLayout";
import { useParams } from "react-router-dom";


export const ExternalViewScreen = () => {
    const { uuid } = useParams();

    return (
        <NoSidebarLayout>
            {uuid &&
            <ExternalViewTableContainer uuid={uuid}/>
            }
        </NoSidebarLayout>
    );
};
