import fileDownload from "js-file-download";
import { call } from "redux-saga-test-plan/matchers";
import { put, select, takeLatest } from "redux-saga/effects";
import { IOrderFilter, IPageResponse } from "../../types/api.types";
import { IOrder } from "../../types/order.types";
import { mapPageResponseToPageRequest } from "../../utils/mapping";
import { orderFetchCreator, orderTransitionsFetchCreator } from "../actions";
import {
    itactCsvDownloadActionCreator,
    itactOrderUpdateItactDoneDoneActionCreator,
    itactOrderUpdateItactNeededDoneActionCreator,
    itactPageFetchActionCreator,
    itactPageFetchFailedActionCreator,
    itactPageLoadActionCreator,
    itactPageOrderUpdateDoneActionCreator,
    itactPageReceivedActionCreator
} from "../actions/itact";
import { orderApi } from "../api/order";
import { itactPageSelector } from "../selectors/itact.selector";
import { errorHandler } from "./errorHandler";
import { fetchPage as orderFetchPage } from "./order";
import { AxiosResponse } from "axios";

export function* fetchPage(action: ReturnType<typeof itactPageFetchActionCreator>) {
    const pageResponse: IPageResponse<IOrder, IOrderFilter> = yield orderFetchPage(action.payload);
    yield put(itactPageReceivedActionCreator(pageResponse));
}

export function* loadPage(action: ReturnType<typeof itactPageLoadActionCreator>) {
    yield put(itactPageFetchActionCreator(action.payload));
}

export function* pageUpdateDone() {
    const page: IPageResponse<IOrder, IOrderFilter> = yield select(itactPageSelector);
    const pageRequest = mapPageResponseToPageRequest(page);
    yield put(itactPageFetchActionCreator(pageRequest));
}

export function* updateItactNeededDone(action: ReturnType<typeof itactOrderUpdateItactNeededDoneActionCreator>) {
    yield put(orderFetchCreator(action.payload.orderId));
}

export function* updateItactDoneDone(action: ReturnType<typeof itactOrderUpdateItactDoneDoneActionCreator>) {
    yield put(orderFetchCreator(action.payload.orderId));
    yield put(orderTransitionsFetchCreator(action.payload.orderId));
}

export function* downloadCsv(action: ReturnType<typeof itactCsvDownloadActionCreator>) {
    const response: AxiosResponse<any> = yield call(orderApi.getItactCsv, action.payload);
    if (response.headers && response.headers["content-disposition"]) {
        const fileName = response.headers["content-disposition"].split("filename=")[1];
        fileDownload(response.data, fileName);
    }
}

export function* itactSaga() {
    yield takeLatest(itactPageLoadActionCreator.type, errorHandler(loadPage));
    yield takeLatest(itactPageFetchActionCreator.type, errorHandler(fetchPage, itactPageFetchFailedActionCreator));
    yield takeLatest(itactPageOrderUpdateDoneActionCreator.type, errorHandler(pageUpdateDone));
    yield takeLatest(itactOrderUpdateItactNeededDoneActionCreator.type, errorHandler(updateItactNeededDone));
    yield takeLatest(itactOrderUpdateItactDoneDoneActionCreator.type, errorHandler(updateItactDoneDone));
    yield takeLatest(itactCsvDownloadActionCreator.type, errorHandler(downloadCsv));
}
