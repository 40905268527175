import { createAction } from "@reduxjs/toolkit";
import { IFailedActionPayload } from "../../types/action.types";
import { ISlot, ISlotFetchParams } from "../../types/slot.types";

enum SlotsActionTypes {
    SLOTS_FETCH = "[Slots] FETCH",
    SLOTS_RECEIVED = "[Slots] RECEIVED",
    SLOTS_FETCH_FAILED = "[Slots] FETCH_FAILED",
}

export const slotsFetchCreator = createAction<{tankId: number; slotsFetchParams: ISlotFetchParams}>(SlotsActionTypes.SLOTS_FETCH);
export const slotsReceivedCreator = createAction<ISlot[]>(SlotsActionTypes.SLOTS_RECEIVED);
export const slotsFetchFailedCreator = createAction<IFailedActionPayload>(SlotsActionTypes.SLOTS_FETCH_FAILED);
