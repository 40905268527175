import { call, put, takeLatest } from "redux-saga/effects";
import {
    ttatCalculationFetchCreator,
    ttatCalculationFetchFailedCreator,
    ttatCalculationReceivedCreator
} from "../actions";
import { errorHandler } from "./errorHandler";
import { ttatCalculationApi } from "../api/ttatCalculation";
import { AxiosResponse } from "axios";
import { ITtatCalculation } from "../../types/ttatCalculation.types";

export function* fetchTtatCalculation(action: ReturnType<typeof ttatCalculationFetchCreator>) {
    const response: AxiosResponse<ITtatCalculation | null> = yield call(ttatCalculationApi.getTtatCalculation, action.payload.ttatCalculationFetchParams);
    yield put(ttatCalculationReceivedCreator(response.data));
}

export function* ttatCalculationSaga() {
    yield takeLatest(ttatCalculationFetchCreator.type, errorHandler(fetchTtatCalculation, ttatCalculationFetchFailedCreator));
}
