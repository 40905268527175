import * as React from "react";
import { Col, Row } from "antd";
import { PADDING } from "../../../../theme/typescript";
import { OrderInfoPane } from "./panes/orderInfoPane/OrderInfoPane";
import { DriverInfoPane } from "./panes/driverInfoPane/DriverInfoPane";
import { VehiclesInfoPane } from "./panes/vehicleInfoPane/VehiclesInfoPane";
import { CompartmentsPane } from "./panes/compartmentsPane/CompartmentsPane";
import { TransportDetailsPane } from "./panes/transportDetailsPane/TransportDetailsPane";
import { ICheckIn } from "../../../../types/checkIn.types";
import { ActionsPane } from "./panes/actionsPane/ActionsPane";
import { DocumentsPane } from "./panes/documentsPane/DocumentsPane";
import { useSelector } from "react-redux";
import { checkInIsBulkFlowSelector } from "../../../../store/selectors/checkInOverview.selector";
import { RemarksPane } from "./panes/remarksPanes/RemarksPane";

interface IProps {
    checkIn: ICheckIn;
}

export const NonVisitCheckInDetail = (props: IProps) => {
    const isBulkFlow = useSelector(checkInIsBulkFlowSelector);

    return (
        <Row gutter={PADDING}>
            <Col span={8}>
                <OrderInfoPane checkIn={props.checkIn} />
                {
                    props.checkIn.driver &&
                        <DriverInfoPane checkIn={props.checkIn} driver={props.checkIn.driver}/>
                }
            </Col>
            <Col span={8}>
                <VehiclesInfoPane
                    tractor={props.checkIn.tractor}
                    tanker={props.checkIn.tanker}
                    chassis={props.checkIn.chassis}
                    container={props.checkIn.container}
                    checkIn={props.checkIn}
                />
            </Col>
            <Col span={8}>
                {
                    isBulkFlow && (
                        <CompartmentsPane
                            compartments={props.checkIn.compartmentDistributions}
                            initialWeight={props.checkIn.initialWeight}
                            weighingDate={props.checkIn.weighingDate}
                            overridden={props.checkIn.overridden}/>
                    )
                }
                <TransportDetailsPane
                    className={isBulkFlow ? "margin-top" : ""}
                    borderCrossing={props.checkIn.borderCrossingLocation}
                    transportationMethod={props.checkIn.transportationMethod}
                />

                <ActionsPane className={isBulkFlow ? "margin-top" : ""} />

                <DocumentsPane checkIn={props.checkIn} className="margin-top"/>
                <RemarksPane checkIn={props.checkIn}/>
            </Col>
        </Row>
    );
};
