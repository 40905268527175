import * as React from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

interface IProps {
  information: string | null;
}

export const InfoHover = (props: IProps) => {
    return props.information ? (
        <Tooltip placement="top" title={props.information}>
            <InfoCircleOutlined className="icon-info-tooltip" />
        </Tooltip>
    ) : null;
};
