import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import { ICheckIn, IContractor, ISupplier } from "../../../../../../types/checkIn.types";
import { Col, Row } from "antd";
import { InfoLabel } from "../../../customerservicemodal/panes/detailinfopane/sections/infofields/InfoLabel";
import { InfoField } from "../../../customerservicemodal/panes/detailinfopane/sections/infofields/InfoField";
import { VehicleInfo } from "../vehicleInfoPane/VehicleInfo";
import { Box } from "../../../../Box";

interface IProps {
    person: ISupplier|IContractor;
    checkin?: ICheckIn | null;
}

const MESSAGES = defineMessages({
    title: {
        id: "check_in_modal.vehicle_pane.title",
        defaultMessage: "Voertuig info"
    },
    licensePlate: {
        id: "vehicle_info.license_plate",
        defaultMessage: "Nummerplaat"
    },
    driver: {
        id: "vehicle_info.driver",
        defaultMessage: "Chauffeur?"
    },
    yes: {
        id: "vehicle_info.yes",
        defaultMessage: "Ja"
    },
    no: {
        id: "vehicle_info.no",
        defaultMessage: "No"
    },
    reference: {
        id: "vehicle_info.referenceNumber",
        defaultMessage: "Referentienummer"
    },
});

export const VehiclePane = (props: IProps) => {
    const intl = useIntl();

    return (
        <VehicleInfo title={intl.formatMessage(MESSAGES.title)}>
            <Box>
                {
                    "driver" in props.person ?
                        <Row className="margin-bottom">
                            <Col span={12}>
                                <InfoLabel text={intl.formatMessage(MESSAGES.driver)}/>
                                <InfoField value={intl.formatMessage(props.person.driver ? MESSAGES.yes : MESSAGES.no)}/>
                            </Col>
                        </Row> :
                        null
                }
                {
                    props.person.licensePlate ?
                        <Row className="margin-bottom">
                            <Col span={12}>
                                <InfoLabel text={intl.formatMessage(MESSAGES.licensePlate)}/>
                                <InfoField value={props.person.licensePlate}/>
                            </Col>
                            {props.checkin?.supplierReference && (
                                <Col span={12}>
                                    <InfoLabel text={intl.formatMessage(MESSAGES.reference)}/>
                                    <InfoField value={props.checkin.supplierReference}/>
                                </Col>
                            )}
                        </Row> :
                        null
                }

            </Box>
        </VehicleInfo>
    );
};
