import { Button } from "antd";
import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import { CheckInState, ICheckIn } from "../../../../../types/checkIn.types";

interface IProps {
  onClick: () => void;
  checkIn: ICheckIn | null;
  disabled: boolean;
}

const MESSAGES = defineMessages({
    finish: {
        id: "check_in_modal_footer.reopen",
        defaultMessage: "Terug in wachtrij",
    }
});

export const ReopenCheckinButton = (props: IProps) => {
    const intl = useIntl();
    const states = [CheckInState.CANCELLED, CheckInState.FINISHED];

    const checkInState = (_checkInState: CheckInState) => {
        return _checkInState === CheckInState.FINISHED ? "primary": "default";
    };

    return props.checkIn && states.includes(props.checkIn.checkInState) ? (
        <Button onClick={props.onClick} type={checkInState(props.checkIn.checkInState)} disabled={props.disabled ?? false}>
            {intl.formatMessage(MESSAGES.finish)}
        </Button>
    ) : null;
};
