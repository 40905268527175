import { Alert, Checkbox, Table } from "antd";
import * as React from "react";
import { ICompartment } from "../../../../../../types/checkIn.types";
import { defineMessages, useIntl } from "react-intl";
import { compartmentMock } from "../../../../../../mocks/checkIn";
import { formatThousands, roundToTens } from "../../../../../../utils/format";

interface IProps {
    compartments: ICompartment[];
    initialWeight: number;
    weighingDateTime: string;
    overridden: boolean;
    maxWeightExceeded: boolean;
    temperatureUnknown: boolean;
}

const MESSAGES = defineMessages({
    capacity: {
        id: "compartment_info.capacity",
        defaultMessage: "Capac. (L)"
    },
    amount: {
        id: "compartment_info.amount",
        defaultMessage: "Hoev. (KG)"
    },
    nitrogen: {
        id: "compartment_info.nitrogen",
        defaultMessage: "Stikstof"
    },
    cleaned: {
        id: "compartment_info.cleaned",
        defaultMessage: "Gereinigd"
    },
    restProduct: {
        id: "compartment_info.rest_product",
        defaultMessage: "Rest?"
    },
    box: {
        id: "compartment_info.box",
        defaultMessage: "Vak"
    },
    total: {
        id: "compartment_info.total",
        defaultMessage: "Totaal"
    },
    overridden: {
        id: "compartment_info.overridden",
        defaultMessage: "Compartimentering werd niet correct ingegeven"
    },
    maxWeightExceeded: {
        id: "compartment_info.maxWeightExceeded",
        defaultMessage: "Maximum toegelaten gewicht overschreden"
    },
    unknownTemperature: {
        id: "compartment_info.temperature_unknown",
        defaultMessage: "Tanktemperatuur staat niet goed, geen compartimentering mogelijk"
    }
});

export const CompartmentsInfo = (props: IProps) => {
    const intl = useIntl();

    const compartments: ICompartment[] = [...props.compartments];
    // this mock is never actually displayed. The last row shows different information.
    // the other possibility is to add a separate component beneath the table displaying the 'total' information but \
    // this is a quick and dirty way to have something with nice css fitting the table
    compartments.push(compartmentMock);

    return (
        <>
            {
                props.overridden &&
                    <Alert
                        message={intl.formatMessage(MESSAGES.overridden)}
                        type="error"/>
            }
            {
                props.temperatureUnknown &&
                <Alert message={intl.formatMessage(MESSAGES.unknownTemperature)}
                    type={"error"}
                />
            }
            {
                props.maxWeightExceeded &&
                    <Alert
                        style={{ marginTop: 5 }}
                        message={intl.formatMessage(MESSAGES.maxWeightExceeded)}
                        type="error"/>
            }
            {
                props.initialWeight &&
                <p><b>Eerste weging: </b> {props.initialWeight.toLocaleString("de")} kg - {props.weighingDateTime}</p>
            }
            <Table
                dataSource={compartments}
                size="small"
                rowKey={(record) => (record.productionOrderId || 0).toString()}
                pagination={false}
            >
                <Table.Column
                    key="order"
                    dataIndex="order"
                    title={intl.formatMessage(MESSAGES.box)}
                    render={(val, record, index) => (index === props.compartments.length ? intl.formatMessage(MESSAGES.total) : formatThousands(val))}
                />
                <Table.Column
                    key="capacity"
                    dataIndex="capacity"
                    title={intl.formatMessage(MESSAGES.capacity)}
                    render={(val, record, index) => (index === props.compartments.length ?
                        formatThousands(props.compartments.reduce((tot, c) => tot + c.capacity, 0)) :
                        formatThousands(val)
                    )}
                />
                <Table.Column
                    key="amount"
                    dataIndex="loadAmount"
                    title={intl.formatMessage(MESSAGES.amount)}
                    render={(val, record, index) => {
                        const compartment = record as ICompartment;
                        const tolerance = compartment.toleranceLoadAmount;
                        return (index === props.compartments.length ?
                            formatThousands(roundToTens(props.compartments.reduce((tot, c) => tot + (c.loadAmount + (c?.toleranceLoadAmount || 0)), 0))) :
                            formatThousands(roundToTens(val + tolerance))
                        );
                    }}
                />
                <Table.Column
                    key="nitrogen"
                    dataIndex="nitrogen"
                    title={intl.formatMessage(MESSAGES.nitrogen)}
                    render={(val, record, index) => index === props.compartments.length ? "" : <Checkbox disabled checked={val}/>}
                />
                <Table.Column
                    key="cleaned"
                    dataIndex="cleaned"
                    title={intl.formatMessage(MESSAGES.cleaned)}
                    render={(val, record, index) => index === props.compartments.length ? "" : <Checkbox disabled checked={val}/>}
                />
                <Table.Column
                    key="restProduct"
                    dataIndex="restProduct"
                    title={intl.formatMessage(MESSAGES.restProduct)}
                    render={(val, record, index) => index === props.compartments.length ? "" : <Checkbox disabled checked={val}/>}
                />
            </Table>
        </>
    );
};
