import { useDispatch, useSelector } from "react-redux";
import { bookedSlotPageFetchActionCreator, bookedSlotPageLoadActionCreator } from "../../../store/actions";
import { bookedSlotLoadingPageSelector, bookedSlotPageSelector } from "../../../store/selectors/bookedSlot.selector";
import { IApplicationState } from "../../../store/state";
import { IPageRequest, IPageResponse } from "../../../types/api.types";
import { IBookedSlot, IBookedSlotPageFetchParams } from "../../../types/bookedSlot.types";
import { IPageResourceLoader } from "../../../types/hook.types";
import { mapPageResponseToPageRequest } from "../../../utils/mapping";

export type IBookedSlotPageLoader = IPageResourceLoader<IBookedSlot, IBookedSlotPageFetchParams>;

export const useBookedSlotPageLoader = (): IBookedSlotPageLoader => {
    const dispatch = useDispatch();

    const page = useSelector<IApplicationState, IPageResponse<IBookedSlot, IBookedSlotPageFetchParams> | null>(bookedSlotPageSelector);
    const loading = useSelector<IApplicationState, boolean>(bookedSlotLoadingPageSelector);

    const loadResource = (pageRequest: IPageRequest<IBookedSlotPageFetchParams>) => {
        return dispatch(bookedSlotPageLoadActionCreator(pageRequest));
    };

    const refresh = () => {
        const pageRequest = page ? mapPageResponseToPageRequest(page) : {
            pageNumber: 0,
            filter: {}
        };

        return dispatch(bookedSlotPageFetchActionCreator(pageRequest));
    };

    return {
        resource: page,
        loadingResource: loading,
        loadResource,
        refresh
    };
};
