import { IChecklistEntryStepSubmit } from "../../types/api.types";
import { getHttpClient } from "./httpClient";
import { URL_PATHS } from "../../constants/api.constants";

const baseUrl = URL_PATHS.CLEANING + "/checklist/";

const getChecklists = (compartmentId: number) => getHttpClient().get(`${baseUrl}?compartment=${compartmentId}`);
const submitChecklistEntryStep = (checklistId: number, checklistEntryStepSubmit: IChecklistEntryStepSubmit) =>
    getHttpClient().post(`${baseUrl}${checklistId}/entries`, checklistEntryStepSubmit);

export const checklistApi = {
    submitChecklistEntryStep,
    getChecklists
};
