import { Divider } from "antd";
import * as React from "react";
import {
    IChassis,
    ICheckIn,
    IContainer,
    ITanker,
    ITractor,
    TruckComposition
} from "../../../../../../types/checkIn.types";
import { TractorInfo } from "./TractorInfo";
import { TankerInfo } from "./TankerInfo";
import { ChassisInfo } from "./ChassisInfo";
import { ContainerInfo } from "./ContainerInfo";
import { filterOutBlendComponentsInProductionOrder } from "../../../../../../utils/order";

interface IProps {
    tractor?: ITractor;
    tanker?: ITanker;
    chassis?: IChassis;
    container?: IContainer;
    checkIn: ICheckIn;
}

export const VehiclesInfo = (props: IProps) => {
    const exemptAdr = props.checkIn.exemptAdr;
    const hasAdr = props.checkIn.salesOrders && props.checkIn.salesOrders[0] && props.checkIn.salesOrders[0].productionOrders.filter(filterOutBlendComponentsInProductionOrder)[0].adr;
    const showAdrInfo = exemptAdr || hasAdr === true;

    const assembleView = () => {
        const components = [];

        if (props.tractor) {
            if (components.length) {
                components.push(<Divider key={`${props.tractor.id}-${props.tractor.licensePlate}`}/>);
            }

            components.push(<TractorInfo key={props.tractor.id} tractor={props.tractor} isTruck={props.checkIn.truckComposition === TruckComposition.TRACTOR} showAdrInfo={showAdrInfo}/>);
        }

        if (props.tanker) {
            if (components.length) {
                components.push(<Divider key={`${props.tanker.id}-${props.tanker.licensePlate}`}/>);
            }

            components.push(<TankerInfo key={props.tanker.id} tanker={props.tanker} compartments={props.checkIn.compartmentDistributions} showAdrInfo={showAdrInfo} />);
        }

        if (props.chassis) {
            if (components.length) {
                components.push(<Divider key={`${props.chassis.id}-${props.chassis.licensePlate}`}/>);
            }

            components.push(<ChassisInfo key={props.chassis.id} chassis={props.chassis} showAdrInfo={showAdrInfo}/>);
        }

        if (props.container) {
            if (components.length) {
                components.push(<Divider key={`${props.container.containerNumber}-${props.container.tankCode}`}/>);
            }

            components.push(<ContainerInfo key={props.container.containerNumber} container={props.container} checkIn={props.checkIn} showAdrInfo={showAdrInfo} />);
        }

        return components;
    };

    return (
        <React.Fragment>
            {
                assembleView()
            }
        </React.Fragment>
    );
};
