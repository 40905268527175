import * as React from "react";
import { useEffect } from "react";
import { defineMessages, useIntl } from "react-intl";
import { customerServiceOrderUpdateDoneActionCreator } from "../../store/actions/customerservice";
import { IOrderUpdateDto } from "../../types/api.types";
import { State } from "../../types/order.types";
import { formatState } from "../../utils/format";
import { DropdownMenu } from "../components/modals/customerservicemodal/panes/statuspane/DropdownMenu";
import { useAllowedOrderTransitions } from "../hooks/useAllowedOrderTransitions";
import { useOrderUpdate } from "../hooks/useOrderUpdate";
import { useSelector } from "react-redux";
import { isExternalViewSelector } from "../../store/selectors/externalView.selector";

interface IProps {
    orderId: number;
}

const MESSAGES = defineMessages({
    stateDropdownButton: {
        id: "state_dropdown.button",
        defaultMessage: "Update status",
    },
    stateDropdownPlaceholder: {
        id: "state_dropdown.placeholder",
        defaultMessage: "Selecteer een status"
    }
});

export const TransitionDropdownContainer = (props: IProps) => {
    const [transitions, fetchingTransitions, fetchTransitions] = useAllowedOrderTransitions();
    const intl = useIntl();
    const [update, ordersBeingUpdated] = useOrderUpdate(customerServiceOrderUpdateDoneActionCreator);
    const isExternalView = useSelector(isExternalViewSelector);

    useEffect(() => {
        fetchTransitions(props.orderId);
    }, []);

    const onSave = (option: string) => {
        const subState = State[option as State];
        const orderUpdateDto: IOrderUpdateDto = {
            state: subState
        };

        update(props.orderId, orderUpdateDto);
    };

    return isExternalView ? null : (
        <DropdownMenu
            options={transitions}
            onSave={onSave}
            buttonText={intl.formatMessage(MESSAGES.stateDropdownButton)}
            placeholder={intl.formatMessage(MESSAGES.stateDropdownPlaceholder)}
            loading={fetchingTransitions}
            optionRenderer={(option) => formatState(option as State, intl)}
            saving={ordersBeingUpdated[props.orderId] && !!ordersBeingUpdated[props.orderId].state}
        />
    );
};
