import { Divider } from "antd";
import * as React from "react";
import { IOrder } from "../../../../../../types/order.types";
import { products } from "../../../../../../utils/order";
import { DateSection } from "../../../customerservicemodal/panes/detailinfopane/sections/DateSection";
import { InfoSection } from "../../../customerservicemodal/panes/detailinfopane/sections/InfoSection";
import { ITACTSection } from "../../../customerservicemodal/panes/detailinfopane/sections/ITACTSection";
import { PaidStorageSection } from "../../../customerservicemodal/panes/detailinfopane/sections/PaidStorageSection";
import { RestSection } from "../../../customerservicemodal/panes/detailinfopane/sections/RestSection";

interface IProps {
    order: IOrder;
    onItactNeededSave: (itactNeeded: boolean) => void;
    itactNeededSaving: boolean;
    manualWork?: boolean;
}

export const ItactOrderInfo = (props: IProps) => {
    const productsArr = products(props.order);
    const productsStr = productsArr.length ? productsArr.join(", ") : "-";

    return (
        <>
            <InfoSection
                customer={props.order.cleaningCustomer}
                containerNumber={props.order.container.sapId}
                product={productsStr}
                type={props.order.orderType}
                priorityPickerEnabled={false}
                clientOrderRef={props.order.clientOrderRef}
                deliveryId={props.order.deliveryId}
                sapId={props.order.sapId}
                externalViewLinkEnabled={false}
                savingPriority={false}
            />

            <Divider/>

            <DateSection
                orderId={props.order.id}
                savingDateParkingIn={false}
                savingDateParkingOut={false}
                dateParkingIn={props.order.dateParkingIn}
                dateCleaned={props.order.dateCleaned}
                dateAvailable={props.order.dateAvailable}
                dateParkingOut={props.order.dateParkingOut}
            />

            <Divider/>

            <RestSection compartments={props.order.compartments} showRestWeight={true}/>

            <Divider/>

            <ITACTSection
                itactNeeded={props.order.itactNeeded}
                editable
                onItactNeededSave={props.onItactNeededSave}
                savingItactNeeded={props.itactNeededSaving}
                manualWork={props.manualWork}
            />

            <Divider/>

            <PaidStorageSection
                paidStorageDays={props.order.storageDays}
                toplosser={props.order.toplosser}
                baffles={props.order.baffles}
            />
        </>
    );
};
