import * as React from "react";

interface IProps {
    title: string;
    timestamp?: string;
    className?: string;
}

export const MessageHeader = (props: IProps) => (
    <div className={`message-header flex space-between ${props.className}`}>
        <strong className="no-margin">{props.title}</strong>
        <span className="timestamp">{props.timestamp}</span>
    </div>
);
