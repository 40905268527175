import * as React from "react";
import { Col, Row } from "antd";
import { IContractor } from "../../../../types/checkIn.types";
import { useSelector } from "react-redux";
import { isAssignedToMeSelector } from "../../../../store/selectors/authentication.selector";
import { checkInRecordSelector } from "../../../../store/selectors/checkInOverview.selector";
import { DocumentsPane } from "./panes/documentsPane/DocumentsPane";
import { ActionsPane } from "./panes/actionsPane/ActionsPane";
import { PersonPane } from "./panes/personPane/PersonPane";
import { defineMessages, useIntl } from "react-intl";
import { VehiclePane } from "./panes/vehiclePane/VehiclePane";
import { ExamPane } from "./panes/examPane/ExamPane";
import { RemarksPane } from "./panes/remarksPanes/RemarksPane";

interface IProps {
    contractor: IContractor;
}

const MESSAGES = defineMessages({
    title: {
        id: "check_in_modal.contractor.title",
        defaultMessage: "Contractor info"
    }
});

export const ContractorCheckInDetail = (props: IProps) => {
    const intl = useIntl();
    const checkIn = useSelector(checkInRecordSelector);
    const isAssignedToMe = useSelector(isAssignedToMeSelector(checkIn?.assignedTo || null));

    return (
        <Row gutter={10}>
            <Col span={6}>
                <PersonPane person={props.contractor} title={intl.formatMessage(MESSAGES.title)}/>
            </Col>
            <Col span={6}>
                <VehiclePane person={props.contractor}/>
            </Col>
            <Col span={6}>
                <ExamPane person={props.contractor}/>
            </Col>

            <Col span={6}>
                {isAssignedToMe && <ActionsPane/>}
                <DocumentsPane checkIn={checkIn}/>
                <RemarksPane checkIn={checkIn}/>
            </Col>

        </Row>
    );
};
