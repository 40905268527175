import React, { useEffect, useState } from "react";
import { ISelfCheckInParameters, ISettingsUpdate, SettingsType } from "../../../../types/selfCheckIn.types";
import { defineMessages, useIntl } from "react-intl";
import { Pane } from "../../modals/customerservicemodal/panes/Pane";
import { Button, Form, InputNumber, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { settingsValuePerTypeSelector } from "../../../../store/selectors/selfCheckInSettings.selector";
import { getRequiredRule } from "../../../../utils/forms/general.utils";
import { settingsUpdateActionCreator } from "../../../../store/actions/selfCheckInSettings.actions";

const MESSAGES = defineMessages({
    title: {
        id: "time_out_settings_pane.title",
        defaultMessage: "Time out instellingen"
    },
    idleTimer: {
        id: "time_out_settings_pane.idle_timer",
        defaultMessage: "Pop-up na x seconden:"
    },
    popUpTimer: {
        id: "time_out_settings_pane.pop_up_timer",
        defaultMessage: "Pop-up timer:"
    },
    idleLabel: {
        id: "time_out_settings_pane.idle_timer.label",
        defaultMessage: "Idle"
    },
    popUpLabel: {
        id: "time_out_settings_pane.pop_up_timer.label",
        defaultMessage: "Pop-up"
    },
    save: {
        id: "time_out_settings_pane.save_button.label",
        defaultMessage: "Time out instellingen opslaan"
    }
});

export const TimeOutSettingsPane = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const idleTimer = useSelector(settingsValuePerTypeSelector(SettingsType.IDLE_TIMER));
    const popUpTimer = useSelector(settingsValuePerTypeSelector(SettingsType.POP_UP_TIMER));
    const [loading, setLoading] = useState<boolean>(false);

    enum settingsForm {
        IDLE_TIMER = "IDLE_TIMER",
        POP_UP_TIMER = "POP_UP_TIMER",
    }

    useEffect(() => {
        if (idleTimer) {
            form.setFieldsValue({ [settingsForm.IDLE_TIMER]: idleTimer.value });
        }
        if (popUpTimer) {
            form.setFieldsValue({ [settingsForm.POP_UP_TIMER]: popUpTimer.value });
        }
    }, [idleTimer, popUpTimer]);

    const onSubmit = (values: any) => {
        if (idleTimer && popUpTimer) {
            setLoading(true);
            const idleTimerParams: ISelfCheckInParameters = {
                settingType: SettingsType.IDLE_TIMER,
                value: values.IDLE_TIMER
            };
            const popUpTimerParams: ISelfCheckInParameters = {
                settingType: SettingsType.POP_UP_TIMER,
                value: values.POP_UP_TIMER
            };

            const settingsToUpdate: ISettingsUpdate[] = [
                { parameter: idleTimerParams },
                { parameter: popUpTimerParams }
            ];

            dispatch(settingsUpdateActionCreator({ data: settingsToUpdate, callback: () => setLoading(false) }));
        }
    };

    return (loading ? <Spin /> :
        <Pane className="margin-top" title={intl.formatMessage(MESSAGES.title)}>
            <Form
                name={"settings"}
                form={form}
                autoComplete={"off"}
                onFinish={onSubmit}
            >
                {idleTimer &&
                    <Form.Item
                        name={settingsForm.IDLE_TIMER}
                        label={intl.formatMessage(MESSAGES.idleTimer)}
                        rules={[getRequiredRule(intl.formatMessage(MESSAGES.idleLabel), intl)]}>
                        <InputNumber
                            min={0}
                            max={600}
                        />
                    </Form.Item>
                }
                {popUpTimer &&
                    <Form.Item
                        name={settingsForm.POP_UP_TIMER}
                        label={intl.formatMessage(MESSAGES.popUpTimer)}
                        rules={[getRequiredRule(intl.formatMessage(MESSAGES.popUpLabel), intl)]}
                    >
                        <InputNumber
                            min={0}
                            max={600}
                        />
                    </Form.Item>
                }
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        {intl.formatMessage(MESSAGES.save)}
                    </Button>
                </Form.Item>
            </Form>
        </Pane>
    );
};