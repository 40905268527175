import { createAction } from "@reduxjs/toolkit";
import * as Msal from "msal";
import { IFailedActionPayload } from "../../types/action.types";

enum AuthenticationActionTypes {
    LOGIN = "[Application] LOGIN",
    LOGIN_OK = "[Application] LOGIN_OK",
    LOGIN_FAILED = "[Application] LOGIN_FAILED",
    LOGOUT = "[Application] LOGOUT",
    UNAUTHORIZED_ACTION = "[Application] UNAUTHORIZED_ACTION"
}

export const loginAction = createAction(AuthenticationActionTypes.LOGIN);
export const logoutAction = createAction(AuthenticationActionTypes.LOGOUT);
export const loginOkAction = createAction<Msal.AuthResponse, AuthenticationActionTypes.LOGIN_OK>(AuthenticationActionTypes.LOGIN_OK);
export const loginFailedAction = createAction<Error, AuthenticationActionTypes.LOGIN_FAILED>(AuthenticationActionTypes.LOGIN_FAILED);
export const unauthorizedAction = createAction<IFailedActionPayload>(AuthenticationActionTypes.UNAUTHORIZED_ACTION);
