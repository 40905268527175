import * as React from "react";
import { Pane } from "../../../customerservicemodal/panes/Pane";
import { IContractor, ISupplier, IVisitor } from "../../../../../../types/checkIn.types";
import { Box } from "../../../../Box";
import { PersonInfo } from "./PersonInfo";

interface IProps {
    person: ISupplier|IContractor|IVisitor;
    title: string;
}

export const PersonPane = (props: IProps) => {
    return (
        <Pane title={props.title}>
            <Box>
                <PersonInfo person={props.person}/>
            </Box>
        </Pane>
    );
};
