import { useDispatch, useSelector } from "react-redux";
import { ttatCalculationFetchCreator, ttatCalculationReceivedCreator } from "../../store/actions";
import { ITtatCalculation, ITtatCalculationFetchParams } from "../../types/ttatCalculation.types";
import { IApplicationState } from "../../store/state";

export const useTtatCalculation = (): [
    (ttatCalculationFetchParams: ITtatCalculationFetchParams) => void,
    (ttatCalculation: ITtatCalculation | null) => void,
    ITtatCalculation | null
] => {
    const ttatCalculation = useSelector<IApplicationState, ITtatCalculation | null>(state => state.slot.ttatCalculation);
    const dispatch = useDispatch();
    const fetch = (ttatCalculationFetchParams: ITtatCalculationFetchParams) => dispatch(ttatCalculationFetchCreator({ ttatCalculationFetchParams }));
    const assign = (ttatCalculation: ITtatCalculation | null) => dispatch(ttatCalculationReceivedCreator(ttatCalculation));

    return [fetch, assign, ttatCalculation];
};
