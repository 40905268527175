import React, { useEffect, useState } from "react";
import { Alert, Button, Input, Modal, Select } from "antd";
import { ModalFooter } from "./ModalFooter";
import { defineMessages, useIntl } from "react-intl";
import { SearchOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
    checkInAddLinkSalesOrderToManualOrderActionCreator,
    checkInGetSalesOrderIdForLinkActionCreator,
    checkInUpdateLinkSalesOrderToManualOrderActionCreator
} from "../../../../store/actions/checkIn";
import { StorageLocation } from "../../../../types/salesOrder.types";
import {
    approvedSalesOrderIdSelector,
    salesOrderLinkedToManualOrderSelector
} from "../../../../store/selectors/checkInOverview.selector";
import { ICheckIn } from "../../../../types/checkIn.types";

interface IProps {
    manualOrderId: number | null;
    onClose:() => void;
    activeCheckIn: ICheckIn;
}

const MESSAGES = defineMessages({
    cancel: {
        id: "linkSalesOrderFooter.close",
        defaultMessage: "Annuleer koppeling"
    },
    submit: {
        id: "linkSalesOrderFooter.submit",
        defaultMessage: "Koppel sales order aan check-in"
    },
    salesOrder: {
        id: "linkSalesOrderFooter.salesOrder",
        defaultMessage: "Sales order nummer"
    },
    search: {
        id: "linkSalesOrderFooter.search",
        defaultMessage: "Zoek salesorder"
    },
    terminal: {
        id: "linkSalesOrderFooter.terminal",
        defaultMessage: "Terminal"
    },
    salesOrderFound: {
        id: "linkSalesOrderFooter.salesOrderFound",
        defaultMessage: "Salesorder gevonden; koppeling mogelijk."
    },
});

export const LinkSalesOrderModal = (props: IProps) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [inputValue, setInputValue] = useState<string>("");
    const [terminalSelectValue, setTerminalSelectValue] = useState<StorageLocation>(StorageLocation.ADPO);
    const [responseType, setResponseType] = useState<"success" | "warning" | null>(null);
    const [response, setResponse] = useState<string | null>(null);
    const approvedSalesOrderId = useSelector(approvedSalesOrderIdSelector);
    const linkedSalesOrder = useSelector(salesOrderLinkedToManualOrderSelector(props.manualOrderId || -1));

    useEffect(() => {
        if (linkedSalesOrder) {
            setInputValue(linkedSalesOrder.reservationId);
            setTerminalSelectValue(linkedSalesOrder.productionOrders[0].storageLocation);
        }
    }, [linkedSalesOrder]);

    const onClose = () => {
        setTerminalSelectValue(StorageLocation.ADPO);
        setResponse(null);
        setResponseType(null);
        setInputValue("");
        props.onClose();
    };

    const updateCheckin = () => {
        const payload = {
            checkinId: props.activeCheckIn.id,
            salesOrderId: approvedSalesOrderId as number,
            manualOrderId: props.manualOrderId as number
        };
        const isEdit = !!linkedSalesOrder;
        if (isEdit) {
            dispatch(checkInUpdateLinkSalesOrderToManualOrderActionCreator(payload));
        } else {
            dispatch(checkInAddLinkSalesOrderToManualOrderActionCreator(payload));
        }
        onClose();
    };

    const renderFooter = () => (
        <ModalFooter
            disabled={!approvedSalesOrderId || approvedSalesOrderId === linkedSalesOrder?.id}
            onClose={onClose}
            onSubmit={updateCheckin}
            cancelLabel={intl.formatMessage(MESSAGES.cancel)}
            confirmLabel={intl.formatMessage(MESSAGES.submit)}
        />
    );

    const checkLinkedCallBack = (response: any | null) => {
        if (response?.status) {
            setResponse(response.data.detail);
            setResponseType("warning");
        } else {
            setResponse(intl.formatMessage(MESSAGES.salesOrderFound));
            setResponseType("success");
        }
    };

    const onSearch = () => {
        setResponseType(null);
        setResponse(null);
        dispatch(checkInGetSalesOrderIdForLinkActionCreator({ reservationId: inputValue, callBack: checkLinkedCallBack, terminal: terminalSelectValue }));
    };

    return (
        <Modal
            open={!!props.manualOrderId}
            className="link-sales-order-modal"
            onCancel={onClose}
            destroyOnClose
            centered
            width={600}
            footer={renderFooter()}
        >
            <div className="flex-column align-items-center">
                <h3>{intl.formatMessage(MESSAGES.submit)}</h3>
                <div className="flex-row wrap margin-double-top margin-bottom padded-right padded-left fill-width">
                    <div className="width-50-percent flex-row align-items-center">
                        <span className="margin-left-auto margin-right">{intl.formatMessage(MESSAGES.terminal)}:</span>
                    </div>
                    <Select value={terminalSelectValue} onSelect={(e) => setTerminalSelectValue(e)}>
                        {Object.values(StorageLocation).map(terminal => (
                            <Select.Option key={terminal} value={terminal}>{terminal}</Select.Option>
                        ))}
                    </Select>
                </div>
                <div className="flex-row wrap margin-bottom padded-right padded-left fill-width">
                    <div className="width-50-percent flex-row align-items-center">
                        <span className="margin-left-auto margin-right">{intl.formatMessage(MESSAGES.salesOrder)}:</span>
                    </div>
                    <Input value={inputValue} className="width-30-percent" onChange={(e) => setInputValue(e.target.value)} />
                </div>
                <div className="flex-row wrap margin-double-bottom padded-right padded-left fill-width">
                    <div className="width-50-percent"/>
                    <Button disabled={!inputValue} className="width-30-percent" onClick={onSearch} type="primary">
                        <SearchOutlined /> {intl.formatMessage(MESSAGES.search)}
                    </Button>
                </div>
                {responseType &&
                <Alert
                    message={response}
                    type={responseType}
                    showIcon
                />
                }
            </div>
        </Modal>
    );
};
