import { getHttpClient } from "./httpClient";
import { ISlotFetchParams } from "../../types/slot.types";
import { URL_PATHS } from "../../constants/api.constants";

const baseUrl = URL_PATHS.BOOKING + "/tanks/";

const getSlots = (tankId: number, slotFetchParams: ISlotFetchParams) => getHttpClient()
    .get(`${baseUrl}${tankId}/slots`, { params: slotFetchParams });

export const slotApi = {
    getSlots,
};
