import { Alert, Col, Row } from "antd";
import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import { ICheckIn, IDriver } from "../../../../../../types/checkIn.types";
import { formatBoolField, formatIso8601Date } from "../../../../../../utils/format";
import { InfoField } from "../../../customerservicemodal/panes/detailinfopane/sections/infofields/InfoField";
import { InfoLabel } from "../../../customerservicemodal/panes/detailinfopane/sections/infofields/InfoLabel";
import { useSelector } from "react-redux";
import { checkInRecordHasExpiredByAdrType } from "../../../../../../store/selectors/checkInOverview.selector";
import { AdrTypes } from "../../../../../../types/adr.types";
import { filterOutBlendComponentsInProductionOrder } from "../../../../../../utils/order";

interface IProps {
    checkIn: ICheckIn;
    driver: IDriver;
}

const MESSAGES = defineMessages({
    firstName: {
        id: "driver_info.first_name",
        defaultMessage: "Voornaam"
    },
    lastName: {
        id: "driver_info.last_name",
        defaultMessage: "Achternaam"
    },
    birthDate: {
        id: "driver_info.birth_day",
        defaultMessage: "Geboortedatum"
    },
    transportCompany: {
        id: "driver_info.trasport_company",
        defaultMessage: "Transportfirma"
    },
    adrCode: {
        id: "driver_info.adr_code",
        defaultMessage: "ADR code"
    },
    adrExpiration: {
        id: "driver_info.adr_expiration_date",
        defaultMessage: "ADR vervaldatum"
    },
    adrExpiredWarning: {
        id: "driver_info.adr_expired_warning",
        defaultMessage: "ADR code is vervallen."
    },
    requiredCertificatesWarning: {
        id: "driver_info.required_certificates_warning",
        defaultMessage: "Extra certificaat vereist: {certificates}"
    },
    certificateExpiredWarning: {
        id: "driver_info.certificate_expired_warning",
        defaultMessage: "Certificaat vervallen: {certificate}"
    },
    certificateExpirationDate: {
        id: "driver_info.certificate_expiration_date",
        defaultMessage: "Vervaldatum"
    },
    phoneNumber: {
        id: "driver_info.phone_number",
        defaultMessage: "GSM nummer"
    }
});

export const DriverInfo = (props: IProps) => {
    const intl = useIntl();
    const driverAdrExpired = useSelector(checkInRecordHasExpiredByAdrType(AdrTypes.DRIVER));
    const exemptAdr = props.checkIn.exemptAdr;
    const hasAdr = props.checkIn.salesOrders && props.checkIn.salesOrders[0] && props.checkIn.salesOrders[0].productionOrders.filter(filterOutBlendComponentsInProductionOrder)[0].adr;
    const showAdrInfo = exemptAdr || hasAdr === true;
    const requiredCertificates = props.checkIn.salesOrders && props.checkIn.salesOrders[0] && props.checkIn.salesOrders[0].requiredCertificates;
    const certificates = props.checkIn.certificates;

    return (

        <>
            <Row className="margin-bottom">
                <Col span={12}>
                    <InfoLabel text={intl.formatMessage(MESSAGES.firstName)}/>
                    <InfoField value={props.driver.firstName}/>
                </Col>
                <Col span={12}>
                    <InfoLabel text={intl.formatMessage(MESSAGES.birthDate)}/>
                    <InfoField value={formatIso8601Date(props.driver.dateOfBirth)}/>
                </Col>
            </Row>
            <Row className="margin-bottom">
                <Col span={12}>
                    <InfoLabel text={intl.formatMessage(MESSAGES.lastName)}/>
                    <InfoField value={props.driver.lastName}/>
                </Col>
                <Col span={12}>
                    <InfoLabel text={intl.formatMessage(MESSAGES.transportCompany)}/>
                    <InfoField value={props.driver.transportCompany}/>
                </Col>
            </Row>
            {
                props.driver.adrCertificate && showAdrInfo &&
                <>
                    <Row className="margin-bottom">
                        <Col span={12}>
                            <InfoLabel text={intl.formatMessage(MESSAGES.adrCode)}/>
                            <InfoField value={props.driver.adrCertificate.adrCode}/>
                        </Col>
                        <Col span={12}>
                            <InfoLabel text={intl.formatMessage(MESSAGES.adrExpiration)}/>
                            <InfoField value={formatIso8601Date(props.driver.adrCertificate.adrExpirationDate)}/>
                        </Col>
                    </Row>
                    {
                        showAdrInfo && driverAdrExpired &&
                        <Row className="margin-bottom">
                            <Col span={24}>
                                <Alert message={intl.formatMessage(MESSAGES.adrExpiredWarning)}
                                    type={"error"}
                                />
                            </Col>

                        </Row>
                    }
                </>
            }
            {
                requiredCertificates && requiredCertificates.length > 0 &&
                <Row className="margin-bottom">
                    <Col span={24}>
                        <Alert message={intl.formatMessage(MESSAGES.requiredCertificatesWarning, { certificates:  requiredCertificates?.map(certificate => certificate.name).join(", ") })}
                            type={"error"}
                        />
                    </Col>
                </Row>
            }
            {
                certificates && certificates?.map((certificate) =>
                    <>
                        <Row className="margin-bottom" key={certificate.name}>
                            <Col span={12}>
                                <InfoLabel text={certificate.name}/>
                                <InfoField value={formatBoolField(certificate.present, intl)}/>
                            </Col>
                            <Col span={12}>
                                <InfoLabel text={intl.formatMessage(MESSAGES.certificateExpirationDate)}/>
                                <InfoField value={formatIso8601Date(certificate.expirationDate)}/>
                            </Col>
                        </Row>
                        { certificate.expired &&
                        <Row className="margin-bottom">
                            <Col span={24}>
                                <Alert message={intl.formatMessage(MESSAGES.certificateExpiredWarning, { certificate: certificate.name })}
                                    type={"error"}
                                />
                            </Col>
                        </Row>
                        }
                    </>
                )}
            {
                props.driver.countryCallingCode && props.driver.phoneNumber &&
                    <Row className="margin-bottom">
                        <Col span={12}>
                            <InfoLabel text={intl.formatMessage(MESSAGES.phoneNumber)}/>
                            <InfoField value={"+" + props.driver.countryCallingCode + " - " + props.driver.phoneNumber}/>
                        </Col>
                    </Row>
            }
        </>
    );
};
