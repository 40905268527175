import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import { ItactDoneButtonContainer } from "../../../../../containers/ItactDoneButtonContainer";
import { StateTimelineContainer } from "../../../../../containers/StateTimelineContainer";
import { Box } from "../../../../Box";
import { Pane } from "../../../customerservicemodal/panes/Pane";

interface IProps {
    orderId: number;
}

const MESSAGES = defineMessages({
    title: {
        id: "itact_status_pane.title",
        defaultMessage: "Status info"
    }
});

export const ItactStatusPane = (props: IProps) => {
    const intl = useIntl();

    return (
        <Pane title={intl.formatMessage(MESSAGES.title)}>
            <Box>
                <StateTimelineContainer orderId={props.orderId} className="margin-bottom"/>
                <div className="fill-width flex justify-center">
                    <ItactDoneButtonContainer orderId={props.orderId}/>
                </div>
            </Box>
        </Pane>
    );
};
