import * as React from "react";
import { useDispatch } from "react-redux";
import { checklistStepSubmitActionCreator } from "../../../store/actions/checklist";
import { IChecklistEntryStepSubmit } from "../../../types/api.types";
import { ChecklistItem } from "../../../types/checklist.types";
import { UNNumberForm } from "../../components/tabbars/cleaningtabs/cleaningtab/compartmenttab/checklist/forms/UNNumberForm";

interface IProps {
    unNumber: string;
    prev: () => void;
    checklistId: number;
    compartmentId: number;
}

export const UNNumberFormContainer = (props: IProps) => {
    const dispatch = useDispatch();

    const submit = (error: boolean, fields: string, submittedBy?: string, msg?: string) => {
        const checklistEntryStepSubmit: IChecklistEntryStepSubmit = {
            errorDetected: error,
            customerMessage: msg,
            stepOrder: 1,
            checklistItem: ChecklistItem.PRE_OPERATION_CHECKLIST,
            formFields: fields,
            restFound: false,
            manualWorkNeeded: false,
            submittedBy
        };

        dispatch(checklistStepSubmitActionCreator({
            checklistId: props.checklistId,
            checklistEntryStepSubmit,
            compartmentId: props.compartmentId
        }));
    };

    const onError = (fields: string, submittedBy: string, msg?: string) => {
        submit(true, fields, submittedBy, msg);
    };

    const onOk = (fields: string) => {
        submit(false, fields);
    };

    return (
        <UNNumberForm
            unNumber={props.unNumber}
            onError={onError}
            onOk={onOk}
            prev={props.prev}
        />
    );
};
