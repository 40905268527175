import * as React from "react";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import { NOTIFICATION_TYPES } from "../../constants/notification";
import { BlockAlreadyScheduledNotification } from "../components/notifications/BlockAlreadyScheduledNotification";
import { BlockSlotBookingsExistNotification } from "../components/notifications/BlockSlotBookingsExistNotification";
import { BlockSucceededNotification } from "../components/notifications/BlockSucceededNotification";
import { ClipBoardWrittenNotification } from "../components/notifications/ClipBoardWrittenNotification";
import { NotTruckBlendNotification } from "../components/notifications/NotTruckBlendNotification";
import { ProductionOrdersNotFoundNotification } from "../components/notifications/ProductionOrdersNotFoundNotification";
import { ProductsNotFoundNotification } from "../components/notifications/ProductsNotFoundNotification";
import { SalesOrderNotFoundNotification } from "../components/notifications/SalesOrderNotFoundNotification";
import { SlotBookingSucceededNotification } from "../components/notifications/SlotBookingSucceededNotification";
import { SlotBookingUpdateSucceededNotification } from "../components/notifications/SlotBookingUpdateSucceededNotification";
import { SlotNotFoundNotification } from "../components/notifications/SlotNotFoundNotification";
import { useNotification } from "../hooks/useNotification";
import { AlreadyBookingSalesOrderNotification } from "../components/notifications/AlreadyBookingSalesOrderNotification";

export const NotificationManager = () => {
    const [notification, , resetNotification] = useNotification();
    const intl = useIntl();

    useEffect(() => {
        resetNotification();
    }, []);

    switch (notification) {
    case NOTIFICATION_TYPES.BOOK_SLOT_SUCCEEDED:
        return <SlotBookingSucceededNotification intl={intl} reset={resetNotification}/>;
    case NOTIFICATION_TYPES.UPDATE_SLOT_SUCCEEDED:
        return <SlotBookingUpdateSucceededNotification intl={intl} reset={resetNotification}/>;
    case NOTIFICATION_TYPES.BLOCK_SUCCEEDED:
        return <BlockSucceededNotification intl={intl} reset={resetNotification}/>;
    case NOTIFICATION_TYPES.BLOCK_ALREADY_SCHEDULED:
        return <BlockAlreadyScheduledNotification intl={intl} reset={resetNotification}/>;
    case NOTIFICATION_TYPES.BLOCK_SLOT_BOOKINGS_EXIST:
        return <BlockSlotBookingsExistNotification intl={intl} reset={resetNotification}/>;

    case NOTIFICATION_TYPES.SALES_ORDER_NOT_FOUND:
        return <SalesOrderNotFoundNotification intl={intl} reset={resetNotification}/>;
    case NOTIFICATION_TYPES.PRODUCTION_ORDERS_NOT_FOUND:
        return <ProductionOrdersNotFoundNotification intl={intl} reset={resetNotification}/>;
    case NOTIFICATION_TYPES.PRODUCTS_NOT_FOUND:
        return <ProductsNotFoundNotification intl={intl} reset={resetNotification}/>;
    case NOTIFICATION_TYPES.SLOT_NOT_FOUND:
        return <SlotNotFoundNotification intl={intl} reset={resetNotification}/>;

    case NOTIFICATION_TYPES.WRITTEN_TO_CLIPBOARD:
        return <ClipBoardWrittenNotification intl={intl} reset={resetNotification}/>;
    case NOTIFICATION_TYPES.NOT_TRUCK_BLEND_BOOKING_TYPE:
        return <NotTruckBlendNotification reset={resetNotification}/>;

    case NOTIFICATION_TYPES.ALREADY_BOOKING_SALES_ORDER:
        return <AlreadyBookingSalesOrderNotification intl={intl} reset={resetNotification}/>;

    default:
        return null;
    }
};
