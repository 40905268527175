import { useDispatch } from "react-redux";
import {
    customerServiceCsvDownloadOnlyFinishedOrdersActionCreator,
} from "../../../store/actions/customerservice";

export const useCsvDownloadOnlyFinishedOrdersCustomerService = () => {
    const dispatch = useDispatch();

    const requestResource = () => dispatch(customerServiceCsvDownloadOnlyFinishedOrdersActionCreator());

    return {
        resource: null,
        requestResource: requestResource
    };
};
