import { ITtatCalculation } from "../types/ttatCalculation.types";
import { ISelectedSlot } from "../types/slot.types";
import { TIME_FORMATS } from "../constants/time";
import dayjs, { Dayjs } from "dayjs";
import duration, { Duration } from "dayjs/plugin/duration";
dayjs.extend(duration);

export const calculateTotalTime = (ttatCalculation: ITtatCalculation): Duration => {
    const arrivalTime = dayjs.duration(ttatCalculation.arrivalTime);
    const tankTime = dayjs.duration(ttatCalculation.tankTime);
    const departureTime = dayjs.duration(ttatCalculation.departureTime);
    return arrivalTime.add(tankTime).add(departureTime);
};

export const calculateTankStartTime = (selectedSlot: ISelectedSlot, ttatCalculation: ITtatCalculation): Duration => {
    const arrivalTime = dayjs.duration(ttatCalculation.arrivalTime);
    return dayjs.duration(selectedSlot.startTime).add(arrivalTime);
};

export const calculateTankEndTime = (selectedSlot: ISelectedSlot, ttatCalculation: ITtatCalculation): Duration => {
    const tankTime = dayjs.duration(ttatCalculation.tankTime);
    const tankTimeStart = calculateTankStartTime(selectedSlot, ttatCalculation);
    return tankTimeStart.add(tankTime);
};

export const calculateEndTime = (selectedSlot: ISelectedSlot, ttatCalculation: ITtatCalculation): Duration => {
    return dayjs.duration(selectedSlot.startTime).add(calculateTotalTime(ttatCalculation));
};

export const calculateUntilDate = (selectedSlot: ISelectedSlot, ttatCalculation: ITtatCalculation): Dayjs => {
    return dayjs(selectedSlot.startDate).add(calculateTotalTime(ttatCalculation));
};

export const durationToTimeString = (duration: Duration): string => {
    return dayjs.utc(duration.asMilliseconds()).format(TIME_FORMATS.TIME);
};

export const momentToIsoString = (moment: Dayjs): string => {
    return moment.toISOString();
};
