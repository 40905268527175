import { URL_PATHS } from "../../constants/api.constants";
import { ICheckInFilter, IPageRequest } from "../../types/api.types";
import { formatPageQueryParams } from "../../utils/format";
import { getHttpClient } from "./httpClient";
import { CheckInEvent, CheckInReason, IBadgeParameters, IUpdateCheckInParameters } from "../../types/checkIn.types";
import { StorageLocation } from "../../types/salesOrder.types";

export const checkInBaseUrl = URL_PATHS.CHECKIN + "/check-ins";
export const ADMIN_URL_CHECK_INS = `${URL_PATHS.CHECKIN}/admin/check-ins`;

export const getCheckInPage = (pageRequest: IPageRequest<ICheckInFilter>) => {
    if (Object.keys(pageRequest.filter).indexOf("archived") < 0) {
        pageRequest.filter.archived = false;
    }
    return getHttpClient().get(`${ADMIN_URL_CHECK_INS}${formatPageQueryParams(pageRequest)}`);
};
const getRecord = (checkInId: number) => getHttpClient().get(`${ADMIN_URL_CHECK_INS}/${checkInId}`);
const cancel = (checkInId: number, reason: string) => getHttpClient().post(`${checkInBaseUrl}/${checkInId}/cancel`, { reason });
const validate = (checkInId: number, cmrWeight: number) => getHttpClient().post(`${checkInBaseUrl}/${checkInId}/validate`, { cmrWeight });
const assign = (checkInId: number) => getHttpClient().post(`${checkInBaseUrl}/${checkInId}/transition`, { event: CheckInEvent.ASSIGN });
const unAssign = (checkInId: number) => getHttpClient().post(`${checkInBaseUrl}/${checkInId}/transition`, { event: CheckInEvent.UN_ASSIGN });
const finish = (checkInId: number) => getHttpClient().post(`${checkInBaseUrl}/${checkInId}/transition`, { event: CheckInEvent.FINISH });
const reopen = (checkInId: number) => getHttpClient().post(`${checkInBaseUrl}/${checkInId}/transition`, { event: CheckInEvent.REOPEN });
export const postReturnToKiosk = (checkInId: number) => getHttpClient().post(`${checkInBaseUrl}/${checkInId}/transition`, { event: CheckInEvent.RETURN_TO_KIOSK });
export const deleteCheckInApi = (checkInId: number) => getHttpClient().delete(`${ADMIN_URL_CHECK_INS}/${checkInId}`);
export const putCheckin = (checkInId: number, data: IUpdateCheckInParameters) => getHttpClient().put(`${ADMIN_URL_CHECK_INS}/${checkInId}`, data);
export const postBadge = (checkInId: number, data: IBadgeParameters) => getHttpClient().post(`${ADMIN_URL_CHECK_INS}/${checkInId}/activate-badge`, data);
export const linkBadge = (checkInId: number, data: IBadgeParameters) => getHttpClient().post(`${ADMIN_URL_CHECK_INS}/${checkInId}/link-badge`, data);
const getCompartmentsAsHtml = (checkInId: number) => {
    const definedConfig = { headers: { "Content-Type": "text/html" } };
    return getHttpClient().get(`${checkInBaseUrl}/${checkInId}/compartment-distributions?report=compartment-report`, definedConfig);
};
export const getLinkedSalesOrder = (reservationId: string, type: CheckInReason, terminal: StorageLocation) => getHttpClient().get(`${ADMIN_URL_CHECK_INS}/sales-order/${reservationId}?type=${type}&terminal=${terminal}`);
export const postLinkSalesOrderToManualOrder = (checkInId: number, manualOrderId: number, salesOrderId: number) => getHttpClient().post(`${ADMIN_URL_CHECK_INS}/${checkInId}/link-manual-order`, { manualOrderId, salesOrderId });
export const putLinkSalesOrderToManualOrder = (checkInId: number, manualOrderId: number, salesOrderId: number) => getHttpClient().put(`${ADMIN_URL_CHECK_INS}/${checkInId}/link-manual-order`, { manualOrderId, salesOrderId });

export const checkInApi = {
    getRecord,
    cancel,
    validate,
    assign,
    unAssign,
    finish,
    reopen,
    getCompartmentsAsHtml,
};
