import { combineReducers, createReducer, PayloadAction } from "@reduxjs/toolkit";
import { setHideHeaderActionCreator } from "../actions/ui.actions";
import { IUiState } from "../../types/ui.types";

const hideHeader = createReducer<boolean>(false, {
    [setHideHeaderActionCreator.type]: (state, { payload }: PayloadAction<boolean>) => payload
});

export const uiReducer = combineReducers<IUiState>({
    hideHeader
});
