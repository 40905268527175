import * as React from "react";
import { BookedSlotsTableContainer } from "../containers/tablecontainers/BookedSlotsTableContainer";
import { AppLayout } from "./AppLayout";

export const BookedSlotsScreen = () => {
    return (
        <AppLayout>
            <BookedSlotsTableContainer/>
        </AppLayout>
    );
};
