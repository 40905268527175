import { Layout } from "antd";
import * as React from "react";
import { TopBar } from "../components/topbar/TopBar";
import { PageTitle } from "../components/topbar/pagetitle/PageTitle";
import { ApplicationVersion } from "../components/version/ApplicationVersion";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { hideHeaderSelector } from "../../store/selectors/ui.selector";
import { Paths } from "../../types/api.types";

type Props = {
    children?: React.ReactNode;
};

export const AppLayout = (props: Props) => {
    const { Content } = Layout;
    const location = useLocation();
    const hideHeader = useSelector(hideHeaderSelector);

    const getHideTitle = () => {
        return location.pathname === Paths.CHECKIN_OVERVIEW_READ_ONLY && hideHeader;
    };

    return (
        <Layout className="full-page" hasSider>
            <Layout>
                <TopBar/>
                <Content className="padded scroll" style={{ backgroundColor: "white" }}>
                    {!getHideTitle() &&
                        <PageTitle />
                    }
                    {props.children}
                </Content>
                <ApplicationVersion/>
            </Layout>
        </Layout>
    );
};
