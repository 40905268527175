import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { ExternalViewStateTimelineContainer } from "../../../../../containers/ExternalViewStateTimelineContainer";
import { Box } from "../../../../Box";
import { Pane } from "../../../customerservicemodal/panes/Pane";

interface IProps {
    id: number;
    uuid: string;
}

const MESSAGES = defineMessages({
    title: {
        defaultMessage: "Status info",
        id: "status_pane.title"
    }
});

export const ExternalViewStatusPane = (props: IProps) => {
    const intl = useIntl();

    return (
        <Pane title={intl.formatMessage(MESSAGES.title)}>
            <Box>
                <ExternalViewStateTimelineContainer orderId={props.id} uuid={props.uuid}/>
            </Box>
        </Pane>
    );
};
