import { getHttpClient } from "./httpClient";
import { ISalesOrderListFetchParams } from "../../types/salesOrder.types";
import { URL_PATHS } from "../../constants/api.constants";

const baseUrl = URL_PATHS.BOOKING + "/sales-orders/";

const getSalesOrder = (salesOrderReference: string) => getHttpClient().get(`${baseUrl}${salesOrderReference}`);
const getSalesOrderList = (salesOrderListFetch: ISalesOrderListFetchParams) => getHttpClient().get(baseUrl, { params: salesOrderListFetch });

export const salesOrderApi = {
    getSalesOrder,
    getSalesOrderList,
};
