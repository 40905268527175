import { Spin, Tabs } from "antd";
import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import { ORDER_FILTERS } from "../../../../constants/order";
import { PlanningTableContainer } from "../../../containers/tablecontainers/PlanningTableContainer";

interface IProps {
    ctrTotalPriorityElements?: number;
    ctrTotalElements?: number;
    restTotalPriorityElements?: number;
    restTotalElements?: number;
    rtcTotalPriorityElements?: number;
    rtcTotalElements?: number;
    ibcTotalPriorityElements?: number;
    ibcTotalElements?: number;
    hoseTotalPriorityElements?: number;
    hoseTotalElements?: number;
    mannedTotalPriorityElements?: number;
    mannedTotalElements?: number;
    ctrTotalElementsLoading: boolean;
    restTotalElementsLoading: boolean;
    rtcTotalElementsLoading: boolean;
    ibcTotalElementsLoading: boolean;
    hoseTotalElementsLoading: boolean;
    mannedTotalElementsLoading: boolean;
    className?: string;
}

const MESSAGES = defineMessages({
    manned: {
        id: "planning_tab_bar.manned",
        defaultMessage: "bemand"
    },
    hose: {
        id: "planning_tab_bar.hose",
        defaultMessage: "slang"
    }
});

export const PlanningTabBar = (props: IProps) => {
    const intl = useIntl();

    const tabPaneFactory = (loading: boolean, key: string, totalElements?: number, totalPriorityElements?: number) => {
        return loading ? <Spin/> : `${key} (${totalElements} - ${totalPriorityElements})`;
    };

    return (
        <Tabs destroyInactiveTabPane animated={false} className={props.className}>
            <Tabs.TabPane
                key="ctr"
                tab={tabPaneFactory(props.ctrTotalElementsLoading, "ctr", props.ctrTotalElements, props.ctrTotalPriorityElements)}
            >
                <PlanningTableContainer filter={ORDER_FILTERS.CTR}/>
            </Tabs.TabPane>

            <Tabs.TabPane key="rest" tab={tabPaneFactory(props.restTotalElementsLoading, "rest", props.restTotalElements, props.restTotalPriorityElements)}>
                <PlanningTableContainer filter={ORDER_FILTERS.REST}/>
            </Tabs.TabPane>

            <Tabs.TabPane key="rtc" tab={tabPaneFactory(props.rtcTotalElementsLoading, "rtc", props.rtcTotalElements, props.rtcTotalPriorityElements)}>
                <PlanningTableContainer filter={ORDER_FILTERS.RTC}/>
            </Tabs.TabPane>

            <Tabs.TabPane key="ibc" tab={tabPaneFactory(props.ibcTotalElementsLoading, "ibc", props.ibcTotalElements, props.ibcTotalPriorityElements)}>
                <PlanningTableContainer filter={ORDER_FILTERS.IBC}/>
            </Tabs.TabPane>

            <Tabs.TabPane key="manned" tab={tabPaneFactory(props.mannedTotalElementsLoading, intl.formatMessage(MESSAGES.manned), props.mannedTotalElements, props.mannedTotalPriorityElements)}>
                <PlanningTableContainer filter={ORDER_FILTERS.MANNED}/>
            </Tabs.TabPane>

            <Tabs.TabPane key="hose" tab={tabPaneFactory(props.hoseTotalElementsLoading, intl.formatMessage(MESSAGES.hose), props.hoseTotalElements, props.hoseTotalPriorityElements)}>
                <PlanningTableContainer filter={ORDER_FILTERS.HOSE}/>
            </Tabs.TabPane>
        </Tabs>
    );
};
