import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { ICheckInFilter, IPageRequest } from "../../../types/api.types";
import { ICheckIn } from "../../../types/checkIn.types";
import { CheckInOverviewTable } from "../../components/tables/checkintable/CheckInOverviewTable";
import { useCheckInOverviewPageLoader } from "../../hooks/pageloaders/useCheckInOverviewPageLoader";
import { CheckInDetailModalContainer } from "../CheckInDetailModalContainer";
import { AutoRefreshButton } from "../../components/autoRefresh/AutoRefreshButton";
import { useDispatch, useSelector } from "react-redux";
import { documentsForCheckinFetchActionCreator } from "../../../store/actions/documents.actions";
import { Row } from "antd";
import { CheckInTableQuickFilters } from "../../components/tables/checkintable/CheckinTableQuickFilters";
import { CONFIG } from "../../../config/config";
import { filterTerminalsSelector, typeValuesSelector } from "../../../store/selectors/filter.selector";
import { UseClearFiltersOnDestroy } from "../../hooks/useClearFiltersOnDestroy";

interface IProps {
    readonly?: boolean;
}

export const CheckInOverviewTableContainer = ({ readonly }: IProps) => {
    const dispatch = useDispatch();
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [clickedCheckIn, setClickedCheckIn] = useState<ICheckIn | null>(null);
    const checkInOverviewPageLoader = useCheckInOverviewPageLoader();
    const [refresher, setRefresher] = useState<any>();
    const terminalValues = useSelector(filterTerminalsSelector);
    const typeValues = useSelector(typeValuesSelector);
    UseClearFiltersOnDestroy({ dispatch });

    const refreshData = useCallback(() => {
        setRefresher({});
    }, []);

    useEffect(() => {
        const defaultCheckinFilters = readonly ? CONFIG.defaultCheckinFilters : CONFIG.defaultExtendedCheckinFilters;
        const checkInFiltersPresent = checkInOverviewPageLoader.resource?.filter.checkInState?.length || checkInOverviewPageLoader.resource?.filter.extendedCheckInState?.length;
        const pageRequest: IPageRequest<ICheckInFilter> = {
            pageNumber: checkInOverviewPageLoader.resource?.page.meta.number || 0,
            filter: checkInFiltersPresent ? { ...checkInOverviewPageLoader.resource?.filter, archived: false, checkInReason: typeValues, terminals: terminalValues } : defaultCheckinFilters,
            sorting: checkInOverviewPageLoader.resource?.sorting,
        };
        checkInOverviewPageLoader.loadResource(pageRequest);
    }, [refresher, terminalValues, typeValues, checkInOverviewPageLoader.resource?.filter.checkInState, checkInOverviewPageLoader.resource?.page.meta.number]);

    useEffect(() => {
        if (clickedCheckIn) {
            dispatch(documentsForCheckinFetchActionCreator(clickedCheckIn.id));
        }
    }, [clickedCheckIn]);

    const onModalClose = () => {
        setModalVisible(false);
        refreshData();
    };

    const onRow = (record: ICheckIn) => !readonly && ({
        onClick: () => {
            setClickedCheckIn(record);
            setModalVisible(true);
        }
    });

    return (
        <div className="check-in-overview-table">
            <Row className="margin-half-bottom">
                <div className="text-left">
                    <AutoRefreshButton callback={refreshData} loading={checkInOverviewPageLoader.loadingResource} />
                </div>
                <CheckInTableQuickFilters />
            </Row>

            <CheckInOverviewTable
                onRow={onRow}
                page={checkInOverviewPageLoader.resource ? checkInOverviewPageLoader.resource.page : undefined}
                fetchingPage={checkInOverviewPageLoader.loadingResource}
                fetchPage={checkInOverviewPageLoader.loadResource}
                sorting={checkInOverviewPageLoader.resource ? checkInOverviewPageLoader.resource.sorting : undefined}
                filter={checkInOverviewPageLoader.resource ? checkInOverviewPageLoader.resource.filter : undefined}
                readonly={!!readonly}
            />
            {
                clickedCheckIn &&
                <CheckInDetailModalContainer
                    visible={modalVisible}
                    checkIn={clickedCheckIn}
                    closeModal={onModalClose}
                />
            }
        </div>
    );
};
