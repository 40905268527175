import { call, put, takeLatest } from "redux-saga/effects";
import { errorHandler } from "./errorHandler";
import { customerFetchCreator, customerFetchFailedCreator, customerReceivedCreator } from "../actions/customer";
import { customerApi } from "../api/customer";
import { AxiosResponse } from "axios";
import { ICustomer } from "../../types/salesOrder.types";

export function* getCustomer(action: ReturnType<typeof customerFetchCreator>) {
    const response: AxiosResponse<ICustomer> = yield call(customerApi.getCustomer, action.payload.id);
    yield put(customerReceivedCreator(response.data));
}

export function* customerSaga() {
    yield takeLatest(customerFetchCreator.type, errorHandler(getCustomer, customerFetchFailedCreator));
}
