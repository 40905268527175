import { notification } from "antd";
import { defineMessages, IntlShape } from "react-intl";

interface IProps {
    intl: IntlShape;
    reset: () => void;
}

const MESSAGES = defineMessages({
    salesOrderNotFound: {
        id: "salesorder_not_found_notification.message",
        defaultMessage: "Geen order gevonden voor deze referentie"
    }
});

export const SalesOrderNotFoundNotification = (props: IProps) => {
    notification.error({
        message: props.intl.formatMessage(MESSAGES.salesOrderNotFound),
        onClose: () => props.reset(),
        placement: "bottomRight",
    });
    return null;
};
