import { Button, DatePicker } from "antd";
import dayjs, { Dayjs } from "dayjs";
import * as React from "react";
import { useState } from "react";

import { ReactComponent as DisketteIcon } from "../../../../assets/diskette.svg";
import { IDisableTimeFn } from "../../../../types/ant.types";
import Icon, { LoadingOutlined } from "@ant-design/icons";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

interface IProps {
    onSave?: (value: Dayjs | null) => void;
    disableDateFn?: (current: Dayjs | null) => boolean;
    disabled?: boolean;
    defaultValue?: string;
    saving: boolean;
    disableTimeFn?: IDisableTimeFn|any;
}

export type IDateTimePicker = React.FC<IProps>;

export const DateTimePicker: IDateTimePicker = (props: IProps) => {
    const [selectedDateTime, setSelectedDateTime] = useState<Dayjs | null>(props.defaultValue ? dayjs(props.defaultValue) : null);

    const onButtonClick = () => {
        props.onSave && props.onSave(selectedDateTime);
    };

    const buttonDisabled = () => {
        if (!selectedDateTime && !props.defaultValue) {
            return true;
        }

        if (selectedDateTime && props.defaultValue) {
            return dayjs.utc(selectedDateTime).startOf("minute").format() === props.defaultValue;
        }

        return false;
    };

    return (
        <div>
            <DatePicker
                showTime={{ format: "HH:mm" }}
                onOk={setSelectedDateTime}
                className="margin-half-right"
                disabledDate={props.disableDateFn}
                onChange={setSelectedDateTime}
                disabled={props.disabled || props.saving}
                value={selectedDateTime}
                disabledTime={props.disableTimeFn}
                format="DD-MM-YYYY HH:mm"
            />
            <Button type="primary" onClick={onButtonClick} disabled={buttonDisabled() || props.saving}>
                {
                    props.saving ?
                        <LoadingOutlined/> :
                        <Icon component={DisketteIcon}/>
                }
            </Button>
        </div>
    );
};
