import { createSelector } from "@reduxjs/toolkit";
import { IApplicationState } from "../state";
import { IExternalViewState } from "../reducers/externalview";

const externalViewStateSelector = (state: IApplicationState) => state.externalView;

export const externalViewPageSelector = createSelector(
    externalViewStateSelector,
    (externalViewState: IExternalViewState) => externalViewState.page
);

export const externalViewLoadingPageSelector = createSelector(
    externalViewStateSelector,
    (externalViewState: IExternalViewState) => externalViewState.loadingPage
);

export const externalViewOrdersSelector = createSelector(
    externalViewStateSelector,
    (externalViewState: IExternalViewState) =>
        (externalViewState.page && externalViewState.page.page) ?
            externalViewState.page.page.content : []
);

export const isExternalViewSelector = createSelector(
    externalViewStateSelector,
    (externalViewState: IExternalViewState) => externalViewState.page !== null
);
