import { notification } from "antd";
import { defineMessages, IntlShape } from "react-intl";

interface IProps {
    intl: IntlShape;
    reset: () => void;
}

const MESSAGES = defineMessages({
    slotBookingSucceededMessage: {
        id: "slot_booking_succeeded.message",
        defaultMessage: "Slot succesvol geboekt"
    },
});

export const SlotBookingSucceededNotification = (props: IProps) => {
    notification.success({
        message: props.intl.formatMessage(MESSAGES.slotBookingSucceededMessage),
        onClose: () => props.reset(),
        placement: "bottomRight",
    });
    return null;
};
