import { createAction } from "@reduxjs/toolkit";
import { IFailedActionPayload, IOrderUpdateActionPayload } from "../../types/action.types";
import { IOrderFilter, IPageRequest, IPageResponse } from "../../types/api.types";
import { IOrder } from "../../types/order.types";

enum CustomerServiceActionTypes {
    CUSTOMER_SERVICE_PAGE_LOAD = "[CustomerService] PAGE_LOAD",
    CUSTOMER_SERVICE_PAGE_FETCH = "[CustomerService] PAGE_FETCH",
    CUSTOMER_SERVICE_PAGE_RECEIVED = "[CustomerService] PAGE_RECEIVED",
    CUSTOMER_SERVICE_PAGE_FETCH_FAILED = "[CustomerService] PAGE_FETCH_FAILED",

    CUSTOMER_SERVICE_ORDER_UPDATE_DONE = "[CustomerService] ORDER_UPDATE_DONE",

    CUSTOMER_SERVICE_CSV_DOWNLOAD_ALL_SELECTED_ORDERS = "[CustomerService] CUSTOMER_SERVICE_CSV_DOWNLOAD_ALL_SELECTED_ORDERS",
    CUSTOMER_SERVICE_CSV_DOWNLOAD_ONLY_FINISHED_ORDERS = "[CustomerService] CUSTOMER_SERVICE_CSV_DOWNLOAD_ONLY_FINISHED_ORDERS",
}

export const customerServicePageLoadCreator = createAction<IPageRequest<IOrderFilter>>(CustomerServiceActionTypes.CUSTOMER_SERVICE_PAGE_LOAD);
export const customerServicePageFetchCreator = createAction<IPageRequest<IOrderFilter>>(CustomerServiceActionTypes.CUSTOMER_SERVICE_PAGE_FETCH);
export const customerServicePageReceivedCreator = createAction<IPageResponse<IOrder, IOrderFilter>>(CustomerServiceActionTypes.CUSTOMER_SERVICE_PAGE_RECEIVED);
export const customerServicePageFetchFailedCreator = createAction<IFailedActionPayload>(CustomerServiceActionTypes.CUSTOMER_SERVICE_PAGE_FETCH_FAILED);

export const customerServiceOrderUpdateDoneActionCreator = createAction<IOrderUpdateActionPayload>(CustomerServiceActionTypes.CUSTOMER_SERVICE_ORDER_UPDATE_DONE);

export const customerServiceCsvDownloadAllSelectedOrdersActionCreator = createAction<IPageRequest<IOrderFilter>>(CustomerServiceActionTypes.CUSTOMER_SERVICE_CSV_DOWNLOAD_ALL_SELECTED_ORDERS);
export const customerServiceCsvDownloadOnlyFinishedOrdersActionCreator = createAction(CustomerServiceActionTypes.CUSTOMER_SERVICE_CSV_DOWNLOAD_ONLY_FINISHED_ORDERS);
