import { call, put, takeLatest } from "redux-saga/effects";
import { errorHandler } from "./errorHandler";
import { PayloadAction } from "@reduxjs/toolkit";
import { IDeleteRemarkActionPayload, IPostRemarkActionPayload, IRemark } from "../../types/remarks.types";
import { deleteRemarkForCheckIn, postRemarkForCheckIn } from "../api/remarks.api";
import {
    deleteRemarkForCheckinAction,
    postRemarkForCheckinAction,
    setRemarksForCheckinAction
} from "../actions/remarks.actions";
import { AxiosResponse } from "axios";

export function* setRemark({ payload }: PayloadAction<IPostRemarkActionPayload>) {
    const response: AxiosResponse<IRemark[]> = yield call(postRemarkForCheckIn, payload.checkinId, payload.remark);
    yield put(setRemarksForCheckinAction({ checkinId: payload.checkinId, remarks: response.data }));
}

export function* removeRemark({ payload }: PayloadAction<IDeleteRemarkActionPayload>) {
    const response: AxiosResponse<IRemark[]> = yield call(deleteRemarkForCheckIn, payload.remarkId);
    yield put(setRemarksForCheckinAction({ checkinId: payload.checkinId, remarks: response.data }));
}

export function* remarksSaga() {
    yield takeLatest(postRemarkForCheckinAction.type, errorHandler(setRemark));
    yield takeLatest(deleteRemarkForCheckinAction.type, errorHandler(removeRemark));
}
