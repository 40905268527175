import { Button } from "antd";
import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import { State } from "../../../../../../types/order.types";
import { allowedToMarkItactDone } from "../../../../../../utils/order";

interface IProps {
    itactDoneSaving: boolean;
    onItactDoneSave: () => void;
    state: State | null;
}

const MESSAGES = defineMessages({
    markAvailable: {
        id: "itact_available_button.mark_available",
        defaultMessage: "Markeer als beschikbaar"
    }
});

export const ItactDoneButton = (props: IProps) => {
    const intl = useIntl();

    return (
        <Button
            type="primary"
            size="large"
            disabled={!props.state || !allowedToMarkItactDone(props.state) || props.itactDoneSaving}
            onClick={props.onItactDoneSave}
            loading={props.itactDoneSaving}
        >
            {intl.formatMessage(MESSAGES.markAvailable)}
        </Button>
    );
};
