import { createAction } from "@reduxjs/toolkit";
import {
    IDeleteRemarkActionPayload,
    IPostRemarkActionPayload,
    ISetRemarksActionPayload
} from "../../types/remarks.types";

enum RemarksActionTypes {
  POST_REMARK_FOR_CHECKIN = "[REMARKS] POST REMARK FOR CHECKIN",
  SET_REMARKS_FOR_CHECKIN = "[REMARKS] SET REMARKS FOR CHECKIN",
  DELETE_REMARK_FOR_CHECKIN = "[REMARKS] DELETE REMARK FOR CHECKIN",
}

export const postRemarkForCheckinAction = createAction<IPostRemarkActionPayload>(RemarksActionTypes.POST_REMARK_FOR_CHECKIN);
export const setRemarksForCheckinAction = createAction<ISetRemarksActionPayload>(RemarksActionTypes.SET_REMARKS_FOR_CHECKIN);
export const deleteRemarkForCheckinAction = createAction<IDeleteRemarkActionPayload>(RemarksActionTypes.DELETE_REMARK_FOR_CHECKIN);
