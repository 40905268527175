import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { IOrderFilter, IPageResponse } from "../../types/api.types";
import { IOrder } from "../../types/order.types";
import { mapPageResponseToPageRequest } from "../../utils/mapping";
import {
    checklistsFetchActionCreator,
    checklistsFetchFailedActionCreator,
    checklistsReceivedActionCreator,
    checklistStepSubmitActionCreator,
    checklistStepSubmitFailedActionCreator,
    checklistStepSubmittedActionCreator
} from "../actions/checklist";
import { cleaningPageFetchCreator } from "../actions/cleaning";
import { checklistApi } from "../api/checklist";
import { cleaningPageSelector } from "../selectors/cleaning.selector";
import { errorHandler } from "./errorHandler";
import { AxiosResponse } from "axios";
import { IChecklist } from "../../types/checklist.types";

export function* submitEntry(action: ReturnType<typeof checklistStepSubmitActionCreator>) {
    yield call(checklistApi.submitChecklistEntryStep, action.payload.checklistId, action.payload.checklistEntryStepSubmit);
    yield put(checklistStepSubmittedActionCreator(action.payload.compartmentId));
}

export function* entrySubmitted(action: ReturnType<typeof checklistStepSubmittedActionCreator>) {
    yield put(checklistsFetchActionCreator(action.payload));

    const page: IPageResponse<IOrder, IOrderFilter> = yield select(cleaningPageSelector);
    const pageRequest = mapPageResponseToPageRequest(page);

    yield put(cleaningPageFetchCreator(pageRequest));
}

export function* fetchChecklists(action: ReturnType<typeof checklistsFetchActionCreator>) {
    const response: AxiosResponse<IChecklist[]> = yield call(checklistApi.getChecklists, action.payload);
    yield put(checklistsReceivedActionCreator(response.data));
}

export function* compartmentSaga() {
    yield takeLatest(checklistsFetchActionCreator.type, errorHandler(fetchChecklists, checklistsFetchFailedActionCreator));
    yield takeEvery(checklistStepSubmitActionCreator.type, errorHandler(submitEntry, checklistStepSubmitFailedActionCreator));
    yield takeEvery(checklistStepSubmittedActionCreator.type, errorHandler(entrySubmitted));
}
