import { URL_PATHS } from "../../constants/api.constants";
import { getHttpClient } from "./httpClient";
import { ISelfCheckInParameters } from "../../types/selfCheckIn.types";

const baseUrl = URL_PATHS.CHECKIN + "/admin/settings";

const getSettings = () => {return getHttpClient().get(`${baseUrl}`);};
const putSetting = (parameters: ISelfCheckInParameters) => {
    return getHttpClient().put(`${baseUrl}`, parameters);
};

export const selfCheckInSettingsApi = {
    getSettings,
    putSetting
};