import { createSelector } from "@reduxjs/toolkit";
import { IOrderState } from "../reducers/order";
import { IApplicationState } from "../state";
import { ISalesOrder } from "../../types/salesOrder.types";

export const salesOrdersSelector = (state: IApplicationState): ISalesOrder[] => state.checkIn?.record?.salesOrders || [];

const orderStateSelector = (state: IApplicationState) => state.order;

export const ordersBeingUpdatedSelector = createSelector(
    orderStateSelector,
    (orderState: IOrderState) => orderState.ordersBeingUpdated
);

export const orderSelector = createSelector(
    orderStateSelector,
    (orderState: IOrderState) => orderState.order
);

export const loadingOrderSelector = createSelector(
    orderStateSelector,
    (orderState: IOrderState) => orderState.loadingOrder
);

export const filterTotalElementsMapSelector = createSelector(
    orderStateSelector,
    (orderState: IOrderState) => orderState.filterTotalElementsMap
);

export const filterTotalElementsFiltersLoadingSelector = createSelector(
    orderStateSelector,
    (orderState: IOrderState) => orderState.totalElementsFiltersLoading
);

export const salesOrdersWithUnknownTemperatureSelector = createSelector(
    salesOrdersSelector,
    (salesOrders: ISalesOrder[]) => salesOrders.filter(order => order.temperatureNotKnown)
);