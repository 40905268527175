import { Button } from "antd";
import * as React from "react";

interface IProps {
    onClose: () => void;
    cancelLabel: string;
    confirmLabel: string;
    onSubmit: (e: { preventDefault: () => void }) => void;
    disabled: boolean;
}

export const ModalFooter = (props: IProps) => {

    return (
        <div className="text-middle">
            <Button onClick={props.onClose} type="default" className="margin-right">
                {props.cancelLabel}
            </Button>

            <Button onClick={props.onSubmit} type="primary" disabled={props.disabled}>
                {props.confirmLabel}
            </Button>
        </div>
    );
};
