import { Button, Form, Radio } from "antd";
import * as React from "react";
import { useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { RestFoundModal } from "./RestFoundModal";

interface IProps {
    onError: (fields: any, submittedBy: string, msg?: string) => void;
    onOk: (fields: any) => void;
    prev: () => void;
    containerLocation?: string;
    restFound: boolean;
}

const MESSAGES = defineMessages({
    toplosserLabel: {
        id: "pre_radio_form.toplosser_label",
        defaultMessage: "Is het een toplosser?"
    },
    baffleLabel: {
        id: "pre_radio_form.baffle_label",
        defaultMessage: "Keerschotten aanwezig?"
    },
    restPresent: {
        id: "pre_radio_form.rest_present_label",
        defaultMessage: "Is er restlading aanwezig?"
    },
    groundConnected: {
        id: "pre_radio_form.ground_connected_label",
        defaultMessage: "Aarding aangesloten?"
    },
    yes: {
        id: "pre_radio_form.yes",
        defaultMessage: "Ja"
    },
    no: {
        id: "pre_radio_form.no",
        defaultMessage: "Nee"
    },
    ok: {
        id: "pre_radio_form.ok",
        defaultMessage: "OK"
    },
    nok: {
        id: "pre_radio_form.nok",
        defaultMessage: "NOK"
    },
    nvt: {
        id: "pre_radio_form.nvt",
        defaultMessage: "NVT"
    },
    prev: {
        id: "pre_radio_form.prev",
        defaultMessage: "Vorige"
    },
    confirm: {
        id: "pre_radio_form.confirm",
        defaultMessage: "Bevestig bovenstaande gegevens en ga over tot cleaning"
    },
    groundNotConnected: {
        id: "pre_radio_form.ground_not_connected",
        defaultMessage: "Gelieve de aarding aan te sluiten en te bevestigen."
    },
    fieldRequired: {
        defaultMessage: "Alle velden moeten ingevuld zijn.",
        id: "pre_radio_form.field_required",
    },
    restRemoved: {
        defaultMessage: "Rest verwijderd",
        id: "pre_radio_form.rest_removed"
    }
});

export const PreRadioForm = (props: IProps) => {
    const intl = useIntl();
    const [restModalVisible, setRestModalVisible] = useState<boolean>(false);
    const [values, setValues] = useState<any>();
    const [form] = Form.useForm();

    const canSubmit = (values: any) => values && values.restFound !== true;

    const isGroundConnectedNvt = !!(props.containerLocation && props.containerLocation.toLowerCase().match(/cl[1-4]/));

    const submit = (fn: (...arg: any) => void, values: any, submittedBy?: string, msg?: string) => {
        // Third option has value 0 because it's a pseudo option
        // Backend is not interested in removing the rest as the rest is never really removed (ADPO wants to keep seeing it)
        const mappedValues = {
            ...values,
            restFound: !!values.restFound
        };

        if (fn === props.onError) {
            fn(mappedValues, submittedBy, msg);
        } else {
            fn(mappedValues);
        }

    };

    const onSubmit = (values: any) => {
        setValues(values);

        if (values.restFound) {
            setRestModalVisible(true);
        }

        if (canSubmit(values)) {
            submit(props.onOk, values);
        }
    };

    const restModalConfirm = (submittedBy: string, restAmount: number, msg?: string) => {
        values.restAmount = restAmount;

        submit(props.onError, values, submittedBy, msg);
    };

    const nvtValidator = (rule: any, value: any, callback: any) => {
        if (isGroundConnectedNvt || value === true) {
            callback();
            return;
        }

        if (value !== true) {
            callback(intl.formatMessage(MESSAGES.groundNotConnected));
        }
    };

    return (
        <div className="flex justify-center align-items-center fill-width">
            <Form onFinish={onSubmit} form={form} layout="vertical">
                <Form.Item
                    name="toplosser"
                    label={intl.formatMessage(MESSAGES.toplosserLabel)}
                    rules={[
                        {
                            required: true,
                            message: intl.formatMessage(MESSAGES.fieldRequired)
                        }
                    ]}
                >
                    <Radio.Group>
                        <Radio.Button value={true}>{intl.formatMessage(MESSAGES.yes)}</Radio.Button>
                        <Radio.Button value={false}>{intl.formatMessage(MESSAGES.no)}</Radio.Button>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    name="baffles"
                    label={intl.formatMessage(MESSAGES.baffleLabel)}
                    rules={[
                        {
                            required: true,
                            message: intl.formatMessage(MESSAGES.fieldRequired)
                        }
                    ]}
                >
                    <Radio.Group>
                        <Radio.Button value={true}>{intl.formatMessage(MESSAGES.yes)}</Radio.Button>
                        <Radio.Button value={false}>{intl.formatMessage(MESSAGES.no)}</Radio.Button>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    name="restFound"
                    label={intl.formatMessage(MESSAGES.restPresent)}
                    rules={[
                        {
                            required: true,
                            message: intl.formatMessage(MESSAGES.fieldRequired)
                        }
                    ]}
                >
                    <Radio.Group>
                        <Radio.Button value={true} disabled={props.restFound}>{intl.formatMessage(MESSAGES.yes)}</Radio.Button>
                        <Radio.Button value={false} disabled={props.restFound}>{intl.formatMessage(MESSAGES.no)}</Radio.Button>
                        <Radio.Button value={0} disabled={!props.restFound}>{intl.formatMessage(MESSAGES.restRemoved)}</Radio.Button>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    name="groundConnected"
                    label={intl.formatMessage(MESSAGES.groundConnected)}
                    initialValue={isGroundConnectedNvt ? null : undefined}
                    rules={[
                        {
                            validator: nvtValidator
                        }
                    ]}
                >
                    <Radio.Group>
                        <Radio.Button value={true}>{intl.formatMessage(MESSAGES.ok)}</Radio.Button>
                        <Radio.Button value={false}>{intl.formatMessage(MESSAGES.nok)}</Radio.Button>
                        <Radio.Button value={null}>{intl.formatMessage(MESSAGES.nvt)}</Radio.Button>
                    </Radio.Group>
                </Form.Item>
                <div className="margin-top fill-width flex flex-row">
                    <Form.Item>
                        <Button onClick={props.prev}>
                            {intl.formatMessage(MESSAGES.prev)}
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="margin-half-left">
                            {intl.formatMessage(MESSAGES.confirm)}
                        </Button>
                    </Form.Item>
                </div>
            </Form>
            <RestFoundModal closeModal={() => setRestModalVisible(false)} onConfirm={restModalConfirm} visible={restModalVisible}/>
        </div>
    );
};
