import { ModalProps } from "antd/lib/modal";
import * as React from "react";
import { CleaningModal } from "../cleaningmodal/CleaningModal";
import { ConfirmDeleteModalFooter } from "./ConfirmDeleteModalFooter";
import { defineMessages, useIntl } from "react-intl";

interface IProps extends ModalProps {
    closeModal: () => void;
    onConfirm: () => void;
    disabled?: boolean;
}

const MESSAGES = defineMessages({
    title: {
        id: "confirm_delete_modal.title",
        defaultMessage: "Annuleer boeking"
    },
    cancelSlot: {
        id: "confirm_delete_modal.cancel_slot",
        defaultMessage: "Bent u zeker dat u dit slot wilt annuleren?"
    }

});

export const ConfirmDeleteModal = (props: IProps) => {
    const intl = useIntl();

    const onConfirm = () => {
        props.onConfirm();
        props.closeModal();
    };

    return (
        <CleaningModal
            {...props}
            title={intl.formatMessage(MESSAGES.title)}
            closeModal={props.closeModal}
            footer={<ConfirmDeleteModalFooter onConfirm={onConfirm} onBack={props.closeModal} disabled={props.disabled}/>}
        >
            {intl.formatMessage(MESSAGES.cancelSlot)}
        </CleaningModal>
    );
};
