import * as React from "react";
import { useSelector } from "react-redux";
import { IApplicationState } from "../../../store/state";
import en from "../../../translations/en.json";
import nl from "../../../translations/nl.json";
import { NotificationManager } from "../../containers/notificationManager";
import { Routing } from "../../Routing";
import { IntlProvider } from "react-intl";
import { activeLanguageSelector } from "../../../store/selectors/language.selectors";
import { LanguageCode } from "../../../types/language.types";

export const Initialized = () => {
    const initialized = useSelector<IApplicationState, boolean>((state) => state.init.initialized);
    const activeLanguage = useSelector(activeLanguageSelector);

    const getMessages = () => {
        return activeLanguage !== LanguageCode.NL ? en : nl;
    };

    if (initialized) {
        return (
            <IntlProvider defaultLocale={LanguageCode.NL} locale={activeLanguage} messages={getMessages()}>
                <NotificationManager />
                <Routing />
            </IntlProvider>
        );
    }

    return <></>;
};

