import { combineReducers, createReducer } from "@reduxjs/toolkit";
import { IPageResponse } from "../../types/api.types";
import { IBookedSlot, IBookedSlotPageFetchParams } from "../../types/bookedSlot.types";
import {
    bookedSlotListReceivedCreator,
    bookedSlotPageFetchFailedActionCreator,
    bookedSlotPageLoadActionCreator,
    bookedSlotPageReceivedActionCreator,
    bookedSlotReceivedCreator,
    dependsOnBookedSlotReceivedCreator
} from "../actions";

export interface IBookedSlotState {
    bookedSlotList: IBookedSlot[];
    selected: IBookedSlot | null;
    dependsOn: IBookedSlot | null;
    page: IPageResponse<IBookedSlot, IBookedSlotPageFetchParams> | null;
    loadingPage: boolean;
}

export const bookedSlotListReducer = createReducer<IBookedSlot[]>([], {
    [bookedSlotListReceivedCreator.type]: (bookedSlotList, action) => action.payload,
});

export const selectedBookedSlotReducer = createReducer<IBookedSlot | null>(null, {
    [bookedSlotReceivedCreator.type]: (bookedSlot, action) => action.payload,
});

export const dependsOnBookedSlotReducer = createReducer<IBookedSlot | null>(null, {
    [dependsOnBookedSlotReceivedCreator.type]: (bookedSlot, action) => action.payload,
});

export const pageReducer = createReducer<IPageResponse<IBookedSlot, IBookedSlotPageFetchParams> | null>(null, (builder) => {
    builder.addCase(bookedSlotPageReceivedActionCreator, (state, action) => action.payload);
});

export const pageLoadingReducer = createReducer<boolean>(false, (builder) => {
    builder.addCase(bookedSlotPageLoadActionCreator, () => true);
    builder.addCase(bookedSlotPageReceivedActionCreator, () => false);
    builder.addCase(bookedSlotPageFetchFailedActionCreator, () => false);
});

export const bookedSlotReducer = combineReducers<IBookedSlotState>({
    bookedSlotList: bookedSlotListReducer,
    selected: selectedBookedSlotReducer,
    dependsOn: dependsOnBookedSlotReducer,
    page: pageReducer,
    loadingPage: pageLoadingReducer
});
