import { CheckInState, ICheckIn } from "../../../../../types/checkIn.types";
import { defineMessages, useIntl } from "react-intl";
import { Button } from "antd";
import * as React from "react";

interface IProps {
    onClick: () => void;
    checkIn: ICheckIn | null;
}

const MESSAGES = defineMessages({
    finish: {
        id: "check_in_modal_footer.delete",
        defaultMessage: "Verwijderen",
    }
});

export const DeleteCheckinButton = (props: IProps) => {
    const intl = useIntl();
    const states = [CheckInState.CANCELLED];

    return props.checkIn && states.includes(props.checkIn.checkInState) ? (
        <Button onClick={props.onClick} className={"check-in-delete-button"}>
            {intl.formatMessage(MESSAGES.finish)}
        </Button>
    ) : null;
};
