import axios from "axios";
import { BASE_URL_API } from "../../config/config";
import { getToken } from "../../security";

const instance = axios.create({
    baseURL: BASE_URL_API,
    timeout: 10000,
});

/**
 * Get a configured http axios client
 */
export function getHttpClient() {
    return instance;
}

/**
 * Set the authentication on the configured http axios client
 *
 * @param idToken
 */
export function setHttpAuthentication(idToken: string) {
    instance.interceptors.request.use(async(config) => {
        const token = await getToken();
        if (config && config.headers) {
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            } else {
                config.headers.Authorization = `Bearer ${idToken}`;
            }
        }
        return config;
    });
}
