import * as React from "react";
import { useEffect, useState } from "react";
import { IOrderFilter, IPageRequest } from "../../../types/api.types";
import { IOrder } from "../../../types/order.types";
import { CustomerServiceModal } from "../../components/modals/customerservicemodal/CustomerServiceModal";
import { CustomerServiceTable } from "../../components/tables/customerservicetable/CustomerServiceTable";
import { ICustomerServicePageLoader, useCustomerServicePageLoader } from "../../hooks/pageloaders/useCustomerServicePageLoader";
import { ICsvDownloadPageLoader, useCsvDownloadAllSelectedOrdersCustomerService } from "../../hooks/pageloaders/useCsvDownloadAllSelectedOrdersCustomerService";
import {
    useCsvDownloadOnlyFinishedOrdersCustomerService
} from "../../hooks/pageloaders/useCsvDownloadOnlyFinishedOrdersCustomerService";

export const CustomerServiceTableContainer = () => {
    const [clickedOrderId, setClickedOrderId] = useState<number | undefined>();
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const customerServicePageLoader: ICustomerServicePageLoader = useCustomerServicePageLoader();
    const csvDownloadAllSelectedOrders: ICsvDownloadPageLoader = useCsvDownloadAllSelectedOrdersCustomerService();
    const csvDownloadOnlyFinishedOrders = useCsvDownloadOnlyFinishedOrdersCustomerService();

    useEffect(() => {
        const pageRequest: IPageRequest<IOrderFilter> = {
            pageNumber: customerServicePageLoader.resource?.page.meta.number || 0,
            filter: {},
        };

        customerServicePageLoader.loadResource(pageRequest);
    }, []);

    const onRow = (record: IOrder) => ({
        onClick: () => {
            setClickedOrderId(record.id);
            setModalVisible(true);
        }
    });

    const onModalClose = () => {
        setModalVisible(false);
        customerServicePageLoader.refresh();
    };

    const pageResponse = customerServicePageLoader.resource;

    return (
        <>
            <CustomerServiceTable
                csvDownloadAllSelectedOrders={(pageRequest: IPageRequest<IOrderFilter>) => csvDownloadAllSelectedOrders.requestResource(pageRequest)}
                csvDownloadOnlyFinishedOrders={() => csvDownloadOnlyFinishedOrders.requestResource()}
                filter={pageResponse?.filter}
                sorting={pageResponse?.sorting}
                page={pageResponse?.page}
                fetchingPage={customerServicePageLoader.loadingResource}
                fetchPage={customerServicePageLoader.loadResource}
                onRow={onRow}
            />
            <CustomerServiceModal visible={modalVisible} closeModal={onModalClose} orderId={clickedOrderId}/>
        </>
    );
};
