import { createAction } from "@reduxjs/toolkit";
import { IFailedActionPayload } from "../../types/action.types";
import { IPit } from "../../types/salesOrder.types";

enum TankActionTypes {
    PIT_LIST_FETCH = "[Pit] LIST_FETCH",
    PIT_LIST_RECEIVED = "[Pit] LIST_RECEIVED",
    PIT_LIST_FETCH_FAILED = "[Pit] LIST_FETCH_FAILED",

}

export const pitListFetchCreator = createAction(TankActionTypes.PIT_LIST_FETCH);
export const pitListReceivedCreator = createAction<IPit[]>(TankActionTypes.PIT_LIST_RECEIVED);
export const pitListFetchFailedCreator = createAction<IFailedActionPayload>(TankActionTypes.PIT_LIST_FETCH_FAILED);
