import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import { ICheckIn, IDriver } from "../../../../../../types/checkIn.types";
import { Box } from "../../../../Box";
import { Pane } from "../../../customerservicemodal/panes/Pane";
import { DriverInfo } from "./DriverInfo";

interface IProps {
    checkIn: ICheckIn;
    driver: IDriver;
}

const MESSAGES = defineMessages({
    title: {
        id: "driver_info_pane.title",
        defaultMessage: "Chauffeur info"
    }
});

export const DriverInfoPane = (props: IProps) => {
    const intl = useIntl();

    return (
        <Pane className="margin-top" title={intl.formatMessage(MESSAGES.title)}>
            <Box className="scrollable-box" >
                <DriverInfo checkIn={props.checkIn} driver={props.driver}/>
            </Box>
        </Pane>
    );
};
