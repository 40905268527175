import * as React from "react";
import { AppLayout } from "./AppLayout";
import { TimeLineContainer } from "../components/timeline/TimeLineContainer";

export const CalendarScreen = () => {
    return (
        <AppLayout>
            <TimeLineContainer />
        </AppLayout>
    );
};
