import { notification } from "antd";
import { defineMessages, IntlShape } from "react-intl";

interface IProps {
    intl: IntlShape;
    reset: () => void;
}

const MESSAGES = defineMessages({
    blockSlotBookingsExistMessage: {
        id: "block_slot_bookings_exist_notification.message",
        defaultMessage: "Tank blokkeren mislukt"
    },
    blockSlotBookingsExistDescription: {
        id: "block_slot_bookings_exist_notification.description",
        defaultMessage: "Er zijn reeds timeslots geboekt voor deze tank in dit tijdsinterval. Gelieve deze slots eerst te annuleren en dan opnieuw te proberen."
    }
});

export const BlockSlotBookingsExistNotification = (props: IProps) => {
    notification.error({
        message: props.intl.formatMessage(MESSAGES.blockSlotBookingsExistMessage),
        description: props.intl.formatMessage(MESSAGES.blockSlotBookingsExistDescription),
        onClose: () => props.reset(),
        placement: "bottomRight"
    });
    return null;
};
