import { PayloadAction } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { orderTransitionsFetchCreator } from "../../store/actions";
import { IApplicationState } from "../../store/state";
import { IStateTransition } from "../../types/order.types";

export const useOrderTransitions = (): [IStateTransition[], boolean, (orderId: number) => PayloadAction<number>] => {
    const dispatch = useDispatch();
    const transitions = useSelector<IApplicationState, IStateTransition[]>(state => state.order.transitions);
    const fetchingTransitions = useSelector<IApplicationState, boolean>(state => state.order.fetchingTransitions);

    const fetch = (orderId: number) => dispatch(orderTransitionsFetchCreator(orderId));

    return [transitions, fetchingTransitions, fetch];
};
