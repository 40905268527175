import { createAction } from "@reduxjs/toolkit";
import { IFailedActionPayload } from "../../types/action.types";
import { IEventParams } from "../../types/bookedSlot.types";

enum CalendarActionTypes {
    CALENDAR_EVENTS_FETCH = "[Calendar] EVENTS_FETCH",
    CALENDAR_EVENTS_FETCH_FAILED = "[Calendar] EVENTS_FETCH_FAILED"
}

export const calendarEventsFetchActionCreator = createAction<{ tankIds: number[]; eventParams: IEventParams }>(CalendarActionTypes.CALENDAR_EVENTS_FETCH);
export const calendarEventsFetchFailedActionCreator = createAction<IFailedActionPayload>(CalendarActionTypes.CALENDAR_EVENTS_FETCH_FAILED);
