import { useDispatch, useSelector } from "react-redux";
import { orderTotalElementsFetchCreator } from "../../store/actions";
import {
    filterTotalElementsFiltersLoadingSelector,
    filterTotalElementsMapSelector
} from "../../store/selectors/order.selector";
import { IOrderFilter } from "../../types/api.types";
import { IResourceLoader } from "../../types/hook.types";
import { formatFilterQueryParams } from "../../utils/format";

export enum Elements {
    PRIORITY,
    TOTAL,
}

export interface ITotalElements {
    numberOfPriorityElements: number;
    numberOfElements: number;
}

export type IFilterTotalElementsLoader = IResourceLoader<ITotalElements | undefined>;

export const useFilterTotalElementsLoader = (filter: IOrderFilter): IFilterTotalElementsLoader => {
    const prioritizedFilter: IOrderFilter = { ...filter, isPrio: true };

    const dispatch = useDispatch();
    const filterString = formatFilterQueryParams(filter);
    const filterPrioritizedString = formatFilterQueryParams(prioritizedFilter);

    const filterTotalElementsMap = useSelector(filterTotalElementsMapSelector);
    const totalElementsFiltersLoading = useSelector(filterTotalElementsFiltersLoadingSelector);

    const numberOfPriorityElements = filterTotalElementsMap[filterPrioritizedString];
    const numberOfElements = filterTotalElementsMap[filterString];

    const loadingResource = totalElementsFiltersLoading.includes(filterPrioritizedString) && totalElementsFiltersLoading.includes(filterString);

    const loadResource = () => {
        dispatch(orderTotalElementsFetchCreator(prioritizedFilter));
        dispatch(orderTotalElementsFetchCreator(filter));
    };

    return {
        resource: {
            numberOfPriorityElements,
            numberOfElements
        },
        loadingResource,
        loadResource,
    };
};
