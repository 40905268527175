export enum StateGroup {
    PREPARATION = "PREPARATION",
    TO_CLEAN = "TO_CLEAN",
    CLEANING = "CLEANING",
    FINISH_UP = "FINISH_UP",
    DONE = "DONE",
}

export enum State {
    ORDER_CREATED = "ORDER_CREATED",
    ORDER_RECEIVED = "ORDER_RECEIVED",
    CONTAINER_ARRIVED = "CONTAINER_ARRIVED",
    PLANNED = "PLANNED",
    WAITING_FOR_FEEDBACK_CUSTOMER = "WAITING_FOR_FEEDBACK_CUSTOMER",
    WAITING_FOR_INTERNAL_PROCESSING = "WAITING_FOR_INTERNAL_PROCESSING",
    NOT_CLEANED_READY_FOR_PICKUP = "NOT_CLEANED_READY_FOR_PICKUP",
    ERROR_DURING_CLEANING = "ERROR_DURING_CLEANING",
    ORDER_DELETED = "ORDER_DELETED",
    READY_FOR_CLEANING = "READY_FOR_CLEANING",
    CLEANING = "CLEANING",
    CLEANING_FINISHED = "CLEANING_FINISHED",
    CLEANED_READY_FOR_PICKUP = "CLEANED_READY_FOR_PICKUP",
    ITACT_REPAIR = "ITACT_REPAIR",
    DONE_PICKED_UP = "DONE_PICKED_UP",

    // PSEUDO STATE PURELY FOR VISUAL PURPOSES
    CLEANED = "CLEANED"
}

export enum Event {
    ACTION_ON_PRIORITY_ASSIGNED,
    ACTION_ON_CHECKLIST_STARTED,
    ACTION_ERROR_DETECTED,
    ACTION_ON_CLEANING_FINISHED,
    ACTION_ON_ITACT_DONE,
    MANUAL_ORDER_RECEIVED_TO_CONTAINER_ARRIVED,
    MANUAL_CONTAINER_ARRIVED_TO_ORDER_RECEIVED,
    MANUAL_CONTAINER_ARRIVED_TO_PLANNED,
    MANUAL_PLANNED_TO_CONTAINER_ARRIVED,
    MANUAL_PLANNED_TO_READY_FOR_CLEANING,
    MANUAL_READY_FOR_CLEANING_TO_PLANNED,
    MANUAL_CONTAINER_ARRIVED_TO_READY_FOR_CLEANING,
    MANUAL_READY_FOR_CLEANING_TO_CONTAINER_ARRIVED,
    MANUAL_READY_FOR_CLEANING_TO_CLEANING,
    MANUAL_CLEANING_TO_READY_FOR_CLEANING,
    MANUAL_CLEANING_TO_ERROR_DETECTED,
    MANUAL_ERROR_DETECTED_TO_CLEANING,
    MANUAL_ERROR_DETECTED_TO_WAITING_FOR_CUSTOMER_FEEDBACK,
    MANUAL_ERROR_DETECTED_TO_WAITING_FOR_INTERNAL_PROCESSING,
    MANUAL_WAITING_FOR_TO_PLANNED,
    MANUAL_WAITING_FOR_TO_ERROR_DETECTED,
    MANUAL_WAITING_FOR_TO_NOT_CLEANED_READY_FOR_PICKUP,
    MANUAL_CLEANING_TO_CLEANED_READY_FOR_PICKUP,
    MANUAL_CLEANING_TO_ITACT_REPAIR,
    MANUAL_CLEANED_READY_FOR_PICKUP_TO_ITACT_REPAIR,
    MANUAL_ITACT_REPAIR_TO_CLEANED_READY_FOR_PICKUP,
    MANUAL_PICKED_UP_TO_CLEANED_READY_FOR_PICK_UP,
    SAP_ON_ORDER_RECEIVED,
    SAP_ON_ORDER_DELETED,
    SAP_ON_ORDER_DELIVERED,
    SAP_ON_CONTAINER_LOCATION_UPDATED,
    SAP_ON_CONTAINER_LOCATION_DELETED,
    SAP_ON_ORDER_CLOSED,

    // Fake event for fake cleaned state transition
    FAKE
}

export enum OrderType {
    UNMANNED_CLEANING = "UNMANNED_CLEANING",
    MANNED_CLEANING = "MANNED_CLEANING",
    INTERNAL_CONTAINER = "INTERNAL_CONTAINER",
    WAGON = "WAGON",
    IBC = "IBC",
    HOSE = "HOSE",
}

export interface ILocation {
    id: number;
    locationType: string;
    location: string;
}

export interface IContainer {
    id: number;
    sapId: string;
    location?: ILocation;
}

export interface ICleaningProcedure {
    id: number;
    code: number;
    step1: string;
    step2: string;
    step3: string;
    step4: string;
    step5: string;
    step6: string;
    step7: string;
    step8: string;
    step9: string;
    step10: string;
    step11: string;
    step12: string;
}

export interface IProduct {
    id: number;
    name: string;
    cleaningProcedure: ICleaningProcedure;
    pbmBreatheProtection: string;
    pbmBreatheProtectionDescription: string;
    pbmClothing: string;
    pbmClothingDescription: string;
    pbmGloves: string;
    pbmGlovesDescription: string;
    pbmHeadProtection: string;
    pbmHeadProtectionDescription: string;
    pbmInflammable: string;
    pbmInflammableDescription: string;
    pbmKemmler: string;
    pbmShoes: string;
    pbmShoesDescription: string;
    pbmSkinProtection: string;
    pbmSkinProtectionDescription: string;
    pbmUnNumber: string;
    instructionId: string;
    labelNumber: string;
    hazardId: string;
    mainVlarems: string[];
    sideVlarems: string[];
    adrInstructions: string;
}

export interface ICompartment {
    id: number;
    cid: number;
    product: IProduct;
    restFound: boolean;
    restAmount: number;
}

export interface IStateTransition {
    id: number;
    sourceStateGroup: StateGroup;
    targetStateGroup: StateGroup;
    sourceState: State;
    targetState: State;
    event: Event;
    cleaningOrderId: number;
    timestamp: string;
}

export interface ICleaningCustomer {
    name: string;
    sapId: number;
    uuid: string;
}

export interface IOrder {
    id: number;
    container: IContainer;
    clientOrderRef: string;
    cleaningCustomer: ICleaningCustomer;
    itactWorkRequired: boolean;
    manualWorkNeeded: boolean;
    itactNeeded: boolean;
    itactDone: boolean;
    dateAvailable: string;
    dateCleaned: string;
    dateParkingIn: string;
    dateParkingOut: string;
    priority: string;
    orderType: OrderType;
    stateGroup: StateGroup;
    state: State;
    compartments: ICompartment[];
    toCleaningMessage?: string;
    fromCleaningMessage?: string;
    toItactMessage?: string;
    fromItactMessage?: string;
    beforeLeaveMessage?: string;
    storageDays: number;
    csActionRequired: boolean;
    sapId: string;
    deliveryId: string;
    toplosser?: boolean;
    baffles?: boolean;
    mostRecentStateTransition: IStateTransition;
}
