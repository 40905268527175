import { take } from "redux-saga-test-plan/matchers";
import { put } from "redux-saga/effects";

import { initDoneAction, loginAction, loginFailedAction, loginOkAction } from "../actions";
import { Paths } from "../../types/api.types";

/**
 * Saga watcher: init process, this saga is run once
 */
export function* init() {
    try {
        // No login action for external view
        if (!window.location.pathname.includes(Paths.EXTERNAL_VIEW)) {
            // Put login action
            yield put(loginAction());

            // Wait for actions to be completed and then continue
            yield take([loginOkAction.type, loginFailedAction.type]);
        }
    } catch (e) {
        console.error(e);
    } finally {
        yield put(initDoneAction());
    }
}
