import { createSelector } from "@reduxjs/toolkit";
import { IPlanningState } from "../reducers/planning";
import { IApplicationState } from "../state";

const planningStateSelector = (state: IApplicationState) => state.planning;

export const planningPageSelector = createSelector(
    planningStateSelector,
    (planningState: IPlanningState) => planningState.page,
);

export const planningLoadingPageSelector = createSelector(
    planningStateSelector,
    (planningState: IPlanningState) => planningState.loadingPage
);
