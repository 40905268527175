import * as React from "react";
import { useSelectedSlot } from "../hooks/useSelectedSlot";
import { useBookedSlot } from "../hooks/useBookedSlot";
import { SlotBookingBookSelectedSlotForm } from "../components/forms/SlotBookingBookSelectedSlotForm";
import { parseSearchQuery } from "../../utils/format";
import { IEditSlotBookingParams } from "../../types/bookedSlot.types";
import { useSlot } from "../hooks/useSlot";
import { useTtatCalculation } from "../hooks/useTtatCalculation";
import { IBookingLoader, useBooking } from "../hooks/useBooking";
import { useLocation } from "react-router-dom";

export const SlotBookingBookSelectedSlot = () => {
    const [selectedSlot,] = useSelectedSlot();
    const [, , ttatCalculation] = useTtatCalculation();
    const [, config] = useSlot();
    const [createBookedSlot, updateBookedSlot, , selectedBookedSlot] = useBookedSlot();
    const location = useLocation();
    const params = parseSearchQuery(location.search) as IEditSlotBookingParams;
    const bookingHook: IBookingLoader = useBooking();

    return (
        config && <SlotBookingBookSelectedSlotForm
            // https://stackoverflow.com/questions/60326078/sending-custom-props-to-component-with-form-in-react-and-antd-jsx-element
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            selectedSlot={selectedSlot}
            ttatCalculation={ttatCalculation}
            selectedBookedSlot={selectedBookedSlot}
            createBookedSlot={createBookedSlot}
            updateBookedSlot={updateBookedSlot}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            updateId={params.id}
            updatingSlotStartTime={params.start}
            updatingSlotEndTime={params.end}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            updatingTankId={params.tankId && parseInt(params.tankId)}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            booking={bookingHook.resource}
            config={config}
        />
    );
};

export const SlotBookingBookSelectedSlotContainer = SlotBookingBookSelectedSlot;
