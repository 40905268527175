import * as React from "react";
import { SideMenu } from "../sidemenu/SideMenu";
import { Logo } from "./Logo";
import { Layout } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setHideHeaderActionCreator } from "../../../store/actions/ui.actions";
import { hideHeaderSelector } from "../../../store/selectors/ui.selector";
import { useLocation } from "react-router-dom";
import { Paths } from "../../../types/api.types";

const { Header } = Layout;

export const TopBar = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const hideHeader = useSelector(hideHeaderSelector);

    const onClickLogo = () => {
        if (location.pathname === Paths.CHECKIN_OVERVIEW_READ_ONLY) {
            dispatch(setHideHeaderActionCreator(!hideHeader));
        }
    };

    return (
        <Header className="top-header">
            <Logo className="logo" onClick={onClickLogo} />
            {!hideHeader &&
              <SideMenu />
            }
        </Header>
    );
};
