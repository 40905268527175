import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { Pane } from "../../modals/customerservicemodal/panes/Pane";
import { StorageLocation } from "../../../../types/salesOrder.types";
import { UploadArea } from "../../areas/UploadArea";
import { buildUrl, URL_PATHS } from "../../../../constants/api.constants";

interface IProps {
    terminal: StorageLocation;
}

const MESSAGES = defineMessages({
    uploadTitle: {
        id: "terminal_settings_pane.upload_title",
        defaultMessage: "Testbestand met vragen:"
    },
    uploadMessage: {
        id: "terminal_settings_pane.upload_message",
        defaultMessage: "Selecteer een CSV bestand om te uploaden met benodigde vragen."
    },
});

export const TerminalSettingsPane = (props: IProps) => {
    const intl = useIntl();

    return (
        <Pane className="margin-top" title={props.terminal}>
            <h3>{intl.formatMessage(MESSAGES.uploadTitle)}</h3>
            <div style={{ minHeight: 250 }}>
                <UploadArea
                    formattedMessage={intl.formatMessage(MESSAGES.uploadMessage)}
                    action={buildUrl(URL_PATHS.CHECKIN, "admin","safety", "questions-import", props.terminal)}
                />
            </div>
        </Pane>
    );
};