import * as React from "react";

interface IProps {
    children: React.ReactNode;
    className?: string;
}

export const Box = (props: IProps) => {
    return (
        <div className={`${props.className} box`}>
            {props.children}
        </div>
    );
};
